export default (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  spaced: {
    justifyContent: 'space-between',
  },
  color: {
    width: '36px',
    height: '14px',
    borderRadius: '2px',
  },
  swatch: {
    padding: '5px',
    background: '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
    position: 'relative',
  },
  popover: {
    position: 'absolute',
    background: '#fff',
    zIndex: '9999',
    '& > span': {
      '& > div:first-child': {
        padding: '16px !important',
        boxShadow: 'none !important',
      },
    },
    boxShadow:
      'rgb(0 0 0 / 15%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 8px 16px',
    borderRadius: '5px',
  },

  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    padding: '8px 16px',
    boxSizing: 'border-box',
  },
});
