import React from 'react';
import clsx from 'clsx';

import { CustomTitle } from '..';

import { useStyles } from '../../hooks';
import styles from './jss/CustomSimpleNumberInput';

const FunctionComponent = (props) => {
  const {
    title = '',
    value = '',
    setValue = () => '',
    min = 0,
    max = Number.POSITIVE_INFINITY,
    disabled = false,
    error = null,
  } = props;

  const classes = useStyles(styles, props);

  const inputStyle = clsx({
    [classes.input]: true,
    [classes.invalidInput]:
      error || (min || 0) > value || (max || Number.POSITIVE_INFINITY) < value,
    [classes.validInput]:
      !error && value > (min || 0) && value < (max || Number.POSITIVE_INFINITY),
  });

  return (
    <div className={classes.root}>
      {title && (
        <CustomTitle
          classes={{
            container: classes.customTitleContainer,
          }}
          title={title}
        />
      )}
      <input
        className={inputStyle}
        value={value}
        onChange={(e) => {
          setValue(e.target.value.replace(/\D/g, ''));
        }}
        type="number"
        step="1"
        pattern="\d*"
        min={min}
        max={max}
        disabled={disabled}
      />
    </div>
  );
};

export const CustomSimpleNumberInput = React.memo(FunctionComponent);

export default CustomSimpleNumberInput;
