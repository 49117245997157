export default (theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    userSelect: 'none',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  disabled: {
    opacity: '0.7',
  },
  font: {
    fontFamily: theme.typography.standard.fontFamily,
    letterSpacing: theme.typography.standard.letterSpacing,
    fontWeight: theme.typography.standard.fontWeightBold,
    color: theme.palette.default.text,
    fontSize: '1rem',
  },

  title: {
    fontSize: theme.typography.standard.fontSize,
  },

  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '32px',
    '& > div:nth-last-child(1)': {
      marginRight: '0px !important',
    },
  },

  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '16px',
  },

  label: {
    fontSize: '0.85rem',
  },

  radio: {
    cursor: 'pointer',
    accentColor: theme.palette.primary.main,
    width: '18px',
    height: '18px',
    border: `3px solid ${theme.palette.default.text}`,
    all: 'revert',
  },
});
