/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { FaTools, FaRegKeyboard } from 'react-icons/fa';
import clsx from 'clsx';

import timeExtense from '../../utils/timeExtense';
import styles from './jss/notificationCard';
import { useStyles } from '../../core';

import axleInactiveIcon from '../Navbar/icons/axleInactive.svg';
import axleActiveIcon from '../Navbar/icons/axleActive.svg';
import { useSelector } from 'react-redux';

const alert = require('../../assets/images/alert.png');

const NotificationCard = ({ notification, history, onClose, cardIsRead, setReadNotifications }) => {
  const {
    isRead,
    notificationTitle,
    notificationDescription,
    notificationTimestamp,
    notificationURL,
    notificationType,
    notificationId,
  } = notification;

  const classes = useStyles(styles);
  const auth = useSelector((state) => state.auth);

  const getNotificationIcon = useCallback(() => {
    switch (notificationType) {
      case 'revisao':
        return <FaTools className={classes.image} />;
      case 'macro':
        return <FaRegKeyboard className={classes.image} />;
      case 'TRACKER_INPUT_UNASSIGNED':
        return <img src={alert} alt="alert-icon" className={classes.image} />;
      case 'TRACKER_INPUT_AXLE':
        return notification.notificationState ? (
          <img className={classes.image} src={axleActiveIcon} alt={notificationDescription} />
        ) : (
          <img className={classes.image} src={axleInactiveIcon} alt={notificationDescription} />
        );
      case 'TRACKER_INPUT_SENSOR':
        return <img src={alert} alt="alert-icon" className={classes.image} />;
      case 'TRACKER_INPUT_BUTTON':
        return <img src={alert} alt="alert-icon" className={classes.image} />;
      case 'TRACKER_OUTPUT_UNASSIGNED':
        return <img src={alert} alt="alert-icon" className={classes.image} />;
      case 'TRACKER_OUTPUT_LOCK':
        return <img src={alert} alt="alert-icon" className={classes.image} />;
      default:
        return <img src={alert} alt="alert-icon" className={classes.image} />;
    }
  }, [notification]);

  const handleNotification = useCallback(() => {
    history.push(notificationURL);
    // if (!isRead) cardIsRead(notificationType, notification);
    onClose();
  }, []);

  const getBadgeStyle = (wasRead) => {
    return clsx({
      [classes.badge]: true,
      [classes.wasRead]: !wasRead,
    });
  };

  return (
    <div className={classes.root} onClick={handleNotification}>
      <span className={getBadgeStyle(isRead)} />
      <div className={classes.content}>
        <div className={classes.title}>{notificationTitle}</div>
        <div className={classes.message}>{notificationDescription}</div>
        <div className={classes.footer}>{timeExtense(notificationTimestamp, auth.language)}</div>
      </div>
      <div className={classes.left}>{getNotificationIcon()}</div>
    </div>
  );
};

export default React.memo(NotificationCard);
