import axios from 'axios';
import { format } from 'date-fns';
import { path } from '../../utils/api';

export const fetchDetails = (payload, cancelToken) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token');
    const endpoint = `${path}/dadosveiculo/historico`;
    const headers = { headers: { Authorization: `bearer ${token}` }, cancelToken };
    axios
      .post(
        endpoint,
        {
          ...payload,
          chave: {
            ...payload.chave,
            data: format(new Date(payload.chave.data), 'yyyy-MM-dd HH:mm:ss'),
          },
        },
        headers
      )
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};
