import React from 'react';

//Styles
import { makeStyles } from '@material-ui/core';
import styles from './jss/forgot';
import classnames from 'classnames';

const useStyles = makeStyles(styles);

const LoginForgot = ({ onClick, language = 'en-US' }) => {
  const classes = useStyles();

  const loginForgotText = classnames({
    [classes.loginForgotText]: true,
  });

  return (
    <div className={loginForgotText}>
      <h5>
        {language.forgotPassword}
        <span className="link" onClick={onClick}>
          {language.clickHere}
        </span>
      </h5>
    </div>
  );
};

export default LoginForgot;
