export default theme => ({
    root: {
        height: 25,
        width: 25,
        borderRadius: '50%',
        backgroundColor: 'white',
        border: `3px solid`,
        boxShadow: theme.elevation[2],
        position: 'relative',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        userSelect: 'none',
        '&:hover': {
            boxShadow: theme.elevation[3]
        },
        '&:active': {
            height: 26,
            width: 26,
        },
        transition: 'height 60ms cubic-bezier(.25, .46, .45, .94), width 60ms cubic-bezier(.25, .46, .45, .94)'
    },

    active: {
        border: `5px solid`,
        height: 26,
        width: 26,
    },

    primary: { borderColor: theme.palette.primary.dark },
    success: { borderColor: theme.palette.success.dark },
    accent: { borderColor: theme.palette.accent.dark },
    warning: { borderColor: theme.palette.warning.dark },
    danger: { borderColor: theme.palette.danger.dark },

    label: {
        fontSize: 12,
        fontWeight: 600,
        color: theme.palette.text.default,
    }

})