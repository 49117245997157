import React from "react";
import clsx from "clsx";
import { useStyles } from "../../hooks";
import { MdCheck } from "react-icons/md";
import { FaToggleOff, FaToggleOn } from "react-icons/fa";
import intl from "react-intl-universal";
import styles from "./jss/ButtonsManuBar";

const MenuBarMapButtons = (props) => {
  const {
    forceCloseMenu,
    optionsMenuButton,
    openMenuButtonsMap,
    mapRef,
    mapTypeId,
    setMapTypeId,
    setOptions,
    buttonTrafficLayer,
    setButtonTrafficLayer,
    setButtonGroupVehicles,
    setButtonSaveLocation,
    buttonSaveLocation,
    buttonGroupVehicles,
  } = props;
  const classes = useStyles(styles, props);

  const rootStyle = clsx({
    [classes.menuBarMapButtons]: true,
    [classes.menuOpen]: openMenuButtonsMap,
  });

  const handleClickButton = (type) => {
    setOptions((prev) => {
      let mapTypeId = prev && prev.mapTypeId === type ? "roadmap" : type;

      setMapTypeId(mapTypeId);

      return {
        mapTypeId,
        zoom: mapRef.getZoom(),
        center: mapRef.getCenter(),
      };
    });
  };
  if (forceCloseMenu === true && window.innerWidth < 820) {
    return <div />;
  }
  return (
    <div className={rootStyle}>
      {intl.get(`CustomGoogleMap.map`)}
      {optionsMenuButton.relief && (
        <span
          onClick={() => handleClickButton("terrain")}
          className={classes.optionMapCheck}
        >
          {intl.get(`CustomGoogleMap.relief`)}
          {mapTypeId === "terrain" && <MdCheck className={classes.checkIcon} />}
        </span>
      )}
      {optionsMenuButton.satellite && (
        <span
          onClick={() => handleClickButton("satellite")}
          className={classes.optionMapCheck}
        >
          {intl.get(`CustomGoogleMap.satellite`)}
          {mapTypeId === "satellite" && (
            <MdCheck className={classes.checkIcon} />
          )}
        </span>
      )}
      {optionsMenuButton.hybrid && (
        <span
          onClick={() => handleClickButton("hybrid")}
          className={classes.optionMapCheck}
        >
          {intl.get(`CustomGoogleMap.hybrid`)}
          {mapTypeId === "hybrid" && <MdCheck className={classes.checkIcon} />}
        </span>
      )}
      <hr className={classes.hrStyle} />
      {optionsMenuButton.trafficLayer && (
        <span
          onClick={() => setButtonTrafficLayer(!buttonTrafficLayer)}
          className={classes.optionMapCheck}
        >
          {intl.get(`CustomGoogleMap.traffic`)}
          {buttonTrafficLayer ? (
            <FaToggleOn className={classes.toggleOn} />
          ) : (
            <FaToggleOff className={classes.toggleOff} />
          )}
        </span>
      )}
      {optionsMenuButton.ungroupVehicles && (
        <span
          onClick={() => setButtonGroupVehicles(!buttonGroupVehicles)}
          className={classes.optionMapCheck}
        >
         {intl.get(`CustomGoogleMap.groupVehicles`)}
          {buttonGroupVehicles ? (
            <FaToggleOn className={classes.toggleOn} />
          ) : (
            <FaToggleOff className={classes.toggleOff} />
          )}
        </span>
      )}
      {optionsMenuButton.saveLocationButton && (
        <span
          onClick={() => setButtonSaveLocation(!buttonSaveLocation)}
          className={classes.optionMapCheck}
        >
          {intl.get(`CustomGoogleMap.saveLocation`)}
          {buttonSaveLocation ? (
            <FaToggleOn className={classes.toggleOn} />
          ) : (
            <FaToggleOff className={classes.toggleOff} />
          )}
        </span>
      )}
    </div>
  );
};

export default React.memo(MenuBarMapButtons);
