import React, { useRef, useCallback, useContext } from "react";
import { format, startOfMonth } from "date-fns";

const TrunkVehicleRefContext = React.createContext();
const TrunkVehiclesRefContext = React.createContext();
const TrunkVehiclesFiltersRefContext = React.createContext();
const TrunkStartDateRefContext = React.createContext();
const TrunkEndDateRefContext = React.createContext();
const TrunkFetchedVehiclesAndFilterContext = React.createContext();

const ContextProvider = (props) => {
  const { children } = props;
  const currentDateRef = useRef(new Date());
  const firstFetchRef = useRef(true);
  const initiallySelectedRef = useRef(true);
  const initiallyListedRef = useRef(true);
  const vehiclesRef = useRef([]);
  const vehicleRef = useRef();
  const vehiclesFiltersRef = useRef([]);
  const startDateRef = useRef(
    format(startOfMonth(currentDateRef.current), "yyyy-MM-dd'T'HH:mm")
  );
  const endDateRef = useRef(
    format(currentDateRef.current, "yyyy-MM-dd'T'HH:mm")
  );

  const fetchedVehiclesAndFiltersCallback = useCallback(
    (vehicles, vehiclesFilters) => {
      const inactiveFilters = vehiclesFiltersRef.current.filter(
        ({ filterParameter, active }) => filterParameter && !active
      );
      const inactiveFiltersIds = inactiveFilters.map(({ id }) => id);
      const selectedVehiclesIds = vehiclesRef.current
        .filter(({ active }) => active)
        .map(({ id }) => id);

      if (vehiclesFilters)
        vehiclesFiltersRef.current = vehiclesFilters.map((vehicleFilter) => ({
          ...vehicleFilter,
          active: firstFetchRef.current
            ? initiallyListedRef.current
            : !inactiveFiltersIds.includes(vehicleFilter.id),
        }));
      else vehiclesFiltersRef.current = [];

      if (vehicles)
        vehiclesRef.current = vehicles.map((vehicle) => {
          const listed = !inactiveFilters.some(
            ({ filterParameter, filterValue }) =>
              vehicle[filterParameter] === filterValue
          );

          return {
            ...vehicle,
            active: firstFetchRef.current
              ? initiallyListedRef.current && initiallySelectedRef.current
              : listed && selectedVehiclesIds.includes(vehicle.id),
            listed: firstFetchRef.current ? initiallyListedRef.current : listed,
          };
        });
      else vehiclesRef.current = [];

      firstFetchRef.current = false;
    },
    []
  );

  return (
    <TrunkVehiclesRefContext.Provider value={vehiclesRef}>
      <TrunkVehicleRefContext.Provider value={vehicleRef}>
        <TrunkVehiclesFiltersRefContext.Provider value={vehiclesFiltersRef}>
          <TrunkFetchedVehiclesAndFilterContext.Provider
            value={fetchedVehiclesAndFiltersCallback}
          >
            <TrunkStartDateRefContext.Provider value={startDateRef}>
              <TrunkEndDateRefContext.Provider value={endDateRef}>
                {children}
              </TrunkEndDateRefContext.Provider>
            </TrunkStartDateRefContext.Provider>
          </TrunkFetchedVehiclesAndFilterContext.Provider>
        </TrunkVehiclesFiltersRefContext.Provider>
      </TrunkVehicleRefContext.Provider>
    </TrunkVehiclesRefContext.Provider>
  );
};

export const useVehicleRef = () => {
  return useContext(TrunkVehicleRefContext);
};

export const useVehiclesRef = () => {
  return useContext(TrunkVehiclesRefContext);
};

export const useVehiclesFiltersRef = () => {
  return useContext(TrunkVehiclesFiltersRefContext);
};

export const useStartDateRef = () => {
  return useContext(TrunkStartDateRefContext);
};

export const useEndDateRef = () => {
  return useContext(TrunkEndDateRefContext);
};

export const useFetchedVehiclesAndFiltersCallback = () => {
  return useContext(TrunkFetchedVehiclesAndFilterContext);
};

export const TrunkContextProvider = ContextProvider;

export default TrunkContextProvider;
