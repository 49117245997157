import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';

import { CustomTitle } from '..';

import { useStyles } from '../../hooks';
import styles from './jss/CustomTimerPicker';

const FunctionComponent = (props) => {
  const {
    title = '',
    value = '',
    setValue = () => {},
    error = false,
    errorMessage = '',
    inputProps = {},
    disabled = false,
    maxLength = 5,
  } = props;

  const classes = useStyles(styles, props);
  const [input, setInput] = useState(() => value);

  const rootStyle = clsx({
    [classes.root]: true,
    [classes.font]: true,
  });

  const containerStyle = clsx({
    [classes.container]: true,
    [classes.containerError]: error,
  });

  const handleChange = (event) => {
    const value = event.target.value
      .replace(/\D/g, '')
      .replace(/(\d)(\d{2})$/, '$1:$2');

    setInput(value);
    if (value.split(':')[1] && parseInt(value.split(':')[1]) >= 60) {
      setValue(value, false);
    } else {
      setValue(value, true);
    }
  };

  return (
    <div className={rootStyle}>
      {title && (
        <CustomTitle
          classes={{
            container: classes.title,
          }}
          title={title}
          message={error ? errorMessage : null}
          messageType={error ? 'error' : null}
        />
      )}
      <div style={{ display: 'flex' }}>
        <TextField
          classes={{ root: containerStyle }}
          type='text'
          fullWidth={true}
          value={value}
          onChange={handleChange}
          InputProps={inputProps}
          disabled={disabled}
          required
          inputProps={{
            maxLength: maxLength,
            pattern: '([01]?[0-9]|2[0-3]):[0-5][0-9]',
          }}
        />
      </div>
    </div>
  );
};

export const CustomTimerPicker = React.memo(FunctionComponent);

export default CustomTimerPicker;
