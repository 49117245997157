export default (theme) => ({
  loader: {
    display: "flex",
    justifyContent: "center",
    "& > div": {
      width: "1rem",
      height: "1rem",
      margin: "2rem 0.3rem",
      background: "#aaa",
      borderRadius: "50%",
      animation: "0.9s $bounce infinite alternate",

      "&:nth-child(2)": {
        animationDelay: "0.3s",
      },

      "&:nth-child(3)": {
        animationDelay: "0.6s",
      },
    },
  },

  "@keyframes bounce": {
    to: {
      opacity: "0.3",
      transform: "translate3d(0, -1rem, 0)",
    },
  },
});
