import React from 'react'
import { withRouter } from 'react-router-dom'

import { useStyles } from '..'
import styles from './jss/chart'

import { Header } from './Header'
import { Footer } from './Footer'

import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip, BarChart, XAxis, YAxis, Bar } from 'recharts'

const COLORS = ['#4365cc', '#ffc247', '#3abf94']

export const Chart = withRouter(({ history, title, data, footer, subvariant, entries, unit, onRemoveCard, redirect, isLoading }) => {
    const classes = useStyles(styles)
    const loading = isLoading
    
    return (
        (!loading) ?
            <div className={classes.root}>
                <Header
                    title={title}
                    onRemoveCard={onRemoveCard}
                    redirect={redirect}
                    onRedirect={() => history.push(redirect)}
                />
                {
                    !data.length ?
                        <div className={classes.empty}> Sem dados para o período</div> :

                        <div className={classes.chart}>
                            <ResponsiveContainer>
                                {
                                    !subvariant ?
                                        <PieChart>
                                            <Pie
                                                dataKey="value"
                                                data={data}
                                                cx={100}
                                                labelLine={false}
                                                isAnimationActive={false}
                                            >
                                                {
                                                    data.map((entry, index) => <Cell key={`cell-${index}`} fill={entry.color} />)
                                                }

                                            </Pie>
                                            <Tooltip formatter={v => `${v} %`} />

                                            <Legend content={() => renderLegend(classes.legend, data)} align='right' />
                                        </PieChart> :
                                        <BarChart
                                            data={data}
                                        >
                                            <XAxis dataKey='part' />
                                            <YAxis />
                                            <Tooltip formatter={v => `${v} ${unit}`} />
                                            {entries.map((key, index) => (
                                                <Bar
                                                    cursor='pointer'
                                                    isAnimationActive={false}
                                                    key={key}
                                                    dataKey={key}
                                                    fill={COLORS[index]}
                                                />
                                            ))}

                                        </BarChart>


                                }
                            </ResponsiveContainer>
                        </div>
                }
                <Footer items={footer} title={title} />
            </div> :
            <div className={classes.root}>
                <Header
                    title={title}
                    onRemoveCard={onRemoveCard}
                    redirect={redirect}
                    onRedirect={() => history.push(redirect)}
                />
                <div className={classes.loader}>
                    <div className={classes.circleChart}>
                        <div><div></div></div>
                        <div>
                            <div><div></div><div></div></div>
                            <div><div></div><div></div></div>
                            <div><div></div><div></div></div>
                        </div>
                    </div>
                    <div className={classes.footer}>
                        <div><div></div><div></div><div></div></div>
                        <div><div></div><div></div><div></div></div>
                    </div>
                </div>
            </div>
    )
})

const renderLegend = (style, data) => (
    <div className={style}>
        {
            data.map(item => (
                <div className='item' key={item.name}>
                    <div className='indicator' style={{ backgroundColor: item.color }} />
                    <span className='value'>{item.name}</span>
                </div>
            ))
        }
    </div>
)




Chart.defaultProps = {
    title: '',
    label: '',
    footer: []
}

export default Chart