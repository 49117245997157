import React, { useState, useEffect, useCallback } from 'react';
import intl from 'react-intl-universal';

import { FaBackward, FaPlay, FaForward, FaPause, FaCaretDown } from 'react-icons/fa';
import { mapValueToRange } from '../../utils/helpers';
import { Menu, MenuItem } from '@material-ui/core';

import { useStyles, Button } from '..';
import styles from './jss/controllers';

export const Controlllers = ({ tick, onChange, value, range, color, frames, setFrames }) => {
  const classes = useStyles(styles);

  const onChangeCB = useCallback(onChange, []);

  const [isPressed, setIsPressed] = useState(null);
  const [journey, setJourney] = useState(false);
  const [anchor, setAnchor] = useState(null);

  useEffect(() => {
    let interval;
    const mappedTick = mapValueToRange(tick, { min: 0, max: 100 }, range);
    const handler = () =>
      onChangeCB((value) => {
        if (value + mappedTick > range.max) {
          clearInterval(interval);
          setJourney((j) => !j);
        }
        return value + mappedTick > range.max ? range.max : value + mappedTick;
      });
    if (journey) interval = setInterval(handler, 1500);

    if (!journey) clearInterval(interval);
    return () => clearInterval(interval);
  }, [journey, onChangeCB, tick, range]);

  useEffect(() => {
    let interval;
    let timeout;
    const mappedTick = mapValueToRange(tick, { min: 0, max: 100 }, range);
    const handler = () =>
      onChangeCB((value) => {
        if (isPressed === 'forward') return value + mappedTick > range.max ? range.max : value + mappedTick;
        if (isPressed === 'backward') return value - mappedTick < range.min ? range.min : value - mappedTick;
        return value;
      });

    if (isPressed)
      timeout = setTimeout(() => {
        handler();
        interval = setInterval(handler, tick);
      }, 600);

    if (!isPressed) {
      clearInterval(interval);
      clearTimeout(timeout);
    }
    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [isPressed, onChangeCB, tick, range]);

  const onBackward = () => {
    const newValue = value - mapValueToRange(tick, { min: 0, max: 100 }, range);
    onChange(newValue > range.min ? newValue : range.min);
  };
  const onForward = () => {
    const newValue = value + mapValueToRange(tick, { min: 0, max: 100 }, range);
    onChange(newValue < range.max ? newValue : range.max);
  };

  const onPlay = () => {
    setJourney((j) => !j);
    if (value === range.max && !journey) onChange(range.min);
  };

  const onClickOption = (value) => {
    setFrames(value);
    setAnchor(null);
  };

  return (
    <>
      <div className={classes.root}>
        <Button
          variant="icon"
          color={color}
          size={25}
          className={classes.button}
          onClick={onBackward}
          onMouseDown={() => setIsPressed('backward')}
          onMouseUp={() => setIsPressed(null)}
          onMouseLeave={() => setIsPressed(null)}
        >
          <FaBackward />
        </Button>
        <Button variant="icon" size={25} className={classes.button} color={color} onClick={onPlay}>
          {journey ? <FaPause /> : <FaPlay />}
        </Button>
        <Button
          variant="icon"
          size={25}
          color={color}
          className={classes.button}
          onClick={onForward}
          onMouseDown={() => setIsPressed('forward')}
          onMouseUp={() => setIsPressed(null)}
          onMouseLeave={() => setIsPressed(null)}
        >
          <FaForward />
        </Button>
        <div className={classes.grow} />
        <div className={classes.options} onClick={(e) => setAnchor(e.target)}>
          <div className={classes.picker}>
            <div className={classes.option}>{frames}</div>
            <FaCaretDown className={classes.caret} />
          </div>
          <div className={classes.optionsTitle}>{intl.get('monitoring.alerts.alertsHistory.routeDetail.minutes')}</div>
        </div>
      </div>
      <Menu open={Boolean(anchor)} anchorEl={anchor} className={classes.paper} onClose={() => setAnchor(null)}>
        <MenuItem onClick={() => onClickOption(10)}>10</MenuItem>
        <MenuItem onClick={() => onClickOption(30)}>30</MenuItem>
        <MenuItem onClick={() => onClickOption(60)}>60</MenuItem>
      </Menu>
    </>
  );
};
