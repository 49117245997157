export const generateCsvFile = ({
  columns,
  data,
  totalizersColumns = '',
  totalizers = '',
  title = '',
  fileName,
  userName,
  period = '',
}) => {
  let csvString = `${title}\n${userName}\n${period}${
    totalizersColumns.length !== 0 ? `\n\n\n${totalizersColumns.toString()}\n${totalizers.toString()}` : ''
  }\n\n${columns.map(({ title }) => title).toString()}\n`;

  data.forEach((row) => {
    let rowCsvString = '';
    columns.forEach(({ field }, index) => {
      rowCsvString += `${row[field] ? row[field] : ''}${index === columns.length - 1 ? '\n' : ','}`;
    });

    csvString += rowCsvString;
  });

  const csvFile = new Blob([csvString], { type: 'text/csv' });
  const link = document.createElement('a');
  link.download = `${fileName}.csv`;
  link.href = window.URL.createObjectURL(csvFile);
  link.click();
};
