import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { GoogleMap, TrafficLayer, useLoadScript, StreetViewPanorama } from '@react-google-maps/api';

import intl from 'react-intl-universal';

import Spinner from '../Spinner/Spinner';

import { useStyles } from '..';
import styles from './styles';

const { REACT_APP_GOOGLE_KEY: key } = process.env;

const defaultStyle = { height: '100vh', width: '100%', borderRadius: '0.3rem' };
const libraries = ['geometry', 'drawing', 'places'];

export const Map = ({
  options: _options,
  children,
  containerStyle,
  routeBounds,
  onIdle,
  setZoom,
  setLoading = () => {},
}) => {
  const style = containerStyle ? { ...defaultStyle, ...containerStyle } : defaultStyle;
  const { center, zoom, ...optionsWithoutCenterZoom } = _options;
  const options = { ...optionsWithoutCenterZoom };
  const _showTraffic = options.showTraffic ? options.showTraffic : false;
  const [traffic, setTraffic] = useState(false);
  const [mapRef, setMapRef] = useState(null);
  const auth = useSelector((state) => state.auth);

  const classes = useStyles(styles);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: key,
    libraries,
    language: auth.language,
  });

  useEffect(() => {
    if (window.google && mapRef) fitBounds(routeBounds, mapRef);
  }, [mapRef, routeBounds]);

  useEffect(() => {
    if (mapRef) mapRef.setZoom(_options.zoom);
  }, [_options]);

  const loadHandler = (map) => {
    setMapRef(map);
    setLoading();
  };

  const handleIdle = () => {
    if (mapRef) {
      onIdle({ zoom: mapRef.getZoom(), center: mapRef.getCenter() });
      setZoom(mapRef.getZoom());
    }
  };

  const renderMap = () => {
    return (
      <GoogleMap
        options={options}
        mapContainerStyle={style}
        center={_options.center}
        zoom={_options.zoom}
        onLoad={loadHandler}
        id="drawing-map"
        streetViewControl={true}
        onIdle={() => handleIdle()}
      >
        {_showTraffic && (
          <div className={classes.customButton} onClick={() => setTraffic(!traffic)} title="Mostrar trânsito agora">
            Trânsito
          </div>
        )}
        <StreetViewPanorama
          options={{
            addressControl: true,
            addressControlOptions: {
              position: window.google.maps.ControlPosition.BOTTOM_CENTER,
            },
            panControl: true,
            enableCloseButton: true,
          }}
        />
        {traffic && <TrafficLayer autoUpdate />}
        {children}
      </GoogleMap>
    );
  };

  if (loadError) {
    return <div>{intl.get('CustomGoogleMap.errorMessage')}</div>;
  }

  return isLoaded ? (
    renderMap()
  ) : (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <Spinner />
    </div>
  );
};

// Iterate data to size, center, and zoom map to contain all markers
const fitBounds = (routeBounds, mapRef) => {
  if (routeBounds) {
    const bounds = new window.google.maps.LatLngBounds();
    routeBounds.map((point) => {
      return bounds.extend({ lat: point.lat, lng: point.lng });
    });
    mapRef.fitBounds(bounds);
  }
};

Map.defaultProps = {
  onIdle: () => {},
  setZoom: () => {},
};

export default Map;
