export default (theme) => ({
  root: {},

  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: 5,
  },

  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    whiteSpace: "nowrap",
  },

  value: {
    fontSize: 14,
    color: theme.palette.default.text,
  },

  label: {
    fontSize: 12,
    color: "#798892",
  },

  pointer: {
    cursor: "pointer",
  },
});
