export default theme => ({
    paperRoot: {
        boxShadow: theme.cardShadow,
        minHeight: 400,
        borderRadius: '.4rem',
        marginTop: 10
    },

    listContainer: {
        flex: 1,
        overflowX: 'auto',
        minHeight: 300,
        padding: 5,
        borderRight: 'solid 10px white'

    },

    header: {
        display: 'flex',
        justifyContent: 'space-between'
    },

    title: {
        color: '#757575',
        fontWeight: 400,
        fontSize: 19,
        marginLeft: 20,
        padding: 10,
        paddingTop: 20
    },

    containerEmpty: {
        overflowX: 'auto',
        textAlign: 'center'
    },

    empty: {
        padding: 20
    },

    paginationContainer: {
        flex: 1,
        overflowX: 'auto',
        maxHeight: '54vh',
        padding: 5,
        borderRight: 'solid 10px white'
    },
})