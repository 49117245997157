export const styles = (theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    userSelect: 'none',
  },
  customTitleContainer: {
    minHeight: '1rem',
    marginBottom: 4,
    marginLeft: 3,
    fontSize: '0.75em',
  },
  sliderContainer: {
    padding: '15px 20px',
  },
  slider: {
    '& .MuiSlider-thumb': {
      height: 30,
      width: 30,
      backgroundColor: '#fff',
      border: '1px solid currentColor',
      marginLeft: '-15px',
      marginTop: '-13px',
      '&:hover': {
        boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
      },
    },
    '& .MuiSlider-markLabel': {
      transform: 'none',
      top: '35px',
    },
    '& [data-index="1"]': {
      transform: 'translateX(-50%)',
    },
    '& [data-index="2"]': {
      transform: 'translateX(-100%)',
    },
    '& .MuiSlider-valueLabel': {
      left: '-1px',
      top: '9px',
      height: 30,
      width: 30,
      '& *': {
        borderRadius: '50%',
      },
    },
    '& .MuiSlider-rail': {
      height: '6px',
    },
    '& .MuiSlider-track': {
      height: '6px',
    },
    '& .MuiSlider-mark': {
      height: '6px',
    },
  },
});

export default styles;
