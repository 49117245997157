export default theme => ({
    paper: {
        zIndex: theme.zIndex.multiSelect,
        position: 'absolute',
        boxShadow: 'none',
        boxSizing: 'border-box',
        border: `1px solid ${theme.palette.default.dark}`,
        borderRadius: '0 0 5px 5px',
        top: 'calc(100% - 1px)',
        width: '100%',
        maxHeight: 200,
        overflowY: 'auto',
        padding: 0,
        margin: 0,
        transformOrigin: 'top',
        animation: 'modalExpand 200ms',
        '&::-webkit-scrollbar': {
            width: 10,
            height: '40px'
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: '10px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#cccccc',
            height: '10px',
            borderRadius: '10px'
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#919191',
            height: '10px',
            borderRadius: '10px'
        }
    },

    hidden: {
        animation: 'modalRetract 200ms',
        transform: 'scaleY(0)'
    },

    '@keyframes modalExpand': {
        from: {
            transform: 'scaleY(0)'
        },
        to: {
            transform: 'scaleY(1)'
        }
    },
    '@keyframes modalRetract': {
        from: {
            transform: 'scaleY(1)'
        },
        to: {
            transform: 'scaleY(0)'
        }
    },


})