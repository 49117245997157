import { format } from 'date-fns';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import intl from 'react-intl-universal';

import getTimezoneName from './getTimezoneName';

import getConfig from '../config/configLoader';

export const generatePdfFile = ({
  reportColumns = [],
  reportData = [],
  reportFileName = 'report',
  pageOrientation = 'portrait',
  reportPeriod = '',
  reportTitle = '',
  tableTheme = 'grid',
  totalizersData = [],
  totalizersColumnsData = [],
  issuerName = '',
  issuedToRole = 'user',
  issuerDocumentNumber = '',
  issuerDocumentType = 'license',
  repeatHeaderOnEveryPage = false,
  includeDriverSignature = false,
  companyNameToFooter = false,
  reportLegend = '',
  language = 'pt-BR',
}) => {
  const { logo: logoImage, originName } = getConfig();

  const doc = new jsPDF({ orientation: pageOrientation, unit: 'mm', format: 'a4' });
  const totalPagesExp = '{total_pages_count_string}';

  let tableMarginTop = 10;
  if (repeatHeaderOnEveryPage) tableMarginTop += 20;

  const generateHeader = () => {
    doc.setTextColor(68, 68, 68);
    doc.setFont('time', 'bold');
    doc.setFontSize(14);
    doc.text(reportTitle, 4, 8);

    doc.setFontSize(12);
    doc.text(
      `${intl.get('utils.generatePdfFile.issue')} ${format(
        new Date(),
        intl.get('global.dateTime')
      )} - ${getTimezoneName(language)}`,
      4,
      16
    );
    doc.addImage(logoImage, 'PNG', 260, 4, 32, 12);

    doc.setFillColor(86, 86, 86);

    doc.roundedRect(4, 18, 289, 8, 0, 0);

    doc.setFont('time', 'normal');
    doc.setFontSize(10);
    doc.text(intl.get(`utils.generatePdfFile.${issuedToRole}`, { name: issuerName }), 6, 23);

    if (issuerDocumentNumber)
      doc.text(
        intl.get(`utils.generatePdfFile.${issuerDocumentType}`, { issuerDocument: issuerDocumentNumber }),
        120,
        23
      );
  };

  if (!repeatHeaderOnEveryPage) {
    generateHeader();
  }

  if (totalizersData.length) {
    doc.autoTable({
      theme: tableTheme,
      columns: totalizersColumnsData,
      headStyles: {
        overflow: 'linebreak',
        halign: 'center',
        fontStyle: 'bold',
        textColor: [0, 0, 0],
        fillColor: [93, 125, 194],
        fontSize: 10,
      },
      bodyStyles: {
        halign: 'center',
        textColor: [0, 0, 0],
        cellPadding: { top: 2, bottom: 2, left: 2, right: 2 },
        fontSize: 8,
      },
      margin: { right: 4, left: 4 },
      body: totalizersData,
      startY: 30,
    });
  }

  doc.autoTable({
    theme: tableTheme,
    columns: reportColumns,
    styles: {},
    headStyles: {
      overflow: 'linebreak',
      halign: 'center',
      fontStyle: 'bold',
      textColor: [0, 0, 0],
      fillColor: [93, 125, 194],
      cellPadding: { top: 2, bottom: 2, left: 2, right: 2 },
      fontSize: 8,
    },
    bodyStyles: {
      halign: 'center',
      textColor: [0, 0, 0],
      cellPadding: { top: 2, bottom: 2, left: 2, right: 2 },
      fontSize: 6,
    },
    margin: {
      right: 4,
      left: 4,
      bottom: includeDriverSignature ? 26 : 10,
      top: tableMarginTop,
    },
    body: reportData,
    startY: totalizersData.length ? 48 : 30,
    didDrawPage: function (data) {
      let pageSize = doc.internal.pageSize;
      let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
      let pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();

      doc.setFontSize(8);

      let str = `${intl.get('utils.generatePdfFile.page')} ${doc.internal.getNumberOfPages()}`;

      if (typeof doc.putTotalPages === 'function') {
        str = str + ` ${intl.get('utils.generatePdfFile.from')} ${totalPagesExp}`;
      }

      doc.text(str, data.settings.margin.left, pageHeight - 2);
      doc.text(
        intl.get('utils.generatePdfFile.footer').replace('.{name}', originName),
        pageWidth / 2 - 37,
        companyNameToFooter ? pageHeight - 8 : pageHeight - 4
      );

      if (repeatHeaderOnEveryPage) {
        generateHeader();
      }

      if (companyNameToFooter) {
        doc.text(
          intl.get('utils.generatePdfFile.companyParameters').replace('.{name}', companyNameToFooter),
          pageWidth / 2,
          pageHeight - 4,
          { align: 'center' }
        );
      }

      if (includeDriverSignature) {
        doc.setFontSize(10);
        doc.text(intl.get('utils.generatePdfFile.acceptText'), data.settings.margin.left, pageHeight - 20);
        doc.text(intl.get('utils.generatePdfFile.signature'), data.settings.margin.left, pageHeight - 16);
        doc.text(
          intl.get(`utils.generatePdfFile.${issuedToRole}`, { name: issuerName }),
          data.settings.margin.left,
          pageHeight - 11
        );
      }
      if (reportLegend) {
        doc.text(intl.get('module.Reports.export.legend'), 204, pageHeight - 25);
        doc.roundedRect(202, pageHeight - 24, 91, 13, 1, 1);
        doc.setFillColor(239, 138, 151);
        doc.rect(204, pageHeight - 22, 3, 3, 'F');
        doc.text(intl.get('module.Reports.export.journey'), 208, pageHeight - 19);
        doc.text('**', 204, pageHeight - 14);
        doc.text(reportLegend, 208, pageHeight - 14);
      }
    },
  });

  if (typeof doc.putTotalPages === 'function') {
    doc.putTotalPages(totalPagesExp);
  }

  doc.save(`${reportFileName}.pdf`);
};
