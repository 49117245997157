export default (theme) => ({
  root: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    width: theme.dimensions.customWidth.full,
    userSelect: "none",
  },
  font: {
    fontFamily: theme.typography.standard.fontFamily,
    fontSize: theme.typography.standard.fontSize,
    fontWeight: theme.typography.standard.fontWeightBold,
    letterSpacing: theme.typography.standard.letterSpacing,
    color: theme.palette.text.secondary,
  },
  title: {
    marginLeft: theme.dimensions.customMargin.xSmall,
    marginBottom: theme.dimensions.customMargin.xSmall,
    fontSize: theme.typography.standard.fontSizeSm,
  },
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    boxSizing: "border-box",
    backgroundColor: theme.palette.background.paper,
    width: theme.dimensions.customWidth.full,
    height: theme.dimensions.customHeight.small,
    borderColor: theme.palette.text.secondary,
    borderStyle: "solid",
    borderWidth: theme.dimensions.customBorder.small,
    borderRadius: theme.dimensions.customBorderRadius.small,
    padding: `0 ${theme.dimensions.customBorder.medium - theme.dimensions.customBorder.small}px`,
    fontSize: theme.typography.standard.fontSizeSm,
    overflow: "hidden",
    cursor: "pointer",
    "&:hover": {
      borderColor: theme.palette.text.primary,
    },
  },
  focusedContainer: {
    borderColor: theme.palette.primary.dark,
    borderWidth: theme.dimensions.customBorder.medium,
    borderRadius: `${theme.dimensions.customBorderRadius.small}px ${theme.dimensions.customBorderRadius.small}px ${theme.dimensions.customBorderRadius.none} ${theme.dimensions.customBorderRadius.none}`,
    padding: `0 0`,
    "&:hover": {
      borderColor: theme.palette.primary.dark,
      borderWidth: theme.dimensions.customBorder.medium,
      borderRadius: `${theme.dimensions.customBorderRadius.small}px ${theme.dimensions.customBorderRadius.small}px ${theme.dimensions.customBorderRadius.none} ${theme.dimensions.customBorderRadius.none}`,
    },
  },
  labelContainer: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    height: "inherit",
    paddingLeft: theme.dimensions.customPadding.medium,
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  caretContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: "1em",
    height: "inherit",
    paddingRight: theme.dimensions.customPadding.medium,
    paddingLeft: theme.dimensions.customPadding.small,
  },
});
