import React, { useContext } from 'react';
import { context } from '../../components/Navbar/context';
import intl from 'react-intl-universal';

import { NavLink as Link } from 'react-router-dom';

import { useStyles } from '..';
import styles from './jss/permanent';
import clsx from 'clsx';

import { Header, List } from '.';

export const Permanent = ({ items }) => {
  const classes = useStyles(styles);

  const drawer = useContext(context);

  const { expanded, setExpanded } = drawer;

  const rootStyle = clsx({
    [classes.root]: true,
    [classes.expanded]: expanded,
  });

  const onEnter = () => setExpanded(true);
  const onLeave = () => setExpanded(false);

  const headerProps = {
    expanded,
  };

  const listProps = {
    items: items.map((item1) => {
      if (item1.itens) {
        return {
          ...item1,
          itens: item1.itens.map((item2) => ({ ...item2, apelido: intl.get(`navbar.menu.nickname.${item2.id}`) })),
          apelido: intl.get(`navbar.menu.nickname.${item1.id}`),
        };
      }

      return {
        ...item1,
        apelido: intl.get(`navbar.menu.nickname.${item1.id}`),
      };
    }),
    expanded,
    setExpanded,
    wrapper: Link,
  };

  return (
    <div className={rootStyle} onMouseEnter={onEnter} onMouseLeave={onLeave}>
      <div className={classes.inner}>
        <Header {...headerProps} />
        <List {...listProps} />
      </div>
    </div>
  );
};

export default Permanent;
