import React from "react";

import { useStyles } from "../../styles";
import styles from "./styles";

export const Loader = ({ label, color, size, children }) => {
  const interpolation = { color, size };
  const classes = useStyles(styles, interpolation);

  return (
    <div className={classes.root}>
      {!children && <div className={classes.loader}></div>}
      {children}
      <div className={classes.label}>{label}</div>
    </div>
  );
};

export default Loader;
