export default (theme) => ({
  wrapper: {
    position: 'relative',
    userSelect: 'none',
    '@media (max-width: 900px)': {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },

  avatar: {
    height: 40,
    width: 40,
    borderRadius: '50%',
    overflow: 'hidden',
  },

  chevron: {
    color: theme.palette.default.dark,
    fontSize: 12,
    marginLeft: 5,
  },

  image: {
    height: '100%',
    width: '100%',
  },

  pseudoImage: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    fontWeight: 600,
    color: 'white',
    backgroundColor: theme.palette.warning.main,
  },

  paper: {
    zIndex: 2600,
    width: 216,
    position: 'absolute',
    top: '110%',
    right: 0,
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.default.dark}`,
    borderRadius: '5px',
    transition: 'transform 180ms ease',
    transformOrigin: 'top',
    transform: 'scaleY(0)',
    overflow: 'auto',
    maxHeight: 'calc(90vh - 60px)',
    '@media (max-width: 900px)': {
      left: 0,
    },
  },

  expanded: {
    transform: 'scaleY(1)',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
  },

  item: {
    width: 'calc(100%-10px)',
    borderBottom: `1px solid ${theme.palette.default.main}`,
    padding: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    color: theme.palette.default.text,
  },

  name: {
    fontSize: '0.8rem',
    width: '100%',
  },

  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    width: '40px',
  },

  check: {
    color: theme.palette.default.dark,
    fontSize: 12,
  },
});
