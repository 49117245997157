import axios from 'axios';
import colorNames from '../styles/theme/colorNames';

export const eraserSecondsInTimestamp = (date) =>
  new Date(date).getTime() - new Date(date).getMilliseconds() - new Date(date).getSeconds() * 1000;

// Throttling
export const throttled = (delay, fn) => {
  let lastCall = 0;
  let fix = process.env.REACT_APP_FIX_TIME ? parseInt(process.env.REACT_APP_FIX_TIME) : 0;

  return (...args) => {
    const now = Date.now() + fix;
    if (now - lastCall < delay) return;

    lastCall = now;
    return fn(...args);
  };
};

//Does exactly what it sounds like.
//Receive an array and a chunk's size and return a matrix of n arrays with $size length
// eg: chunker( [1,2,3,4,5], 2)  will return: [[1,2],[3,4],[5]]
export const chunker = (arr, size) => {
  const chunks = [];

  for (let index = 0; index < arr.length; index += size) {
    const chunk = arr.slice(index, index + size);
    chunks.push(chunk);
  }

  return chunks;
};

//Converts a decimal degree into DMS (degrees, minutes, seconds)
const toDMS = (coordinate) => {
  const absolute = Math.abs(coordinate);
  const degrees = Math.floor(absolute);

  const minutes = Math.floor((absolute - degrees) * 60);
  const seconds = ((absolute - degrees) * 60 - minutes) * 60;

  return `${degrees}° ${minutes}' ${seconds.toFixed(1)}''`;
};

//Convert {lat, lng} into DMS String
export const convertDMS = (_lat, _lng) => {
  const lat = parseFloat(_lat);
  const lng = parseFloat(_lng);

  const latitude = toDMS(lat);
  const latitudeCardinal = lat >= 0 ? 'N' : 'S';

  const longitude = toDMS(lng);
  const longitudeCardinal = lng >= 0 ? 'E' : 'W';

  return latitude + ' ' + latitudeCardinal + '   ' + longitude + ' ' + longitudeCardinal;
};

//Contains the core logic to grant access to a given application
export const checkGeneralPermission = (permissions, permissionId) => {
  const permission = permissions.find((permission) => permission.chave_integracao === permissionId);
  return permission ? true : false;
};

//Contains the core logic to grant access to a given application and a given operation
export const checkPermission = (permissions, permissionId, operation) => {
  //Find the corresponding permission to user gerenciamento (permission_id = 1)
  const permission = permissions.find((permission) => permission.chave_integracao === permissionId);

  //Case no permission
  if (!permission) return false;

  //Case no permission to the specific operation
  if (!permission[operation]) return false;

  const { c, r, u, d } = permission;

  return {
    create: Boolean(c),
    read: Boolean(r),
    update: Boolean(u),
    delete: Boolean(d),
  };
};

//From a center and a radius generate a circular polygon with 33 points
export const drawCircularPolygon = (point, radius, dir) => {
  const d2r = Math.PI / 180; // degrees to radians
  const r2d = 180 / Math.PI; // radians to degrees
  const earthsradius = 6371; // Earth's radius (KM)

  const points = 32;

  // find the raidus in lat/lon
  const rlat = (radius / earthsradius) * r2d;
  const rlng = rlat / Math.cos(point.lat * d2r);

  const extp = [];

  let start, end;

  if (dir === 1) {
    start = 0;
    end = points + 1; // one extra here makes sure we connect the path
  } else {
    start = points + 1;
    end = 0;
  }

  for (let i = start; dir === 1 ? i < end : i > end; i = i + dir) {
    const theta = Math.PI * (i / (points / 2));
    const ey = point.lng + rlng * Math.cos(theta); // center a + radius x * cos(theta)
    const ex = point.lat + rlat * Math.sin(theta); // center b + radius y * sin(theta)
    extp.push({ lat: ex, lng: ey });
  }
  return extp;
};

//Breaks an array into several small pieces.
export const spacer = (path, offset = 10) => {
  const optimized = [];
  optimized.push(path[0]);

  for (let i = 1; i < path.length; i++) {
    const distance = getDistance(
      parseFloat(path[i].latitude),
      parseFloat(path[i].longitude),
      parseFloat(path[i - 1].latitude),
      parseFloat(path[i - 1].longitude)
    );
    if (distance >= offset) optimized.push(path[i]);
  }

  return chunker(optimized, 100);
};

//Return the distance between two points {lat, lng}
const getDistance = (lat1, lon1, lat2, lon2) => {
  const toRadians = (deg) => deg * (Math.PI / 180);
  const R = 6371; // Radius of the earth in km
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c * 1000; // distance in meters
  return distance;
};

//Return the next multiple of 100 of a given number
export const nextHundreth = (number) => Math.ceil(number / 100.0) * 100;

//Return a """random""" integer inside a given interval (limits included)
export const randomInt = (min, max) =>
  Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min) + 1)) + Math.ceil(min);

//Extract all coordinates from a kml file and return an array of points [{lat, lng}, ...]
export const kml2Path = (file) => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onloadend = () => {
        const parser = new DOMParser();
        const xml = parser.parseFromString(reader.result, 'text/xml');

        const resolvedCoords = [];
        let coords = xml.getElementsByTagName('coordinates')[0].childNodes[0].nodeValue.trim();
        let points = coords.split(',0');

        for (const point of points) {
          let coord = point.trim().split(',');
          if (coord.length > 1) {
            resolvedCoords.push({ lat: +coord[1], lng: +coord[0] });
          }
        }
        resolve(resolvedCoords);
      };
    } catch (e) {
      reject('Arquivo inválido');
    }
  });
};

//From an array of points assemble a XML/KML File
export const path2Kml = (path) => {
  return new Promise((resolve, reject) => {
    try {
      const doc = document.implementation.createDocument('', '', null);
      const wrapper = doc.createElement('wrapper');
      const kml = doc.createElement('kml');
      kml.setAttribute('xmlns', 'http://www.opengis.net/kml/2.2');

      const Placemark = doc.createElement('Placemark');

      const Polygon = doc.createElement('Polygon');
      const tessellate = doc.createElement('tessellate');
      tessellate.innerHTML = 1;

      const outerBoundaryIs = doc.createElement('outerBoundaryIs');
      const LinearRing = doc.createElement('LinearRing');

      const coordinates = doc.createElement('coordinates');

      let pathCoordinates = '';
      path.forEach((point) => {
        pathCoordinates = `${pathCoordinates} ${point.lng},${point.lat},0`;
      });
      coordinates.innerHTML = pathCoordinates;

      Placemark.appendChild(Polygon);
      Polygon.appendChild(tessellate);
      Polygon.appendChild(outerBoundaryIs);
      outerBoundaryIs.appendChild(LinearRing);
      LinearRing.appendChild(coordinates);

      kml.appendChild(Placemark);
      wrapper.appendChild(kml);
      doc.appendChild(wrapper);

      const result = [].slice.call(doc.getElementsByTagName('wrapper'))[0].innerHTML;
      const header = '<?xml version="1.0" encoding="UTF-8"?>';
      //Returning just the HTML(XML) string
      resolve(header + result);
    } catch (e) {
      reject(e);
    }
  });
};

export const geocode = (query) => {
  return new Promise((resolve, reject) => {
    const endpoint = `https://nominatim.openstreetmap.org/search.php?q=${query}&polygon_geojson=0&format=json`;
    axios
      .get(endpoint)
      .then((res) => {
        if (!res.data.length) return reject('Lugar inválido');

        return resolve(res.data[0]);
      })
      .catch((e) => reject(e));
  });
};

export const reverseGeocode = ({ lat, lng }, cancelToken) => {
  //console.log('Consulta no Reverse GeoCode');

  //const { REACT_APP_GOOGLE_KEY: key } = process.env;
  const { REACT_APP_HERE_KEY: key } = process.env;
  return new Promise((resolve, reject) => {
    const endpoint = `https://revgeocode.search.hereapi.com/v1/revgeocode?at=${lat},${lng}&apiKey=${key}`;
    //const endpoint = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${key}`
    //const endpoint = `https://geocode.xyz/${lat},${lng}?json=1`;
    axios
      .get(endpoint, { cancelToken })
      .then((res) => {
        var address;
        if ((address = res.data.items[0].title)) address = res.data.items[0].title;
        resolve(address);
      })
      .catch((e) => (axios.isCancel(e) ? null : reject(e)));
  });
};

export const compareIntArrays = (arr1, arr2) => JSON.stringify(arr1.sort()) === JSON.stringify(arr2.sort());

export const floatParser = (n) => {
  if (isNaN(n)) return n;

  return Number(n).toLocaleString('pt-BR', {
    // Ajustando casas decimais
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const decimals = (n, precision) => {
  var places = Math.pow(10, precision);
  return Math.floor(n * places) / places;
};

export const isEmail = (value) => {
  const regex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  return regex.test(value.toLowerCase());
};

export const mapValueToRange = (value, range, newRange) => {
  return (value - range.min) * ((newRange.max - newRange.min) / (range.max - range.min)) + newRange.min;
};

export const getOffset = (element) => {
  const offset = { accLeft: 0, accTop: 0 };
  let parent = element;

  while (parent) {
    offset.accTop += parent.offsetTop;
    offset.accLeft += parent.offsetLeft;
    parent = parent.offsetParent;
  }

  return offset;
};

const presets = ['primary', 'accent', 'warning', 'danger', 'success'];

export const isPresetColor = (color) => presets.includes(color);

export const toRgba = (hex, opacity = 1) => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

  if (colorNames[hex]) hex = colorNames[hex];

  hex = hex.replace(shorthandRegex, (_, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  const colors = result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;

  if (!colors) return `rgba(255,255,255,${opacity})`;

  const { r, g, b } = colors;
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const getColor = (color, theme, variant = 'main') => {
  if (isPresetColor(color)) return theme.palette[color][variant];
  return color;
};

export const isSameLocation = (positionA, positionB) => {
  if (positionA === null || positionB === null) return false;
  if (+positionA.lat !== +positionB.lat) return false;
  if (+positionA.lng !== +positionB.lng) return false;
  return true;
};

export const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export const getPreviousNext = (year, month) => {
  if (!year || !month || month < 1 || month > 12) {
    const { year: customYear, month: customMonth } = getCurrentDate();
    return {
      pYear: customYear,
      pMonth: customMonth - 1,
      nYear: customYear,
      nMonth: customMonth + 1,
      year: customYear,
      month: customMonth,
    };
  }
  if (month === 1)
    return {
      pYear: year - 1,
      pMonth: 12,
      nYear: year,
      nMonth: 2,
      year,
      month,
    };

  if (month === 12)
    return {
      pYear: year,
      pMonth: 11,
      nYear: year + 1,
      nMonth: 1,
      year,
      month,
    };

  return {
    pYear: year,
    pMonth: month - 1,
    nYear: year,
    nMonth: month + 1,
    year,
    month,
  };
};

export const getCurrentDate = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;

  return { now, year, month };
};

export const getMonths = () => {
  return [
    { id: 1, label: 'Janeiro' },
    { id: 2, label: 'Fevereiro' },
    { id: 3, label: 'Março' },
    { id: 4, label: 'Abril' },
    { id: 5, label: 'Maio' },
    { id: 6, label: 'Junho' },
    { id: 7, label: 'Julho' },
    { id: 8, label: 'Agosto' },
    { id: 9, label: 'Setembro' },
    { id: 10, label: 'Outubro' },
    { id: 11, label: 'Novembro' },
    { id: 12, label: 'Dezembro' },
  ];
};

export const setDateTime = (date, hour = 0, minutes = 0, seconds = 0, milliseconds = 0) => {
  let currentDateTime = new Date(date);

  currentDateTime.setHours(hour);
  currentDateTime.setMinutes(minutes);
  currentDateTime.setSeconds(seconds);
  currentDateTime.setMilliseconds(milliseconds);

  return currentDateTime.toISOString();
};
