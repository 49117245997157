import axios from "axios";
import { createRequestHeader, endpoints } from ".";
import { setSnackbar } from "../store/actions/Snackbar";

export const post = (component, payload, token, dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${endpoints[component]}`, payload, createRequestHeader(token))
      .then(({ data }) => resolve(data))
      .catch((error) => {
        dispatch(
          setSnackbar({
            open: true,
            message: error.response.data.message,
            duration: 4000,
            variant: "danger",
          })
        );

        return reject(error);
      });
  });
};

export default post;
