export default (theme) => ({
  root: {
    margin: '8px 5px',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    whiteSpace: 'nowrap',
    '@media (max-width: 900px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
  },

  orientation: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: 5,
  },

  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 900px)': {
      marginTop: 5,
      alignItems: 'center',
      justifyContent: 'center',
    },
  },

  icon: {
    width: 20,
    cursor: 'pointer',
    padding: 5,
    marginRight: 5,
  },

  grip: {
    fontSize: 22,
    color: '#394047',
  },

  totalizers: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    whiteSpace: 'nowrap',
    '@media (max-width: 900px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
  },

  item: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    '@media (max-width: 900px)': {
      flexDirection: 'row-reverse',
      alignItems: 'center',
    },
    '& > div': {
      '@media (max-width: 900px)': {
        margin: 5,
      },
    },
  },

  value: {
    fontSize: 18,
    fontWeight: 800,
    color: '#495057',
    whiteSpace: 'nowrap',
  },

  title: {
    fontSize: 13,
    fontWeight: 800,
    color: theme.palette.default.dark,
    whiteSpace: 'initial',
  },
});
