import React, { useEffect } from 'react'

//Redux
import { useSelector, useDispatch } from 'react-redux'
import { fetchUser } from '../../store/actions/Auth'

//Styles
import { makeStyles } from '@material-ui/core'
import styles from './jss/tableExport'
import classnames from 'classnames'

//Material UI components
import { Tooltip } from '@material-ui/core'

//Date FNS
import format from 'date-fns/format'

//Icon
import { FaDownload } from 'react-icons/fa'

import ReactExport from 'react-data-export'

const useStyles = makeStyles(styles)

const TableExport = ({ title, header, items }) => {
    const classes = useStyles()

    const dispatch = useDispatch()

    const user = useSelector(s => s.auth.user)
    const selectedCompanies = useSelector(s => s.auth.selectedCompanies)
    const companies = useSelector(s => s.company.companies).filter(x => selectedCompanies.includes(x.id))
    const companyNames = companies.map(x => x.name.replace(/\s/g, '')).join('_')

    //Effects
    useEffect(() => {
        if (!user)
            dispatch(fetchUser())
    }, [dispatch, user])

    const buttonStyle = classnames({
        [classes.button]: true
    })

    const iconStyle = classnames({
        [classes.icon]: true
    })

    const exportData = (title, header, items) => {
        //declaring variables needed for file generation
        const ExcelFile = ReactExport.ExcelFile
        const ExcelSheet = ReactExport.ExcelFile.ExcelSheet

        //getting indexes of data without the id
        let keys = Object.keys(items[0])
        keys = keys.splice(1, keys.length)

        //generating headers
        const headers = header.map(column => ({
            value: column.label,
            style: { font: { bold: true } }
        }))

        //generating columns to aplly width
        const columns = headers.map(x => ({
            title: '',
            width: { wch: 20 }
        }))

        //Inserting report title and filters items
        let lines = []

        let fix = (process.env.REACT_APP_FIX_TIME) ? parseInt(process.env.REACT_APP_FIX_TIME) : 0

        lines = [
            [
                { value: 'Relatório: ', style: { font: { bold: true } } },
                { value: (title) ? title : '' },
            ],
            [
                { value: 'Filtros: ', style: { font: { bold: true } } },
                { value: `Data de emissão: ${format((Date.now() + fix), 'dd/MM/yyyy HH:mm')}` },
            ],
            [
                { value: 'Gerador por:', style: { font: { bold: true } } },
                { value: user.name },
            ],
            [
                { value: '' },
                { value: '' },
            ],
            headers
        ]

        //Inserting data
        for (let item of items) {
            let line = []
            keys.map(key => {
                if (typeof item[key] === 'object') return false
                return line.push({ value: item[key] })
            })
            lines.push(line)
        }

        //declaring dataSet variable
        const dataSet = [
            {
                columns: columns,
                data: lines
            }
        ]
        
        return <ExcelFile
            filename={`${format((Date.now() + fix), 'dd-MM-yyyy-HH-mm_')}${companyNames}`}
            element={
                <Tooltip title={'Exportar como .XLSX'}>
                    <button className={buttonStyle}>
                        <FaDownload className={iconStyle} />
                    </button>
                </ Tooltip>}>
            <ExcelSheet dataSet={dataSet} name='Dados' />
        </ExcelFile>

    }

    return (
        <div>
            {
                Boolean(items.length) &&
                exportData(title, header, items)
            }
        </div>
    )
}

export default TableExport