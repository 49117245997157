import intl from 'react-intl-universal';

const client = process.env.REACT_APP_CLIENT;

const getConfig = () => {
  try {
    const settings = require(`./${client.trim()}/settings`);

    return settings.default;
  } catch (error) {
    console.error(intl.get('configLoader.errorMessage', { client }), error);

    return {};
  }
};

export default getConfig;
