export default (theme) => ({
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "5px",
      "& > * + *": {
        marginTop: "2px",
      },
    },
  
    pagination: {
      width: "100%",
      fontWeight: "bold",
      "& > ul ": {
        justifyContent: "center",
        padding: "2px",
        boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.14)",
        "& > li ": {
          margin: "0 3px",
          "& > button": {
            fontWeight: "bold",
            margin: 0,
          },
        },
      },
    },
    blinck: {
      width: "100%",
      "& > ul ": {
        justifyContent: "center",
        padding: "2px",
        boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.14)",
        "& > li ": {
          margin: "0 3px",
          "& > button": {
            fontWeight: "bold",
            margin: 0,
          },
        },
        "& > li:nth-last-child(2)": {
          background: `${theme.palette.danger.main}`,
          borderRadius: "50%",
          animation: "$pgBlinck .95s infinite",
          "&:-webkit-animation": "$pgBlinck .75s linear infinite",
          "&:-moz-animation": "$pgBlinck .75s linear infinite",
          "&:-ms-animation": "$pgBlinck .75s linear infinite",
          "&:-o-animation": " $pgBlinck .75s linear infinite",
        },
      },
    },
    "@keyframes pgBlinck": {
      "0%": {
        opacity: 0.1,
      },
      "50%": {
        opacity: 0.2,
      },
      "100%": {
        opacity: 1,
      },
    },
  
    header: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      color: "#aaa",
      margin: "10px 0",
      "@media(max-width: 767px)": {
        margin: "2px 0",
      },
    },
  
    title: {
      display: "flex",
      flexDirection: "row",
    },
  
    titleText: {
      color: `${theme.palette.text.warning}`,
      fontSize: 18,
      fontWeight: "bold",
      whiteSpace: "nowrap",
      marginBottom: "5px",
    },
    body: {
      height: "45vh",
      width: "100%",
      display: "inline",
      flexDirection: "colum",
      justifyContent: "center",
      alignItems: "center",
      userSelect: "none",
      color: "#aaa",
      fontSize: 12,
      padding: "1px",
      margin: "0% 0",
      borderRadius: "0.3em",
      backgroundColor: "#aaa",
      boxShadow:
        "rgba(0, 0, 0, 0.2) 0px 1px 5px 0px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 3px 1px -2px",
      "& > *button": {
        width: "24px",
        height: "24px",
      },
      "@media(max-width: 767px)": {
        width: "100%",
        minWidth: "40vw",
        minHeight: "45vh",
      },
    },
    address: {
      position: "absolute",
      padding: "3px",
      width: "250px",
      backgroundColor: `${theme.palette.text.accent}`,
      margin: "7px 0 0 7px",
      zIndex: 10,
      borderRadius: "0.3em", 
      boxShadow:
        "rgba(0, 0, 0, 0.2) 0px 1px 5px 0px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 3px 1px -2px",
      "& > span": {
        color: `${theme.palette.default.text}`,
        fontWeight: "bold",
        fontSize: "13px",
      },
      "@media(max-width: 767px)": {
        display: "none",
      },
    },
    addressMobile: {
      display: "none",
      padding: "3px",
      width: "250px",
      margin: "5px 0",
      textAlign: "center",
      "& > span": {
        fontSize: "14px",
        color: `${theme.palette.text.warning}`,
        fontWeight: "bold",
      },
      "@media(max-width: 767px)": {
        display: "block",
      },
    },
    alert: {
      width: 200,
      justifyContent: "center",
      marginRight: -20,
    },
    badge: {
      background: `${theme.palette.danger.main}`,
      position: "absolute",
      width: 10,
      height: 10,
      borderRadius: "50%",
      marginLeft: "2px",
    },
    imgTitle: {
      marginRight: "5px",
    },
    image: {
      width: 22,
      height: 22,
    },
    icons: {
      width: 22,
      height: 22,
      color:  `${theme.palette.text.accent}`,
      background: `${theme.palette.text.warning}`,
      padding: 3,
      borderRadius: "50%",
      border: `3px solid ${theme.palette.danger.main}`,
    },
    close: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      float: "right",
      padding: 5,
      width: 15,
      height: 15,
    },
    closeIcon: {
      width: 15,
      height: 15,
      color: `${theme.palette.text.warning}`,
      borderRadius: "50%",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "rgb(224, 224, 209, 0.5)",
      },
      "&:active": {
        backgroundColor: "rgb(224, 224, 209, 0.5)",
      },
    },
    footer: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      margin: "10px",
      flexFlow: "wrap",
    },
    footerContent: {
      display: "flex",
      margin: "0 5px",
      flex: 1,
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      whiteSpace: "nowRap",
      textAlign: "center",
    },
    btnContent: {
      width: "100%",
      padding: 10,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    btnClose: {
      "&:hover": {
        backgroundColor: ` ${theme.palette.default.dark}`,
      },
    },
    btnDetail: {
      "&:hover": {
        backgroundColor: ` ${theme.palette.primary.dark}`,
      },
    },
  });
  