import React from "react";

export const Brazil = ({ width, height, x, y }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="svg171"
      viewBox="0 0 1000 1000"
      version="1.0"
      width={width}
      height={height}
      x={x}
      y={y}
    >
      <circle cx="500" cy="500" r="500" fill="#009c3b" />
      <polygon points="50,500 500,150 950,500 500,850" fill="#ffdf00" />
      <circle cx="500" cy="500" r="175" fill="#002776" />
    </svg>
  );
};

export default Brazil;
