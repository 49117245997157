export default (theme) => ({
  above: {
    zIndex: "99999 !important",
  },

  hidden: {
    height: 0,
    width: 0,
    position: "absolute",
    pointerEvents: "none",
    top: 0,
    left: 0,
    overflow: "hidden",
  },

  container: {
    width: "calc(100% - 4px)",
    margin: "0 2px",
    zIndex: 2000,
  },

  input: {
    height: 28,
    paddingLeft: 10,
    backgroundColor: "white",
    borderRadius: "5px",
    boxSizing: "border-box",
    minHeight: 40,
    padding: 8,
    border: `1px solid ${theme.palette.default.dark}`,
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    position: "relative",
    color: theme.palette.default.text,
    fontFamily: theme.typography.standard.fontFamily,
    fontSize: theme.typography.standard.fontSize,
    letterSpacing: theme.typography.standard.letterSpacing,
    fontWeight: theme.typography.standard.fontWeightBold,
  },

  label: {
    fontSize: 11,
    fontFamily: theme.typography.standard.fontFamily,
    letterSpacing: theme.typography.standard.letterSpacing,
    fontWeight: theme.typography.standard.fontWeightBold,
    color: theme.palette.default.text,
  },

  text: {
    fontSize: 15,
    //fontWeight: 400,
    textAlign: "left",
    flex: 1,
    fontFamily: theme.typography.standard.fontFamily,

    letterSpacing: theme.typography.standard.letterSpacing,
    fontWeight: theme.typography.standard.fontWeightBold,
    color: theme.palette.default.text,
  },

  icon: {
    height: 30,
    width: 30,
    fill: theme.palette.default.text,
    marginTop: 5,
  },

  now: {
    display: "flex",
    alignItems: "center",
    marginLeft: 5,
    borderLeft: "1px solid #a4a4a4",
    padding: 6,
    color: theme.palette.default.text,
    "& > svg": {
      color: theme.palette.default.text,
      marginLeft: 2,
    },
  },

  disabled: {
    opacity: "70%",
    pointerEvents: "none",
  },
});
