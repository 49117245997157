import React from "react";
import LicensePlate from "./LicensePlate";

const colors = ["#bc0000", "#00bf00"];
const sind = (degrees) => {
  return Math.sin(degrees * (Math.PI / 180));
};
const cosd = (degrees) => {
  return Math.cos(degrees * (Math.PI / 180));
};
const abs = (value) => {
  return Math.abs(value);
};
const vehicleDraw = {
  width: 358.85,
  height: 789.36,
};
const licensePlateDraw = {
  ratio: 1 / 3,
  width: 1400,
  marginTop: 30,
  marginRight: 30,
  marginBottom: 30,
  marginLeft: 30,
};
licensePlateDraw.height = licensePlateDraw.width * licensePlateDraw.ratio;
licensePlateDraw.totalWidth =
  licensePlateDraw.width +
  licensePlateDraw.marginLeft +
  licensePlateDraw.marginRight;
licensePlateDraw.totalHeight =
  licensePlateDraw.height +
  licensePlateDraw.marginTop +
  licensePlateDraw.marginBottom;

export const TruckIcon = ({
  ignition,
  license,
  degrees,
  countryAbbreviation,
}) => {
  vehicleDraw.rotatedWidth =
    abs(vehicleDraw.width * cosd(degrees)) +
    abs(vehicleDraw.height * sind(degrees));
  vehicleDraw.rotatedHeight =
    abs(vehicleDraw.width * sind(degrees)) +
    abs(vehicleDraw.height * cosd(degrees));

  const viewBoxWidth =
    vehicleDraw.rotatedWidth >= licensePlateDraw.totalWidth
      ? vehicleDraw.rotatedWidth
      : licensePlateDraw.totalWidth;
  const viewBoxHeight =
    vehicleDraw.rotatedHeight + licensePlateDraw.totalHeight;

  vehicleDraw.widthPosition =
    (viewBoxWidth - vehicleDraw.width) / 2 +
    (licensePlateDraw.totalHeight / 2) * sind(degrees);
  vehicleDraw.heightPosition =
    (viewBoxHeight - vehicleDraw.height) / 2 +
    (licensePlateDraw.totalHeight / 2) * cosd(degrees);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="svg171"
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      version="1.0"
    >
      <LicensePlate
        width={licensePlateDraw.width}
        x={licensePlateDraw.marginLeft}
        y={licensePlateDraw.marginTop}
        countryAbbreviation={countryAbbreviation}
        license={license}
      />
      <g
        transform={`rotate(${degrees}) translate(${vehicleDraw.widthPosition}, ${vehicleDraw.heightPosition})`}
        style={{
          transformOrigin: "center",
        }}
      >
        <defs>
          <linearGradient id="linearGradient239">
            <stop id="stop240" stopColor="#f2f2f2" offset="0" />
            <stop id="stop241" stopColor="#b5b5b5" offset="1" />
          </linearGradient>
          <radialGradient
            id="radialGradient3193"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xlinkHref="#linearGradient239"
            gradientUnits="userSpaceOnUse"
            cy="598.63"
            cx="294.43"
            gradientTransform="matrix(1.5041 0 0 .74199 -264.04 -146.71)"
            r="91.234"
          />
          <radialGradient
            id="radialGradient3205"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xlinkHref="#linearGradient239"
            gradientUnits="userSpaceOnUse"
            cy="319.37"
            cx="657.76"
            gradientTransform="matrix(.65803 0 0 1.5197 -252.14 -111.82)"
            r="1269.3"
          />
        </defs>
        <g>
          <rect
            id="rect959"
            fillRule="evenodd"
            rx="8.5849"
            ry="8.5849"
            height="78.696"
            width="27.775"
            y="623.04"
            x="16.287"
            strokeWidth="1pt"
            fill="#60585a"
          />
          <rect
            id="rect958"
            fillRule="evenodd"
            rx="8.5849"
            ry="8.5849"
            height="78.696"
            width="27.775"
            y="613.04"
            x="311.29"
            strokeWidth="1pt"
            fill="#60585a"
          />
          <rect
            id="rect317"
            fillRule="evenodd"
            rx="8.5849"
            ry="8.5849"
            height="78.696"
            width="27.775"
            y="98.038"
            x="315.153616"
            strokeWidth="1pt"
            fill="#60585a"
          />
          <rect
            id="rect316"
            fillRule="evenodd"
            rx="8.5849"
            ry="8.5849"
            height="78.696"
            width="27.775"
            y="101.12"
            x="12.269684"
            strokeWidth="1pt"
            fill="#60585a"
          />
          <path
            id="path184"
            d="m178.674826,782.98c-106.99,2.362 -123.388132,-17.92 -139.199602,-21.261c-15.806739,-38.776 -18.808174,-365.73 -9.325077,-392.46c7.090086,-60.54 -4.670581,-70.565 -8.219883,-143.53c-6.756068,-85.213 9.287228,-37.829 -4.200306,-124.48c20.493406,-90.216 -18.107019,-92.053 159.458343,-100.63c162.949924,2.401 140.003895,10.415 160.489732,100.63c-13.487534,86.652 2.555762,39.268 -4.200306,124.48c-3.549301,72.961 -15.309969,82.986 -8.219883,143.53c9.483097,26.727 6.481662,353.68 -9.325077,392.46c-24.746701,15.153 -90.325979,21.261 -137.269296,21.261l0.011355,0z"
            fillRule="evenodd"
            strokeWidth="1pt"
            fill="url(#radialGradient3205)"
            stroke="#000"
          />
          <path
            id="path230"
            d="m70.024515,42.673538c-9.057351,3.654675 -8.396701,38.416479 6.094906,41.157436c13.997089,-2.907569 95.083942,-5.608391 110.501701,-5.357397c15.750025,-0.292108 77.394664,1.57703 96.333821,4.44368c5.105871,0.417017 15.314508,-40.243131 0.822125,-42.984089c-45.290637,-3.199872 -195.640955,-3.199872 -213.752552,2.739l0,0.00137l-0.000001,0z"
            strokeOpacity="0.45912"
            fillRule="evenodd"
            strokeWidth="1pt"
            fill="#f2f2f2"
            stroke="#000"
          />
          <path
            id="path275"
            d="m41.764,115.283158s65.53,-12.448416 138.64,-12.766834c65.833,-0.296611 95.565,4.453698 135.61,11.579243c-4.8534,22.565154 -9.7078,43.941329 -21.843,51.067338c-111.64,-13.065352 -118.92,-13.065352 -230.56,0c-2.43,-7.122769 -24.273,-48.693081 -21.846,-49.877896l-0.001,-0.001851z"
            fillRule="evenodd"
            fill="url(#radialGradient3193)"
            stroke="null"
          />
          <path
            id="path185"
            d="m45.51,109.146213s63.783,-17.537672 134.95,-17.985994c64.078,-0.417867 93.017,6.274382 132,16.313109c-4.724,31.789775 -9.449,61.90454 -21.261,71.943975c-108.67,-18.404569 -115.75,-18.403861 -224.42,0.000708c-2.362,-10.038727 -23.623,-68.598205 -21.261,-70.27109l-0.008,-0.000708z"
            fillRule="evenodd"
            fillOpacity="0.7044"
            strokeWidth="1pt"
            stroke="#000"
          />
          <path
            id="path189"
            d="m321.9,139.089234l28.348,2.362s14.174,14.174 4.725,21.261c-9.45,7.087 -33.073,-2.362 -33.073,-2.362l0,-21.261z"
            strokeOpacity="0.55346"
            fillRule="evenodd"
            stroke="#000"
            strokeWidth="1.25"
            fill="#b5b5b5"
          />
          <path
            id="path190"
            d="m36.946,140.18285l-28.348,2.362s-14.174,14.174 -4.725,21.261c9.45,7.087 33.073,-2.362 33.073,-2.362l0,-21.261z"
            strokeOpacity="0.54717"
            fillRule="evenodd"
            stroke="#000"
            strokeWidth="1pt"
            fill="#b5b5b5"
          />
          <path
            id="path304"
            d="m52.582,17.025c-9.023,1.573 -19.32,18.998 -14.584,21.902c11.281,-5.689 33.327,-13.506 54.984,-15.684c4.286,-2.3 10.138,-9.356 10.358,-10.929c-14.886,-0.847 -40.915,2.775 -50.758,4.711z"
            strokeOpacity="0.25157"
            fillRule="evenodd"
            fillOpacity="0.56604"
            stroke="#000"
            strokeWidth="1.082pt"
            fill="#2e87cf"
          />
          <path
            id="path306"
            d="m298.7,12.115c9.0231,1.5731 19.32,14.633 14.584,17.537c-11.28,-5.6886 -33.327,-7.5051 -54.984,-9.6837c-4.2855,-2.2999 -10.134,-10.992 -10.36,-12.565c14.888,-0.84719 40.917,2.7754 50.76,4.7115l0,0.0002z"
            strokeOpacity="0.25157"
            fillRule="evenodd"
            fillOpacity="0.56604"
            stroke="#000"
            strokeWidth="1.082pt"
            fill="#2e87cf"
          />
          <path
            id="path307"
            d="m112.29,10.38c37.098,-6.547 99.837,-6.002 126.57,-2.729"
            strokeOpacity="0.37107"
            stroke="#000"
            strokeWidth="1pt"
            fill="none"
          />
          <path
            id="path308"
            d="m112.29,13.108c37.098,-6.547 99.837,-6.002 126.57,-2.729"
            strokeOpacity="0.37107"
            stroke="#000"
            strokeWidth="1pt"
            fill="none"
          />
          <path
            id="path309"
            d="m112.29,16.381c37.098,-6.547 99.837,-6.002 126.57,-2.729"
            strokeOpacity="0.37107"
            stroke="#000"
            strokeWidth="1pt"
            fill="none"
          />
          <path
            id="path310"
            d="m112.29,20.2c37.098,-6.001 98.2,-4.911 124.39,-3.275"
            strokeOpacity="0.37107"
            stroke="#000"
            strokeWidth="1pt"
            fill="none"
          />
          <rect
            fill="#b5b5b5"
            stroke="#000000"
            strokeWidth="2"
            x="4.878598"
            y="233.684788"
            width="349.092817"
            height="549.09391"
            id="svg_2"
          />
          <path
            id="svg_1"
            d="m90.227862,633.590995l0,0c0,-49.030325 39.746934,-88.777251 88.777266,-88.777251l0,0c23.545178,0 46.126022,9.353288 62.774989,26.002255c16.648967,16.648967 26.002247,39.22981 26.002247,62.774996l0,0c0,49.030317 -39.746926,88.777243 -88.777236,88.777243l0,0c-49.030332,0 -88.777266,-39.746934 -88.777266,-88.777243zm88.777266,-88.777251l0,177.554494m-88.777266,-88.777243l177.554501,0"
            strokeWidth="0"
            fill={colors[ignition > 1 ? 0 : ignition]}
            stroke="#000"
          />
        </g>
      </g>
    </svg>
  );
};

export default TruckIcon;
