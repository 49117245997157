import React from 'react'

import { useStyles } from '..'
import styles from './jss/path'
import clsx from 'clsx'
import { isPresetColor } from '../../utils/helpers'

export const Path = ({ offset, color }) => {
    const classes = useStyles(styles)

    const rootStyle = clsx({
        [classes.root] : true,
        [classes[color]] : isPresetColor(color)
    })

    return <div className={rootStyle} style={{ width: `${offset}%` }} />
}