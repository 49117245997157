export default theme => ({
    root: {
        margin: 5,
        padding: 5,
        marginTop: 10,
        display: 'flex',
        alignItems: ' center',
        justifyContent: 'center',
        position: 'relative'
    },

    button: {
        margin: '0 10px'
    },

    options: {
        position: 'absolute',
        right: 20,
        top: 5,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },

    optionsTitle: {
        fontSize: 14,
        color: theme.palette.default.text,
        marginLeft: 5,
        '@media (max-width: 900px)': {
            display: 'none'
        }
    },

    picker: {
        border: `1px solid ${theme.palette.default.text}`,
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
        color: theme.palette.default.text,
    },

    caret: {
        fontSize: 14,
        padding: 5
    },

    option:{
        padding: 5,
        paddingRight: 0,
        fontSize: 14,
    },

    paper:{
        zIndex: '99999 !important'
    }

})