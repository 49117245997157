export default (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: 180,
    margin: 5,
    backgroundColor: 'white',
    boxShadow:
      'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;',
    borderRadius: '5px',
    padding: 5,
    userSelect: 'none',
  },

  body: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },

  content: {
    fontSize: 24,
    color: theme.palette.default.text,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  span: { color: theme.palette.default.text, fontSize: 16 },

  value: {
    color: theme.palette.default.text,
    display: 'flex',
    fontSize: '24px',
    alignItems: ' center',
    justifyContent: 'space-around',
    width: '100%',
    textAlign: 'center',
  },

  pointer: {
    cursor: 'pointer',
  },

  loader: {
    marginTop: '8%',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
  },

  average: {
    backgroundColor: '#444',
    animation: '$placeHolderShimmer 1.5s infinite',
    width: '40%',
    height: '20%',
  },

  footer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '40%',
    '& > div:first-child': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      width: '90%',
      height: '50%',
      '& > div': {
        backgroundColor: '#444',
        animation: '$placeHolderShimmer 1.5s infinite',
        width: '15%',
        height: '50%',
      },
    },
    '& > div:nth-child(2)': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      width: '90%',
      height: '30%',
      '& > div': {
        backgroundColor: '#444',
        animation: '$placeHolderShimmer 1.5s infinite',
        width: '20%',
        height: '50%',
      },
    },
  },

  '@keyframes placeHolderShimmer': {
    '0%': {
      opacity: 0.1,
    },
    '50%': {
      opacity: 0.2,
    },
    '100%': {
      opacity: 0.1,
    },
  },
});
