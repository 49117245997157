import React, { useRef, useCallback, useContext } from 'react';

const VehiclesLastEventRefContext = React.createContext();
const UpdateVehiclesLastEventRefCallback = React.createContext();

const ContextProvider = (props) => {
  const { children } = props;
  const vehiclesLastEventRef = useRef({});
  const firstUpdateRef = useRef(true);

  const updateVehiclesLastEventCallback = useCallback((vehiclesLastEvent) => {
    if (vehiclesLastEvent.length) {
      vehiclesLastEvent.forEach(({ vehicleId, lastEventTimestamp, lastView }) => {
        vehiclesLastEventRef.current[vehicleId] = {
          lastEventTimestamp,
          lastView: firstUpdateRef.current ? null : lastView || vehiclesLastEventRef.current[vehicleId]?.lastView,
        };
      });
      firstUpdateRef.current = false;
    }
  }, []);

  return (
    <VehiclesLastEventRefContext.Provider value={vehiclesLastEventRef}>
      <UpdateVehiclesLastEventRefCallback.Provider value={updateVehiclesLastEventCallback}>
        {children}
      </UpdateVehiclesLastEventRefCallback.Provider>
    </VehiclesLastEventRefContext.Provider>
  );
};

export const useVehiclesLastEventRef = () => {
  return useContext(VehiclesLastEventRefContext);
};

export const useUpdateVehiclesLastEventCallback = () => {
  return useContext(UpdateVehiclesLastEventRefCallback);
};

export const ControlTowerContextProvider = ContextProvider;

export default ControlTowerContextProvider;
