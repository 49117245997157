import React from 'react'

import { useStyles } from '..'
import styles from './jss/marker'
import clsx from 'clsx'
import { isPresetColor } from '../../utils/helpers'



export const Marker = ({ offset, color, value, activeSpots, showValue }) => {
    const classes = useStyles(styles)
    
    const rootStyle = clsx({
        [classes.root]: true,
        [classes[color]]: isPresetColor(color),
        [classes.active]: activeSpots.includes(parseInt(value.toFixed()))
    })

    return (
        <div
            className={rootStyle}
            style={{ left: `calc(${offset}% - 12.5px)` }}
        >
            {showValue && <div className={classes.label} style={{ left: `calc(${offset}% - 12.5px)` }}>{value}</div>}
        </div>
    )
}