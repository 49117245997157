import axios from 'axios';
import { chunker } from '.';

export const geocode = (query) => {
  return new Promise((resolve, reject) => {
    const endpoint = `https://nominatim.openstreetmap.org/search.php?q=${query}&polygon_geojson=0&format=json`;
    axios
      .get(endpoint)
      .then((res) => {
        if (!res.data.length) return reject('Lugar inválido');

        return resolve(res.data[0]);
      })
      .catch((e) => reject(e));
  });
};

export const kml2Path = (file) => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onloadend = () => {
        const parser = new DOMParser();
        const xml = parser.parseFromString(reader.result, 'text/xml');
        const HTMLcoords = xml.getElementsByTagName('coordinates');
        const parsedCoords = [].slice
          .call(HTMLcoords)
          .map((tag) => tag.innerHTML);

        const coords = parsedCoords.map((point) => {
          const items = point.split(',');
          return {
            lat: parseFloat(items[1]),
            lng: parseFloat(items[0]),
          };
        });

        resolve(coords);
      };
    } catch (e) {
      reject('Arquivo inválido');
    }
  });
};

//From an array of points assemble a XML/KML File
export const path2Kml = (path) => {
  return new Promise((resolve, reject) => {
    try {
      const doc = document.implementation.createDocument('', '', null);
      const wrapper = doc.createElement('wrapper');
      const kml = doc.createElement('kml');
      kml.setAttribute('xmlns', 'http://www.opengis.net/kml/2.2');

      const Placemark = doc.createElement('Placemark');

      const Polygon = doc.createElement('Polygon');
      const tessellate = doc.createElement('tessellate');
      tessellate.innerHTML = 1;

      const outerBoundaryIs = doc.createElement('outerBoundaryIs');
      const LinearRing = doc.createElement('LinearRing');

      const coordinates = doc.createElement('coordinates');

      let pathCoordinates = '';
      path.forEach((point) => {
        pathCoordinates = `${pathCoordinates} ${point.lng},${point.lat},0`;
      });
      coordinates.innerHTML = pathCoordinates;

      Placemark.appendChild(Polygon);
      Polygon.appendChild(tessellate);
      Polygon.appendChild(outerBoundaryIs);
      outerBoundaryIs.appendChild(LinearRing);
      LinearRing.appendChild(coordinates);

      kml.appendChild(Placemark);
      wrapper.appendChild(kml);
      doc.appendChild(wrapper);

      const result = [].slice.call(doc.getElementsByTagName('wrapper'))[0]
        .innerHTML;
      const header = '<?xml version="1.0" encoding="UTF-8"?>';
      //Returning just the HTML(XML) string
      resolve(header + result);
    } catch (e) {
      reject(e);
    }
  });
};

export const drawCircularPolygon = (point, radius, dir) => {
  const d2r = Math.PI / 180; // degrees to radians
  const r2d = 180 / Math.PI; // radians to degrees
  const earthsradius = 6371; // Earth's radius (KM)

  const points = 32;

  // find the raidus in lat/lon
  const rlat = (radius / earthsradius) * r2d;
  const rlng = rlat / Math.cos(point.lat * d2r);

  const extp = [];

  let start, end;

  if (dir === 1) {
    start = 0;
    end = points + 1; // one extra here makes sure we connect the path
  } else {
    start = points + 1;
    end = 0;
  }

  for (let i = start; dir === 1 ? i < end : i > end; i = i + dir) {
    const theta = Math.PI * (i / (points / 2));
    const ey = point.lng + rlng * Math.cos(theta); // center a + radius x * cos(theta)
    const ex = point.lat + rlat * Math.sin(theta); // center b + radius y * sin(theta)
    extp.push({ lat: ex, lng: ey });
  }
  return extp;
};

//Filter a path in order to stablish a minimal distance between pointer (lower distances are discarded).
export const spacer = (path, offset = 10) => {
  const optimized = [];
  optimized.push(path[0]);

  for (let i = 1; i < path.length; i++) {
    const distance = getDistance(
      parseFloat(path[i].latitude),
      parseFloat(path[i].longitude),
      parseFloat(path[i - 1].latitude),
      parseFloat(path[i - 1].longitude)
    );
    if (distance >= offset) optimized.push(path[i]);
  }

  return chunker(optimized, 100);
};

//Return the distance between two points (in meters) {lat, lng}
const getDistance = (lat1, lon1, lat2, lon2) => {
  const toRadians = (deg) => deg * (Math.PI / 180);
  const R = 6371; // Radius of the earth in km
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c * 1000; // distance in meters
  return distance;
};
