export default theme => ({
    container: {
        padding: 10,
    },
    root: {
        position: 'relative',
        cursor: 'pointer',
        padding: 10,
        marginTop: 10,
    },
})