import { TravelTypes as types } from "../types";

const initialState = {
  travels: [],
  loading: false,
  lastFetch: null,
  lastFilter: null,
};

export const TravelReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_TRAVELS:
      return fetchTravels(state, action);

    case types.LOADING_TRAVELS:
      return { ...state, loading: true };

    default:
      return state;
  }
};

const fetchTravels = (state, action) => {
  let fix = process.env.REACT_APP_FIX_TIME
    ? parseInt(process.env.REACT_APP_FIX_TIME)
    : 0;

  return {
    ...state,
    travels: action.data,
    loading: false,
    lastFetch: Date.now() + fix,
    lastFilter: JSON.stringify(action.filter),
  };
};
