import React from "react";

import { useStyles } from "../../hooks";
import styles from "./jss/customCards";

export function CustomCards(props) {
  const { cards, loading = false } = props;
  const classes = useStyles(styles);

  if (loading)
    return (
      <div className={classes.row}>
        <div className={classes.loader}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );

  return (
    <div className={classes.row}>
      {cards &&
        cards.map((card) => (
          <div className={classes.column} key={card.title}>
            <div className={classes.cardContent}>
              <div className={classes.title}>{card.title}</div>
              <div className={classes.content}>{card.value}</div>
            </div>
          </div>
        ))}
    </div>
  );
}
