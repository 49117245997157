export default theme => ({
    wrapper: {
        position: 'relative',
        width: '98%',
        userSelect: 'none',
    },

    root: {
        minHeight: 40,
        padding: 8,
        border: `1px solid ${theme.palette.default.dark}`,
        borderRadius: '5px',
        boxSizing: 'border-box',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
        '&:active': {
            border: `1px solid ${theme.palette.default.text}`
        },
        outline: 'none',
        position: 'relative',
        fontSize: 14,
        color: theme.palette.default.text,
        zIndex: theme.zIndex.multiSelect,
        overflow: 'hidden',
        backgroundColor: 'white'
    },

    innerContainer: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        marginRight: 5,
    },

    spreader: {
        marginTop: 10,
    },

    tags: {
        display: 'flex',
        flexDirection: 'row',
        zIndex: theme.zIndex.multiSelect,
    },

    focus: {
        borderRadius: '5px 5px 0 0',
    },

    grow: {
        flexGrow: 1
    },

    caret: {
        zIndex: theme.zIndex.multiSelect,
        transition: 'transform 180ms ease'
    },

    caretDown: {
        transform: 'rotate(180deg)'
    },


    input: {
        flex: 1,
        zIndex: theme.zIndex.multiSelect,
        height: '95%',
        width: '90%',
        border: 'none',
        outline: 'none',
        cursor: 'pointer',
        fontSize: 14,
        fontFamily: 'Roboto',
        color: theme.palette.default.text,
        '&:active': {
            border: 'none',
        },
        '&:focus': {
            '&::-webkit-input-placeholder': {
                color: theme.palette.default.text,

            }
        },
    },

    placeholder: {
        fontSize: 14,
        color: theme.palette.default.text,
    },

    label: {
        fontSize: 12,
        fontWeight: 600,
        color: theme.palette.default.text
    }


})