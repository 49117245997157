import React, { useState } from 'react'

//Styles
import { makeStyles } from '@material-ui/core'
import styles from './jss/tableSearch'
import classnames from 'classnames'

//Material UI components
import { TextField } from '@material-ui/core'

//Icon
import { FaSearch, FaTimes } from 'react-icons/fa'

//Components
import TableExport from './TableExport'

const useStyles = makeStyles(styles)

const TableSearch = ({ searchString, onSearch, title, items, header, customAction, report, setSearchString }) => {
    const classes = useStyles()

    //State
    const [inputOpen, inputToggler] = useState(false)

    const tableSearchStyle = classnames({
        [classes.tableSearch]: true
    })

    const inputStyle = classnames({
        [classes.input]: true,
        [classes.inputShow]: inputOpen
    })

    const iconStyle = classnames({
        [classes.icon]: true
    })

    const clearSearch = () => {
        setSearchString('')
        inputToggler(!inputOpen)
    }

    return (
        <div className={tableSearchStyle}>
            <>
                {inputOpen &&
                    <TextField
                        className={inputStyle}
                        id='input-search'
                        placeholder='Pesquisar'
                        value={searchString}
                        onChange={e => onSearch(e.target.value)}
                    />
                }
                <label onClick={() => clearSearch()} htmlFor='input-search'>
                    {!inputOpen && <FaSearch className={iconStyle} />}
                    {inputOpen && <FaTimes className={iconStyle} />}
                </label>
                {report &&
                    <TableExport title={title} items={items} header={header} />
                }
                {customAction}

            </>
        </div>
    )
}

export default TableSearch