import React from 'react';
import intl from 'react-intl-universal';

//Icon
import { FaArrowUp } from 'react-icons/fa';

//Styles
import { useStyles } from '..';
import styles from './jss/inputFile';

export const InputFile = ({ value = '', onChange }) => {
  const classes = useStyles(styles);
  const title = intl.get('monitoring.fences.addPanel.selectFile');
  return (
    <>
      <input
        type="file"
        id="input-file"
        name="input-file"
        value={value}
        onChange={onChange}
        className={classes.hidden}
      />
      <label className={classes.button} htmlFor="input-file">
        <FaArrowUp />
      </label>
      <label htmlFor="input-file">
        <h3 className={classes.title}>{title}</h3>
      </label>
    </>
  );
};

export default InputFile;
