import React from "react";
import ReactDOMServer from "react-dom/server";
import { Marker } from "@react-google-maps/api";
import { TruckIcon } from "./TruckIcon";
import { CarIcon } from "./CarIcon";
import Route from "./Route";

const getVehicleIcon = (idCategory, ignition, license, degrees) => {
  if (idCategory === 2)
    return `data:image/svg+xml;base64,${btoa(
      ReactDOMServer.renderToStaticMarkup(
        <CarIcon
          ignition={ignition}
          license={license}
          degrees={degrees}
          countryAbbreviation="BR"
        />
      )
    )}`;

  return `data:image/svg+xml;base64,${btoa(
    ReactDOMServer.renderToStaticMarkup(
      <TruckIcon
        ignition={ignition}
        license={license}
        degrees={degrees}
        countryAbbreviation="BR"
      />
    )
  )}`;
};

export const VehicleMarker = ({
  id_veiculo: id,
  id_categoria: idCategory,
  latitude: lat,
  longitude: lng,
  status_ignicao: ignition,
  placa: license,
  bussola: degrees,
  visibleRoute,
  showRoute,
  showVehicleDetails,
  setShowVehicleDetails,
  route,
  clusterer,
}) => {
  const icon = getVehicleIcon(idCategory, ignition, license, degrees);

  const handleMarkerClick = () => {
    if (showVehicleDetails !== id) setShowVehicleDetails(id);
  };

  const markerProps = {
    position: { lat, lng },
    icon: {
      url: icon,
      scaledSize: new window.google.maps.Size(80, 80),
    },
    onClick: handleMarkerClick,
    clusterer,
  };

  return (
    <Marker {...markerProps}>
      {route && showRoute && visibleRoute === id && <Route route={route} />}
    </Marker>
  );
};

export default VehicleMarker;
