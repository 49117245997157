import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { registerTheme } from 'echarts';

import { recycleToken } from './store/actions/Auth';

import App from './App';
import { Login } from './components';

import { ThemeProvider } from './styles';
import echartsTheme from './styles/theme/echartsTheme.json';

export default () => {
  const token = useSelector((s) => s.auth.token);
  const storageToken = localStorage.getItem('token');
  const dispatch = useDispatch();

  registerTheme('cst', echartsTheme);

  useEffect(() => {
    if (!token) dispatch(recycleToken(storageToken));
  }, [token, dispatch, storageToken]);

  // atualiza o token a cada 1 hora
  useEffect(() => {
    let interval = null;

    interval = setInterval(() => dispatch(recycleToken(storageToken)), 1000 * 60 * 60);

    return () => clearInterval(interval);
  }, [dispatch, storageToken]);

  axios.interceptors.response.use(
    (res) => {
      /* se o status code retornado estiver na faixa 2xx, somente retorna a resposta */
      return res;
    },
    (err) => {
      /* se o status code retornado estiver fora da faixa 2xx, aplica tratamento de acordo com o código */
      if (err && err.response && err.response.status && err.response.status === 401) {
        dispatch({ type: 'LOGOUT' });
      }

      return Promise.reject(err);
    }
  );

  const Content = () => {
    if (token) {
      return <App />;
    }

    if (storageToken) {
      return null;
    }

    return <Login />;
  };

  return (
    <ThemeProvider>
      <Content />
    </ThemeProvider>
  );
};
