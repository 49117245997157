import React, { useRef } from "react";
import { useStyles } from "../../hooks";
import intl from "react-intl-universal";
import clsx from "clsx";
import { FaFileCsv, FaFileExcel, FaFilePdf } from "react-icons/fa";
import { Tooltip, IconButton } from "@material-ui/core";
import Table, { MTableToolbar } from "material-table";
import { List } from "../../core";

import {
  styles,
  headerStyle,
  headerStyleWithSorting,
  cellStyle,
} from "./jss/CustomTableResponsive";

const FunctionComponent = (props) => {
  const {
    tableRef = null,
    components = null,
    data = [],
    columns: _columns = [],
    title = "",
    minBodyHeight = null,
    maxBodyHeight = null,
    padding = "dense",
    paginationType = "normal",
    pageSize = Math.ceil(Math.floor((window.innerHeight - 192) / 48) / 5) * 5,
    pageSizeOptions = [5, 10, 15, 20, 25, 30],
    draggable = false,
    emptyRowsWhenPaging = false,
    search = false,
    sorting = false,
    toolbar = false,
    noCsvExport = false,
    noXlsExport = false,
    noPdfExport = false,
    onExportReport = null,
    fixedColumns = {},
  } = props;

  const columns = _columns.map((_column) => ({
    ..._column,
    headerStyle: sorting
      ? { ...headerStyle, ...headerStyleWithSorting, ..._column.headerStyle }
      : { ...headerStyle, ..._column.headerStyle },
    cellStyle: { ...cellStyle, ..._column.cellStyle },
  }));

  const _header = columns.map((x, z) =>{
    const obj = {...x};
    obj.id= z;
    return obj;
  }); 

  const header = _header.filter((x) =>{
    if(x.id === 0){
        return x
    }
  })
  const body = _header.filter((x) => x.id > 0);

  const counterRef = useRef(0);


  const classes = useStyles(styles, props);

  const rootStyle = clsx({
    [classes.root]: true,
    [classes.font]: true,
  });

  const ExportReport = () => {
    return (
      <div className={classes.exportIconsContainer}>
        {!noCsvExport && (
          <Tooltip
            title={intl.get("core.CustomTable.exportCSV")}
            placement="top"
          >
            <IconButton onClick={() => onExportReport("csv")}>
              <FaFileCsv />
            </IconButton>
          </Tooltip>
        )}
        {!noXlsExport && (
          <Tooltip
            title={intl.get("core.CustomTable.exportXLS")}
            placement="top"
          >
            <IconButton onClick={() => onExportReport("xls")}>
              <FaFileExcel />
            </IconButton>
          </Tooltip>
        )}
        {!noPdfExport && (
          <Tooltip
            title={intl.get("core.CustomTable.exportPDF")}
            placement="top"
          >
            <IconButton onClick={() => onExportReport("pdf")}>
              <FaFilePdf />
            </IconButton>
          </Tooltip>
        )}
      </div>
    );
  };
  return (
    <div className={rootStyle}>
      <Table
        style={{ boxShadow: "none" }}
        tableRef={tableRef}
        data={data}
        title={title}
        columns={header}
        options={{
          fixedColumns,
          padding,
          paginationType,
          minBodyHeight,
          maxBodyHeight,
          pageSize,
          pageSizeOptions,
          draggable,
          emptyRowsWhenPaging,
          search,
          sorting,
          toolbar,
        }}
        components={
          (components || onExportReport) && {
            Toolbar: (props) => (
              <div className={classes.toolbarContent}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {components && components()}
                </div>
                <div className={classes.toolbarContainer}>
                  <MTableToolbar {...props} />
                </div>
                <div className={classes.exportContent}>
                  {onExportReport && ExportReport()}
                </div>
              </div>
            ),
          }
        }
        localization={{
          pagination: {
            labelDisplayedRows: intl.get("core.CustomTable.labelDisplayedRows"),
            labelRowsSelect: intl.get("core.CustomTable.labelRowsSelect"),
            labelRowsPerPage: intl.get("core.CustomTable.labelRowsPerPage"),
            firstAriaLabel: intl.get("core.CustomTable.firstAriaLabel"),
            firstTooltip: intl.get("core.CustomTable.firstTooltip"),
            previousAriaLabel: intl.get("core.CustomTable.previousAriaLabel"),
            previousTooltip: intl.get("core.CustomTable.previousTooltip"),
            nextAriaLabel: intl.get("core.CustomTable.nextAriaLabel"),
            nextTooltip: intl.get("core.CustomTable.nextTooltip"),
            lastAriaLabel: intl.get("core.CustomTable.lastAriaLabel"),
            lastTooltip: intl.get("core.CustomTable.lastTooltip"),
          },
          grouping: {
            placeholder: intl.get("core.CustomTable.lastTooltip"),
            groupedBy: intl.get("core.CustomTable.groupedBy"),
          },
          toolbar: {
            searchPlaceholder: intl.get("core.CustomTable.searchPlaceholder"),
            searchTooltip: intl.get("core.CustomTable.searchTooltip"),
          },
          body: {
            emptyDataSourceMessage: intl.get(
              "core.CustomTable.emptyDataSourceMessage"
            ),
          },
          header: {
            actions: intl.get("core.CustomTable.actions"),
          },
        }}
        detailPanel={rowData => {
          return body.map((x) => {
            const {location, ...obj} = rowData;
            return(
                  <List
                  key={x.id}
                  body={x}
                  data={obj}
                  location={location}
                  />
              )
          })
        }}
      />
    </div>
  );
};

export const CustomTableResponsive = React.memo(FunctionComponent);

export default CustomTableResponsive;