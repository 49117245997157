import React from 'react'

import { useStyles } from '..'
import styles from './jss/pdf'
import Overlay from '../Overlay/Overlay'
import { FaTimes } from 'react-icons/fa'


const cancel = e => e.stopPropagation()

export const PdfReader = ({ url, open, onClose }) => {
    const classes = useStyles(styles)

    if (!open) return null

    return (
        <Overlay onOverlayClick={onClose}>
            <div className={classes.reader} onClick={cancel}>
                <div className={classes.header}>
                    <FaTimes onClick={onClose} />
                </div>
                <div className={classes.wrapper}>
                    <iframe 
                    src={url} 
                    className={classes.iframe} 
                    title={'Assistente de Reparo'}
                    allowFullScreen
                    ></iframe>
                </div>
            </div>
        </Overlay>
    )
}