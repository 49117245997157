import React, { useEffect, useState } from 'react'

import { useStyles } from '..'
import styles from './jss/optionPanel'
import clsx from 'clsx'

import { Paper, ClickAwayListener } from '..'
import { Option } from './Option'

//i18n
import intl from 'react-intl-universal'

export const OptionPanel = ({ multi, items, onClose, open, onClickOption, selected, setQuery, id, onSelectAll, feminine }) => {
    const classes = useStyles(styles)
    
    const [render, setRender] = useState(open)
    const [exiting, setExiting] = useState(false)

    useEffect(() => {
        let timeout, frame
        if (open) setRender(true)
        if (!open) {
            setExiting(true)
            timeout = setTimeout(() => {
                setExiting(false)
                setQuery('')
                frame = requestAnimationFrame(() => setRender(false))
            }, 200)
        }
        return () => {
            clearTimeout(timeout)
            cancelAnimationFrame(frame)
        }
    }, [open, setQuery])



    const paperStyle = clsx({
        [classes.paper]: true,
        [classes.hidden]: exiting,
    })

    const renderOptions = multi => items.map(item => (
        <Option
            key={item.id}
            item={item}
            onClickOption={onClickOption}
            selected={selected}
            multi={multi}
        />
    ))

    if (!render) return null

    return (
        <ClickAwayListener onClickAway={onClose} ignore={[id]}>
            <Paper className={paperStyle}>
                {
                    multi && Boolean(items.length) &&
                    <Option
                        item={{ id: 0, label: feminine ? intl.get('optionpanel.allFeminine') : intl.get('optionpanel.all') }}
                        onClickOption={onSelectAll}
                        selected={selected.length === items.length ? null : []}
                        multi={multi}
                    />
                }
                {
                    items.length ?
                        renderOptions(multi) :
                        <Option
                            item={{ label: intl.get('optionpanel.noitems') }}
                            onClickOption={() => { }}
                            selected={[]}
                        />
                }
            </Paper>
        </ClickAwayListener>
    )
}

OptionPanel.defaultProps = {
    setQuery: () => { },
    multi: true
}

export default OptionPanel