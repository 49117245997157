export default theme => ({
    root: {
        height: 35,
        fontSize: 14,
        color: theme.palette.default.text,
        padding: 8,
        boxSizing: 'border-box',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.text
        },
    },

    enhanced: {
        color: theme.palette.default.dark
    },

    grow: {
        flexGrow: 1
    },

    active: {
        fontWeight: 600,
        //backgroundColor: '#ebedf7',
        color: theme.palette.default.text
    },

    icon: {
        color: theme.palette.primary.dark
    }
})