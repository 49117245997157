import React, { useState } from "react";

import { useStyles } from "..";
import styles from "./jss/footer";
import { chunker } from "../../helpers";
import { Tooltip } from "@material-ui/core";
import { ListPanel } from "./ListPanel";
import { TrackerDrillDown } from "../DrillDown/Tracker";

export const Footer = ({ items, title, history }) => {
  const classes = useStyles(styles);
  const rows = items.length !== 4 ? chunker(items, 3) : [items];

  const [open, setOpen] = useState(false);
  const [expandedTool, setExpandedTool] = useState(null);
  const [details, setDetails] = useState(null);
  const [data, setData] = useState({ type: "card", title, details: null });

  const onClickItem = (item) => {
    if (item.type === "list") {
      if (!item.tooltip.length) return null;

      setOpen(true);
      return setExpandedTool(item.tooltip);
    }
    if (item.type === "details") {
      setData({ ...data, details: { ...item } });
      return setDetails(item.detailsId);
    }
    if (item.type === "redirect") {
      return history.push(item.to);
    }
    if (item.type === "info") {
      return history.push(item.to);
    }
  };

  return (
    <div className={classes.root}>
      {rows.map((row, i) => (
        <div key={i} className={classes.row}>
          {row.map((item) => (
            <CustomTool
              type={item.type}
              tooltip={item.tooltip}
              key={item.label}
            >
              <div
                className={`${classes.item} ${
                  item.tooltip && item.type !== "info" ? classes.pointer : ""
                } `}
                onClick={() => onClickItem(item)}
              >
                <div className={classes.value}>{item.value}</div>
                <div className={classes.label}>{item.label}</div>
              </div>
            </CustomTool>
          ))}
        </div>
      ))}
      <ListPanel
        open={open}
        onClose={() => setOpen(false)}
        items={expandedTool}
      />

      <TrackerDrillDown
        keyValue={details}
        info={data}
        onClose={() => setDetails(null)}
        open={Boolean(details)}
      />
    </div>
  );
};

const getTitle = (tooltip) => {
  if (tooltip.length < 4) return tooltip.map((x) => <div key={x}>{x}</div>);

  return tooltip
    .filter((_, i) => i < 3)
    .map((x) => <div key={x}>{x}</div>)
    .concat(<div key={"more"}>Ver mais...</div>);
};

const CustomTool = ({ children, type, tooltip }) => {
  if (!tooltip || (Array.isArray(tooltip) && !tooltip.length)) return children;
  switch (type) {
    case "list":
      return getListTooltip(tooltip, children);
    case "details":
      return getDetailsToolTip(tooltip, children);
    case "redirect":
      return getRedirectTooltip(tooltip, children);
    case "info":
      return getInfoTooltip(tooltip, children);
    default:
      return children;
  }
};

const getDetailsToolTip = (tooltip, children) => {
  if (!tooltip) return children;
  return (
    <Tooltip title={tooltip} placement="top">
      {children}
    </Tooltip>
  );
};

const getListTooltip = (tooltip, children) => {
  return (
    <Tooltip title={getTitle(tooltip)} placement="top">
      {children}
    </Tooltip>
  );
};

const getRedirectTooltip = (tooltip, children) => {
  return (
    <Tooltip title={tooltip} placement="top">
      {children}
    </Tooltip>
  );
};

const getInfoTooltip = (tooltip, children) => {
  return (
    <Tooltip title={tooltip} placement="top">
      {children}
    </Tooltip>
  );
};

export default Footer;
