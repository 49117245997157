import React, { useContext } from "react";
import { useStyles } from "../../hooks";
import { context } from "../../components/Navbar/context";
import clsx from "clsx";

import styles from "./jss/CustomWrapper";

const FunctionComponent = (props) => {
  const {
    children,
    withNavBar = true,
    withNavTabs = false,
    withNavFilters = false,
    withShadowTop = true,
    withPanel = true,
  } = props;
  const { variant, expanded } = useContext(context);
  const classes = useStyles(styles, props);

  const rootStyle = clsx({
    [classes.root]: true,
    [classes.withNavBar]: withNavBar,
    [classes.withNavBarTabs]: withNavBar && withNavTabs,
    [classes.withNavBarFilters]: withNavBar && withNavFilters,
    [classes.temporary]: variant === "temporary",
    [classes.permanent]: variant === "permanent",
    [classes.expanded]: variant === "permanent" && expanded,
  });
  const containerStyle = clsx({
    [classes.container]: true,
    [classes.borderTop]: !withShadowTop,
  });

  return (
    <div className={rootStyle}>
      {withShadowTop ? <div className={classes.shadowTop} /> : null}
      <div className={containerStyle}>
        {withPanel ? <div className={classes.panel}>{children}</div> : children}
      </div>
    </div>
  );
};

export const CustomWrapper = React.memo(FunctionComponent);

export default CustomWrapper;
