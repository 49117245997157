export default (theme) => ({
  permanent: {
    height: theme.navbar.height,
    backgroundColor: theme.navbar.background,
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    transition: 'margin 220ms ease',
    marginLeft: theme.drawer.expandedWidth,
    boxShadow: theme.elevation[2],
    padding: '0 10px',
  },

  temporary: {
    height: theme.navbar.height,
    backgroundColor: theme.navbar.background,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    boxShadow: theme.elevation[2],
    padding: '0 10px',
  },

  expanded: {
    marginLeft: theme.drawer.width,
  },

  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '8px 0px',
    opacity: 0.7,
    fontSize: '10px',
    width: '121px',
    '@media (max-width: 686px)': {
      display: 'none !important',
    },
  },

  logo: {
    height: '24px',
    objectFit: 'contain',
  },
});
