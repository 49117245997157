import React from 'react'

import { useStyles } from '../../../styles'
import styles from './jss/totalizers'
import { Paper } from '../../../core'

export const Totalizers = props => {
    const classes = useStyles(styles)

    const {
        exportTypes,
        totalizers,
        getExport
    } = props

    return (
        <Paper className={classes.root}>
            <div className={classes.totalizers}>
                {
                    totalizers.map(x =>
                        <div className={classes.item} key={x.title}>
                            <div className={classes.value}>
                                {x.value}
                            </div>
                            <div className={classes.title}>
                                {x.title}
                            </div>
                        </div>
                    )
                }

            </div>
            <div className={classes.iconContainer}>
                {
                    Boolean(exportTypes.length) &&
                    exportTypes.map(x =>
                        <img
                            key={x.type}
                            src={x.image}
                            alt='logo'
                            className={classes.icon}
                            onClick={() => getExport(x.type)}
                        />
                    )
                }
            </div>

        </Paper>
    )
}