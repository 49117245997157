import { toRgba, getColor } from '../../../utils/helpers'
export default theme => ({
    root: {
        transition: 'color 300ms ease',
        borderRadius: '50%',
        border: 'none',
        outline: 'none',
        backgroundColor: 'transparent',
        position: 'relative',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
    },

    grow: ({ size, color, rippleColor }) => ({
        position: 'absolute',
        height: Math.round(size / 5),
        width: Math.round(size / 5),
        top: `calc(50% - ${Math.round(size / 5) / 2})`,
        left: `calc(50% - ${Math.round(size / 5) / 2})`,
        borderRadius: '50%',
        backgroundColor: rippleColor ? toRgba(rippleColor, .4) : toRgba(getColor(color, theme), .4),
        transform: 'scale(0)',
        zIndex: -1,
        padding: 1
    }),

    animateGrow: {
        animation: '$growIcon 60ms',
        animationTimingFunction: 'cubic-bezier(.55, .085, .68, .53)',
        transform: 'scale(7)',
    },

    hideGrow: {
        transform: 'scale(0)',
        transition: 'transform 60ms cubic-bezier(.55, .085, .68, .53)',
    },


    primary: { color: theme.palette.primary.main },
    success: { color: theme.palette.success.main },
    accent: { color: theme.palette.accent.main },
    warning: { color: theme.palette.warning.main },
    danger: { color: theme.palette.danger.main },

    disabled: {
        color: theme.palette.default.dark,
        pointerEvents: 'none'
    },

    '@keyframes growIcon': {
        from: {
            transform: 'scale(0)'
        },
        to: {
            transform: 'scale(6)'
        }
    }

})