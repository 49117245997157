import React, { useRef, useState, useCallback, useEffect } from 'react';

import intl from 'react-intl-universal';
import clsx from 'clsx';
import debounce from 'lodash.debounce';
import { uniqueId } from '../../helpers';
import {
  FaCaretUp,
  FaCaretDown,
  FaFilter,
  FaTimesCircle,
} from 'react-icons/fa';
import { Tooltip, IconButton } from '@material-ui/core';
import {
  CustomTitle,
  CustomCloseListener,
  CustomFilterOptions,
  CustomGroupedOptions,
} from '..';
import { useStyles } from '../../hooks';
import styles from './jss/CustomAutoCompleteComboBox';

const FunctionComponent = (props) => {
  const {
    title = '',
    items = [],
    setItems = () => [],
    filtersRef = { current: [] },
    inputPlaceholder = null,
    feminine = false,
    required = false,
    focused = true,
    multiSelect = false,
    loading = false,
    disabled = false,
    inputTextRef = { current: '' },
    eraseAfterSearch = false,
  } = props;

  const comboBoxIdRef = useRef(uniqueId('ComboBox-'));
  const filterContainerRef = useRef(null);
  const caretContainerRef = useRef(null);
  const itemsRef = useRef([]);
  const staterItemsRef = useRef([]);
  const inputRef = useRef(null);

  const [inputText, setInputText] = useState(() => inputTextRef.current);

  const [showOptions, setShowOptions] = useState(() => false);
  const [showFilters, setShowFilters] = useState(() => false);
  const classes = useStyles(styles, props);

  itemsRef.current = items.filter(({ listed }) => listed);

  if (
    !staterItemsRef.current.length ||
    staterItemsRef.current.length !== itemsRef.current.length
  )
    staterItemsRef.current = itemsRef.current;

  const rootStyle = clsx({
    [classes.root]: true,
    [classes.font]: true,
    [classes.disabled]: disabled,
  });
  const containerStyle = clsx({
    [classes.container]: true,
    [classes.focusedContainer]: showOptions,
    [classes.responsiveContainer]: !showFilters,
  });
  const filterContainerStyle = clsx({
    [classes.filterContainer]: true,
    [classes.focusedFilterContainer]: showFilters,
  });
  const filterStyle = clsx({
    [classes.filter]: true,
    [classes.focusedFilter]: showFilters,
  });

  const inputStyle = clsx({
    [classes.input]: true,
    [classes.hover]: !focused,
    [classes.focusedInput]: true,
    [classes.focusedVisibleInput]: true,
  });

  useEffect(() => {
    if (focused) inputRef.current.focus();
  }, [focused]);

  const renderText = () => {
    const selectedItems = itemsRef.current.filter(({ active }) => active);

    if (selectedItems.length === 1 && selectedItems[0]) {
      return selectedItems[0].label;
    } else if (!selectedItems.length || !itemsRef.current.length) {
      return `${
        feminine
          ? intl.get('core.CustomFilterableComboBox.noneSelectedFeminine')
          : intl.get('core.CustomFilterableComboBox.noneSelected')
      }`;
    } else if (selectedItems.length === itemsRef.current.length) {
      return `${
        feminine
          ? intl.get('core.CustomFilterableComboBox.allSelectedFeminine')
          : intl.get('core.CustomFilterableComboBox.allSelected')
      }`;
    } else {
      return feminine
        ? `${selectedItems.length} ${intl.get(
            'core.CustomFilterableComboBox.selectedFeminine'
          )}`
        : `${selectedItems.length} ${intl.get(
            'core.CustomFilterableComboBox.selected'
          )}`;
    }
  };

  const debouncedCallback = debounce(
    (value) => inputFilterDebounce(value),
    100
  );

  const inputFilterDebounce = (value) => {
    itemsRef.current = items.map((item) => {
      const match =
        (typeof item.label === 'string' &&
          item.label.toLowerCase().includes(value.toLowerCase())) ||
        (item.details &&
          typeof item.details === 'string' &&
          item.details.toLowerCase().includes(value.toLowerCase()));

      return {
        ...item,
        hidden: !match,
        active:
          !match || (eraseAfterSearch && value.length === 1)
            ? false
            : item.active,
      };
    });

    setItems(itemsRef.current);
  };

  const onChangeText = (e) => {
    inputTextRef.current = e.target.value;
    setInputText(inputTextRef.current);
    let value = e.target.value;
    if (value.length) setShowOptions(true);

    debouncedCallback(value);
  };

  const onClearItems = () => {
    itemsRef.current = staterItemsRef.current.map((item) => ({
      ...item,
      active: false,
    }));

    setItems(itemsRef.current);
    inputTextRef.current = '';
    setInputText(inputTextRef.current);
  };

  const toggleOptions = () => {
    if (showOptions && showFilters) setShowFilters(false);
    setShowOptions(!showOptions);
  };

  const toggleFilters = () => {
    if (!showOptions) setShowOptions(true);
    setShowFilters((showFilters) => !showFilters);
  };

  const closeOptionsAndFilters = () => {
    setShowFilters(false);
    setShowOptions(false);
  };

  const handleClick = useCallback(
    ({ id, active }) => {
      let updatedItemsRef = [];
      let updatedItems = [];

      items.forEach((item) => {
        const updatedItem = {
          ...item,
          active: item.id === id && (required || !active),
        };

        if (item.listed) updatedItemsRef.push(updatedItem);

        updatedItems.push(updatedItem);
      });

      itemsRef.current = updatedItemsRef;
      setItems(updatedItems);
    },
    [items]
  );

  const handleClickMultiSelect = useCallback(
    ({ id, active }) => {
      const totalSelectedItems = itemsRef.current.filter(
        ({ active }) => active
      ).length;
      let updatedItemsRef = [];
      let updatedItems = [];

      items.forEach((item) => {
        const updatedItem = {
          ...item,
          active: item.hidden
            ? false
            : item.id === id
            ? (required && totalSelectedItems === 1) || !active
            : item.active,
        };

        if (item.listed) updatedItemsRef.push(updatedItem);

        updatedItems.push(updatedItem);
      });

      itemsRef.current = updatedItemsRef;
      setItems(updatedItems);
    },
    [items]
  );

  const toggleAll = useCallback(
    ({ active }) => {
      const firstListedId = itemsRef.current[0].id;
      let updatedItemsRef = [];
      let updatedItems = [];

      items.forEach((item) => {
        const updatedItem = {
          ...item,
          active: item.hidden
            ? false
            : required && item.id === firstListedId && active
            ? true
            : item.listed && !active,
        };

        if (item.listed) updatedItemsRef.push(updatedItem);

        updatedItems.push(updatedItem);
      });

      itemsRef.current = updatedItemsRef;
      setItems(updatedItems);
    },
    [items]
  );

  const handleClickMultiSelectFilter = useCallback(
    ({ id, active }) => {
      filtersRef.current = filtersRef.current.map((filterItem) => ({
        ...filterItem,
        active: filterItem.id === id ? !active : filterItem.active,
      }));

      const inactiveFilters = filtersRef.current.filter(
        ({ filterParameter, active }) => filterParameter && !active
      );
      let someActive = false;
      let firstListedIndex = null;
      let updatedItemsRef = [];
      let updatedItems = [];

      items.forEach((item, index) => {
        const listed = !inactiveFilters.some(
          ({ filterParameter, filterValue }) =>
            item[filterParameter] === filterValue
        );

        const updatedItem = {
          ...item,
          listed,
          active: listed && item.active,
        };

        someActive = someActive || (listed && item.active);

        if (listed && firstListedIndex === null) {
          firstListedIndex = index;
        }

        if (listed) updatedItemsRef.push(updatedItem);

        updatedItems.push(updatedItem);
      });

      if (required && !someActive && updatedItemsRef.length) {
        updatedItemsRef[0].active = true;
        updatedItems[firstListedIndex].active = true;
      }

      itemsRef.current = updatedItemsRef;

      setItems(updatedItems);
    },
    [filtersRef, items, required, setItems]
  );

  return (
    <div className={rootStyle}>
      {title && (
        <CustomTitle
          classes={{
            container: classes.title,
          }}
          title={title}
        />
      )}
      <label htmlFor={comboBoxIdRef.current} className={containerStyle}>
        <div
          id={comboBoxIdRef.current}
          className={classes.labelContainer}
          onClick={toggleOptions}
        >
          {showFilters ? (
            <div className={classes.labelSelectedFilterContainer}>
              <span className={classes.labelFilter}>
                {intl.get('core.CustomFilterableComboBox.filters')}
              </span>
            </div>
          ) : (
            <div className={classes.labelSelectedContainer}>
              <div className={classes.labelSelected}>
                <span className={classes.labelSelectedText}>
                  {renderText()}
                </span>
                {!required && itemsRef.current.find(({ active }) => active) && (
                  <Tooltip
                    title={intl.get('core.CustomFilterableComboBox.clear')}
                    placement="top"
                  >
                    <IconButton className={filterStyle} onClick={onClearItems}>
                      <FaTimesCircle />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
              <div className={classes.labelInput}>
                <input
                  value={inputText}
                  className={inputStyle}
                  placeholder={
                    inputPlaceholder
                      ? inputPlaceholder
                      : intl.get(
                          'core.CustomFilterableComboBox.inputPlaceholder'
                        )
                  }
                  onChange={onChangeText}
                  ref={inputRef}
                  type="search"
                />
                {filtersRef.current && filtersRef.current.length > 0 && (
                  <div
                    ref={filterContainerRef}
                    className={filterContainerStyle}
                    onClick={toggleFilters}
                  >
                    <Tooltip
                      title={intl.get('core.CustomFilterableComboBox.filters')}
                      placement="top"
                    >
                      <IconButton className={filterStyle}>
                        <FaFilter />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
                <div
                  ref={caretContainerRef}
                  className={classes.caretContainer}
                  onClick={toggleOptions}
                >
                  {showOptions ? <FaCaretUp /> : <FaCaretDown />}
                </div>
              </div>
            </div>
          )}
          {showFilters && (
            <>
              <div
                ref={filterContainerRef}
                className={filterContainerStyle}
                onClick={toggleFilters}
              >
                <Tooltip
                  title={intl.get('core.CustomFilterableComboBox.filters')}
                  placement="top"
                >
                  <IconButton className={filterStyle}>
                    <FaFilter />
                  </IconButton>
                </Tooltip>
              </div>
              <div
                className={classes.caretContainer}
                onClick={toggleOptions}
                ref={caretContainerRef}
              >
                {showOptions ? <FaCaretUp /> : <FaCaretDown />}
              </div>
            </>
          )}
        </div>
      </label>
      {showOptions && (
        <CustomCloseListener
          ignoreRefs={
            filtersRef.current && filtersRef.current.length > 0
              ? [filterContainerRef, caretContainerRef]
              : [caretContainerRef]
          }
          onClose={closeOptionsAndFilters}
        >
          {showFilters ? (
            <CustomFilterOptions
              items={filtersRef.current}
              handleClick={handleClickMultiSelectFilter}
              loading={loading}
            />
          ) : (
            <CustomGroupedOptions
              items={itemsRef.current.filter((item) => !item.hidden)}
              handleClick={multiSelect ? handleClickMultiSelect : handleClick}
              toggleAll={multiSelect ? toggleAll : null}
              feminine={feminine}
              loading={loading}
            />
          )}
        </CustomCloseListener>
      )}
    </div>
  );
};

export const CustomAutoCompleteComboBox = React.memo(FunctionComponent);

export default CustomAutoCompleteComboBox;
