import intl from 'react-intl-universal';
import { get } from '../api';

export const reverseGeocode = async (
  { lat, lng, origin = null },
  token,
  dispatch
) => {
  return new Promise((resolve, reject) => {
    get(
      'location.geocode',
      {
        latitude: lat,
        longitude: lng,
        origin,
      },
      token,
      dispatch
    )
      .then(({ location }) => resolve(location))
      .catch(() => reject(intl.get('monitoring.macros.detail.errorAddress')));
  });
};

export default reverseGeocode;
