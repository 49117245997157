export default theme => ({
    button: {
        marginLeft: 20,
        border: 'none',
        backgroundColor: 'transparent'
    },

    icon: {
        color: theme.navbar.iconColor,
        marginTop: 5,
        cursor: 'pointer'
    }
})