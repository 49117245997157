import React from "react";

import validate from "../../utils/Validation";

import { makeStyles } from "@material-ui/core";
import styles from "./styles";

import Input from "../Input/Input";

const useStyles = makeStyles(styles);

const FormFactory = ({ describer, setter }) => {
  const classes = useStyles();

  const onSimpleChange = (e) => {
    const {
      target: { name, value },
    } = e;
    return setter({
      ...describer,
      [name]: {
        ...describer[name],
        value,
        valid: validate(
          value,
          describer[name].rules,
          checkEquals(describer[name].rules)
        ),
      },
    });
  };

  const checkEquals = (rules) => {
    if (!rules) return null;
    if (!rules.hasOwnProperty("equalsTo")) return null;

    return describer[rules.equalsTo].value;
  };

  const renderInputs = () => {
    const inputs = [];
    Object.keys(describer).forEach((key) => {
      const input = describer[key];
      switch (input.variant) {
        case "simple":
          inputs.push(<Input key={key} {...input} onChange={onSimpleChange} />);
          break;

        default:
          break;
      }
    });

    return inputs;
  };

  return <div className={classes.root}>{renderInputs()}</div>;
};

export default FormFactory;
