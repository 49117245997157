import React, { useContext } from 'react';
import intl from 'react-intl-universal';

import { context } from '../../components/Navbar/context';
import { NavLink as Link } from 'react-router-dom';

import { useStyles } from '..';
import styles from './jss/temporary';

import { Header, List } from '.';
import Overlay from '../Overlay/Overlay';

export const Temporary = ({ items }) => {
  const classes = useStyles(styles);
  const drawer = useContext(context);
  const { expanded, setExpanded } = drawer;

  if (!expanded) return null;

  const headerProps = {
    expanded,
  };

  const listProps = {
    items: items.map((item1) => {
      if (item1.itens) {
        return {
          ...item1,
          itens: item1.itens.map((item2) => ({ ...item2, apelido: intl.get(`navbar.menu.nickname.${item2.id}`) })),
          apelido: intl.get(`navbar.menu.nickname.${item1.id}`),
        };
      }

      return {
        ...item1,
        apelido: intl.get(`navbar.menu.nickname.${item1.id}`),
      };
    }),
    expanded,
    setExpanded,
    wrapper: Link,
  };

  const cancelBubbling = (e) => e.stopPropagation();

  return (
    <Overlay onOverlayClick={() => setExpanded(false)}>
      <div className={classes.root} onClick={cancelBubbling}>
        <div className={classes.inner}>
          <Header {...headerProps} />
          <List {...listProps} />
        </div>
      </div>
    </Overlay>
  );
};
