export const styles = () => ({
    '@media (max-width: 290px)': {
        myText: {
            marginLeft: "auto",
            marginRight: "auto",
            paddingLeft: 10,
        }
    },
    '@media (min-width: 300px)': {
        myText: {
            marginLeft: "auto",
            marginRight: "auto",
            paddingLeft: 20,
        }
    },
    })
    
    export default styles;