import React from 'react';

import { useStyles } from '../../';
import styles from './jss/shallowChild';
import clsx from 'clsx';

export const ShallowChild = ({ children: item, location, wrapper: Wrapper, setExpanded }) => {
  const classes = useStyles(styles);
  const { apelido, path } = item;
  const isActive = () => {
    const { pathname } = location;
    if (path) return path === pathname.slice(0, path.length);
  };

  const active = isActive();

  const rootStyle = clsx({
    [classes.root]: true,
    [classes.active]: active,
  });

  return (
    <Wrapper to={path} onClick={() => setExpanded(false)} className={classes.wrapper}>
      <div className={rootStyle}>
        <div className={classes.label}>{apelido}</div>
      </div>
    </Wrapper>
  );
};

const DefaultWrapper = ({ children }) => <>{children}</>;
ShallowChild.defaultProps = {
  wrapper: DefaultWrapper,
};
export default ShallowChild;
