import { TrackerTypes as types } from '../types'

const initialState = {
    vehicles: [],
    fences: [],
    forced: false,
    lastFetch: null
}

export const TrackerReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.STORE_TRACKER: return storeTracker(state, action)
        case types.STORE_FENCES: return storeFences(state, action)
        default: return state
    }
}

let fix = (process.env.REACT_APP_FIX_TIME) ? parseInt(process.env.REACT_APP_FIX_TIME) : 0

const storeTracker = (state, action) => ({
    ...state,
    vehicles: action.data,
    forced: action.forced,
    lastFetch: Date.now() + fix
})


const storeFences = (state, action) => ({
    ...state,
    fences: action.data,
    lastFetch: Date.now() + fix
})