const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    alignItems: "flex-end",
    justifyContent: "space-between",
    boxSizing: "border-box",
    height: "44px",
    minHeight:
      theme.dimensions.customHeight.small -
      2 * theme.dimensions.customBorder.medium,
    padding: "2px",
    cursor: "pointer",
  },
  font: {
    fontFamily: theme.typography.standard.fontFamily,
    fontSize: theme.typography.standard.fontSize,
    fontWeight: theme.typography.standard.fontWeightBold,
    letterSpacing: theme.typography.standard.letterSpacing,
    color: theme.palette.text.primary,
  },
  hover: {
    "&:hover": {
      backgroundColor: theme.palette.grey["300"],
    },
  },
  container: {
    fontSize: "0.95em",
    overflow: "hidden",
    position: "relative",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  labelTitle: {
    position: "absolute",
    whiteSpace: "nowrap",
    padding: "8px 2px",
    fontSize: 10,
    fontWeight: 600,
    top: -8,
    left: 5,
    "@media (max-width: 520px)": {
      display: "none",
    }
  },
  label: {},
  details: {
    color: theme.palette.text.secondary,
  },
  icon: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: theme.dimensions.customMargin.small,
  },
});

export default styles;
