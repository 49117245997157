/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { Dialog } from "../../core";

import styles from "./jss/notificationAlert";
import { useStyles } from "../../styles";
import NotificationPage from "./NotificationPage";
import classnames from "classnames";

const NotificationAlert = ({ history, onClose, open, intl }) => {
  const { notification } = open;
  const [notifications, setNotifications] = useState([notification]);
  const [indexNotification, setIndex] = useState(notifications.length);

  const classes = useStyles(styles);

  useEffect(() => {
    if (notification !== notifications[indexNotification - 1]) {
      notifications.push(notification);
      setNotifications(notifications);
    }
    if (notifications) setIndex(notifications.length);
  }, [notification]);

  const dialogStyle = classnames({
    [classes.dialog]: true,
  });

  const renderData = () => {
    return (
      <div
        open={open}
        classes={{ paper: dialogStyle }}
        style={{ width: "100%" }}
      >
        <NotificationPage
          notifications={notifications}
          history={history}
          onClose={onClose}
          indexNotification={indexNotification}
          intl={intl}
        />
      </div>
    );
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} className={classes.root}>
        {renderData()}
      </Dialog>
    </>
  );
};

export default NotificationAlert;
