import ExcelJS from 'exceljs';

export const generateXlsFile = async ({ data, fileName, userName }) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(userName.toUpperCase());

  worksheet.columns = data.columns;
  data.rows.forEach((row) => worksheet.addRow(row));

  worksheet.columns.forEach((column) => {
    column.width = column.header.length < 12 ? 20 : column.header.length + 10;
  });

  worksheet.eachRow((row, rowNumber) => {
    row.eachCell((cell, colNumber) => {
      if (rowNumber === 1) {
        cell.font = { bold: true };
      }
    });
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${fileName}.xlsx`;
  link.click();
};
