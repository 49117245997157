import { DtcTypes as types } from '../types'

const initialState = {
    dtcs: [],
    loading: false,
    lastFetch: null,
    lastFilter: null
}

export const DtcReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_DTCS: return fetchDtcs(state, action)

        case types.LOADING_DTC: return { ...state, loading: true }

        default: return state
    }
}

const fetchDtcs = (state, action) => {
    let fix = (process.env.REACT_APP_FIX_TIME) ? parseInt(process.env.REACT_APP_FIX_TIME) : 0

    return{
        ...state,
        dtcs: action.data,
        loading: false,
        lastFetch: Date.now() + fix,
        lastFilter: JSON.stringify(action.filter)
    }
}