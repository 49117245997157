export default (theme) => ({
    root: {
      backgroundColor: "white",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: "5px",
      padding: "15px",
      zIndex: "99999",
      overflow: "auto",
      minWidth: "450px",
      maxWidth: "450px",
      minHeight: "50vh",
      "@media(max-width: 693px)": {
        minWidth: "65%",
      },
    },
  });
  
  