import intl from 'react-intl-universal';
import { format } from 'date-fns';

const decimalToString = (value, toFixed = 4, suffix = '') => {
  if (!value && value !== 0) return intl.get(`global.notAvailable`);
  return (
    Number(value.toFixed(toFixed)).toLocaleString(intl.get('global.toLocaleString'), {
      minimumFractionDigits: toFixed,
      maximumFractionDigits: toFixed,
    }) + `${suffix !== '' ? ' ' + suffix : suffix}`
  );
};

const nullNumber = (value, suffix = '') => {
  if (!value && value !== 0) return intl.get(`global.notAvailable`);

  return `${Number(value).toLocaleString(intl.get('global.toLocaleString'))} ${suffix !== '' ? ' ' + suffix : suffix}`;
};

const nullValue = (value) => {
  if (!value && value !== '') return intl.get(`global.notAvailable`);

  return value;
};

const nullString = (value) => value || intl.get(`global.notAvailable`);

const nullDateTime = (value, intlFormat = 'global.dateTime') =>
  value ? format(new Date(value), intl.get(intlFormat)) : intl.get(`global.notAvailable`);

const vehicleMotionStatus = (status) => intl.get(`global.vehicleMotionStatus.${status}`);

const compareEngineHoursIdleAndMovement = (
  compareEngineHours,
  translationInMotion = `module.Performance.compareEngineHoursIdleAndMovementTotalEngineHoursMotion`,
  translationIdle = `module.Performance.compareEngineHoursIdleAndMovementTotalEngineHoursIdle`
) => {
  return compareEngineHours
    ? [
        {
          name: intl.get(translationInMotion),
          value: compareEngineHours.totalEngineHoursMotion
            ? compareEngineHours.totalEngineHoursMotion.toFixed(2)
            : intl.get(`global.notAvailable`),
        },
        {
          name: intl.get(translationIdle),
          value: compareEngineHours.totalEngineHoursIdle
            ? compareEngineHours.totalEngineHoursIdle.toFixed(2)
            : intl.get(`global.notAvailable`),
        },
      ]
    : [];
};
const band = (value) => ({
  initialTorqueBand: value && value.initialTorqueBand ? value.initialTorqueBand : 1200,
  economicTorqueBand: value && value.economicTorqueBand ? value.economicTorqueBand : 0,
  finalTorqueBand: value && value.finalTorqueBand ? value.finalTorqueBand : 1600,
  initialHorsepowerBand: value && value.initialHorsepowerBand ? value.initialHorsepowerBand : 1601,
  economicHorsePowerBand: value && value.economicHorsePowerBand ? value.economicHorsePowerBand : 0,
  finalHorsepowerBand: value && value.finalHorsepowerBand ? value.finalHorsepowerBand : 2200,
  riskBand: value && value.riskBand ? value.riskBand : 3000,
});

const overallAveragePerVehicle = (value) => {
  if (value)
    return value.map((data) => ({
      vehicle: nullValue(data.vehicle),
      averageRPM: decimalToString(data.averageRPM, 2),
      averageSpeed: decimalToString(data.averageSpeed, 2),
      maximumSpeed: decimalToString(data.maximumSpeed, 2),
      consumption: decimalToString(data.consumption, 2),
      consumptionMotion: decimalToString(data.consumptionMotion, 2),
    }));
  else return [];
};

const scorePerVehicles = (value) => ({
  vehicle: value.vehicle.map((vehicle) => nullValue(vehicle)),
  score: value.score.map((score) => (score !== null ? score.toFixed(2) : 0)),
});

const totalConsumptionPerDay = (value) => ({
  referenceDayInClientUTC:
    value && value.referenceDayInClientUTC
      ? value.referenceDayInClientUTC.map((date) => format(new Date(Date.parse(date)), 'dd/MM/yyyy'))
      : intl.get(`global.notAvailable`),
  totalConsumptionLts: value.totalConsumptionLts,
});

const totalConsumptionPerVehicle = (value) => {
  if (value)
    return value.map((data) => ({
      vehicle: nullValue(data.vehicle),
      totalConsumptionLts: decimalToString(data.totalConsumptionLts, 1),
      tableData: data.tableData,
      totalConsumptionMotion: data.totalConsumptionMotion,
      totalDistanceKm: data.totalDistanceKm,
      totalConsumptionIdle: data.totalConsumptionIdle,
    }));
  else return [];
};

const totalAlertPerVehicle = (value) => {
  if (value)
    return value.map((data) => ({
      vehicleId: nullValue(data.vehicleId),
      vehicleDescription: nullValue(data.vehicleDescription),
      licensePlate: nullValue(data.licensePlate),
      totalAlerts: decimalToString(data.totalAlerts, 0),
      tableData: data.tableData,
    }));
  else return [];
};
const totalOccurrencePerAlert = (
  label,
  total,
  weight,
  otherAlerts,
  alertsFilterRef,
  translationPath = 'module.Performance.totalOccurrencePerAlertItems'
) => {
  const filteredAlerts = alertsFilterRef.current.filter(({ active }) => active).map(({ param }) => param);
  label.forEach((event, index) => {
    if (!filteredAlerts.includes(event)) {
      label[index] = null;
      total[index] = null;
      weight[index] = null;
    }
  });
  label = label.filter((event) => event !== null);
  total = total.filter((event) => event !== null);
  if (weight) weight = weight.filter((event) => event !== null);

  otherAlerts.label.forEach((event, index) => {
    if (!filteredAlerts.includes(event)) {
      otherAlerts.label[index] = null;
      otherAlerts.total[index] = null;
    }
  });
  otherAlerts.label = otherAlerts.label.filter((event) => event !== null);
  otherAlerts.total = otherAlerts.total.filter((event) => event !== null);

  return {
    alerts: {
      labelKey: label && label.length > 0 ? label : intl.get(`global.notAvailable`),
      label:
        label && label.length > 0
          ? label.map((event) => intl.get(`${translationPath}.${event}`))
          : intl.get(`global.notAvailable`),
      total: total && total.length > 0 ? total : intl.get(`global.notAvailable`),
      weight: weight && weight.length > 0 ? weight : intl.get(`global.notAvailable`),
    },
    otherAlerts: {
      label:
        otherAlerts && otherAlerts.label && otherAlerts.label.length > 0
          ? otherAlerts.label.map((event) => intl.get(`${translationPath}.${event}`))
          : intl.get(`global.notAvailable`),
      total:
        otherAlerts && otherAlerts.total && otherAlerts.total.length > 0
          ? otherAlerts.total
          : intl.get(`global.notAvailable`),
    },
  };
};

const totalAlertsPerKm = (value) => ({
  label: decimalToString(value, 4),
  color:
    !value && value !== 0 ? 'secondary' : value === 0 ? 'success' : value > 0 && value <= 0.15 ? 'warning' : 'danger',
});

const alertsRankingPerKm = (value) => ({
  label:
    !value && value !== 0
      ? intl.get('global.notAvailable')
      : value === 0
      ? intl.get('module.Performance.alertsRankingPerKm.good')
      : value > 0 && value <= 0.15
      ? intl.get('module.Performance.alertsRankingPerKm.moderate')
      : intl.get('module.Performance.alertsRankingPerKm.aggressive'),
  color:
    !value && value !== 0 ? 'secondary' : value === 0 ? 'success' : value > 0 && value <= 0.15 ? 'warning' : 'danger',
});

const averageConsumption = (totalConsumption, totalDistanceTraveled) => {
  if (parseFloat(totalConsumption) <= 0) return intl.get('global.notAvailable');
  return decimalToString(totalDistanceTraveled / totalConsumption, 2, 'km/L');
};

const scorePerDay = (value) => {
  return {
    days: value.days.map((dateTime) => format(new Date(dateTime), 'dd/MM')),
    score: value.score.map((score) => decimalToString(score, 1)),
  };
};

export {
  alertsRankingPerKm,
  averageConsumption,
  band,
  compareEngineHoursIdleAndMovement,
  decimalToString,
  overallAveragePerVehicle,
  scorePerDay,
  scorePerVehicles,
  totalAlertsPerKm,
  totalAlertPerVehicle,
  totalConsumptionPerDay,
  totalConsumptionPerVehicle,
  totalOccurrencePerAlert,
  nullDateTime,
  nullNumber,
  nullString,
  nullValue,
  vehicleMotionStatus,
};
