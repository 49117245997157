import React from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { useStyles } from "..";
import styles from "./jss/displayer";
import { Tooltip } from "@material-ui/core";
import { Header } from "./Header";
import { Footer } from "./Footer";

export const Displayer = withRouter(
  ({
    history,
    title,
    content,
    label = false,
    info = false,
    footer,
    onRemoveCard,
    redirect,
    redirectContent,
    tooltipType,
    isLoading,
  }) => {
    const classes = useStyles(styles);
    const vehicles = useSelector((s) => s.searchbar.filters.vehicles.length);

    const onRedirect = () => {
      if (redirect) history.push(redirect);
    };

    const handleContentClick = () => {
      if (redirectContent) history.push(redirectContent);
    };

    const loading = isLoading;

    return (
      <div className={classes.root}>
        <Header
          title={title}
          onRemoveCard={onRemoveCard}
          redirect={redirect}
          onRedirect={onRedirect}
        />
        {!loading ? (
          <>
            <div className={classes.body}>
              <Tooltip title={info ? info : ""} placement="top">
                <div
                  className={`${classes.content} ${
                    redirectContent ? classes.pointer : ""
                  }`}
                  onClick={handleContentClick}
                >
                  <>
                    {content}
                    {label && <span className={classes.span}>{label}</span>}
                  </>
                </div>
              </Tooltip>
            </div>
            {vehicles > 1 && (
              <Footer
                items={footer}
                title={title}
                tooltipType={tooltipType}
                history={history}
              />
            )}
          </>
        ) : (
          <div className={classes.loader}>
            <div className={classes.average}></div>
            <div className={classes.footer}>
              <div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
);

Displayer.defaultProps = {
  label: "",
};

export default Displayer;
