import React from 'react'

import { useStyles } from '../../../styles'
import styles from './jss/shallowParent'
import clsx from 'clsx'

import { icons } from './icons'

const ShallowParentComp = ({ item, expanded, setExpanded, location, wrapper: Wrapper }) => {
    const classes = useStyles(styles);
    const isActive = () => {
      const { pathname } = location;
      const { path } = item;
      return `/${pathname.split("/")[1]}` === path;
    };
    const active = isActive();
    const rootStyle = clsx({
      [classes.root]: true,
      [classes.active]: active,
    });
    const iconStyle = clsx({
      [classes.icon]: true,
      [classes.iconActive]: active,
    });
    const labelStyle = clsx({
      [classes.label]: true,
      [classes.labelExpanded]: expanded,
    });
    const { apelido, icon, path } = item;
    const Icon = icons[icon]

    return (
        <Wrapper to={path} onClick={() => setExpanded(false)} className={classes.wrapper}>
            <div className={rootStyle}>
                <Icon className={iconStyle} />
                <div className={labelStyle}>
                    {apelido}
                </div>
            </div>
        </Wrapper>
    )
}


const DefaultWrapper = ({ children }) => <>{children}</>

ShallowParentComp.defaultProps = {
    wrapper: DefaultWrapper
}

export const ShallowParent = ShallowParentComp
