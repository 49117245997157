export default theme => ({
    reader: {
        height: '86%',
        width: '80%',
        overflow: 'hidden',
        marginLeft: '6%'
    },

    header: {
        width: '90%',
        backgroundColor: '#d9dadc',
        display: 'flex',
        alignItems: 'center',
        padding: '5px 2px',
        justifyContent: 'flex-end',
        borderRadius: '5px 5px 0 0',
        '& > svg': {
            cursor: 'pointer',
            marginRight: 5,
        }
    },

    wrapper: {
        position: 'relative',
        paddingBottom: '56.25%',
        paddingTop: 25,
        height: 0
    },

    iframe: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '90%',
        height: '80%',
    }

})