import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useStyles } from '..'
import styles from './jss/header'
import { FaTimes, FaLink } from 'react-icons/fa'
import { checkPermission } from '../../utils/helpers'

export const Header = ({ title, onRemoveCard, redirect, onRedirect }) => {
    const classes = useStyles(styles)

    const [showRedirect, setShowRedirect] = useState(false)
    const auth = useSelector(s => s.auth)

    useEffect(() => {
        const { permissions } = auth
        const permission = checkPermission(permissions, 'MML0005', 'r')

        if (permission) setShowRedirect(true)
    }, [auth])

    return (
        <div className={classes.root}>
            <div className={classes.title}>
                {title}
            </div>
            <div className={classes.grow} />
            {(Boolean(redirect) && showRedirect) && <FaLink className={classes.icon} onClick={onRedirect} />}
            {/*<FaMinus className={classes.icon} />*/}
            <FaTimes className={classes.icon} onClick={() => onRemoveCard(title)} />
        </div>
    )
}

export default Header