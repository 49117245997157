import React, { useCallback, useState, useEffect, useRef } from 'react';
import { FaGlobeAmericas as Globe } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import intl from 'react-intl-universal';

import { get } from '../../api';

import { useStyles } from '../../hooks';
import styles from './jss/notification';

import NotificationPanel from './NotificationPanel';
import NotificationAlert from './NotificationAlert';
import Badge from '../../core/Badge/Badge';

const Notifications = ({ props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles(styles, props);
  const auth = useSelector((state) => state.auth);
  const limitRef = useRef(1);

  const setTimeOutRef = useRef();

  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [data, setData] = useState([]);
  const [loadMoreCards, setLoadMoreCards] = useState(false);
  const [loadNotifications, setLoadNotifications] = useState(true);

  const getUnreadNotifications = (notifications) => {
    let processNotification;
    const infoLastDateNotification = localStorage.getItem('lastNotificationDate');
    if (infoLastDateNotification) {
      let totalCount = 0;
      processNotification = notifications.map((notification) => {
        const isRead =
          new Date(notification.notificationTimestamp).getTime() > new Date(infoLastDateNotification).getTime();
        totalCount = isRead ? totalCount + 1 : totalCount;
        return {
          ...notification,
          isRead: !isRead,
        };
      });

      return { notifications: processNotification, totalCount };
    }

    if (notifications.length) localStorage.setItem('lastNotificationDate', notifications[0].notificationTimestamp);

    return { notifications, totalCount: 0 };
  };

  const getNotifications = useCallback(async () => {
    const { data } = await get(
      'notifications',
      {
        limit: 10 * limitRef.current,
        companies: auth.selectedCompanies.map(({ id }) => id),
      },
      auth.token,
      dispatch
    );

    clearTimeout(setTimeOutRef.current);
    setTimeOutRef.current = setTimeout(getNotifications, 60000 * 5);
    setLoadMoreCards(false);
    setLoadNotifications(false);
    setData(getUnreadNotifications(data.notifications));
  }, [auth.selectedCompanies, auth.token, dispatch]);

  const setReadNotifications = () => {
    setData((prev) => {
      prev.totalCount = 0;
      prev.notifications = prev.notifications.map((notification) => ({
        ...notification,
        isRead: 1,
      }));
      prev.notifications.splice(10);
      if (prev.notifications[0])
        localStorage.setItem('lastNotificationDate', prev.notifications[0].notificationTimestamp);
      return { ...prev };
    });
  };

  const handleOpen = useCallback(
    (notifications) => {
      if (open) {
        limitRef.current = 1;
        setReadNotifications();
      }
      setOpen(!open);
    },
    [open]
  );

  useEffect(() => {
    getNotifications();
    return () => clearTimeout(setTimeOutRef.current);
  }, [auth.selectedCompanies, getNotifications]);

  const handleClickLoadMoreCards = useCallback(() => {
    limitRef.current += 1;
    setLoadMoreCards(true);
    getNotifications();
  }, [getNotifications]);

  const handleCloseNotificationPainel = useCallback(() => {
    limitRef.current = 1;
    setReadNotifications();
    setOpen(false);
  }, []);

  return (
    <>
      <div className={classes.notifications} onClick={() => handleOpen(data.notifications)}>
        <Badge value={data.totalCount}>
          <Globe />
        </Badge>
      </div>
      {open && (
        <NotificationPanel
          notifications={data.notifications}
          setReadNotifications={setReadNotifications}
          // noReadNotifications={data.totalCount}
          loadNotifications={loadNotifications}
          // setData={setData}
          history={history}
          loadMoreCards={loadMoreCards}
          handleClickLoadMoreCards={handleClickLoadMoreCards}
          onClose={handleCloseNotificationPainel}
          intl={intl}
          // auth={auth}
          // dispatch={dispatch}
        />
      )}
      {openAlert && (
        <NotificationAlert
          open={openAlert}
          history={history}
          onClose={() => setOpenAlert({ openAlert: false })}
          intl={intl}
        />
      )}
    </>
  );
};

export default Notifications;
