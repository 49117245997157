import { DriverTypes as types } from "../types";

const initialState = {
  drivers: [],
  driversRFID: [],
  driverVehicles: [],
  ibuttons: [],
  schedules: [],
  awards: [],
  statistics: [],
  workloads: [],
};

export const DriverReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_DRIVERS:
      return fetchDrivers(state, action);

    case types.FETCH_DRIVER_VEHICLES:
      return fetchDriverVehicles(state, action);

    case types.FETCH_DRIVER_RFID:
      return fetchDriverRFID(state, action);

    case types.FETCH_DRIVER_IBUTTONS:
      return fetchIButtons(state, action);

    case types.FETCH_DRIVER_RFIDCARDS:
      return fetchRFIDCards(state, action);

    case types.FETCH_DRIVER_SCHEDULES:
      return fetchSchedule(state, action);

    case types.FETCH_DRIVER_AWARDS:
      return fetchAwards(state, action);

    case types.FETCH_DRIVER_STATISTICS:
      return fetchStatistics(state, action);

    case types.FETCH_WORKLOAD:
      return fecthWorkload(state, action);

    default:
      return state;
  }
};

const fetchDrivers = (state, action) => ({
  ...state,
  drivers: action.data,
});

const fetchDriverVehicles = (state, action) => ({
  ...state,
  driverVehicles: action.data,
});

const fetchDriverRFID = (state, action) => ({
  ...state,
  driversRFID: action.data,
});

const fetchIButtons = (state, action) => ({
  ...state,
  ibuttons: action.data,
});

const fetchRFIDCards = (state, action) => ({
  ...state,
  rfidcards: action.data,
});

const fetchSchedule = (state, action) => ({
  ...state,
  schedules: action.data,
});

const fetchAwards = (state, action) => ({
  ...state,
  awards: action.data,
});

const fetchStatistics = (state, action) => ({
  ...state,
  statistics: action.data,
});

const fecthWorkload = (state, action) => ({
  ...state,
  workloads: action.data,
});
