export const initialCards = [
    {
        title: 'Eventos',
        content: `0 Eventos`,
        footer: [
            { label: 'Acelerações', value: 0 },
            { label: 'Frenagens', value: 0 },
            { label: 'Colisões', value: 0 },
        ],
        variant: 'displayer',
        lastFetch: null,
        lastFilters: null,
        isLoading: true
    },
    {
        title: 'Velocidade',
        label: 'Média',
        content: `0 km/h`,
        footer: [
            { label: 'Mínima', value: `0 km/h` },
            { label: 'Máxima', value: `0 km/h` },
            { label: 'Período', value: 'N/A' },
        ],
        variant: 'displayer',
        lastFetch: null,
        lastFilters: null,
        isLoading: true
    },
    {
        title: 'Rotação',
        content: '0 RPM',
        footer: [
            { label: 'Mínima', value: `0 RPM` },
            { label: 'Média', value: `0 RPM` },
            { label: 'Máxima', value: `0 RPM` },
        ],
        variant: 'displayer',
        lastFetch: null,
        lastFilters: null,
        isLoading: true
    },
    {
        title: 'Vazão Combustível',
        label: 'Média',
        content: '0 l/h',
        footer: [
            { label: 'Mínima', value: '0 l/h' },
            { label: 'Média', value: '0 l/h' },
            { label: 'Máxima', value: '0 l/h' },
            { label: '< 1500 RPM', value: '0 l/h' },
            { label: '< 3000 RPM', value: '0 l/h' },
            { label: '< 6000 RPM', value: '0 l/h' },
        ],
        variant: 'displayer',
        lastFetch: null,
        lastFilters: null,
        isLoading: true
    },
    {
        title: 'Acionamento Acelerador',
        label: 'Média',
        content: '0 %',
        footer: [
            { label: 'Média', value: '0 %' },
            { label: 'Máxima', value: '0 %' }
        ],
        variant: 'displayer',
        lastFetch: null,
        lastFilters: null,
        isLoading: true
    },
    {
        title: 'Acionamento Pedais',
        content: `0 vezes`,
        footer: [
            { label: 'Freio', value: `0 vezes` },
            { label: 'Embreagem', value: `0 vezes` },
        ],
        variant: 'displayer',
        lastFetch: null,
        lastFilters: null,
        isLoading: true
    },
    {
        title: 'Portas',
        label: 'Travados',
        content: '0 Veículos',
        footer: [
            { label: 'Fechados', value: '0 Veículos' },
            { label: 'Travados', value: '0 Veículos' }
        ],
        variant: 'displayer',
        lastFetch: null,
        lastFilters: null,
        isLoading: true
    },
    {
        title: 'Sinalizadores Ligados',
        label: 'Ligados',
        content: `0 Piscas / 0 Setas`,
        footer: [
            { label: 'Conversão Esquerda', value: `0 km/h` },
            { label: 'Conversão Direita', value: `0 km/h` },
        ],
        variant: 'displayer',
        lastFetch: null,
        lastFilters: null,
        isLoading: true
    },
    {
        title: 'Limpadores',
        content: `0 Ligados`,
        footer: [
            { label: 'Vel. média chuva', value: `0 km/h` },
            { label: 'Vel. máxima chuva', value: `0 km/h` },
        ],
        variant: 'displayer',
        lastFetch: null,
        lastFilters: null,
        isLoading: true
    },
    {
        title: 'Tempo de uso',
        data: [],
        footer: [
            { label: 'Desligado', value: `0 horas` },
            { label: 'Ligado parado', value: `0 horas` },
            { label: 'Ligado', value: `0 horas` },
        ],
        variant: 'chart',
        lastFetch: null,
        lastFilters: null,
        isLoading: true
    },
    {
        title: 'Frota',
        data: [],
        footer: [
            { label: 'Caminhões', value: 0 },
            { label: 'Carros', value: 0 },
            { label: 'Agrícolas', value: 0 },
        ],
        variant: 'chart',
        lastFetch: null,
        lastFilters: null,
        isLoading: true
    },
    {
        title: 'Distância Percorrida',
        variant: 'chart',
        subvariant: 'bar',
        data: [],
        lineData: [],
        footer: [
            { label: 'Caminhões', value: `0 km` },
            { label: 'Carros', value: `0 km` },
            { label: 'Agrícolas', value: `0 km` },
        ],
        lastFetch: null,
        lastFilters: null,
        isLoading: true
    },
    {
        title: 'Marcha',
        variant: 'chart',
        data: [],
        footer: [
            { label: 'Lenta', value: '0 h' },
            { label: 'Engrenada', value: '0h' }
        ],
        lastFetch: null,
        lastFilters: null,
        isLoading: true
    },
    {
        title: 'Nível Combustível',
        variant: 'chart',
        data: [],
        footer: [
            { label: 'Cheio', value: '0' },
            { label: '< 75%', value: '0 ' },
            { label: '< 50%', value: '0' },
            { label: 'Reserva', value: '0' }
        ],
        lastFetch: null,
        lastFilters: null,
        isLoading: true
    },
    {
        title: 'Ar Condicionado',
        variant: 'chart',
        data: [],
        footer: [
            { label: 'Ligado', value: '0 h' },
            { label: 'Em Movimento', value: '0 h' },
            { label: 'Parado', value: '0 h' }
        ],
        lastFetch: null,
        lastFilters: null,
        isLoading: true
    },
    {
        title: 'Temperatura',
        variant: 'chart',
        data: [],
        footer: [
            { label: 'Mínima', value: `0 °C` },
            { label: 'Média', value: `0 °C` },
            { label: 'Máxima', value: `0 °C` }
        ],
        unit: '°C',
        lastFetch: null,
        lastFilters: null,
        isLoading: true
    },
    {
        title: 'Cinto de segurança',
        variant: 'chart',
        data: [],
        footer: [
            { label: 'Com cinto', value: '0 h' },
            { label: 'Sem cinto', value: '0 h' }
        ],
        lastFetch: null,
        lastFilters: null,
        isLoading: true
    },
    {
        title: 'Consumo',
        variant: 'displayer',
        value: `0 km/l`,
        label: 'Média',
        footer: [
            { label: 'Mínima', value: `0 km/l` },
            { label: 'Máxima', value: `0 km/l` }
        ],
        lastFetch: null,
        lastFilters: null,
        isLoading: true
    },
    {
        title: 'Gráfico Velocidade',
        variant: 'custom-chart',
        dataKey: 'Velocidade',
        data: [],
        lastFetch: null,
        lastFilters: null,
        isLoading: true
    },
    {
        title: 'Gráfico Rotação',
        variant: 'custom-chart',
        dataKey: 'Rotação',
        data: [],
        lastFetch: null,
        lastFilters: null,
        isLoading: true
    },
    {
        title: 'Gráfico Distância',
        variant: 'custom-chart',
        dataKey: 'Distância',
        data: [],
        lastFetch: null,
        lastFilters: null,
        isLoading: true
    },
    {
        title: 'Gráfico Vazão',
        variant: 'custom-chart',
        dataKey: 'Vazão',
        data: [],
        lastFetch: null,
        lastFilters: null,
        isLoading: true
    },
    {
        title: 'Gráfico Pedais',
        variant: 'custom-chart',
        dataKey: 'Pedais',
        data: [],
        lastFetch: null,
        lastFilters: null,
        isLoading: true
    }

]