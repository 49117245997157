import React, { useState } from 'react'

import { useStyles } from '..'
import styles from './jss/multiSelect'
import clsx from 'clsx'

import { FaCaretDown } from 'react-icons/fa'


import { uniqueId } from '../../helpers'

import { OptionPanel } from './OptionPanel'

export const Select = ({ placeholder, items, selected, onSelect, label, className }) => {
    const classes = useStyles(styles)

    const id = uniqueId('multi-')

    const [open, setOpen] = useState(false)



    const handleClick = e => {
        e.stopPropagation()
        e.preventDefault()
        setOpen(true)
    }

    const onClose = () => open ? setOpen(false) : null

    const onClickOption = option => {
        onSelect(option)
        setOpen(false)
    }


    const rootStyle = clsx({
        [classes.root]: true,
        [classes.focus]: open
    })

    const caretStyle = clsx({
        [classes.caret]: true,
        [classes.caretDown]: open
    })

    return (
        <div className={`${classes.wrapper} ${className}`} onClick={handleClick}>
            <div className={classes.label}>{label}</div>
            <label htmlFor={id} className={rootStyle}>
                <div className={classes.innerContainer}>
                    {
                        Boolean(selected) && <div>{selected.label}</div>
                    }
                    {
                        !Boolean(selected) &&
                        <div className={classes.placeholder}>
                            {placeholder}
                        </div>
                    }
                </div>
                <div className={classes.grow} />
                <FaCaretDown className={caretStyle} />
            </label>

            <OptionPanel
                open={open}
                id={id}
                items={items}
                selected={selected}
                onClickOption={onClickOption}
                onClose={onClose}
                multi={false}
            />
        </div>
    )
}

Select.defaultProps = {
    placeholder: '',
    label: '',
    items: [],
    className : ''
}

export default Select