import intl from 'react-intl-universal';
import formatDistance from 'date-fns/formatDistance';

import localeDateFns from './localeDateFns';

export const timeExtense = (time, locale = 'pt-BR') => {
  let fix = process.env.REACT_APP_FIX_TIME ? parseInt(process.env.REACT_APP_FIX_TIME) : 0;
  return intl.get('navbar.notification.lastUpdateNotificationsMsg', {
    time: formatDistance(new Date(time), new Date(Date.now() + fix), {
      locale: localeDateFns(locale),
    }),
  });
};

export default timeExtense;
