export default (theme) => ({
  wrapper: {
    position: "relative",
    width: "calc(100% - 4px)",
    margin: "0 2px",
    userSelect: "none",
  },

  root: {
    zIndex: 1200,
    minHeight: 40,
    padding: 8,
    border: `1px solid ${theme.palette.default.dark}`,
    borderRadius: "5px",
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    "&:active": {
      border: `1px solid ${theme.palette.default.text}`,
    },
    outline: "none",
    position: "relative",
    fontFamily: theme.typography.standard.fontFamily,
    fontWeight: theme.typography.standard.fontWeightBold,
    letterSpacing: theme.typography.standard.letterSpacing,
    fontSize: 14,
    color: theme.palette.default.text,
    overflow: "hidden",
    backgroundColor: "white",
  },

  innerContainer: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    marginRight: 5,
    whiteSpace: "nowrap",
  },

  spreader: {
    marginTop: 10,
  },

  tags: {
    display: "flex",
    flexDirection: "row",
    zIndex: 3500,
  },

  focus: {
    borderRadius: "5px 5px 0 0",
  },

  grow: {
    flexGrow: 1,
  },

  caret: {
    zIndex: 3600,
    transition: "transform 180ms ease",
  },

  caretDown: {
    transform: "rotate(180deg)",
  },

  input: {
    flex: 1,
    zIndex: 3500,
    height: "95%",
    width: "100%",
    border: "none",
    outline: "none",
    cursor: "pointer",
    fontSize: 14,
    fontFamily: "Roboto",
    color: theme.palette.default.text,
    "&:active": {
      border: "none",
    },
  },

  placeholder: {
    fontSize: 14,
    color: theme.palette.default.text,
  },

  label: {
    fontSize: 11,
    fontWeight: 600,
    color: theme.palette.default.text,
  },
});
