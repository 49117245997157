export default (theme) => ({
  root: {
    maxHeight: 450,
    overflowY: 'auto',
    width: 380,
    position: 'absolute',
    right: 10,
    top: 54,
    backgroundColor: 'white',
    borderRadius: '.5rem',
    boxShadow: theme.cardShadow,
    padding: 5,
    border: '1px solid #c6cbcf',
    '&::-webkit-scrollbar': {
      width: 4,
    },
    zIndex: 99999,
    '@media (max-width: 900px)': {
      width: 298,
    },
  },

  empty: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    userSelect: 'none',
    color: '#aaa',
    fontSize: 130,
    margin: '30% 0',
    '& > span': {
      fontSize: 16,
      margin: 20,
    },
  },
  noReadAll: {
    color: '#444444',
    cursor: 'pointer',
    fontSize: '0.85rem',
    userSelect: 'none',
    margin: '8px',
    alignSelf: 'end',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },

  disableNoReadAll: {
    opacity: 0.5,
    cursor: 'default',
    '&:hover': {
      color: '#444444',
    },
  },

  panelTitle: {
    padding: '8px',
    fontSize: '1.2rem',
    textAlign: 'center',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    alignItems: 'center',
    color: '#444444',
    borderBottom: '1px solid #e3e6f0',
  },
  moreNotifications: {
    padding: '16px',
    fontSize: '12px',
    textAlign: 'center',
    fontWeight: '600',
    userSelect: 'none',
    cursor: 'pointer',
    color: '#444444',

    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  moreContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div + div ': {
      borderLeft: '1px solid #c6cbcf',
    },
  },
  more: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
    width: '96%',
    height: 30,
    fontSize: 14,
    fontWeight: 'bold',
    cursor: 'pointer',
  },

  loadNotifications: {
    color: `#aaafc5`,
    fontSize: '0.44em',
    animation: '$loader 1s infinite linear',
  },

  animation: {
    color: `${theme.palette.button.dark}`,
    fontSize: '1rem',
    animation: '$loader 1s infinite linear',
  },
  '@keyframes loader': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
});
