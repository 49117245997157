import React from 'react';
import intl from 'react-intl-universal';

import { withRouter } from 'react-router-dom';
import { FaUserLock } from 'react-icons/fa';
import { Wrapper } from '../core';

import { useStyles } from '../styles';
import styles from './jss/forbidden';

export const Forbidden = withRouter(({ history }) => {
  const classes = useStyles(styles);

  const redirect = () => {
    history.push(`/`);
  };

  return (
    <Wrapper className={classes.wrapper}>
      <div className={classes.icon}>
        <FaUserLock />
      </div>
      <div className={classes.title}>{intl.get('components.Forbidden.title')}</div>
      <div className={classes.redirect} onClick={() => redirect()}>
        {intl.get('components.Forbidden.redirect')}
      </div>
    </Wrapper>
  );
});

export default Forbidden;
