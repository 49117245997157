import React from 'react';
import { useSelector } from 'react-redux';

//Styles
import { makeStyles } from '@material-ui/core';
import styles from './jss/loginReset';

//Material UI components
import { Dialog, Button } from '@material-ui/core';
import { InputLogin, Snackbar } from '../../core';

const useStyles = makeStyles(styles);

const LoginForgotDialog = ({ open, openReset, state, onChange, onSubmit, language = 'en-US' }) => {
  const classes = useStyles();

  const snackbar = useSelector((s) => s.snackbar.snackbar);

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      onSubmit();
    }
  };

  return (
    <Dialog open={open} onClose={() => openReset(false)}>
      <div className={classes.root}>
        <span>{language.recoveryPasswordTitle}</span>
        <div className={classes.paper}>
          <div className={classes.header}>
            <div>{language.recoveryPasswordSubtitle}</div>
          </div>
          <div className={classes.form}>
            <InputLogin
              type="email"
              value={state.email}
              onChange={onChange}
              name="email"
              className={classes.input}
              label="Email"
              autoFocus
            />
            <InputLogin
              type="password"
              value={state.senha}
              onChange={onChange}
              name="senha"
              className={classes.input}
              label={language.newPasswordPlaceholder}
            />
            <InputLogin
              type="password"
              value={state.confirmacao}
              onChange={onChange}
              name="confirmacao"
              className={classes.input}
              label={language.confirmNewPasswordPlaceholder}
              onKeyDown={onKeyDown}
            />
          </div>
          <div className={classes.footer}>
            <Button variant="contained" color="primary" className={classes.button} onClick={onSubmit}>
              {language.btnContinue}
            </Button>
            <Button color="primary" className={classes.button} onClick={() => openReset(false)}>
              {language.back}
            </Button>
          </div>
          <Snackbar {...snackbar} />
        </div>
      </div>
    </Dialog>
  );
};

export default LoginForgotDialog;
