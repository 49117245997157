export const createRequestHeader = (token, download = false) => {
  const headers = {
    Authorization: `bearer ${token}`,
  };
  const responseType = "arraybuffer";

  if (download) return { headers, responseType };
  else return { headers };
};

export default createRequestHeader;
