import React, { useRef } from 'react'

import { useStyles } from '..'
import styles from './jss/slider'

import { Trail } from './Trail'
import { Marker } from './Marker'
import { Path } from './Path'
import { Controlllers } from './Controllers'
import { mapValueToRange } from '../../utils/helpers'

const percent = { min: 0, max: 100 }

export const Slider = ({
    color,
    range,
    tick,
    isTickPercentage,
    value,
    onChange: _onChange,
    controllers,
    activeSpots,
    frames,
    showValue,
    setFrames
}) => {

    const classes = useStyles(styles)
    const container = useRef(null)


    const percentage = mapValueToRange(value, range, percent)
    const offset = percentage > 100 ? 100 : percentage < 0 ? 0 : percentage

    const formatedTick = isTickPercentage ? tick : mapValueToRange(tick, range, percent)

    const onChange = value => {
        if (value < range.min) return _onChange(range.min)
        if (value > range.max) return _onChange(range.max)
        _onChange(value)
    }

    return (
        <>
            <div className={classes.container}>
                <div
                    ref={container}
                    className={classes.root}
                >
                    <Trail
                        container={container}
                        onChange={value => onChange(value)}
                        tick={formatedTick}
                        range={range}
                    >
                        <Path
                            offset={offset}
                            color={color}
                        />
                        <Marker
                            offset={offset}
                            color={color}
                            value={value}
                            activeSpots={activeSpots}
                            showValue={showValue}
                        />

                    </Trail>
                </div>
                {
                    controllers && (
                        <Controlllers
                            value={value}
                            tick={formatedTick}
                            onChange={onChange}
                            range={range}
                            color={color}
                            frames={frames}
                            setFrames={setFrames}
                        />
                    )
                }
            </div>
        </>
    )
}

Slider.defaultProps = {
    range: { min: 0, max: 100 },
    tick: 1,
    isTickPercentage: false,
    controllers: false,
    value: 0,
    color: 'primary',
    activeSpots: [],
    onChange: () => { },
    showValue: false
}

