export const styles = (theme) => ({
  buttonOpenMenuButtonsMap: {
    position: "absolute",
    display: " flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "3em",
    transition: "all 0.15s",
    right: "8px",
    height: "40px",
    top: "10px",
    width: "40px",
    border: "none",
    boxShadow: "0.5px 0.5px 4px 0px black",
    cursor: "pointer",
  },

  buttonActive: {
    width: "151px",
    height: "410px",
    borderRadius: "1em",
    alignItems: "flex-start",
    padding: 7.5,
  },

  configHoverButton: {
    "@media(min-width: 961px)": {
      "&:hover": {
        cursor: "pointer",
        width: "59px",
        height: "59px",

        "& :first-child": {
          fontSize: 29,
        },
      },
    },
  },

  layersIcon: {
    fontSize: 24,
    color: "#565655",
    transition: "font-size 0.15s",
  },
});

export default styles;
