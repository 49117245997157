import axios from 'axios';
import intl from 'react-intl-universal';
import downloader from 'js-file-download';
import { format } from 'date-fns';
import { setSnackbar } from '../store/actions/Snackbar';
import { createRequestHeader, endpoints } from '.';

const fix = process.env.REACT_APP_FIX_TIME ? parseInt(process.env.REACT_APP_FIX_TIME) : 0;

export const getExport = (component, payload, token, dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${endpoints[component]}/get`, payload, createRequestHeader(token, true))
      .then((response) =>
        resolve(
          downloader(
            response.data,
            format(Date.now() + fix, intl.get(`api.${component}.fileName`)) +
              `.${payload.exportType === 'xls' ? payload.exportType + 'x' : payload.exportType}`, //`${format(Date.now() + fix`,intl.get('api.${component}.fileName'))}${payload.exportType}`,
            payload.exportType === 'pdf'
              ? 'application/pdf'
              : payload.exportType === 'xls'
              ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              : payload.exportType === 'pdf'
              ? ''
              : '\uFEFF'
          )
        )
      )
      .catch((error) => {
        dispatch(
          setSnackbar({
            open: true,
            message: intl.get(`api.${component}.exportError`),
            duration: 4000,
            variant: 'danger',
          })
        );
        return reject(error);
      });
  });
};

export default getExport;
