import React from 'react'

import { makeStyles } from '@material-ui/core'
import styles from './styles'

const useStyles = makeStyles(styles)

const Badge = ({ children, value }) => {
    const classes = useStyles()

    const formatValue = value => {
        const intValue = parseInt(value)

        if (isNaN(intValue)) return ''
        if (intValue > 99) return '99+'

        return intValue
    }

    return (
        <div className={classes.root}>
            {children}
            {value > 0 && <div className={classes.badge}>{formatValue(value)}</div>}
        </div>
    )
}

export default Badge