export default theme => ({
    root: {
        height: 10,
        position: 'absolute',
        left: 0,
        borderRadius: '10px',
    },

    primary: { backgroundColor: theme.palette.primary.main },
    success: { backgroundColor: theme.palette.success.main },
    accent: { backgroundColor: theme.palette.accent.main },
    warning: { backgroundColor: theme.palette.warning.main },
    danger: { backgroundColor: theme.palette.danger.main },


})