const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: theme.dimensions.customWidth.full,
    fontFamily: theme.typography.standard.fontFamily,
    fontSize: theme.typography.standard.fontSizeSm,
    fontWeight: theme.typography.standard.fontWeightBold,
    letterSpacing: theme.typography.standard.letterSpacing,
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'column',
  },

  title: {
    marginLeft: theme.dimensions.customMargin.xSmall,
    marginBottom: theme.dimensions.customMargin.xSmall,
    fontSize: `${theme.typography.standard.fontSizeSm} !important`,
  },
  titleError: {
    marginLeft: theme.dimensions.customMargin.xSmall,
    marginBottom: theme.dimensions.customMargin.xSmall,
    fontSize: theme.typography.standard.fontSizeSm,
    color: `${theme.palette.danger.dark} !important`,
  },
  container: {
    display: 'flex',
    width: '100%',
  },
  pickerContainer: {
    width: '100%',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.background.paper,
    minHeight: theme.dimensions.customHeight.small,
    borderColor: theme.palette.text.secondary,
    borderStyle: 'solid',
    borderWidth: theme.dimensions.customBorder.small,
    borderRadius: theme.dimensions.customBorderRadius.small,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: `8px`,
    '& > div': {
      width: '100% !important',
    },
    '& > div > div': {
      color: 'inherit',
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      letterSpacing: 'inherit',
    },
    '& > div > div::before': {
      border: 'none !important',
    },
    '& > div > div::after': {
      border: 'none !important',
    },
  },
  pickerNowContainer: {
    borderRadius: `${theme.dimensions.customBorderRadius.small}px 0px 0px ${theme.dimensions.customBorderRadius.small}px`,
  },
  pickerContainerError: {
    borderColor: theme.palette.danger.main,
  },
  now: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 0,
    cursor: 'pointer',
    borderColor: theme.palette.text.secondary,
    borderStyle: 'solid',
    borderWidth: theme.dimensions.customBorder.small,
    borderRadius: ` 0px ${theme.dimensions.customBorderRadius.small}px ${theme.dimensions.customBorderRadius.small}px 0px`,
    borderLeft: '0px !important',
    padding: 6,
    color: '#444444',
    '& > svg': {
      color: '#444444',
      marginLeft: 2,
    },
  },
  disable: {
    pointerEvents: 'none',
    opacity: 0.7
  },
  nowError: {
    borderColor: theme.palette.danger.main,
  },
});

export default styles;

// const styles = (theme) => ({
//   root: {
//     position: 'relative',
//     backgroundColor: theme.palette.background.paper,
//     width: theme.dimensions.customWidth.full,
//   },
//   font: {
//     fontFamily: theme.typography.standard.fontFamily,
//     fontSize: theme.typography.standard.fontSizeSm,
//     fontWeight: theme.typography.standard.fontWeightBold,
//     letterSpacing: theme.typography.standard.letterSpacing,
//     color: theme.palette.text.secondary,
//   },
//   title: {
//     marginLeft: theme.dimensions.customMargin.xSmall,
//     marginBottom: theme.dimensions.customMargin.xSmall,
//     fontSize: theme.typography.standard.fontSizeSm,
//   },
//   titleError: {
//     marginLeft: theme.dimensions.customMargin.xSmall,
//     marginBottom: theme.dimensions.customMargin.xSmall,
//     fontSize: theme.typography.standard.fontSizeSm,
//     color: `${theme.palette.danger.dark} !important`,
//   },
//   container: {
//     fontFamily: 'inherit',
//     fontSize: 'inherit',
//     fontWeight: 'inherit',
//     letterSpacing: 'inherit',
//     color: 'inherit',
//     '& :first-child': {
//       fontFamily: theme.typography.standard.fontFamily,
//       fontSize: theme.typography.standard.fontSize,
//       fontWeight: theme.typography.standard.fontWeightBold,
//       letterSpacing: theme.typography.standard.letterSpacing,
//       color: 'inherit',
//     },
//     '& :first-child :first-child': {
//       boxSizing: 'border-box',
//       backgroundColor: theme.palette.background.paper,
//       minHeight: theme.dimensions.customHeight.small,
//       borderColor: theme.palette.text.secondary,
//       borderStyle: 'solid',
//       borderWidth: theme.dimensions.customBorder.small,
//       borderRadius: theme.dimensions.customBorderRadius.small,
//       padding: `${theme.dimensions.customPadding.medium}px ${
//         theme.dimensions.customPadding.medium +
//         (theme.dimensions.customBorder.medium - theme.dimensions.customBorder.small)
//       }px`,
//       fontFamily: 'inherit',
//       fontSize: '0.95em',
//       fontWeight: 'inherit',
//       letterSpacing: 'inherit',
//       color: 'inherit',
//       overflow: 'hidden',
//       cursor: 'pointer',
//     },
//     '& :first-child :first-child:hover': {
//       borderColor: theme.palette.text.primary,
//     },
//     '& :first-child :first-child:focus': {
//       borderColor: theme.palette.primary.dark,
//       borderWidth: theme.dimensions.customBorder.medium,
//       padding: theme.dimensions.customPadding.medium,
//     },
//     '& :first-child :first-child::-webkit-calendar-picker-indicator': {
//       marginLeft: theme.dimensions.customMargin.none,
//       cursor: 'pointer',
//     },
//     '& :first-child::before': {
//       content: 'none',
//     },
//     '& :first-child::after': {
//       content: 'none',
//     },
//   },
//   nowContainer: {
//     fontFamily: 'inherit',
//     fontSize: 'inherit',
//     fontWeight: 'inherit',
//     letterSpacing: 'inherit',
//     color: 'inherit',
//     '& :first-child': {
//       fontFamily: theme.typography.standard.fontFamily,
//       fontSize: theme.typography.standard.fontSize,
//       fontWeight: theme.typography.standard.fontWeightBold,
//       letterSpacing: theme.typography.standard.letterSpacing,
//       color: 'inherit',
//     },
//     '& :first-child :first-child': {
//       boxSizing: 'border-box',
//       backgroundColor: theme.palette.background.paper,
//       minHeight: theme.dimensions.customHeight.small,
//       borderColor: theme.palette.text.secondary,
//       borderStyle: 'solid',
//       borderWidth: theme.dimensions.customBorder.small,
//       borderRadius: '5px 0 0 5px',
//       padding: `${theme.dimensions.customPadding.medium}px ${
//         theme.dimensions.customPadding.medium +
//         (theme.dimensions.customBorder.medium - theme.dimensions.customBorder.small)
//       }px`,
//       fontFamily: 'inherit',
//       fontSize: '0.95em',
//       fontWeight: 'inherit',
//       letterSpacing: 'inherit',
//       color: 'inherit',
//       overflow: 'hidden',
//       cursor: 'pointer',
//     },
//     '& :first-child :first-child:hover': {
//       borderColor: theme.palette.text.primary,
//     },
//     '& :first-child :first-child:focus': {
//       borderColor: theme.palette.primary.dark,
//       borderWidth: theme.dimensions.customBorder.medium,
//       padding: theme.dimensions.customPadding.medium,
//     },
//     '& :first-child :first-child::-webkit-calendar-picker-indicator': {
//       marginLeft: theme.dimensions.customMargin.none,
//       cursor: 'pointer',
//     },
//     '& :first-child::before': {
//       content: 'none',
//     },
//     '& :first-child::after': {
//       content: 'none',
//     },
//   },

//   containerError: {
//     '& :first-child :first-child': {
//       borderColor: theme.palette.danger.main,
//     },
//     '& :first-child :first-child:hover': {
//       borderColor: theme.palette.danger.dark,
//     },
//     '& :first-child :first-child:focus': {
//       borderColor: theme.palette.danger.dark,
//     },
//   },
//   nowContainerError: {
//     '& :first-child :first-child': {
//       borderColor: theme.palette.danger.main,
//     },
//     '& :first-child :first-child:hover': {
//       borderColor: theme.palette.danger.dark,
//     },
//     '& :first-child :first-child:focus': {
//       borderColor: theme.palette.danger.dark,
//     },
//   },

//   now: {
//     display: 'flex',
//     alignItems: 'center',
//     marginLeft: 0,
//     cursor: 'pointer',
//     borderColor: theme.palette.text.secondary,
//     borderStyle: 'solid',
//     borderWidth: theme.dimensions.customBorder.small,
//     borderRadius: '0 5px 5px 0',
//     padding: 6,
//     color: '#444444',
//     '& > svg': {
//       color: '#444444',
//       marginLeft: 2,
//     },
//   },
// });

// export default styles;
