export default theme => ({
    root: {
        minWidth: 300,
    },

    content: {
        display: 'flex',
        flexDirection: 'column',
        alingItems: 'center',
        marginTop: 30,
    },

    item: {
        padding: 10,
        fontSize: 16,
        color: theme.palette.default.text,
    }
})