import { MacroTypes as types } from '../types'

const initialState = {
    macros: [],
    loading: false,
    lastFetch: null,
    lastFilter: null
}

export const MacroReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_MACROS: return fetchMacros(state, action)

        case types.LOADING_MACROS: return { ...state, loading: true }

        default: return state
    }
}

const fetchMacros = (state, action) => {
    let fix = (process.env.REACT_APP_FIX_TIME) ? parseInt(process.env.REACT_APP_FIX_TIME) : 0

    return{
        ...state,
        macros: action.data,
        loading: false,
        lastFetch: Date.now() + fix,
        lastFilter: JSON.stringify(action.filter)
    }
}