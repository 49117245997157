export const hash = {
  Velocidade: 'velocidade',
  Eventos: 'evento',
  // 'Rotação': 'rotacao',
  'Vazão Combustível': 'vazaocombustivel',
  'Acionamento Acelerador': 'acelerador',
  'Acionamento Pedais': 'freio',
  Portas: 'portas',
  'Sinalizadores Ligados': 'luzes',
  Limpadores: 'parabrisa',
  'Tempo de uso': 'statustempo',
  Frota: 'frota',
  'Distância Percorrida': 'graficodistancia',
  Marcha: 'marcha',
  'Nível Combustível': 'nivelcombustivel',
  'Ar Condicionado': 'arcondicionado',
  Temperatura: 'graficotemperatura',
  'Cinto de segurança': 'cinto',
  // 'Consumo': 'consumo',
  'Gráfico Velocidade': 'graficovelocidade',
  'Gráfico Rotação': 'graficorotacao',
  'Gráfico Vazão': 'graficovazao',
  'Gráfico Pedais': 'graficopedais',
  'Gráfico Simples': 'graficosimples',
  'Gráfico Comparativo': 'graficobiaxial',
};

export const hashReverse = {
  velocidade: 'Velocidade',
  evento: 'Eventos',
  // 'rotacao': 'Rotação',
  vazaocombustivel: 'Vazão Combustível',
  acelerador: 'Acionamento Acelerador',
  freio: 'Acionamento Pedais',
  portas: 'Portas',
  luzes: 'Sinalizadores Ligados',
  parabrisa: 'Limpadores',
  statustempo: 'Tempo de uso',
  frota: 'Frota',
  graficodistancia: 'Distância Percorrida',
  marcha: 'Marcha',
  nivelcombustivel: 'Nível Combustível',
  arcondicionado: 'Ar Condicionado',
  graficotemperatura: 'Temperatura',
  cinto: 'Cinto de segurança',
  // 'consumo': 'Consumo',
  graficovelocidade: 'Gráfico Velocidade',
  graficorotacao: 'Gráfico Rotação',
  graficovazao: 'Gráfico Vazão',
  graficosimples: 'Gráfico Simples',
  graficobiaxial: 'Gráfico Comparativo',
};
