import React, { useRef } from "react";
import { useStyles } from "../../hooks";
import intl from "react-intl-universal";
import clsx from "clsx";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
  ScrollSync,
} from "react-virtualized";
import { CircularProgress } from "@material-ui/core";
import { CustomOptionsItem, CustomOptionsSection } from "..";

import { theme } from "../../styles";
import styles from "./jss/CustomFilterOptions";

const borderHeight = 2 * theme.dimensions.customBorder.medium;
const borderWidth = 2.5 * theme.dimensions.customBorder.medium;
const rowHeight = theme.dimensions.customHeight.small - borderHeight + 4;

const FunctionComponent = (props) => {
  const {
    items = [],
    handleClick = () => {},
    error = false,
    loading = false,
  } = props;
  const rowCount = items.length;
  const rootSize = {
    height:
      (rowCount < 5 ? rowCount * rowHeight : 5 * rowHeight) + borderHeight,
  };

  const cacheRef = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: rowHeight,
    })
  );
  const classes = useStyles(styles, props);

  const rootStyle = clsx({
    [classes.root]: true,
    [classes.error]: error,
  });

  if (loading)
    return (
      <div className={rootStyle}>
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      </div>
    );
  else if (!items.length)
    return (
      <div className={rootStyle}>
        <CustomOptionsItem
          item={{
            label: intl.get("core.CustomFilterOptions.noItems"),
            active: false,
          }}
        />
      </div>
    );
  else
    return (
      <ScrollSync>
        {({ scrollTop, onScroll }) => {
          return (
            <div className={rootStyle} style={rootSize}>
              <AutoSizer>
                {({ width, height }) => (
                  <List
                    scrollTop={scrollTop}
                    onScroll={onScroll}
                    width={width - borderWidth}
                    height={height - borderHeight}
                    rowHeight={cacheRef.current.rowHeight}
                    deferredMeasurementCache={cacheRef.current}
                    style={{ outline: "none" }}
                    rowCount={rowCount}
                    rowRenderer={({ key, index, style, parent }) => {
                      return (
                        <CellMeasurer
                          key={key}
                          cache={cacheRef.current}
                          parent={parent}
                          columnIndex={0}
                          rowIndex={index}
                        >
                          <div style={style}>
                            {!items[index].filterParameter ? (
                              <CustomOptionsSection item={items[index]} />
                            ) : (
                              <CustomOptionsItem
                                item={items[index]}
                                handleClick={handleClick}
                              />
                            )}
                          </div>
                        </CellMeasurer>
                      );
                    }}
                  />
                )}
              </AutoSizer>
            </div>
          );
        }}
      </ScrollSync>
    );
};

export const CustomFilterOptions = React.memo(FunctionComponent);

export default CustomFilterOptions;
