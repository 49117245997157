import React from 'react'

import { useStyles, Dialog } from '..'
import styles from './jss/listPanel'

export const ListPanel = ({ items, open, onClose }) => {
    const classes = useStyles(styles)

    const renderItems = () => {
        if(!items) return null
        return items.map(x => (
            <div key={x} className={classes.item}>
                {x}
            </div>
        ))
    }

    return (
        <Dialog
            open={open}
            className={classes.root}
            title={'Listagem Completa'}
            onClose={onClose}
        >
            <div className={classes.content}>
                {renderItems()}
            </div>
        </Dialog >
    )
}