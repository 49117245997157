export default {
  'en-US': {
    errorMessage: 'Error logging in, check your access data!',
    errorMessageEmail: 'Please enter a valid email!',
    errorMessageEmpty: 'Be sure to fill in all the fields to continue!',
    errorMessagePassword: 'Your password must contain between 6 and 16 characters!',
    errorMessageConfirmPassword: 'The passwords entered do not match, check!',
    passwordPlaceholder: 'Password',
    newPasswordPlaceholder: 'New password',
    confirmNewPasswordPlaceholder: 'Confirm new password',
    btnLogin: 'Login',
    btnContinue: 'Continue',
    forgotPassword: 'Forgot your password?',
    clickHere: 'Click here!',
    back: 'Come back',
    recoverPassword: 'Recover Password',
    recoveryPasswordTitle: 'Password Recovery',
    recoveryPasswordSubtitle: 'Enter your email and your new password below',
  },
  'pt-BR': {
    errorMessage: 'Erro ao realizar login, verifique seus dados de acesso!',
    errorMessageEmail: 'Insira um e-mail válido!',
    errorMessageEmpty: 'Certifique-se de preencher todos os campos para continuar!',
    errorMessagePassword: 'Sua senha deve conter entre 6 e 16 caracteres!',
    errorMessageConfirmPassword: 'As senhas inseridas não coincidem, verifique!',
    passwordPlaceholder: 'Senha',
    newPasswordPlaceholder: 'Nova senha',
    confirmNewPasswordPlaceholder: 'Confirmar nova senha',
    btnLogin: 'Login',
    btnContinue: 'Continuar',
    forgotPassword: 'Esqueceu sua senha?',
    clickHere: 'Clique aqui!',
    back: 'Voltar',
    recoverPassword: 'Recuperar Senha',
    recoveryPasswordTitle: 'Recuperação de senha',
    recoveryPasswordSubtitle: 'Informe abaixo seu e-mail e sua nova senha',
  },
  'es': {
    errorMessage: 'Error al iniciar sesión, verifique sus datos de acceso.',
    errorMessageEmail: '¡Ingrese un correo electrónico válido!',
    errorMessageEmpty: '¡Asegúrate de completar todos los campos para continuar!',
    errorMessagePassword: '¡Tu contraseña debe contener entre 6 y 16 caracteres!',
    errorMessageConfirmPassword: 'Las contraseñas introducidas no coinciden, ¡compruébalo!',
    passwordPlaceholder: 'Contraseña',
    newPasswordPlaceholder: 'Nueva contraseña',
    confirmNewPasswordPlaceholder: 'Confirmar nueva contraseña',
    btnLogin: 'Iniciar sesión',
    btnContinue: 'Continuar',
    forgotPassword: '¿Olvidaste tu contraseña?',
    clickHere: '¡Haz clic aquí!',
    back: 'Regresa',
    recoverPassword: 'Recuperar contraseña',
    recoveryPasswordTitle: 'Recuperación de contraseña',
    recoveryPasswordSubtitle: 'Ingrese su correo electrónico y su nueva contraseña a continuación',
  },
};
