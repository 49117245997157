export default (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: 180,
    margin: 5,
    backgroundColor: 'white',
    boxShadow:
      'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;',
    borderRadius: '5px',
    padding: 5,
    position: 'relative',
    userSelect: 'none',
  },

  chart: {
    width: '100%',
    height: 110,
  },
  empty: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    color: theme.palette.default.text,
  },

  legend: {
    fontSize: 12,
    color: '#545454',
    position: 'absolute',
    right: 0,
    bottom: 15,
    '& > div.item': {
      display: 'flex',
      flexDirection: 'row',
      '& > div.indicator': {
        height: 10,
        width: 10,
        borderRadius: '50%',
        content: "' '",
        margin: 5,
        marginTop: 3,
      },
    },
  },

  loader: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
  },

  circleChart: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '70%',
    '& > div:first-child': {
      width: '50%',
      height: '70%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      '& > div': {
        width: '12vh',
        height: '12vh',
        backgroundColor: '#444',
        animation: '$placeHolderShimmer 1.5s infinite',
        borderRadius: '50%',
      },
    },
    '& > div:nth-child(2)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
      width: '50%',
      height: '70%',
      '& > div': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        '& > div:first-child': {
          marginRight: 5,
          backgroundColor: '#444',
          animation: '$placeHolderShimmer 1.5s infinite',
          borderRadius: '50%',
          width: '1.5vh',
          height: '1.5vh',
        },
        '& > div:last-child': {
          marginRight: 5,
          backgroundColor: '#444',
          animation: '$placeHolderShimmer 1.5s infinite',
          width: '40%',
          height: '30%',
        },
      },
    },
  },

  footer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '30%',
    '& > div:first-child': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      width: '90%',
      height: '50%',
      '& > div': {
        backgroundColor: '#444',
        animation: '$placeHolderShimmer 1.5s infinite',
        width: '15%',
        height: '50%',
      },
    },
    '& > div:nth-child(2)': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      width: '90%',
      height: '30%',
      '& > div': {
        backgroundColor: '#444',
        animation: '$placeHolderShimmer 1.5s infinite',
        width: '20%',
        height: '50%',
      },
    },
  },

  '@keyframes placeHolderShimmer': {
    '0%': {
      opacity: 0.1,
    },
    '50%': {
      opacity: 0.2,
    },
    '100%': {
      opacity: 0.1,
    },
  },
});
