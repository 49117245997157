import React from 'react';
import { makeStyles, ClickAwayListener } from '@material-ui/core';
import { FaSync } from 'react-icons/fa';
import NotificationCard from './NotificationCard';
import { FaEnvelopeOpen } from 'react-icons/fa';
import styles from './jss/notificationPanel';

const useStyles = makeStyles(styles);

const NotificationPanel = ({
  handleClickLoadMoreCards,
  // noReadNotifications,
  setReadNotifications,
  notifications = [],
  loadNotifications,
  loadMoreCards,
  // setData,
  onClose,
  intl,
  // dispatch,
  history,
  // auth,
}) => {
  const classes = useStyles();
  // const [loadOffReadAll, setLoadOffReadAll] = useState(false);

  // const styleNoReadButton = clsx({
  // 	[classes.noReadAll]: true,
  // 	[classes.disableNoReadAll]: !noReadNotifications,
  // });

  const distinctNotifications = [...new Set(notifications.map((x) => x.notificationTimestamp))];

  let filteredNotifications = distinctNotifications.map((x) =>
    notifications.find((y) => y.notificationTimestamp === x)
  );

  filteredNotifications = filteredNotifications.filter((x) => x.notificationTimestamp);

  // const cardIsRead = useCallback(
  // 	async (type, notificationRead) => {
  // 		try {
  // 			setLoadOffReadAll(false);
  // 			setData((prev) => {
  // 				prev.totalCount = type === 'ALL' ? 0 : prev.totalCount - 1;
  // 				if (type !== 'ALL') {
  // 					const index = prev.notifications.findIndex(
  // 						(notification) => (
  // 							notification.notificationType === notificationRead.notificationType &&
  // 							notification.notificationTimestamp === notificationRead.notificationTimestamp &&
  // 							notification.vehicleId === notificationRead.vehicleId)

  // 					);
  // 					prev.notifications[index].isRead = 1;
  // 					localStorage.setItem("lastNotificationDate", prev.notifications[index].notificationTimestamp);
  // 					return { ...prev };
  // 				}
  // 				prev.notifications = prev.notifications.map((notification) => ({
  // 					...notification,
  // 					isRead: 1,
  // 				}));
  // 				localStorage.setItem("lastNotificationDate", prev.notifications[0].notificationTimestamp);
  // 				return { ...prev };
  // 			});
  // 		} catch (error) { }
  // 	},
  // 	[setData]
  // );

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className={classes.root}>
        {Boolean(filteredNotifications.length === 0) ? (
          <div className={classes.empty}>
            {!loadNotifications ? (
              <>
                <FaEnvelopeOpen />
                <span>{intl.get('navbar.notification.empty')}</span>
              </>
            ) : (
              <>
                <FaSync className={classes.loadNotifications} />
                <span>{intl.get('navbar.notification.loadNotifications')}</span>
              </>
            )}
          </div>
        ) : (
          <>
            <div className={classes.panelTitle}>
              {intl.get('navbar.notification.title')}
              {/* <span
								className={styleNoReadButton}
								onClick={() => {
									if (noReadNotifications) {
										setLoadOffReadAll(true);
										//cardIsRead('ALL');
									}
								}}>
								{loadOffReadAll ? (
									<FaSync className={classes.animation} />
								) : (
									intl.get('navbar.notification.makeToRead')
								)}
							</span> */}
            </div>
            {filteredNotifications.map((notification) => (
              <NotificationCard
                key={notification.notificationTimestamp}
                notification={notification}
                // cardIsRead={cardIsRead}
                setReadNotifications={setReadNotifications}
                history={history}
                onClose={onClose}
              />
            ))}
            {Boolean(filteredNotifications.length) && (
              <div className={classes.moreNotifications} onClick={handleClickLoadMoreCards}>
                {loadMoreCards ? (
                  <FaSync className={classes.animation} />
                ) : (
                  intl.get('navbar.notification.loadMoreCards')
                )}
              </div>
            )}
          </>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default NotificationPanel;
