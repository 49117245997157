import React, { useState } from 'react';
import intl from 'react-intl-universal';

//Redux
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core';
import styles from './jss/languagePicker';
import classnames from 'classnames';

import { FaCaretDown } from 'react-icons/fa';

const useStyles = makeStyles(styles);

const brazilFlag = require('../../assets/images/flags/brazil.svg');
const spainFlag = require('../../assets/images/flags/spain.svg');
const usaFlag = require('../../assets/images/flags/usa.svg');

const LanguagePicker = ({ onSelect }) => {
  const classes = useStyles();

  const items = [
    { id: 'pt-BR', icon: brazilFlag, name: intl.get('components.LanguagePicker.portuguese') },
    { id: 'en-US', icon: usaFlag, name: intl.get('components.LanguagePicker.english') },
    { id: 'es', icon: spainFlag, name: intl.get('components.LanguagePicker.spain') },
  ];

  const curLanguage = useSelector((s) => s.user.language);
  const _language = items.find((x) => x.id === curLanguage) || { id: '404', icon: null, name: '' };

  const [openLanguage, togglerLanguage] = useState(false);
  const [language, setLanguage] = useState(_language);

  const paperStyle = classnames({
    [classes.paper]: true,
    [classes.open]: openLanguage,
    [classes.closed]: !openLanguage,
  });

  const onClickItem = (id) => {
    setLanguage(items.find((x) => x.id === id));
    onSelect(id);
    togglerLanguage(false);
  };

  const renderItems = () =>
    items.map((item) => (
      <div key={item.id} className={classes.item} onClick={() => onClickItem(item.id)}>
        <img src={item.icon} alt={`Flag ${item.name}`} className={classes.flag} />
        {item.name}
      </div>
    ));

  return (
    <div className={classes.root}>
      <div className={classes.input} onClick={() => togglerLanguage(!openLanguage)}>
        <div className={classes.item}>
          <img src={language.icon} alt={`Flag ${language.name.split(' ')[0]}`} className={classes.flag} />
          {language.name}
        </div>
        <div className={classes.grow} />
        <FaCaretDown className={classes.icon} />
      </div>
      <div className={paperStyle}>{renderItems()}</div>
    </div>
  );
};

export default LanguagePicker;
