import React from 'react';

//Redux
import { useDispatch } from 'react-redux';
import { updatePersonal, updatePersonalPass } from '../../../src/store/actions/User';
import { setDialog } from '../../../src/store/actions/Dialog';
import { setSnackbar } from '../../../src/store/actions/Snackbar';

//Styles
import { makeStyles } from '@material-ui/core';
import styles from './jss/personal';

//i18n
import intl from 'react-intl-universal';

//Components
import { Dialog, DialogTitle } from '@material-ui/core';
import PersonalPanel from './PersonalPanel';
import { FaTimes } from 'react-icons/fa';

const useStyles = makeStyles(styles);

const Personal = ({ forcarTrocar, onClose, open, user }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.editPersonal }}
      disableBackdropClick
      className={classes.dialog}
    >
      <DialogTitle>
        {intl.get('personalprofile.profile')}
        {!forcarTrocar ? (
          <FaTimes className={classes.close} onClick={onClose} />
        ) : (
          <div className={classes.forcarTrocar}>
            <p>{intl.get('navbar.user.changePasswdMessage')}</p>
          </div>
        )}
      </DialogTitle>
      <PersonalPanel
        onClose={onClose}
        user={{ ...user }}
        updatePersonal={updatePersonal}
        updatePersonalPass={updatePersonalPass}
        forcarTrocar={forcarTrocar}
        dispatch={dispatch}
        setDialog={setDialog}
        setSnackbar={setSnackbar}
      />
    </Dialog>
  );
};

export default Personal;
