import React, { useState, useRef } from 'react';

import { SketchPicker } from 'react-color';
import { ClickAwayListener, CustomTitle, Overlay } from '..';
import { Button, Tooltip } from '@material-ui/core';

import clsx from 'clsx';

import { useStyles } from '../../hooks';
import styles from './jss/CustomColorInput';

import intl from 'react-intl-universal';

const RGBAToHexA = (r, g, b, a) =>
  '0x' +
  r.toString(16).padStart(2, 0) +
  g.toString(16).padStart(2, 0) +
  b.toString(16).padStart(2, 0) +
  Math.round(a * 255)
    .toString(16)
    .padStart(2, 0);

const HexToRGBA = (hexCode) => {
  let hex = hexCode.replace('#', '');

  let a = 1;

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  a = +(parseInt(hex.substring(6, 8), 16) / 255).toFixed(3);
  return {
    r: parseInt(hex.substring(0, 2), 16),
    g: parseInt(hex.substring(2, 4), 16),
    b: parseInt(hex.substring(4, 6), 16),
    a,
  };
};

const FunctionComponent = ({
  label = null,
  color = '#002984FF',
  onChange = () => {},
  presetColors = [],
}) => {
  const classes = useStyles(styles);

  const pickerRef = useRef();

  const rootStyle = clsx({
    [classes.root]: true,
    [classes.spaced]: Boolean(label),
  });

  const [displayPicker, setDisplayPicker] = useState(() => false);
  const [picker, setPicker] = useState({
    color: HexToRGBA(color),
  });

  const handleChange = (color) => {
    setPicker({ color: color.rgb });
  };

  const handleChangeComplete = ({ r, g, b, a }) => {
    onChange(RGBAToHexA(r, g, b, a));
    setDisplayPicker(false);
  };

  const handleClose = () => {
    setPicker({ color: HexToRGBA(color) });
    setDisplayPicker(false);
  };

  return (
    <div className={rootStyle}>
      {label && <CustomTitle title={label} />}
      <div
        className={classes.swatch}
        onClick={() => setDisplayPicker(!displayPicker)}
      >
        <Tooltip
          title={intl.get(`core.CustomColorInput.tooltip`)}
          placement={'top'}
        >
          <div
            className={classes.color}
            style={{
              background: `rgba(${picker.color.r}, ${picker.color.g}, ${picker.color.b}, ${picker.color.a})`,
            }}
          />
        </Tooltip>
      </div>
      {displayPicker && (
        <Overlay onOverlayClick={() => {}}>
          <div className={classes.popover} ref={pickerRef}>
            <ClickAwayListener onClickAway={() => handleClose()}>
              <SketchPicker
                color={picker.color}
                onChange={handleChange}
                presetColors={presetColors}
                width={250}
              />
              <div className={classes.footer}>
                <Button
                  onClick={() => handleChangeComplete(picker.color)}
                  variant='outlined'
                  color='primary'
                  fullWidth
                >
                  {intl.get(`core.CustomColorInput.select`)}
                </Button>
              </div>
            </ClickAwayListener>
          </div>
        </Overlay>
      )}
    </div>
  );
};

export const CustomColorInput = React.memo(FunctionComponent);

export default CustomColorInput;
