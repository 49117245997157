import React, { useState } from 'react'

import { useStyles } from '..'
import styles from './jss/multiSelectSearch'
import clsx from 'clsx'

import { FaCaretDown } from 'react-icons/fa'

import { uniqueId } from '../../helpers'

import { OptionPanel } from './OptionPanel'
import Tag from './Tag'

export const MultiSelectSearch = ({ placeholder, items, selected, onSelect, onSelectAll, allText, label }) => {
    const classes = useStyles(styles)

    const id = uniqueId('multisearch-')

    const [open, setOpen] = useState(false)
    const [query, setQuery] = useState('')

    const handleClick = e => {
        e.stopPropagation()
        e.preventDefault()
        setOpen(true)
    }

    const onClose = () => open ? setOpen(false) : null

    const onClickOption = option => {
        const { id } = option
        const included = selected.map(x => x.id).includes(id)
        return included ? onSelect(selected.filter(x => x.id !== id)) : onSelect(selected.concat(option))
    }

    const getItems = () => items.filter(x => x.label.toLowerCase().includes(query.toLowerCase()))

    const handleChange = e => {
        const { target: { value } } = e
        setQuery(value)
        setOpen(true)
    }

    const renderTags = () => {
        return selected.map(item => (
            <Tag key={item.id} item={item} onClick={onClickOption} />
        ))
    }


    const rootStyle = clsx({
        [classes.root]: true,
        [classes.focus]: open
    })

    const caretStyle = clsx({
        [classes.caret]: true,
        [classes.caretDown]: open
    })

    return (
        <div className={classes.wrapper} onClick={handleClick}>
            <div className={classes.label}>{label}</div>
            <label htmlFor={id} className={rootStyle}>
                <div className={classes.innerContainer}>
                    {
                        selected.length > 0 &&
                        <div className={classes.tags}>
                            {selected.length !== items.length ?
                                renderTags() :
                                <Tag onClick={() => onSelect([])} item={{ label: allText }} />
                            }
                        </div>
                    }
                    {
                        Boolean(selected.length) && open &&
                        <div className={classes.spreader} />
                    }
                    {
                        open &&
                        <input
                            className={classes.input}
                            placeholder={`Pesquisar`}
                            id={id}
                            value={query}
                            onChange={handleChange}
                            autoFocus
                        />
                    }
                    {
                        !open && !selected.length &&
                        <div className={classes.placeholder}>
                            {placeholder}
                        </div>
                    }
                </div>
                <div className={classes.grow} />
                <FaCaretDown className={caretStyle} />
            </label>

            <OptionPanel
                open={open}
                id={id}
                items={getItems()}
                selected={selected}
                onClickOption={onClickOption}
                onSelectAll={onSelectAll}
                onClose={onClose}
                setQuery={setQuery}
            />
        </div>
    )
}

MultiSelectSearch.defaultProps = {
    placeholder: '',
    allText: 'Todos',
    label: '',
    items: [],
    onSelectAll: () => { }
}

export default MultiSelectSearch