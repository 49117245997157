import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import { useStyles } from "../../styles";
import styles from "./jss/journey";

import { Wrapper, Preloader } from "../../core";
import { createAxiosHeader } from "../../helpers";
import { checkPermission } from "../../utils/helpers";
import Forbidden from "../../screens/Forbidden";

import { Filters } from "./Filters";
import { TableJourney as Table } from "./TableJourney";

import axios from "axios";
import { format } from "date-fns/esm";

import { setSnackbar } from "../../store/actions/Snackbar";
import downloader from "js-file-download";
import { Totalizers } from "../Journey/Journey/Totalizers";

const csv = require("../../assets/images/csv.png");
const pdf = require("../../assets/images/pdf.png");
//const xls = require("../../assets/images/xls.png");

const { REACT_APP_API: path } = process.env;

const filterTypes = [
  { id: 0, label: "Veículos" },
  { id: 1, label: "Motoristas" },
];

export const Journey = () => {
  const classes = useStyles(styles);
  const dispatch = useDispatch();
  const auth = useSelector((s) => s.auth);
  const { token, selectedCompanies, permissions } = auth;

  const [forbidden, setForbidden] = useState(false);
  const [drivers, setDrivers] = useState(null);
  const [selectedDrivers, setSelectedDrivers] = useState(null);

  const [vehicles, setVehicles] = useState(null);
  const [selectedVehicles, setSelectedVehicles] = useState(null);

  const [fetched, setFetched] = useState(false);

  const [filterType, setFilterType] = useState(filterTypes[1]);

  let fix = process.env.REACT_APP_FIX_TIME
    ? parseInt(process.env.REACT_APP_FIX_TIME)
    : 0;

  const [begin, setBegin] = useState(
    new Date(Date.now() + fix - 86400000 * 30)
  );
  const [end, setEnd] = useState(new Date(Date.now() + fix));

  const [filter, setFilter] = useState({
    drivers: [],
    vehicles: [],
    begin,
    end,
    filterType,
  });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalizers, setTotalizers] = useState({
    tmp_movimento_medio: 0,
    tmp_refeicao_medio: 0,
    tmp_descanso_medio: 0,
    tmp_espera_medio: 0,
    tmp_total_movimento: 0,
    tmp_total_refeicao: 0,
    tmp__total_descanso: 0,
    tmp_total_espera: 0,
  });

  const [exportLoader, setExportLoader] = useState(false);

  useEffect(() => {
    const permission = checkPermission(permissions, "MJT0003", "r");

    if (!permission) setForbidden(true);
  }, [permissions]);

  useEffect(() => {
    const endpoint = `${path}/jornada/filtros`;
    const headers = createAxiosHeader(token);
    const payload = {
      empresasSelecionadas: selectedCompanies.map((x) => x.id),
    };
    axios
      .post(endpoint, payload, headers)
      .then((res) => {
        const {
          data: { motoristas, veiculos },
        } = res;

        const drivers = motoristas.map((x) => ({
          id: x.id,
          label: x.nome,
        }));

        const vehicles = veiculos.map((x) => ({
          id: x.id,
          label: x.descricao,
        }));

        setDrivers(drivers);
        setSelectedDrivers(drivers);
        setVehicles(vehicles);
        setSelectedVehicles(vehicles);
        setFilter((old) => ({ ...old, drivers }));
      })
      .catch((err) => console.error(err));
  }, [selectedCompanies, token]);

  useEffect(() => {
    if (filter.drivers.length) {
      const endpoint = `${path}/jornada/porperiodo`;
      const headers = createAxiosHeader(token);
      const payload = {
        motoristas: filter.drivers.map((x) => x.id),
        veiculos: filter.vehicles.map((x) => x.id),
        inicio: format(filter.begin, "yyyy-MM-dd HH:mm:ss"),
        fim: format(filter.end, "yyyy-MM-dd HH:mm:ss"),
        filtro: filter.filterType.id,
        opcoes: {
          exportar: null,
        },
      };
      setLoading(true);
      axios.post(endpoint, payload, headers).then((res) => {
        setLoading(false);
        setFetched(true);
        setData(res.data.data);
        setTotalizers(res.data.totalizadores);
      });
    }
  }, [filter, token]);

  if (!fetched)
    return (
      <Wrapper>
        <div className={classes.loader}>
          <Preloader status="Carregando Jornadas" />
        </div>
      </Wrapper>
    );

  const filterProps = {
    drivers,
    selectedDrivers,
    setSelectedDrivers,
    begin,
    setBegin,
    end,
    setEnd,
    filter,
    setFilter,
    filterType,
    setFilterType,
    filterTypes,
    vehicles,
    selectedVehicles,
    setSelectedVehicles,
  };

  if (forbidden) return <Forbidden />;

  const getExport = (type) => {
    setExportLoader(true);
    return new Promise((resolve, reject) => {
      const endpoint = `${path}/jornada/porperiodo`;
      const headers = createAxiosHeader(token);
      const f = {
        motoristas: filter.drivers.map((x) => x.id),
        veiculos: filter.vehicles.map((x) => x.id),
        inicio: format(filter.begin, "yyyy-MM-dd HH:mm:ss"),
        fim: format(filter.end, "yyyy-MM-dd HH:mm:ss"),
        filtro: filter.filterType.id,
        opcoes: {
          exportar: type,
        },
      };

      let fix = process.env.REACT_APP_FIX_TIME
        ? parseInt(process.env.REACT_APP_FIX_TIME)
        : 0;

      axios
        .post(endpoint, f, { ...headers, responseType: "arraybuffer" })
        .then((res) => {
          setExportLoader(false);
          if (type === "csv")
            return resolve(
              downloader(
                res.data,
                `${format(Date.now() + fix, "dd-MM-yyyy-HH-mm")}_Jornadas.csv`,
                null,
                "\uFEFF"
              )
            );
          resolve(
            downloader(
              res.data,
              `${format(Date.now() + fix, "dd-MM-yyyy-HH-mm")}_Jornadas.pdf`,
              "application/pdf"
            )
          );
        })
        .catch((err) => {
          setExportLoader(false);
          dispatch(
            setSnackbar({
              open: true,
              message: "Houve um erro ao gerar relatório",
              duration: 4000,
              variant: "danger",
            })
          );
          return axios.isCancel(err) ? null : reject(err);
        });
    });
  };

  const totalizersItens = [
    { title: "Movimento Médio", value: totalizers.tmp_movimento_medio },
    { title: "Refeição Médio", value: totalizers.tmp_refeicao_medio },
    { title: "Descanso Médio", value: totalizers.tmp_descanso_medio },
    { title: "Espera Médio", value: totalizers.tmp_espera_medio },
    { title: "Total Movimento", value: totalizers.tmp_total_movimento },
    { title: "Total Refeição", value: totalizers.tmp_total_refeicao },
    { title: "Total Descanso", value: totalizers.tmp__total_descanso },
    { title: "Total Espera", value: totalizers.tmp_total_espera },
  ];

  const totalizersProps = {
    exportTypes: [
      { type: "pdf", image: pdf },
      //{ type: "xls", image: xls },
      { type: "csv", image: csv },
    ],
    totalizers: totalizersItens,
    getExport,
  };

  if (exportLoader)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div className={classes.loader}>
          <Preloader status="Aguarde... Gerando relatório" />
        </div>
      </div>
    );

  return (
    <Wrapper>
      <div className={classes.root}>
        <Filters {...filterProps} />

        <Totalizers {...totalizersProps} />

        <Table data={data} loading={loading} />
      </div>
    </Wrapper>
  );
};

export default Journey;
