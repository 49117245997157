import React, { useRef, useState, useEffect } from 'react';
import { useStyles } from '../../hooks';
import intl from 'react-intl-universal';
import { uniqueId } from '../../helpers';
import { CustomTitle, CustomInput, ClickAwayListener, CustomOptions } from '..';

import styles from './jss/CustomSimpleTextInput';

const FunctionComponent = (props) => {
	const {
		title = '',
		value = '',
		setValue = () => '',
		items = null,
		setItems = null,
		type = 'text',
		min = 0,
		max = Number.POSITIVE_INFINITY,
		customError = '',
		loading = false,
		showPlaceholder = true,
		customPlaceholder = null,
		focused = false,
		formatted = null,
		handleKeyPressed = () => {},
	} = props;

	let placeholder;
	if (showPlaceholder)
		if (min !== 0 && max !== Number.POSITIVE_INFINITY && !customPlaceholder)
			placeholder = intl
				.get(`core.CustomSimpleTextInput.placeholderMinMax`, { min: min, max: max });
		else if (min !== 0 && max === Number.POSITIVE_INFINITY && !customPlaceholder)
			placeholder = intl.get(`core.CustomSimpleTextInput.placeholderMin`, { min: min });
		else if (min === 0 && max !== Number.POSITIVE_INFINITY && !customPlaceholder)
			placeholder = intl.get(`core.CustomSimpleTextInput.placeholderMax`, { max: max });
		else if (customPlaceholder) placeholder = customPlaceholder;
		else placeholder = intl.get(`core.CustomSimpleTextInput.placeholder`);

	const idRef = useRef(uniqueId('CustomSimpleTextInput-'));
	const [showOptions, setShowOptions] = useState(() => false);
	const [input, setInput] = useState(() => '');
	const [error, setError] = useState(() => customError);
	const classes = useStyles(styles, props);

	const validate = (input) => {
		if (formatted) {
			setInput(formatted(input));
		} else {
			setInput(input);
		}

		if (input.length < min) {
			setError(intl.get(`core.CustomSimpleTextInput.minError`, { min: min }));
			setValue(null);
			return;
		}

		if (input.length > max) {
			setError(intl.get(`core.CustomSimpleTextInput.maxError`, { max: max }));
			setValue(null);
			return;
		}

		setError('');
		setValue(input);
	};

	const openOptions = () => setShowOptions(true);

	const toggleOptions = () => setShowOptions((showOptions) => !showOptions);

	const handleChange = (input) => {
		if (!loading) validate(input);
	};

	const handleClick = ({ label }) => {
		validate(label);
	};

	useEffect(() => {
		if (customError && customError !== '') setError(customError);
	}, [customError]);

	useEffect(() => {
		if (value !== null && value !== input) validate(value);
	}, [value]);

	return (
		<div className={classes.root}>
			{title && (
				<CustomTitle
					classes={{
						container: classes.customTitleContainer,
					}}
					title={title}
					message={error}
					messageType={error ? 'error' : ''}
				/>
			)}

			<CustomInput
				classes={{ input: classes.input }}
				id={idRef.current}
				input={input}
				setInput={handleChange}
				placeholder={placeholder}
				type={type}
				valid={!Boolean(error)}
				focused={showOptions || focused}
				showOptions={showOptions && items && setItems}
				disabled={value && value.length === min && value.length === max}
				handleClick={openOptions}
				handleKeyPressed={handleKeyPressed}
				mask
			/>
			{showOptions && (
				<ClickAwayListener ignore={idRef.current} onClickAway={toggleOptions}>
					{items && setItems && (
						<CustomOptions
							items={items}
							handleClick={handleClick}
							error={Boolean(error)}
							loading={loading}
						/>
					)}
				</ClickAwayListener>
			)}
		</div>
	);
};

export const CustomSimpleTextInput = React.memo(FunctionComponent);

export default CustomSimpleTextInput;
