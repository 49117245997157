import React from 'react'

//Styles
import { makeStyles } from '@material-ui/core'
import styles from './jss/tableBody'
import classnames from 'classnames'

//Material UI components
import { TableBody as MuiTableBody, TableRow, TableCell } from '@material-ui/core'

const useStyles = makeStyles(styles)

const TableBody = ({ items, onClickItem }) => {

    const classes = useStyles()

    const itemStyle = classnames({
        [classes.pointer]: Boolean(onClickItem)
    })

    const renderItems = () => items.map((item, index) => (
        <TableRow key={index} onClick={() => onClickItem ? onClickItem(item.id) : null} className={itemStyle}>
            {
                Object.keys(items[index]).map(key => {
                    if (key === 'id')
                        return null

                    return <TableCell key={item.id + key}>{item[key]}</TableCell>
                })
            }
        </TableRow>
    ))

    return (
        <MuiTableBody className={classes.containerEmpty}>
            {renderItems()}
        </MuiTableBody>
    )
}

export default TableBody