export default (theme) => ({
  root: {
    height: '90vh',
    width: '90vw',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    '@media(mix-width: 1080px)': {
      maxHeight: '80vw',
      maxWidth: '80vw',
    },
  },

  dialogErro: {
    minWidth: '20vw',
    minHeight: '15vh',
    maxHeight: '15vh',
    maxWidth: '20vw',
  },

  mensagemErro: {
    margin: '20px 5px 10px',
  },

  grow: {
    flexGrow: 1,
  },

  indicators: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  indicator: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 5px',
    margin: 5,
    border: `1px solid ${theme.palette.default.text}`,
    borderRadius: '4px',
    position: 'relative',
    whiteSpace: 'nowrap',
  },

  mapContainer: {
    padding: '10px 5px',
  },

  label: {
    position: 'absolute',
    backgroundColor: 'white',
    whiteSpace: 'nowrap',
    padding: '0px 2px',
    fontSize: 10,
    fontWeight: 600,
    top: -8,
    left: 5,
  },

  indicatorIcon: {
    fontSize: 16,
    color: theme.palette.default.text,
  },

  indicatorText: {
    fontSize: 14,
    color: theme.palette.default.text,
    marginLeft: 10,
    fontWeight: 600,
    '@media (max-width: 900px)': {
      fontSize: 10,
    },
  },

  indicatorLarge: {
    fontSize: 10,
    color: theme.palette.default.text,
    marginLeft: 10,
    fontWeight: 600,
    '@media (max-width: 900px)': {
      fontSize: 10,
    },
  },

  infoWindow: {
    fontSize: 14,
    fontWeight: 400,
    '& > div > span': {
      marginRight: 5,
      fontSize: 14,
      fontWeight: 'bold',
    },
  },
});
