export function setLocalStorage(userId = null, item = {}, moduleKey = '', subModuleKey = '') {
  try {
    let data = JSON.parse(localStorage.getItem('userStorage')) || {};

    if (!data[userId]) {
      data[userId] = {};
    }

    if (!data[userId][moduleKey]) {
      data[userId][moduleKey] = {};
    }

    if (subModuleKey) {
      data[userId][moduleKey][subModuleKey] = {
        ...data[userId][moduleKey][subModuleKey],
        ...item,
      };
    } else {
      data[userId][moduleKey] = {
        ...data[userId][moduleKey],
        ...item,
      };
    }

    localStorage.setItem('userStorage', JSON.stringify(data));
  } catch (error) {
    console.error('Error setting localStorage:', error);
  }
}

export function getLocalStorage(userId, moduleKey = '') {
  try {
    const objJSON = localStorage.getItem('userStorage') || null;

    if (objJSON) {
      if (moduleKey) {
        return JSON.parse(objJSON)[userId][moduleKey];
      }
      return JSON.parse(objJSON)[userId] || null;
    }
  } catch (error) {
    console.log(error);
  }
}
