import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { format } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import ptBRLocale from 'date-fns/locale/pt-BR';
import enUSLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';

import { FaRegClock } from 'react-icons/fa';
import { KeyboardDateTimePicker as Picker, MuiPickersUtilsProvider as Provider } from '@material-ui/pickers';
import { Tooltip } from '@material-ui/core';

import { useStyles } from '..';
import styles from './jss/dateTime';

export const DateTime = ({ value, onChange, label, now, className, minDate, disabled, ...rest }) => {
  const classes = useStyles(styles);
  const { language } = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);
  const parsedDate = format(value, intl.get('global.dateInput'));
  let fix = process.env.REACT_APP_FIX_TIME ? parseInt(process.env.REACT_APP_FIX_TIME) : 0;

  const setNow = (e) => {
    onChange(new Date(Date.now() + fix));
    e.stopPropagation();
  };

  return (
    <div style={{ width: '100%' }}>
      <div className={classes.hidden}>
        <Provider
          utils={DateFnsUtils}
          locale={
            language === 'pt-BR'
              ? ptBRLocale
              : language === 'en-US' || language === 'en'
              ? enUSLocale
              : language === 'es'
              ? esLocale
              : ptBRLocale
          }
        >
          <Picker
            value={value}
            open={open}
            onClose={() => setOpen(false)}
            autoOk
            onChange={onChange}
            variant="dialog"
            cancelLabel={intl.get('datetime.cancelLabel')}
            DialogProps={{
              className: classes.above,
            }}
            minDate={minDate ? minDate : undefined}
            {...rest}
          />
        </Provider>
      </div>
      <div className={`${classes.container} ${className} ${disabled ? classes.disabled : ''}`}>
        <div className={classes.label}>{label}</div>
        <div className={classes.input} onClick={() => setOpen(!open)}>
          <h3 className={classes.text}>{parsedDate}</h3>
          <svg className={classes.icon}>
            <path d="M 20 3 h -1 V 1 h -2 v 2 H 7 V 1 H 5 v 2 H 4 c -1.1 0 -2 0.9 -2 2 v 16 c 0 1.1 0.9 2 2 2 h 16 c 1.1 0 2 -0.9 2 -2 V 5 c 0 -1.1 -0.9 -2 -2 -2 Z m 0 18 H 4 V 8 h 16 v 13 Z" />
          </svg>
          {now && (
            <Tooltip title={intl.get('datetime.now')} placement={'top'}>
              <span className={classes.now} onClick={(e) => setNow(e)}>
                <FaRegClock />
              </span>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

DateTime.defaultProps = {
  now: false,
  label: '',
  className: '',
  disablePast: false,
  ampm: false,
};

export default DateTime;
