import React, { useState } from "react";
import { useLayoutEffect } from "react";
import { useEffect } from "react";
import { useStyles } from "../../hooks";
import { reverseGeocode } from "../../utils/helpers";
import styles from "./jss/List";

const FunctionComponent = (props) => {
    const {
        body = [],
        data = {},
        location = {},
    } = props;
    const [address, setAddress] = useState("Carregando Endereço"); 
    const classes = useStyles(styles);

    const List = () =>{
        if(Object.entries(location).length){
            reverseGeocode(location)
            .then((add) => setAddress(add))
            .catch(() => setAddress(""));
            data.location = address;
        }
        return(
            <p className={classes.myText}> {body.title} : <strong>{data[body.field]}</strong> </p>
        )
    }
    return (
        <div>
            {List()}
        </div>
    );
}

export const List = React.memo(FunctionComponent);

export default List;