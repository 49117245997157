export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 20,
        margin: '0 5px',
        marginLeft: 0,
        borderRadius: '5px',
        border: '1px solid #aaa',
        //backgroundColor: theme.palette.default.dark,
        color: 'black'
        //color: theme.palette.default.text,

    },

    label: {
        fontSize: 14,
        padding: 5,
        whiteSpace: 'nowrap',
        textAlign: 'center',
        width: '98%',
        overflow: 'hidden',
    },
    icon: {
        fontSize: 14,
        padding: 5,
        color: '#444444',
        '&:hover': {
            color: 'black'
        },
    }
})