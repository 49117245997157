import React, { useRef, useState, useEffect } from 'react';
import { useStyles } from '../../hooks';
import intl from 'react-intl-universal';
import { uniqueId } from '../../helpers';
import { CustomTitle, CustomInput, ClickAwayListener, CustomOptions } from '..';

import styles from './jss/CustomNumberInputWithStep';

const FunctionComponent = (props) => {
  const {
    title = '',
    value = NaN,
    setValue = () => NaN,
    items = null,
    setItems = null,
    unit = '',
    min = Number.NEGATIVE_INFINITY,
    max = Number.POSITIVE_INFINITY,
    step = null,
    stepOnly = false,
    decimalPlaces = 0,
    loading = false,
    isNotRequired = false,
    placeholder = '',
    setIsEmpty = () => false,
    setCustomError = () => false,
    style = null,
    titleComponent = null,
    disabled = false,
  } = props;
  const point = intl.get('global.point');
  const decimalSeparator = intl.get('global.decimalSeparator');
  const regex = decimalPlaces === 0 ? /^-{0,1}\d+$/ : new RegExp(`^-{0,1}\\d+\\${point}{0,1}\\d{0,${decimalPlaces}}$`);

  const idRef = useRef(uniqueId('NumberInput-'));
  const timeoutRef = useRef();
  const [showOptions, setShowOptions] = useState(() => false);
  const [input, setInput] = useState(() => '');
  const [error, setError] = useState(() => '');
  const classes = useStyles(styles, props);

  const validate = (input) => {
    if (input === '') {
      setIsEmpty(true);
      if (!isNotRequired) {
        setCustomError(true);
        setError(
          decimalPlaces === 0
            ? intl.get(`core.CustomNumberInput.integerError`)
            : intl.get(`core.CustomNumberInput.floatError`).replace('{decimalPlaces}', decimalPlaces)
        );
        setInput(input);
        return;
      }

      setCustomError(false);
      setError('');
      setValue(input);
      setInput(input);
      return;
    }

    setIsEmpty(false);

    if (!regex.test(input.toString())) {
      return;
    }

    setInput(input);

    const value = Number(input);

    if (stepOnly && value % step > 0) {
      setCustomError(true);
      setError(intl.get(`core.CustomNumberInput.multipleError`).replace('{step}', step ? step : ''));
      setValue(NaN);
      return;
    }

    if (value < min) {
      setCustomError(true);
      setError(
        intl
          .get(`core.CustomNumberInput.minError`)
          .replace('{min}', min.toFixed(decimalPlaces).replace(point, decimalSeparator))
          .replace('{unit}', unit ? unit : '')
      );
      setValue(NaN);
      return;
    }

    if (value > max) {
      setCustomError(true);
      setError(
        intl
          .get(`core.CustomNumberInput.maxError`)
          .replace('{max}', max.toFixed(decimalPlaces).replace(point, decimalSeparator))
          .replace('{unit}', unit ? unit : '')
      );
      setValue(NaN);
      return;
    }
    setCustomError(false);
    setError('');
    setValue(value);
  };

  const openOptions = () => setShowOptions(true);

  const toggleOptions = () => setShowOptions((showOptions) => !showOptions);

  const handleChange = (input) => {
    if (!loading) {
      setInput(input);
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => validate(input), 150);
    }
  };

  const handleClick = ({ label }) => {
    validate(label);
  };

  const handleKeyUp = (event) => {
    if ((event.key === ',' || event.target.value.toString().includes('.')) && decimalPlaces === 0) {
      setCustomError(true);
      setError(
        decimalPlaces === 0
          ? intl.get(`core.CustomNumberInput.integerError`)
          : intl.get(`core.CustomNumberInput.floatError`).replace('{decimalPlaces}', decimalPlaces)
      );
    } else {
      setCustomError(false);
      setError('');
    }
  };

  useEffect(() => {
    if (!isNaN(value) && (value === 0 || value !== Number(input.toString().replace(decimalSeparator, point))))
      validate(value);
  }, [value]);

  return (
    <div className={style ? style : classes.root}>
      {title && (
        <CustomTitle
          classes={{
            container: classes.customTitleContainer,
          }}
          title={title}
          message={error}
          messageType={!titleComponent ? (error ? 'error' : '') : ''}
          component={titleComponent}
        />
      )}
      <CustomInput
        id={idRef.current}
        input={input}
        setInput={handleChange}
        placeholder={
          placeholder !== ''
            ? placeholder
            : intl
                .get(`core.CustomNumberInput.placeholder`)
                .replace('{min}', min.toFixed(decimalPlaces).replace(point, decimalSeparator))
                .replace('{max}', max.toFixed(decimalPlaces).replace(point, decimalSeparator))
                .replaceAll('{unit}', unit ? unit : '')
        }
        valid={!Boolean(error)}
        focused={showOptions}
        showOptions={showOptions && items && setItems}
        disabled={disabled}
        handleClick={openOptions}
        step={step}
        type={'number'}
        mask={'number'}
        handleKeyUp={handleKeyUp}
      />
      {showOptions && (
        <ClickAwayListener ignore={idRef.current} onClickAway={toggleOptions}>
          {items && setItems && (
            <CustomOptions items={items} handleClick={handleClick} error={Boolean(error)} loading={loading} />
          )}
        </ClickAwayListener>
      )}
    </div>
  );
};

export const CustomNumberInputWithStep = React.memo(FunctionComponent);

export default CustomNumberInputWithStep;