const styles = (theme) => ({
  tabsRoot: {
    backgroundColor: 'white',
  },
  tabRoot: {
    fontFamily: theme.typography.standard.fontFamily,
    letterSpacing: theme.typography.standard.letterSpacing,
    fontWeight: theme.typography.standard.fontWeightBold,
    color: theme.palette.default.text,
    fontSize: 12,
  },
  tabWrapper: {
    margin: theme.dimensions.customMargin.xLarge,
    borderRadius: theme.dimensions.customBorderRadius.default,
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 1px 5px 0px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 3px 1px -2px',
    fontFamily: theme.typography.standard.fontFamily,
    fontSize: theme.typography.standard.fontSize,
    letterSpacing: theme.typography.standard.letterSpacing,
    color: theme.palette.default.text,
  },
  containerMap: {
    height: '100vh',
    width: '100%',
    padding: '8px',
    boxSizing: 'border-box',
  },
  root: {
    fontFamily: theme.typography.standard.fontFamily,
    letterSpacing: theme.typography.standard.letterSpacing,
    fontWeight: theme.typography.standard.fontWeightBold,
    color: theme.palette.default.text,
    fontSize: 12,
  },
  paper: {
    margin: 32,
    position: 'relative',
    overflowY: 'unset',
    width: '100%',
    maxWidth: '85vw',
    height: '100%',
    maxHeight: '85vh',
  },
  dialogHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    boxSizing: 'border-box',
    padding: '8px 8px 0 8px',
  },
  dialogIconCloseButton: {
    fontSize: '1.7rem',
    padding: 0,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    justifyContent: 'space-around',
  },
  columnComboBox: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    margin: theme.dimensions.customMargin.small,
    boxSizing: 'border-box',
    gap: '8px',
    [theme.breakpoints.down('md')]: {
      width: `calc(100% - ${2 * theme.dimensions.customMargin.small}px)`,
    },
    [theme.breakpoints.between('md', 'lg')]: {
      width: `calc(50% - ${2 * theme.dimensions.customMargin.small}px)`,
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      width: `calc(50% - ${2 * theme.dimensions.customMargin.small}px)`,
    },
    [theme.breakpoints.up('xl')]: {
      width: `calc(30% - ${2 * theme.dimensions.customMargin.small}px)`,
    },
  },
  columnButton: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    margin: theme.dimensions.customMargin.small,
    boxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {
      width: `calc(100% - ${2 * theme.dimensions.customMargin.small}px)`,
    },
    [theme.breakpoints.between('md', 'lg')]: {
      width: `calc(50% - ${2 * theme.dimensions.customMargin.small}px)`,
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      width: `calc(50% - ${2 * theme.dimensions.customMargin.small}px)`,
    },
    [theme.breakpoints.up('xl')]: {
      width: `calc(10% - ${2 * theme.dimensions.customMargin.small}px)`,
    },
  },
  buttonRoot: {
    height: theme.dimensions.customHeight.small,
  },
  animation: {
    color: `${theme.palette.button.light}`,
    animation: '$loader 1s infinite linear',
  },
  '@keyframes loader': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  cardsContainer: {
    margin: `10px 5px`,
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    justifyContent: 'space-around',
    margin: theme.dimensions.customMargin.small,
    marginTop: theme.dimensions.customMargin.medium,
  },
  rowIndicators: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    justifyContent: 'space-around',
    marginTop: theme.dimensions.customMargin.large,
  },
  indicators: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    whiteSpace: 'nowrap',
    marginBottom: theme.dimensions.customMargin.large,
    border: `solid ${theme.palette.text.secondary}`,
    borderWidth: theme.dimensions.customBorder.small,
    borderRadius: theme.dimensions.customBorderRadius.small,
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${2 * theme.dimensions.customMargin.small}px)`,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: `calc(50% - ${2 * theme.dimensions.customMargin.small}px)`,
    },
    [theme.breakpoints.up('md')]: {
      width: `calc(50% - ${2 * theme.dimensions.customMargin.small}px)`,
    },
  },
  title: {
    position: 'absolute',
    backgroundColor: 'white',
    whiteSpace: 'nowrap',
    padding: '0px 2px',
    fontSize: 12,
    fontWeight: 600,
    top: -8,
    left: 5,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
    color: theme.palette.default.text,
    fontWeight: 500,
    '@media (max-width: 900px)': {
      fontSize: 12,
    },
  },
  contentLabel: {
    fontSize: 12,
    fontWeight: 600,
  },
  footerContent: {
    width: '100%',
    display: 'flex',
    padding: '8px',
    justifyContent: 'space-around',
    fontSize: 14,
    color: theme.palette.default.text,
    fontWeight: 600,
  },

  infoContent: {
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  indicatorsContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    padding: '5px',
  },
  timezone: {
    whiteSpace: 'nowrap',
    fontWeight: 600,
    fontSize: '0.85rem',
    marginLeft: 5,
  },
  mapButton: {
    whiteSpace: 'nowrap',
    marginLeft: 5,
  },
});

export default styles;
