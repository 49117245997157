import { CardTypes as types, CardTypes } from '../types';
import axios from 'axios';
import { format } from 'date-fns';
import { storeLastFilters } from './Searchbar';

import { hash } from './hash';
import { setSnackbar } from './Snackbar';
import { compareIntArrays } from '../../helpers/array';

const { REACT_APP_API: path, REACT_APP_DURATION: duration } = process.env;

const telemetryEndpoints = [
  // "rotacao",
  'vazaocombustivel',
  'arcondicionado',
  'graficotemperatura',
  'cinto',
  'marcha',
  'acelerador',
  'evento',
  'freio',
];

export const fetchCardsTelemetry = () => {
  return (dispatch, getState) => {
    const {
      auth: { token },
      searchbar: { filters },
      card: { cards },
    } = getState();
    const headers = { headers: { Authorization: `bearer ${token}` } };

    const { vehicles, begin, end } = filters;

    const payload = {
      veiculos: vehicles.map((x) => x.id),
      inicio: format(begin, "yyyy-MM-dd'T'HH:mm"),
      fim: format(end, "yyyy-MM-dd'T'HH:mm"),
    };
    dispatch(storeLastFilters(filters));

    let fix = process.env.REACT_APP_FIX_TIME
      ? parseInt(process.env.REACT_APP_FIX_TIME)
      : 0;

    const fetched = telemetryEndpoints
      .map((endpoint) => cards.find((x) => hash[x.title] === endpoint))
      .filter(
        (x) => Boolean(x.lastFetch) && Date.now() + fix - x.lastFetch < duration
      )
      .filter((x) => areFiltersEqual(x.lastFilters, filters))
      .map((x) => hash[x.title]);

    let toFetch = telemetryEndpoints.filter((x) => !fetched.includes(x));
    const genericTimeEndPoint = ['cinto', 'marcha', 'arcondicionado'];

    let exists = 0;
    toFetch.map((x) => {
      if (genericTimeEndPoint.includes(x)) {
        toFetch = toFetch.filter((y) => y !== x);
        exists++;
      }
      return x;
    });

    if (exists !== 0) toFetch = toFetch.concat('tempo');

    const promises = toFetch.map((endpoint) =>
      fetcher(endpoint, payload, headers)
    );
    Promise.all(promises).then((res) => {
      const data = {};
      toFetch.map((x, i) => {
        if (x === 'tempo') {
          genericTimeEndPoint.map((y) => {
            return (data[y] = res[i]);
          });
        }
        return (data[x] = res[i]);
      });
      dispatch({ type: types.STORE_CARDS, data, filters });
    });
  };
};

export const fetchCards = (cancelToken) => {
  return (dispatch, getState) => {
    const {
      auth: { token },
      searchbar: { filters },
      card: { cards: reducerCards, selected },
    } = getState();

    const headers = {
      headers: { Authorization: `bearer ${token}` },
      cancelToken,
    };

    const payload = {
      veiculos: filters.vehicles.map((x) => x.id),
      inicio: format(filters.begin, "yyyy-MM-dd'T'HH:mm"),
      fim: format(filters.end, "yyyy-MM-dd'T'HH:mm"),
    };

    const cards = reducerCards.filter((x) =>
      selected.preferencia.includes(hash[x.title])
    );
    if (!cards.length) return dispatch({ type: types.INITIAL_FETCH });

    let fix = process.env.REACT_APP_FIX_TIME
      ? parseInt(process.env.REACT_APP_FIX_TIME)
      : 0;

    const fetched = reducerCards
      .filter((x) => cards.map((y) => y.title).includes(x.title))
      .filter(
        (x) => Boolean(x.lastFetch) && Date.now() + fix - x.lastFetch < duration
      )
      .filter((x) => areFiltersEqual(x.lastFilters, filters))
      .map((x) => hash[x.title]);

    let toFetch = cards
      .map((x) => hash[x.title])
      .filter((x) => !fetched.includes(x));

    if (
      !toFetch.length &&
      !selected.preferencia.includes('graficobiaxial') &&
      !selected.preferencia.includes('graficosimples')
    )
      return null;

    if (toFetch && toFetch.length)
      dispatch({ type: types.IS_LOADING, cards: toFetch });

    const genericTimeEndPoint = [
      'statustempo',
      'cinto',
      'arcondicionado',
      'marcha',
    ];

    let exists = 0;
    toFetch.map((x) => {
      if (genericTimeEndPoint.includes(x)) {
        toFetch = toFetch.filter((y) => y !== x);
        exists++;
      }
      return x;
    });

    if (exists !== 0) toFetch = toFetch.concat('tempo');
    let promises = toFetch.map((endpoint) =>
      fetcher(endpoint, payload, headers)
    );

    Promise.all(promises)
      .then((res) => {
        if (!res.length) return dispatch({ type: types.INITIAL_FETCH });
        const data = {};
        toFetch.map((x, i) => {
          if (x === 'tempo') {
            genericTimeEndPoint.map((y) => {
              return (data[y] = res[i]);
            });
          }
          return (data[x] = res[i]);
        });
        dispatch({ type: types.STORE_CARDS, data, filters });
      })
      .catch((err) => console.error(err));
  };
};

const areFiltersEqual = (lastFilters, filters) => {
  if (!lastFilters) return false;
  if (
    !compareIntArrays(
      lastFilters.vehicles.map((x) => x.id),
      filters.vehicles.map((x) => x.id)
    )
  )
    return false;
  if (lastFilters.begin !== filters.begin) return false;
  if (lastFilters.end !== filters.end) return false;
  return true;
};

export const fetchIndividualCard = (path) => {
  return (dispatch, getState) => {
    const {
      auth: { token },
      searchbar: { filters },
      card: { cards },
    } = getState();

    const card = cards.find((x) => hash[x.title] === path);
    let fix = process.env.REACT_APP_FIX_TIME
      ? parseInt(process.env.REACT_APP_FIX_TIME)
      : 0;
    const isUpdated =
      Boolean(card.lastFetch) && Date.now() + fix - card.lastFetch < duration;
    const sameFilters = areFiltersEqual(card.lastFilters, filters);
    if (isUpdated && sameFilters) return null;

    const headers = { headers: { Authorization: `bearer ${token}` } };
    const payload = {
      veiculos: filters.vehicles.map((x) => x.id),
      inicio: format(filters.begin, "yyyy-MM-dd'T'HH:mm"),
      fim: format(filters.end, "yyyy-MM-dd'T'HH:mm"),
    };

    dispatch({ type: types.IS_LOADING, cards: [path] });

    const genericTimeEndPoint = [
      'statustempo',
      'cinto',
      'arcondicionado',
      'marcha',
    ];
    let newPath = path;
    if (genericTimeEndPoint.includes(path)) {
      newPath = 'tempo';
    }

    fetcher(newPath, payload, headers)
      .then((res) => {
        const data = {};
        data[path] = res;
        dispatch({ type: types.STORE_ADITIONAL, data, filters });
      })
      .catch((err) => console.error(err));
  };
};

const fetcher = (key, payload, headers) => {
  return new Promise((resolve, reject) => {
    const endpoint = `${path}/dadosveiculo/${key}`;
    axios
      .post(endpoint, payload, headers)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const fetchPreferences = (headers, companies) => {
  return new Promise((resolve, reject) => {
    const endpoint = `${path}/dashboard/get`;
    const payload = { empresasSelecionadas: companies.map((x) => x.id) };
    axios
      .post(endpoint, payload, headers)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

//descricao, empresa, preferencia, publica
export const addPreference = (data) => {
  return (dispatch, getState) => {
    const {
      auth: { token, company, selectedCompanies },
    } = getState();
    const endpoint = `${path}/dashboard`;
    const payload = {
      descricao: data.title,
      empresa: company,
      preferencia: data.preference,
      publica: data.isPublic ? 1 : 0,
    };
    const headers = { headers: { Authorization: `bearer ${token}` } };
    axios
      .post(endpoint, payload, headers)
      .then((res) => {
        const { id } = res.data;
        dispatch(
          setSnackbar({
            open: true,
            message: res.data.msg,
            duration: 4000,
            variant: 'success',
          })
        );
        fetchPreferences(headers, selectedCompanies)
          .then((res) =>
            dispatch({ type: CardTypes.STORE_PREFERENCES, data: res, id })
          )
          .catch((err) => console.error(err));
      })
      .catch((err) =>
        dispatch(
          setSnackbar({
            open: true,
            message: err.response.data.msg,
            duration: 4000,
            variant: 'error',
          })
        )
      );
  };
};

export const selectPreference = (preference) => ({
  type: types.SELECT_PREFERENCE,
  preference,
});

export const updatePreference = (payload) => {
  return (dispatch, getState) => {
    const {
      auth: { token, selectedCompanies },
    } = getState();
    const headers = { headers: { Authorization: `bearer ${token}` } };
    const endpoint = `${path}/dashboard`;
    axios
      .put(endpoint, payload, headers)
      .then((res) => {
        dispatch(
          setSnackbar({
            open: true,
            message: res.data.msg,
            duration: 4000,
            variant: 'success',
          })
        );
        fetchPreferences(headers, selectedCompanies).then((res) =>
          dispatch({
            type: CardTypes.STORE_PREFERENCES,
            data: res,
            id: payload.id,
          })
        );
      })
      .catch((err) =>
        dispatch(
          setSnackbar({
            open: true,
            message: err.response.data.msg,
            duration: 4000,
            variant: 'error',
          })
        )
      );
  };
};
