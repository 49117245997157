export default theme => ({
    hidden: {
        position: 'absolute',
        opacity: 0,
        top: 0
    },

    button: {
        borderRadius: '50%',
        minHeight: 35,
        minWidth: 35,
        padding: 5,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        '& > svg': {
            color: 'white',
            fontSize: 22
        },
        '&:active': {
            backgroundColor: theme.palette.primary.dark
        }
    },

    title: {
        padding: 5,
        margin: 0,
        marginLeft: 10,
        fontSize: 20,
        color: '#495057',
        fontWeight: 400,
        cursor : 'pointer'
    }
})