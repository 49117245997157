import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { FaMapMarkedAlt, FaSearch, FaSync, FaTimes } from 'react-icons/fa';
import { Marker } from '@react-google-maps/api';
import { format } from 'date-fns';
import intl from 'react-intl-universal';

import { Button, Dialog, IconButton, Tab, Tabs, Tooltip } from '@material-ui/core';

import {
  CustomAutoCompleteComboBox,
  CustomTable,
  CustomWrapper,
  Wrapper,
  CustomGoogleMap,
  CustomErrorPage,
  CustomPreloader,
} from '../../../core';
import { Location } from '../../../components/Reports/Location';

import mapStyles from '../../../core/Map/jss/customStyle_v2';

import { get } from '../../../api';
import { generatePdfFile } from '../../../utils/generatePdfFile';
import { generateCsvFile } from '../../../utils/generateCsvFile';
import { generateXlsFile } from '../../../utils/generateXlsFile';
import { nullDateTime, nullString } from '../../../utils/formatters';

import { useStyles } from '../../../hooks';
import style from './jss/currentPosition';

const FunctionComponent = withRouter(() => {
  const classes = useStyles(style);
  const dispatch = useDispatch();
  const auth = useSelector((s) => s.auth);
  const user = useSelector((s) => s.user);

  const tableRef = useRef();
  const groupingsRef = useRef([]);
  const operationsRef = useRef([]);
  const firstFetchRef = useRef(true);
  const vehiclesRef = useRef([]);
  const vehiclesFiltersRef = useRef([]);
  const inputFilteredTextRef = useRef('');
  const inputGroupingTextRef = useRef('');
  const inputOperationTextRef = useRef('');
  const mapProps = useRef({
    center: {
      lat: -15.793889,
      lng: -47.882778,
    },
    zoom: 12,
    disableDefaultUI: true,
    showTraffic: false,
    zoomControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    styles: mapStyles,
    mapTypeId: 'hybrid',
    gestureHandling: 'greedy',
  });
  const buttonDisabledRef = useRef({
    vehicle: false,
  });

  const [vehicles, setVehicles] = useState(() => vehiclesRef.current);
  const [groupings, setGroupings] = useState(() => groupingsRef.current);
  const [operations, setOperations] = useState(() => operationsRef.current);
  const [openDetail, setOpenDetail] = useState(() => null);
  const [httpStatus, setHttpStatus] = useState(() => null);
  const [buttonDisabled, setButtonDisabled] = useState(() => true);
  const [searchLoading, setSearchLoading] = useState(() => true);
  const [data, setData] = useState();
  const [tableLoading, setTableLoading] = useState(() => true);
  const [loading, setLoading] = useState(intl.get('module.Reports.CurrentPosition.loading'));
  const [disabledExports, setDisableExports] = useState(true);

  const tableColumnsRef = useRef([]);
  const columnsRef = useRef([
    {
      title: '',
      field: 'detail',
      render: (row) => (
        <Tooltip title={intl.get(`components.Reports.CurrentPosition.showLocation`)} placement="right">
          <span>
            <IconButton
              color="default"
              onClick={() => {
                mapProps.current.center = {
                  lat: row.vehicleData.latitude,
                  lng: row.vehicleData.longitude,
                };
                setOpenDetail({
                  vehicleDescription: row.vehicleDescription,
                  dateTime: row.dateTime,
                });
              }}
            >
              <FaMapMarkedAlt />
            </IconButton>
          </span>
        </Tooltip>
      ),
    },
    { title: intl.get('module.Reports.CurrentPosition.licensePlate'), field: 'licensePlate' },
    { title: intl.get('module.Reports.CurrentPosition.fleetNumber'), field: 'fleetNumber' },
    { title: intl.get('module.Reports.CurrentPosition.modelDescription'), field: 'modelDescription' },
    { title: intl.get('module.Reports.CurrentPosition.year'), field: 'year' },
    { title: intl.get('module.Reports.CurrentPosition.automakerDescription'), field: 'automakerDescription' },
    { title: intl.get('module.Reports.CurrentPosition.category'), field: 'category' },
    { title: intl.get('module.Reports.CurrentPosition.groupingDescription'), field: 'groupingDescription' },
    { title: intl.get('module.Reports.CurrentPosition.operationDescription'), field: 'operationDescription' },
    { title: intl.get('module.Reports.CurrentPosition.timestamp'), field: 'timestamp' },
    { title: intl.get('module.Reports.CurrentPosition.latitude'), field: 'latitude' },
    { title: intl.get('module.Reports.CurrentPosition.longitude'), field: 'longitude' },
    {
      field: 'location',
      title: intl.get('module.Reports.CurrentPosition.location'),
      render: (row) =>
        row.vehicleData.latitude && row.vehicleData.longitude ? (
          <Location lat={parseFloat(row.vehicleData.latitude)} lng={parseFloat(row.vehicleData.longitude)} />
        ) : (
          'N/D'
        ),
    },
  ]);

  const formatCurrentPositionData = (data) =>
    data.map((item, index) => {
      return {
        ...item,
        index,
        licensePlate: nullString(item.vehicleLicensePlate).toUpperCase(),
        fleetNumber: nullString(item.fleetNumber).toUpperCase(),
        modelDescription: nullString(item.vehicleModel).toUpperCase(),
        automakerDescription: nullString(item.vehicleMaker).toUpperCase(),
        year: nullString(item.vehicleYear),
        category: nullString(item.categoryDescription).toUpperCase(),
        groupingDescription: nullString(item.groupingDescription).toUpperCase(),
        operationDescription: nullString(item.operationDescription).toUpperCase(),
        timestamp: nullDateTime(item.vehicleData.trackingTimestamp),
        latitude: nullString(item.vehicleData.latitude),
        longitude: nullString(item.vehicleData.longitude),
        location: nullString(item.location).toUpperCase(),
      };
    });

  const setButtonDisabledCallback = useCallback((obj) => {
    buttonDisabledRef.current = obj;

    setButtonDisabled(buttonDisabledRef.current.vehicle);
  }, []);

  const setVehiclesCallback = useCallback(
    (obj) => {
      if (!!(obj && obj.constructor && obj.call && obj.apply)) vehiclesRef.current = obj(vehiclesRef.current);
      else vehiclesRef.current = obj;

      setVehicles(vehiclesRef.current);
      setButtonDisabledCallback({
        ...buttonDisabledRef.current,
        vehicle: !Boolean(vehiclesRef.current.find(({ active }) => active)),
      });
    },
    [setButtonDisabledCallback]
  );

  const setGroupingsCallback = useCallback(
    (obj) => {
      if (!!(obj && obj.constructor && obj.call && obj.apply)) groupingsRef.current = obj(groupingsRef.current);
      else groupingsRef.current = obj;

      setGroupings(groupingsRef.current);
      setButtonDisabledCallback({
        ...buttonDisabledRef.current,
      });
    },
    [setButtonDisabledCallback]
  );

  const setOperationsCallback = useCallback(
    (obj) => {
      if (!!(obj && obj.constructor && obj.call && obj.apply)) operationsRef.current = obj(operationsRef.current);
      else operationsRef.current = obj;

      setOperations(operationsRef.current);
      setButtonDisabledCallback({
        ...buttonDisabledRef.current,
      });
    },
    [setButtonDisabledCallback]
  );

  const getCallback = useCallback(async () => {
    try {
      setTableLoading(true);
      const vehicle = vehiclesRef.current.filter(({ active }) => active).map(({ id }) => id);
      const groupingsIds = groupingsRef.current.filter(({ active }) => active).map(({ id }) => id);
      const operationsIds = operationsRef.current.filter(({ active }) => active).map(({ id }) => id);
      const result = await get(
        'vehicle.status',
        {
          companies: auth.selectedCompanies.map(({ id }) => id),
          groupingsIds,
          operationsIds,
          vehiclesIds: vehicle,
        },
        auth.token,
        dispatch
      );
      const contentData = result
        .filter((item) => vehicle.includes(item.vehicleId))
        .filter((item) => {
          if (operationsIds.length > 0) {
            return operationsIds.includes(item.operationId);
          }
          return true;
        })
        .filter((item) => {
          if (groupingsIds.length > 0) {
            return groupingsIds.includes(item.groupingId) || operationsIds.includes(item.operationId);
          }
          return true;
        });
      const removeFleetNumber = !vehiclesRef.current.some(
        ({ listed, active, fleetNumber }) =>
          listed && active && fleetNumber && fleetNumber !== intl.get('global.notAvailable')
      );

      tableColumnsRef.current = columnsRef.current.filter(({ field }) => {
        switch (field) {
          case 'operationDescription':
            return operationsRef.current.length > 0;
          case 'groupingDescription':
            return groupingsRef.current.length > 0;
          case 'fleetNumber':
            return !removeFleetNumber;
          default:
            return true;
        }
      });

      let content = formatCurrentPositionData(contentData);
      if (content.length > 0) {
        setDisableExports(false);
      }
      setData(content);

      firstFetchRef.current = false;
      setSearchLoading(false);
      setTableLoading(false);
    } catch (error) {
      setSearchLoading(false);
      setTableLoading(false);
    }
  }, [auth.selectedCompanies, auth.token, dispatch]);

  const getFilters = useCallback(async () => {
    try {
      const selectedCompanies = auth.selectedCompanies.map(({ id }) => id);

      const [vehiclesResponse, operationsResponse, groupingsResponse] = await Promise.all([
        get(
          'filter.vehicle.filterable',
          {
            companies: selectedCompanies,
            options: ['vehicles'],
            eventsFilters: [],
            parametersFilters: [],
            vehiclesFilters: ['categories', 'groupings'],
          },
          auth.token,
          dispatch
        ),

        get(
          'operation',
          {
            companies: selectedCompanies,
          },
          auth.token,
          dispatch
        ),
        get(
          'grouping',
          {
            companies: selectedCompanies,
          },
          auth.token,
          dispatch
        ),
      ]);

      const { vehicles: vehiclesOptions, vehiclesFilters } = vehiclesResponse;
      const { operations } = operationsResponse.data;
      const { groupings } = groupingsResponse.data;

      if (vehiclesFilters)
        vehiclesFiltersRef.current = vehiclesFilters.map((vehicleFilter) => ({
          ...vehicleFilter,
          active: true,
        }));
      else vehiclesFiltersRef.current = [];

      if (vehiclesOptions) {
        vehiclesRef.current = vehiclesOptions.map(
          (vehicle) =>
            vehiclesRef.current.find(({ id }) => vehicle.id === id) || {
              ...vehicle,
              active: true,
              listed: true,
              matched: true,
            }
        );
        if (!vehiclesRef.current.find(({ active }) => active)) {
          vehiclesRef.current[0].active = true;
        }
      } else vehiclesRef.current = [];

      groupingsRef.current = groupings.map(
        (grouping) =>
          groupingsRef.current.find(({ id }) => grouping.id === id) || {
            ...grouping,
            id: grouping.id,
            label: grouping.description,
            active: false,
            listed: true,
            company: grouping.companyName,
          }
      );

      operationsRef.current = operations.map(
        (operation) =>
          operationsRef.current.find(({ id }) => operation.id === id) || {
            ...operation,
            id: operation.id,
            label: operation.description,
            active: false,
            listed: true,
            company: operation.companyName,
          }
      );

      if (firstFetchRef.current) {
        await getCallback();
      } else {
        setButtonDisabled(false);
      }
      setGroupings(groupingsRef.current);
      setOperations(operationsRef.current);
      setVehicles(vehiclesRef.current);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setHttpStatus(error && error.response && error.response.status ? error.response.status : 404);
    }
  }, [auth.selectedCompanies, auth.token, dispatch, getCallback]);

  const handleSearch = () => {
    getCallback();
    setSearchLoading(true);
    setButtonDisabled(true);
  };

  useEffect(() => {
    getFilters();
  }, [auth.selectedCompanies, getFilters]);

  const onExportReport = useCallback(
    async (exportType) => {
      try {
        setLoading(
          intl.get('module.Reports.CurrentPosition.loading').replace('{exportType}', exportType.toUpperCase())
        );
        const reportData = [];
        for (let item of data) {
          if (typeof item.latitude === 'number' && typeof item.longitude !== intl.get(`global.notAvailable`)) {
            const { location } = await get(
              'location.geocode',
              {
                latitude: item.latitude,
                longitude: item.longitude,
                origin,
              },
              auth.token,
              dispatch
            );
            reportData.push({ ...item, location });
          } else {
            reportData.push({ ...item });
          }
        }
        if (exportType === 'pdf') {
          generatePdfFile({
            language: auth.language,
            reportColumns: tableColumnsRef.current
              .filter(({ field }) => field !== 'detail')
              .map((column) => ({
                ...column,
                header: column.title,
                dataKey: column.field,
              })),
            reportData,
            reportFileName: intl.get('module.Reports.CurrentPosition.fileName', {
              timestamp: format(new Date(), intl.get('global.exportTimestamp')),
            }),
            pageOrientation: 'landscape',
            reportPeriod: intl
              .get('module.Reports.CurrentPosition.fileName')
              .replace('{timestamp}', format(new Date(), intl.get('global.exportTimestamp'))),
            reportTitle: intl.get('module.Reports.CurrentPosition.currentPosition'),
            issuerName: user.name,
          });
        } else if (exportType === 'xls') {
          generateXlsFile({
            data: {
              columns: tableColumnsRef.current
                .filter(({ field }) => field !== 'detail')
                .map(({ title, field }) => ({ header: title, key: field })),
              rows: data,
            },
            title: intl.get('module.Reports.CurrentPosition.currentPosition'),
            fileName: intl.get('module.Reports.CurrentPosition.fileName', {
              timestamp: format(new Date(), intl.get('global.exportTimestamp')),
            }),
            userName: user.name,
            period: intl
              .get('module.Reports.CurrentPosition.fileName')
              .replace('{timestamp}_Posição_atual', format(new Date(), intl.get('global.exportTimestamp'))),
          });
        } else if (exportType === 'csv') {
          generateCsvFile({
            columns: tableColumnsRef.current
              .filter(({ field }) => field !== 'detail')
              .map((column) => ({
                ...column,
                header: column.title,
                dataKey: column.field,
              })),
            data: data,
            title: intl.get('module.Reports.CurrentPosition.currentPosition'),
            fileName: intl.get('module.Reports.CurrentPosition.fileName', {
              timestamp: format(new Date(), intl.get('global.exportTimestamp')),
            }),
            userName: user.name,
            period: intl
              .get('module.Reports.CurrentPosition.reportPeriod')
              .replace('{startDate}', format(new Date(), intl.get('global.exportTimestamp'))),
          });
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
    [auth.token, data, dispatch, user.name]
  );

  if (loading) return <CustomPreloader status={loading} />;
  else if (httpStatus) return <CustomErrorPage status={httpStatus} />;
  else
    return (
      <Wrapper>
        <Tabs value={0} classes={{ root: classes.tabsRoot }} variant="scrollable" indicatorColor="primary">
          <Tab
            label={intl.get('module.Reports.CurrentPosition.currentPosition')}
            key={0}
            classes={{ root: classes.tabRoot }}
            disableRipple
          />
        </Tabs>
        <CustomWrapper withNavTabs>
          <div className={classes.row}>
            <div className={classes.columnComboBox}>
              <CustomAutoCompleteComboBox
                title={intl.get('components.Reports.vehiclesComboBox')}
                inputPlaceholder={intl.get('components.Reports.inputPlaceholder')}
                items={vehicles}
                setItems={setVehiclesCallback}
                filtersRef={vehiclesFiltersRef}
                required
                multiSelect
                inputTextRef={inputFilteredTextRef}
              />
            </div>
            {operations.length > 0 && (
              <div className={classes.columnComboBox}>
                <CustomAutoCompleteComboBox
                  title={intl.get(`components.Reports.operationsComboBox`)}
                  inputPlaceholder={intl.get('components.Reports.inputPlaceholder')}
                  items={operations}
                  setItems={setOperationsCallback}
                  inputTextRef={inputOperationTextRef}
                  feminine
                  multiSelect
                />
              </div>
            )}
            {groupings.length > 0 && (
              <div className={classes.columnComboBox}>
                <CustomAutoCompleteComboBox
                  title={intl.get(`components.Reports.groupingsComboBox`)}
                  inputPlaceholder={intl.get('components.Reports.inputPlaceholder')}
                  items={groupings}
                  setItems={setGroupingsCallback}
                  inputTextRef={inputGroupingTextRef}
                  multiSelect
                />
              </div>
            )}

            <div className={classes.columnButton}>
              <Button
                classes={{
                  root: classes.buttonRoot,
                }}
                color="primary"
                variant="contained"
                fullWidth={true}
                disabled={searchLoading || buttonDisabled}
                onClick={handleSearch}
              >
                {searchLoading ? <FaSync className={classes.animation} /> : <FaSearch />}
              </Button>
            </div>
          </div>
          <Dialog
            open={Boolean(openDetail)}
            onClose={() => setOpenDetail(false)}
            classes={{
              root: classes.root,
              paperScrollPaper: classes.paper,
            }}
          >
            <div className={classes.dialogHeader}>
              <IconButton onClick={() => setOpenDetail(false)} className={classes.dialogIconCloseButton}>
                <FaTimes />
              </IconButton>
            </div>
            <div className={classes.containerMap}>
              <CustomGoogleMap
                optionsMap={mapProps.current}
                functionRenderMarker={() => {
                  const markerProps = {
                    position: {
                      lat: mapProps.current.center.lat,
                      lng: mapProps.current.center.lng,
                    },
                  };
                  return <Marker {...markerProps}></Marker>;
                }}
                menuBarMapButtons={false}
              />
            </div>
          </Dialog>
          <CustomTable
            tableRef={tableRef}
            columns={tableColumnsRef.current}
            data={data}
            isLoading={tableLoading}
            noCsvExport={disabledExports}
            noXlsExport={disabledExports}
            noPdfExport={disabledExports}
            onExportReport={onExportReport}
            sorting
            search
            toolbar
          />
        </CustomWrapper>
      </Wrapper>
    );
});

export const CurrentPosition = React.memo(FunctionComponent);

export default CurrentPosition;
