import React, { useEffect, useState, lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSetup } from './store/actions/Auth';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import intl from 'react-intl-universal';
import { Preloader, DrawerProvider } from './core';
import { ToastContainer } from 'react-toastify';
import locales from './locales/locales';
import Catcher from './screens/Catcher';
import ControlTowerContextProvider from './context/ControlTower';
import ReportsContextProvider from './context/Reports';
import TrunkContextProvider from './context/Trunk';
import CurrentPosition from './module/Reports/CurrentPosition/CurrentPosition';

const ControlTower = lazy(() => import('./module/ControlTower/ControlTower'));
const Tracker = lazy(() => import('./module/Tracker'));
const Home = lazy(() => import('./module/Home'));
const Analytics = lazy(() => import('./module/Analytics'));
const CylinderCutting = lazy(() => import('./module/CylinderCutting'));
const Journey = lazy(() => import('./module/Journey/Journey'));
const Rating = lazy(() => import('./module/DriverRating/Rating'));
const AggressiveV2 = lazy(() => import('./module/DriverPerformance/AggressiveV2'));
const RatingTest = lazy(() => import('./module/DriverPerformance/RatingTest'));
const DriverLogAppointments = lazy(() => import('./module/DriverLog/Appointments'));
const DriverLogClocking = lazy(() => import('./module/DriverLog/DriverLogClocking'));
const DriverLogReports = lazy(() => import('./module/DriverLog/DriverLogReports'));
const DriverLogControl = lazy(() => import('./module/DriverLog/Control/Control'));
const DriverLogTimeline = lazy(() => import('./module/DriverLog/DriverLogTimeline'));
const DriverLogConfiguration = lazy(() => import('./module/DriverLog/DriverLogConfiguration'));
const Checklist = lazy(() => import('./module/DriverLog/Checklist/Checklist'));
const ChecklistConfiguration = lazy(() => import('./module/DriverLog/Checklist/ChecklistConfiguration'));
const DriverLogSummaryReport = lazy(() => import('./module/DriverLog/DriverLogSummaryReport'));
const PowerTrain = lazy(() => import('./module/PowerTrain/PowerTrainByCompany'));
const PowerTrainRating = lazy(() => import('./module/PowerTrain/PowerTrainRating'));
const DrivingPerformance = lazy(() => import('./module/PowerTrain/DrivingPerformance'));
const AggressiveVehicles = lazy(() => import('./module/PowerTrain/AggressiveVehicles'));
const PowerTrainPerformance = lazy(() => import('./module/PowerTrain/PowerTrainPerformanceByCompany'));
const PredictiveMaintenance = lazy(() => import('./module/PowerTrain/PredictiveMaintenanceByCompany'));
const DriverLogInternalSettings = lazy(() => import('./module/DriverLog/DriverLogInternalSettings'));
const Trunk = lazy(() => import('./module/Monitoring/Trunk/Trunk'));
const ControlPoints = lazy(() => import('./module/Monitoring/ControlPoints/ControlPoints'));
const Travel = lazy(() => import('./module/Monitoring/Travel/Travel'));
const Macro = lazy(() => import('./components/Macro/Macro'));
const IOEvents = lazy(() => import('./components/IOEvents/IOEvents'));
const Review = lazy(() => import('./components/Maintenance/Maintenance'));
const DriverRecord = lazy(() => import('./components/Reports/DriverRecord/DriverRecord'));
const Alerts = lazy(() => import('./components/AwesomeAlerts/AwesomeAlerts'));
const Navbar = lazy(() => import('./components/Navbar/Navbar'));
const Snackbar = lazy(() => import('./containers/Snackbar'));
const Dialog = lazy(() => import('./containers/Dialog'));
const Fences = lazy(() => import('./screens/Fences'));
const ReferencePoints = lazy(() => import('./screens/ReferencePoints'));
const Dashboard = lazy(() => import('./screens/Dashboard'));
const NotFound = lazy(() => import('./screens/NotFound'));
const Error = lazy(() => import('./screens/Error'));
const User = lazy(() => import('./components/Cruds/Users/UsersManagement'));
const Company = lazy(() => import('./components/Cruds/Companies/CompaniesManagement'));
const Vehicle = lazy(() => import('./components/Cruds/Vehicles/VehiclesManagement'));
const Installation = lazy(() => import('./components/Cruds/Installations/RegisterManagement'));
const Dtc = lazy(() => import('./components/Dtc/Dtc'));
const ReadDTC = lazy(() => import('./components/DiagnosticTroubleCodes/ReadDTC'));
const diagnosticTroubleCode = lazy(() => import('./module/Maintenance/DTC/DTC'));
const DtcDelete = lazy(() => import('./components/DiagnosticTroubleCodes/DeleteDTC'));
const SpecialFunction = lazy(() => import('./components/SpecialFunctions/SpecialFunction'));
const Telemetric = lazy(() => import('./components/Telemetric/Telemetric'));
const JourneyManagement = lazy(() => import('./components/Journey/Journey/JourneyManagement'));
const ReportTelemetry = lazy(() => import('./components/ReportsV2/Telemetry'));
const ReportDistance = lazy(() => import('./components/ReportsV2/Distance'));
const ReportTrackHistory = lazy(() => import('./components/ReportsV2/TrackHistory'));
const ReportTrackHistoryExport = lazy(() => import('./components/ReportsV2/TrackHistoryExport'));
const ReportFence = lazy(() => import('./components/ReportsV2/Fence'));
const TimeStatus = lazy(() => import('./components/ReportsV2/TimeStatus'));
const ConsumptionReports = lazy(() => import('./components/ReportsV2/Consumption'));
const RankingDrivers = lazy(() => import('./components/Rankings/Drivers'));
const ReportLockTrunk = lazy(() => import('./components/ReportsV2/LockTrunk'));
const Block = lazy(() => import('./module/Monitoring/Block/Block'));
const DmsReport = lazy(() => import('./module/Reports/DMS/DmsReport'));
const ReportIO = lazy(() => import('./module/Reports/IOEvents/IOEvents'));
const OperationFence = lazy(() => import('./module/Reports/Fence/OperationFence'));
const AuditCommandLogs = lazy(() => import('./module/Audit/CommandLogs'));

const AlertsOccurrences = lazy(() => import('./module/Alerts/Occurrence/Occurrences'));
const AlertsConfigurations = lazy(() => import('./module/Alerts/Configuration/ConfigurationsSelector'));

const Configuration = lazy(() => import('./module/AdvancedImmobilization/Configuration/Configuration'));
const AlertsConfigurationsHistory = lazy(() => import('./module/Alerts/ConfigurationsHistory/ConfigurationsHistory'));
const ConfigurationHistory = lazy(() => import('./module/AdvancedImmobilization/ConfigurationHistory/ConfigurationHistory'));
const ImmobilizationBlock = lazy(() => import('./module/AdvancedImmobilization/Block/Block'));
const Synchronization = lazy(() => import('./module/AdvancedImmobilization/Synchronization/Synchronization'));
const ImmobilizationHistory = lazy(() => import('./module/AdvancedImmobilization/ImmobilizationHistory/ImmobilizationHistory'));
const ImmobilizationHistoryToExport = lazy(() => import('./module/AdvancedImmobilization/ImmobilizationHistory/ImmobilizationHistoryToExport'));
const VehicleProgressDataReport = lazy(() => import('./module/SupportReport/ProcessData/ProcessDataReport'));
const Maintenance = lazy(() => import('./module/Maintenance/Maintenance'));
const Operation = lazy(() => import('./module/Management/Operation/Operation'));
const Implements = lazy(() => import('./module/Management/Implements/Implements'));
const Grouping = lazy(() => import('./module/Management/Grouping/Grouping'));
const Driver = lazy(() => import('./module/Management/Driver/Driver'));
const ProfileConfigurationAlert = lazy(() => import('./module/Alerts/ProfileConfiguration/ProfileConfigurations'));
const EventsConfigurationsProfile = lazy(() => import('./module/Events/ConfigurationsProfile'));
// const EventsProfileHistory = lazy(() => import('./module/Events/ProfileHistory'));
const ProfileConfigurationAdvancedImmobilization = lazy(() => import('./module/AdvancedImmobilization/ProfileConfiguration/ProfileConfigurations'));
const TrunkTemperatureReport = lazy(() => import('./module/Reports/TrunkTemperature/TrunkTemperature'));

// /DriverPerformance
const DriverPerformance = lazy(() => import('./module/DriverPerformance/DriverPerformance'));
const DriverPerformanceRating = lazy(() => import('./module/DriverPerformance/DriverPerformanceRating'));
const DriverPerformanceV2 = lazy(() => import('./module/DriverPerformance/DriverRatingV2'));
const DriverPerformanceFixedDriver = lazy(() => import('./module/DriverPerformance/DriverPerformanceFixedDriver'));
const DriverPerformanceSettings = lazy(() => import('./module/DriverPerformance/DriverPerformanceSettings'));

// /PiloteBem
const PiloteBemSettings = lazy(() => import('./module/PiloteBem/PiloteBemSettings'));
const PiloteBemRanking = lazy(() => import('./module/PiloteBem/PiloteBemRanking'));
const PiloteBemDriverGoal = lazy(() => import('./module/PiloteBem/PiloteBemDriverGoal'));

const App = () => {
  const dispatch = useDispatch();

  const [loaded, setLoaded] = useState(false);
  const auth = useSelector((s) => s.auth);

  const { menuPermissions, companies, language } = auth;

  const searchbar = useSelector((s) => s.searchbar);

  const warningHandler = (message, detail) => null;

  intl.init({
    currentLocale: language,
    locales,
    warningHandler,
  });

  useEffect(() => {
    dispatch(fetchSetup());
  }, [dispatch]);

  useEffect(() => {
    if (menuPermissions.length && companies.length && searchbar.isFetched) setLoaded(true);
  }, [menuPermissions, companies, searchbar]);

  const routerProps = {
    initialEntries: ['/'],
    initialIndex: 0,
  };

  return (
    <DrawerProvider>
      <TrunkContextProvider>
        <ReportsContextProvider>
          <ControlTowerContextProvider>
            {!loaded ? (
              <Catcher>
                <Preloader />
              </Catcher>
            ) : (
              <Catcher>
                <Routes router={routerProps} />
              </Catcher>
            )}
            <ToastContainer autoClose={3000} newestOnTop />
          </ControlTowerContextProvider>
        </ReportsContextProvider>
      </TrunkContextProvider>
    </DrawerProvider>
  );
};
// prettier-ignore
const Routes = (props) => (
	<Suspense fallback={<div />}>
		<Router>
			<Route path='/' component={Navbar} />
			<Route path='/' component={Snackbar} />
			<Route path='/' component={Dialog} />
			<Switch>
				<Route exact path='/error' component={Error} />
				<Route exact path='/' component={Home} />
				<Route exact path='/dashboard' component={Dashboard} />
				<Route exact path='/analytics' component={Analytics} />
				<Route exact path='/avaliacao' component={Rating} />
				{/*Driver Performance*/}
				<Route exact path='/driverperformance/rating' component={DriverPerformance} />
				<Route exact path='/driverperformance/teste' component={RatingTest} />
				<Route exact path='/driverperformance/avaliacao' component={DriverPerformanceRating} />
				<Route exact path='/driverperformance/motoristasavaliacao' component={DriverPerformanceV2} />
				<Route exact path='/driverperformance/motoristasagressivos' component={AggressiveV2} />
				<Route exact path='/driverperformance/motorista' component={DriverPerformanceFixedDriver} />
				<Route exact path='/driverperformance/parametros' component={DriverPerformanceSettings} />
				{/*Powertrain*/}
				<Route exact path='/powertrain/avaliacao' component={PowerTrainRating} />
				<Route exact path='/powertrain/geral' component={PowerTrain} />
				<Route exact path='/powertrain/conducao' component={DrivingPerformance} />
				<Route exact path='/powertrain/agressivos' component={AggressiveVehicles} />
				<Route exact path='/powertrain/performance' component={PowerTrainPerformance} />
				<Route exact path='/powertrain/manutencao' component={PredictiveMaintenance} />
				<Route exact path='/manutencao/cilindro' component={CylinderCutting} />

				{/*FleetFull driver*/}
				<Route exact path='/fleetfulldriver/apontamentos' component={DriverLogAppointments} />
				<Route exact path='/fleetfulldriver/registrojornada' component={DriverLogClocking} />
				<Route exact path='/fleetfulldriver/historicomotorista' component={DriverLogReports} />
				<Route exact path='/fleetfulldriver/controlejornada' component={DriverLogControl} />
				<Route exact path='/fleetfulldriver/timelinemotorista' component={DriverLogTimeline} />
				<Route exact path='/fleetfulldriver/vistoria' component={Checklist} />
				<Route exact path='/fleetfulldriver/configuracoesjornada' component={DriverLogConfiguration} />
				<Route exact path='/fleetfulldriver/configuracoesinternas' component={DriverLogInternalSettings} />
				<Route exact path='/fleetfulldriver/configuracoesvistoria' component={ChecklistConfiguration} />
				<Route exact path='/fleetfulldriver/controleresumido' component={DriverLogSummaryReport} />
				{/*Pilote Bem*/}
				<Route exact path='/pilotebem/ranking' component={PiloteBemRanking} />
				<Route exact path='/pilotebem/meta' component={PiloteBemDriverGoal} />
				<Route exact path='/pilotebem/parametros' component={PiloteBemSettings} />
				{/*Jornada teclado*/}
				<Route exact path='/jornada/apontamentos' component={Journey} />
				{/*Monitoramento*/}
				<Route exact path='/controle' component={ControlTower} />
				<Route exact path='/monitoramento/rastreador' component={Tracker} />
				<Route exact path='/monitoramento/rastreador/:vehicleId' component={Tracker} />
				<Route exact path='/monitoramento/cercas' component={Fences} />
				<Route exact path='/monitoramento/pontosreferencia' component={ReferencePoints} />
				<Route exact path='/monitoramento/telemetria' component={Telemetric} />
				<Route exact path='/monitoramento/viagens' component={Travel} />
				{/* <Route exact path='/monitoramento/relatorios' component={Reports} />
        <Route exact path='/monitoramento/relatorios/:key/:begin/:end' component={Reports}/>
        <Route exact path='/monitoramento/relatorios/:key' component={Reports}/>
        <Route exact path='/monitoramento/relatorios/:key/:id' component={Reports}/> */}
				<Route exact path='/monitoramento/eventoses' component={IOEvents} />
				<Route exact path='/monitoramento/eventoses/:type/:id' component={IOEvents} />
				<Route exact path='/motorista/:driver' component={DriverRecord} />
				<Route exact path='/motorista/:driver/:year/:month' component={DriverRecord} />
				<Route exact path='/monitoramento/alertas' component={Alerts} />
				<Route exact path='/monitoramento/alertas/:vehicle' component={Alerts} />
				<Route exact path='/monitoramento/alertas/:eventId/:notificationId' component={Alerts} />
				<Route exact path='/monitoramento/controle' component={ControlPoints} />
				<Route exact path='/monitoramento/controle/:open' component={ControlPoints} />
				<Route exact path='/monitoramento/bloqueio' component={Block} />
				{/* -------------- REPORTS -------------- */}
				<Route exact path={intl.get('routes.Reports.Telemetry')} component={ReportTelemetry} />
				<Route exact path={`${intl.get('routes.Reports.Telemetry')}/:startDate/:endDate`} component={ReportTelemetry} />
				<Route exact path={intl.get('routes.Reports.Distance')} component={ReportDistance} />
				<Route exact path={`${intl.get('routes.Reports.Distance')}/:startDate/:endDate`} component={ReportDistance} />
				<Route exact path={`/relatorios/trajeto`} component={ReportTrackHistory} />
				<Route exact path='/relatorios/trajeto/:vehicleId' component={ReportTrackHistory} />
				<Route exact path='/relatorios/trajeto/export/:vehicleId/:startDate/:endDate' component={ReportTrackHistoryExport} />
				<Route exact path={intl.get('routes.Reports.Consumption')} component={ConsumptionReports} />
				<Route exact path='/relatorios/status' component={TimeStatus} />
				<Route exact path='/relatorios/cerca' component={ReportFence} />
				<Route exact path='/relatorios/bau' component={ReportLockTrunk} />
				<Route exact path='/relatorios/eventoses' component={ReportIO} />
				<Route exact path='/relatorios/temperaturadobau' component={TrunkTemperatureReport} />
				<Route exact path='/relatorios/operacao/cerca' component={OperationFence} />
				<Route exact path='/relatorios/posicao' component={CurrentPosition} />
				{/* -------------- REPORTS -------------- */}
				<Route exact path='/ranking/motoristas' component={RankingDrivers} />
				<Route exact path='/gerenciamento/usuario' component={User} />
				<Route exact path='/gerenciamento/empresa' component={Company} />
				<Route exact path='/gerenciamento/veiculo' component={Vehicle} />
				<Route exact path='/gerenciamento/motorista' component={Driver} />
				<Route exact path='/gerenciamento/instalacao' component={Installation} />
				<Route exact path='/gerenciamento/operacao' component={Operation} />
				<Route exact path='/gerenciamento/implementos' component={Implements} />
				<Route exact path='/gerenciamento/agrupamento' component={Grouping} />
				<Route exact path='/manutencao/dtc' component={Dtc} />
				<Route exact path='/manutencao/solicitacaodtc' component={ReadDTC} />
				<Route exact path='/manutencao/diagnosticodefalha' component={diagnosticTroubleCode} />
				<Route exact path='/manutencao/apagarfalhas' component={DtcDelete} />
				<Route exact path='/manutencao/revisao/' component={Review} />
				<Route exact path='/manutencao/revisao/:revisao' component={Review} />
				<Route exact path='/manutencao/funcoesespeciais' component={SpecialFunction} />
				<Route exact path='/jornada/controle' component={JourneyManagement} />
				<Route exact path='/jornada/macro' component={Macro} />
				<Route exact path='/jornada/macro/:macro' component={Macro} />
				<Route exact path='/abastecimento' component={Home} />
				<Route exact path='/carsharing' component={Home} />
				<Route exact path='/seguranca' component={Home} />
				<Route exact path='/agricola' component={Home} />
				<Route exact path={intl.get('routes.Monitoring.Trunk')} component={Trunk} />
				<Route exact path={`${intl.get('routes.Monitoring.Trunk')}/:tab`} component={Trunk} />
				<Route exact path='/relatorios/dms' component={DmsReport} />
				{/* -------------- AUDIT -------------- */}
				<Route exact path='/auditoria/registrocomandos' component={AuditCommandLogs} />
				{/* -------------- ALERTS -------------- */}
				<Route exact path='/alertas/ocorrencias' component={AlertsOccurrences} />
				<Route exact path='/alertas/ocorrencias/:notificationVehicleId/:notificationTimestamp/:notificationAlertType' component={AlertsOccurrences} />
				<Route exact path='/alertas/configuracoes' component={AlertsConfigurations} />
				<Route exact path='/alertas/historico/configuracao' component={AlertsConfigurationsHistory} />
				<Route exact path='/alertas/configuracao/perfil' component={ProfileConfigurationAlert} />
				<Route exact path='/eventos/configuracao/perfil' component={EventsConfigurationsProfile} />
				{/* <Route exact path='/eventos/configuracao/perfilhistorico' component={EventsProfileHistory}></Route> */}
				<Route exact path='/imobilizacao/configuracao' component={Configuration} />
				<Route exact path='/imobilizacao/historico/configuracao' component={ConfigurationHistory} />
				<Route exact path='/imobilizacao/bloqueio' component={ImmobilizationBlock} />
				<Route exact path='/imobilizacao/configuracao/perfil' component={ProfileConfigurationAdvancedImmobilization} />
				<Route exact path='/imobilizacao/sincronismo' component={Synchronization} />
				<Route exact path='/imobilizacao/historico/geral' component={ImmobilizationHistory} />
				<Route exact path='/imobilizacao/historico/geral/:vehicle/:initialTimestamp/:finalTimestamp/:activeEvent' component={ImmobilizationHistoryToExport} />
				<Route exact path='/debug' component={VehicleProgressDataReport} />
				<Route exact path='/manutencao/modo' component={Maintenance} />
				<Route component={NotFound} />
			</Switch>
		</Router>
	</Suspense>
);

export default App;
