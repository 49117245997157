import { FleetTypes as types } from "../types";
import axios from "axios";
import { spacer } from "../../helpers";

const { REACT_APP_API: path, REACT_APP_GOOGLE_KEY: key } = process.env;

export const fetchFleet = () => {
  return (dispatch, getState) => {
    const { token, selectedCompanies } = getState().auth;
    const endpoint = `${path}/dadosveiculo/geral`;
    const headers = { headers: { Authorization: `bearer ${token}` } };
    const payload = {
      empresasSelecionadas: selectedCompanies.map((x) => x.id),
    };
    axios
      .post(endpoint, payload, headers)
      .then((res) => {
        dispatch({ type: types.STORE_FLEET, data: res.data });
      })
      .catch((err) => console.error(err));
  };
};

export const resetTimer = (initial) => (dispatch) =>
  dispatch({ type: types.CLEAR_TIMER, initial });

export const fetchRoute = (payload, token) => {
  return new Promise((resolve, reject) => {
    const { begin, end, id } = payload;
    const endpoint = `${path}/dadosveiculo/rota/${id}/${begin}/${end}`;
    const headers = { headers: { Authorization: `bearer ${token}` } };
    axios
      .get(endpoint, headers)
      .then((res) => storeRoute(res.data, resolve, reject))
      .catch((err) => reject(err));
  });
};

const snapPath = (str) =>
  `https://roads.googleapis.com/v1/snapToRoads?path=${str}&interpolate=true&key=${key}`;

export const storeRoute = (data, resolve, reject) => {
  const rawPaths = data.length ? spacer(data) : [];

  const parsedPaths = rawPaths.map((path) =>
    path.map((point) => {
      return `${point.latitude},${point.longitude}`;
    })
  );

  const promises = [];

  for (let path of parsedPaths) {
    const request = axios.get(snapPath(path.join("|")));
    promises.push(request);
  }
 
  Promise.all(promises)
    .then((res) => {
      const responses = res
        .filter((item) => item.data.hasOwnProperty("snappedPoints"))
        .map((arr) => arr.data.snappedPoints.map((point) => point.location));
      const points = responses.flat(2).map((point) => ({
        lat: parseFloat(point.latitude),
        lng: parseFloat(point.longitude),
      }));  
      resolve(points);
    })
    .catch((err) => reject(err));
};
