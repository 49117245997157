import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CustomTitle } from "..";
import { useStyles } from "../../hooks";
import styles from "./jss/customAutoComboBox";

export const CustomAutoComboBox = ({
  customTitle = false,
  title = "",
  items = [],
  size = "small",
  setter = () => {},
  defaultValue = false,
  disabled = false,
  placeholder = "",
}) => {
  const classes = useStyles(styles);

  const [setDefaultValue] = useState(
    !defaultValue ? null : items[items.findIndex((x) => x.id === defaultValue)]
  );

  return (
    <>
      {customTitle && (
        <CustomTitle
          title={title ? title : customTitle}
          classes={{ container: classes.customTitleContainer }}
        />
      )}
      <Autocomplete
        id="combo-box-auto"
        size={size}
        options={items ? items : []}
        getOptionLabel={(option) => (option.title ? option.title : "")}
        defaultValue={setDefaultValue}
        onChange={(event, newValue) => {
          setter(newValue);
        }}
        disabled={disabled}
        noOptionsText={"Sem opções disponíveis"}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            label={!customTitle && title}
            variant="outlined"
            focused={false}
          />
        )}
      />
    </>
  );
};

export default CustomAutoComboBox;