export const styles = (theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    userSelect: 'none',
    fontFamily: theme.typography.standard.fontFamily,
    fontSize: theme.typography.standard.fontSize,
    fontWeight: theme.typography.standard.fontWeightBold,
    letterSpacing: theme.typography.standard.letterSpacing,
    color: theme.palette.text.secondary,
  },
  customTitleContainer: {
    minHeight: '1rem',
    marginBottom: 3,
    marginLeft: 3,
    fontSize: theme.typography.standard.fontSizeSm,
  },
  input: {
    display: 'block',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.background.paper,
    width: theme.dimensions.customWidth.full,
    minHeight: theme.dimensions.customHeight.small,
    borderStyle: 'solid',
    borderWidth: theme.dimensions.customBorder.small,
    borderRadius: theme.dimensions.customBorderRadius.small,
    padding: theme.dimensions.customPadding.medium,
    paddingLeft:
      theme.dimensions.customPadding.medium +
      theme.dimensions.customBorder.medium -
      theme.dimensions.customBorder.small,
    fontFamily: 'inherit',
    fontSize: '0.95em',
    fontWeight: 'inherit',
    letterSpacing: 'inherit',
    color: 'inherit',
    overflow: 'hidden',
    outline: 'none',
  },
  invalidInput: {
    borderColor: theme.palette.error.dark,
    '&:focus': {
      borderWidth: theme.dimensions.customBorder.medium,
    },
    '&:hover': {
      borderColor: theme.palette.error.dark,
    },
  },
  validInput: {
    borderColor: theme.palette.text.secondary,
    '&:focus': {
      borderColor: theme.palette.primary.dark,
      borderWidth: theme.dimensions.customBorder.medium,
      '&:hover': {
        borderColor: theme.palette.primary.dark,
      },
    },
    '&:hover': {
      borderColor: theme.palette.text.primary,
    },
  },
});

export default styles;
