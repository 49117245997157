import React from 'react';

import { Permanent, Temporary } from '.';

export const Drawer = ({ variant, ...rest }) => {
  const render = () => {
    switch (variant) {
      case 'permanent':
        return <Permanent {...rest} />;
      case 'temporary':
        return <Temporary {...rest} />;
      default:
        return null;
    }
  };
  return render();
};

Drawer.defaultProps = {
  variant: 'permanent',
};

export default Drawer;
