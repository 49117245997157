import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';

import { context } from '../../components/Navbar/context';
import { Drawer, Bar, Companies, User } from '../../core';
import Notifications from '../Notifications/Notification';

import getConfig from '../../config/configLoader';

import styles from './jss/navbar';
import { useStyles } from '../../core';

export const Navbar = () => {
  const { mainDistribution, sponsorLogo } = getConfig();

  const classes = useStyles(styles);

  const auth = useSelector((s) => s.auth);
  const drawer = useContext(context);
  const { variant } = drawer;

  const { menuPermissions: items, companies, selectedCompanies: selected, profile } = auth;

  return (
    <>
      <Bar variant={variant} profile={profile}>
        {!mainDistribution ? (
          <div className={classes.logoContainer}>
            <span>{intl.get('navbar.poweredByImg')}</span>
            <img src={sponsorLogo} className={classes.logo} alt={intl.get('navbar.poweredByImgAlt')} />
          </div>
        ) : (
          <div />
        )}

        <Companies companies={companies} selected={selected} />
        <User />
        <Notifications />
      </Bar>

      <Drawer items={items} variant={variant} />
    </>
  );
};

export default Navbar;
