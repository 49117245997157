import { theme } from '../../../styles';

export const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-around',
    overflow: 'auto',
    userSelect: 'none',
    width: '100%',
  },
  font: {
    fontFamily: theme.typography.standard.fontFamily,
    fontSize: theme.typography.standard.fontSize,
    fontWeight: theme.typography.standard.fontWeightRegular,
    letterSpacing: theme.typography.standard.letterSpacing,
    color: theme.palette.text.secondary,
  },
  exportIconsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: theme.dimensions.customMargin.small,
  },
  toolbarButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  toolbarContentFull: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    '@media(max-width: 696px)': {
      width: `calc(100% - ${2 * theme.dimensions.customMargin.small}px)`,
      flexDirection: 'column',
    },
  },
  toolbarContent: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    '@media(max-width: 396px)': {
      width: `calc(100% - ${2 * theme.dimensions.customMargin.small}px)`,
      flexDirection: 'column',
    },
  },
  toolbarContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '@media(max-width: 696px)': {
      flexDirection: 'column',
      alignItems: 'end',
    },
  },
  componentSearch: {
    margin: '10px',
    position: 'end',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
    },
  },
});

export const headerStyle = {
  height: theme.dimensions.customHeight.medium,
  borderColor: 'rgba(224, 224, 224, 1)',
  borderTopStyle: 'solid',
  borderWidth: theme.dimensions.customBorder.small,
  fontSize: '1.05em',
  fontWeight: theme.typography.standard.fontWeightBold,
  textAlign: 'center',
  whiteSpace: 'nowrap',
};

export const headerStyleWithSorting = {
  paddingLeft: 42,
};

export const cellStyle = {
  fontSize: '0.95em',
  textAlign: 'center',
  whiteSpace: 'nowrap',
};

export default styles;
