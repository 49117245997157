import React from "react";
import LicensePlate from "./LicensePlate";

const colors = ["#bc0000", "#00bf00"];
const sind = (degrees) => {
  return Math.sin(degrees * (Math.PI / 180));
};
const cosd = (degrees) => {
  return Math.cos(degrees * (Math.PI / 180));
};
const abs = (value) => {
  return Math.abs(value);
};
const vehicleDraw = {
  width: 358.85,
  height: 789.36,
};
const licensePlateDraw = {
  ratio: 1 / 3,
  width: 1400,
  marginTop: 30,
  marginRight: 30,
  marginBottom: 30,
  marginLeft: 30,
};
licensePlateDraw.height = licensePlateDraw.width * licensePlateDraw.ratio;
licensePlateDraw.totalWidth =
  licensePlateDraw.width +
  licensePlateDraw.marginLeft +
  licensePlateDraw.marginRight;
licensePlateDraw.totalHeight =
  licensePlateDraw.height +
  licensePlateDraw.marginTop +
  licensePlateDraw.marginBottom;

export const CarIcon = ({
  ignition,
  license,
  degrees,
  countryAbbreviation,
}) => {
  vehicleDraw.rotatedWidth =
    abs(vehicleDraw.width * cosd(degrees)) +
    abs(vehicleDraw.height * sind(degrees));
  vehicleDraw.rotatedHeight =
    abs(vehicleDraw.width * sind(degrees)) +
    abs(vehicleDraw.height * cosd(degrees));

  const viewBoxWidth =
    vehicleDraw.rotatedWidth >= licensePlateDraw.totalWidth
      ? vehicleDraw.rotatedWidth
      : licensePlateDraw.totalWidth;
  const viewBoxHeight =
    vehicleDraw.rotatedHeight + licensePlateDraw.totalHeight;

  vehicleDraw.widthPosition =
    (viewBoxWidth - vehicleDraw.width) / 2 +
    (licensePlateDraw.totalHeight / 2) * sind(degrees);
  vehicleDraw.heightPosition =
    (viewBoxHeight - vehicleDraw.height) / 2 +
    (licensePlateDraw.totalHeight / 2) * cosd(degrees);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="svg171"
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      version="1.0"
    >
      <LicensePlate
        width={licensePlateDraw.width}
        x={licensePlateDraw.marginLeft}
        y={licensePlateDraw.marginTop}
        countryAbbreviation={countryAbbreviation}
        license={license}
      />
      <g
        transform={`rotate(${degrees}) translate(${vehicleDraw.widthPosition}, ${vehicleDraw.heightPosition})`}
        style={{
          transformOrigin: "center",
        }}
      >
        <defs>
          <linearGradient id="linearGradient239">
            <stop id="stop240" stopColor="#f2f2f2" offset="0" />
            <stop id="stop241" stopColor="#b5b5b5" offset="1" />
          </linearGradient>
          <radialGradient
            id="radialGradient3189"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xlinkHref="#linearGradient239"
            gradientUnits="userSpaceOnUse"
            cy="819.9"
            cx="378.79"
            gradientTransform="matrix(1.1693 0 0 .93776 -273.1 -147.55)"
            r="115.39"
          />
          <radialGradient
            id="radialGradient3193"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xlinkHref="#linearGradient239"
            gradientUnits="userSpaceOnUse"
            cy="598.63"
            cx="294.43"
            gradientTransform="matrix(1.5041 0 0 .74199 -264.04 -146.71)"
            r="91.234"
          />
          <radialGradient
            id="radialGradient3197"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xlinkHref="#linearGradient239"
            gradientUnits="userSpaceOnUse"
            cy="153.65"
            cx="897.24"
            gradientTransform="matrix(-.39086 0 0 3.6334 703.38 -167.47)"
            r="15.849"
          />
          <radialGradient
            id="radialGradient3200"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xlinkHref="#linearGradient239"
            gradientUnits="userSpaceOnUse"
            cy="153.65"
            cx="897.24"
            gradientTransform="matrix(.39086 0 0 3.6334 -343 -162.47)"
            r="15.849"
          />
          <radialGradient
            id="radialGradient3205"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xlinkHref="#linearGradient239"
            gradientUnits="userSpaceOnUse"
            cy="319.37"
            cx="657.76"
            gradientTransform="matrix(.65803 0 0 1.5197 -252.14 -111.82)"
            r="1269.3"
          />
        </defs>
        <rect
          id="rect959"
          fillRule="evenodd"
          rx="8.5849"
          ry="8.5849"
          height="78.696"
          width="27.775"
          y="623.04"
          x="16.287"
          strokeWidth="1pt"
          fill="#60585a"
        />
        <rect
          id="rect958"
          fillRule="evenodd"
          rx="8.5849"
          ry="8.5849"
          height="78.696"
          width="27.775"
          y="613.04"
          x="311.29"
          strokeWidth="1pt"
          fill="#60585a"
        />
        <rect
          id="rect317"
          fillRule="evenodd"
          rx="8.5849"
          ry="8.5849"
          height="78.696"
          width="27.775"
          y="98.038"
          x="318.79"
          strokeWidth="1pt"
          fill="#60585a"
        />
        <rect
          id="rect316"
          fillRule="evenodd"
          rx="8.5849"
          ry="8.5849"
          height="78.696"
          width="27.775"
          y="101.12"
          x="8.6333"
          strokeWidth="1pt"
          fill="#60585a"
        />
        <path
          id="path184"
          d="m178.73 782.98c-113.07 2.362-130.4-17.92-147.11-21.261-16.705-38.776-19.877-365.73-9.855-392.46 7.493-60.54-4.936-70.565-8.687-143.53-7.14-85.213 9.815-37.829-4.439-124.48 21.658-90.216-19.136-92.053 168.52-100.63 172.21 2.401 147.96 10.415 169.61 100.63-14.254 86.652 2.701 39.268-4.439 124.48-3.751 72.961-16.18 82.986-8.687 143.53 10.022 26.727 6.85 353.68-9.855 392.46-26.153 15.153-95.459 21.261-145.07 21.261z"
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="1pt"
          fill="url(#radialGradient3205)"
        />
        <path
          id="path272"
          d="m41.537 281.88s21.534 82.442 21.534 82.442 0 154.58-3.0758 157.15c-3.0771 2.5759-27.686 46.372-27.686 46.372s6.1517-280.81 9.2288-285.97z"
          fillRule="evenodd"
          fill="url(#radialGradient3200)"
        />
        <path
          id="path274"
          d="m318.84 276.88s-21.534 82.442-21.534 82.442 0 154.58 3.0758 157.15c3.0771 2.5759 27.686 46.372 27.686 46.372s-6.1517-280.81-9.2288-285.97z"
          fillRule="evenodd"
          fill="url(#radialGradient3197)"
        />
        <path
          id="path230"
          d="m37.198 44.521c-11.667 18.667-10.816 196.22 7.851 210.22 18.03-14.851 122.48-28.646 142.34-27.364 20.288-1.492 99.694 8.055 124.09 22.697 6.577 2.13 19.727-205.55 1.059-219.55-58.34-16.344-252.01-16.344-275.34 13.99z"
          strokeOpacity=".45912"
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="1pt"
          fill="#f2f2f2"
        />
        <path
          id="path275"
          d="m41.764 257.39s65.53-26.897 138.64-27.585c65.833-0.64088 95.565 9.623 135.61 25.019-4.8534 48.756-9.7078 94.943-21.843 110.34-111.64-28.23-118.92-28.23-230.56 0-2.43-15.39-24.273-105.21-21.846-107.77z"
          fillRule="evenodd"
          fill="url(#radialGradient3193)"
        />
        <path
          id="path185"
          d="m45.51 260.2s63.783-24.762 134.95-25.395c64.078-0.59 93.017 8.859 132 23.033-4.724 44.885-9.449 87.405-21.261 101.58-108.67-25.986-115.75-25.985-224.42 0.001-2.362-14.174-23.623-96.856-21.261-99.218z"
          fillRule="evenodd"
          fillOpacity=".70440"
          stroke="#000"
          strokeWidth="1pt"
        />
        <path
          id="path270"
          d="m75.697 531.43c-12.369 59.368-22.263 173.16-22.263 173.16 19.79 17.316 121.21 24.737 123.69 24.737 7.4212 0 108.84-7.4212 126.16-32.158 0-14.842-9.8956-121.21-19.79-168.21-86.579 12.368-202.84 7.4212-207.79 2.4734z"
          fillRule="evenodd"
          fill="url(#radialGradient3189)"
        />
        <path
          id="path188"
          d="m80.945 536.59c-11.812 56.695-21.261 165.36-21.261 165.36 18.899 16.536 115.75 23.623 118.12 23.623 7.087 0 103.94-7.087 120.48-30.71 0-14.174-9.45-115.75-18.899-160.64-82.681 11.811-193.71 7.087-198.44 2.362z"
          fillRule="evenodd"
          fillOpacity=".78616"
          stroke="#000"
          strokeWidth="1pt"
        />
        <path
          id="path189"
          d="m321.9 279.09l28.348 2.362s14.174 14.174 4.725 21.261c-9.45 7.087-33.073-2.362-33.073-2.362v-21.261z"
          strokeOpacity=".55346"
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="1.25"
          fill="#b5b5b5"
        />
        <path
          id="path190"
          d="m36.946 283.82l-28.348 2.362s-14.174 14.174-4.725 21.261c9.45 7.087 33.073-2.362 33.073-2.362v-21.261z"
          strokeOpacity=".54717"
          fillRule="evenodd"
          stroke="#000"
          strokeWidth="1pt"
          fill="#b5b5b5"
        />
        <path
          id="path304"
          d="m52.582 17.025c-9.023 1.573-19.32 18.998-14.584 21.902 11.281-5.689 33.327-13.506 54.984-15.684 4.286-2.3 10.138-9.356 10.358-10.929-14.886-0.847-40.915 2.775-50.758 4.711z"
          strokeOpacity=".25157"
          fillRule="evenodd"
          fillOpacity=".56604"
          stroke="#000"
          strokeWidth="1.082pt"
          fill="#2e87cf"
        />
        <path
          id="path306"
          d="m298.7 12.115c9.0231 1.5731 19.32 14.633 14.584 17.537-11.28-5.6886-33.327-7.5051-54.984-9.6837-4.2855-2.2999-10.134-10.992-10.36-12.565 14.888-0.84719 40.917 2.7754 50.76 4.7115z"
          strokeOpacity=".25157"
          fillRule="evenodd"
          fillOpacity=".56604"
          stroke="#000"
          strokeWidth="1.082pt"
          fill="#2e87cf"
        />
        <path
          id="path307"
          d="m112.29 10.38c37.098-6.547 99.837-6.002 126.57-2.729"
          strokeOpacity=".37107"
          stroke="#000"
          strokeWidth="1pt"
          fill="none"
        />
        <path
          id="path308"
          d="m112.29 13.108c37.098-6.547 99.837-6.002 126.57-2.729"
          strokeOpacity=".37107"
          stroke="#000"
          strokeWidth="1pt"
          fill="none"
        />
        <path
          id="path309"
          d="m112.29 16.381c37.098-6.547 99.837-6.002 126.57-2.729"
          strokeOpacity=".37107"
          stroke="#000"
          strokeWidth="1pt"
          fill="none"
        />
        <path
          id="path310"
          d="m112.29 20.2c37.098-6.001 98.2-4.911 124.39-3.275"
          strokeOpacity=".37107"
          stroke="#000"
          strokeWidth="1pt"
          fill="none"
        />
        <path
          id="path186"
          d="m43.148 295.63s16.536 75.595 16.536 75.595 0 141.74-2.362 144.1c-2.363 2.362-21.261 42.521-21.261 42.521s4.724-257.49 7.087-262.22z"
          fillRule="evenodd"
          fillOpacity=".68554"
          stroke="#000"
          strokeWidth="1pt"
        />
        <path
          id="path211"
          d="m36.35 310.53c-2.333 25.667-14.991 443.45 4.666 443.34 69.856 26.467 221.62 23.584 278.52-7.0003 17.53-0.0711 6.999-417.68 4.666-443.34"
          stroke="#000"
          strokeWidth="1pt"
          fill="none"
        />
        <path
          id="path187"
          d="m317.18 290.91s-16.536 75.595-16.536 75.595 0 141.74 2.362 144.1c2.363 2.362 21.261 42.521 21.261 42.521s-4.724-257.49-7.087-262.22z"
          fillRule="evenodd"
          fillOpacity=".74843"
          stroke="#000"
          strokeWidth="1pt"
        />
        <path
          stroke="#000"
          fill={colors[ignition > 1 ? 0 : ignition]}
          strokeWidth="0"
          d="m90.227862,633.590995l0,0c0,-49.030325 39.746934,-88.777251 88.777266,-88.777251l0,0c23.545178,0 46.126022,9.353288 62.774989,26.002255c16.648967,16.648967 26.002247,39.22981 26.002247,62.774996l0,0c0,49.030317 -39.746926,88.777243 -88.777236,88.777243l0,0c-49.030332,0 -88.777266,-39.746934 -88.777266,-88.777243zm88.777266,-88.777251l0,177.554494m-88.777266,-88.777243l177.554501,0"
          id="svg_1"
        />
      </g>
    </svg>
  );
};

export default CarIcon;
