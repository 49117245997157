import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isAfter from 'date-fns/isAfter';
import intl from 'react-intl-universal';

import { Slider } from '..';
import { Dialog } from '../Dialog';
import Map from '../Map/Map';
import { FaGasPump, FaTachometerAlt, FaThermometerHalf, FaClock, FaPowerOff, FaMapMarkedAlt } from 'react-icons/fa';
import { Marker, InfoWindow } from '@react-google-maps/api';
import { Grid } from '@material-ui/core';
import { Preloader } from '../Preloader/Preloader';
import Fence from '../../components/Tracker/Fence';

import { get } from '../../api';
import { fetchDetails } from '../../store/actions/TrackDetails';
import { useStyles } from '..';
import styles from './jss/tracker';

export const TrackerDrillDown = ({ open, onClose, keyValue, info = null, type }) => {
  const classes = useStyles(styles);

  const auth = useSelector((s) => s.auth);
  const dispatch = useDispatch();

  const [data, setData] = useState(null);
  const [active, setActive] = useState([]);
  const [value, setValue] = useState(0);
  const [addresses, setAddresses] = useState([]);
  const [frames, setFrames] = useState(10);
  const [fence, setFence] = useState(null);
  const [zoom, setZoom] = useState(12);

  const onChange = (value) => setValue(value);

  useEffect(() => {
    if (keyValue) {
      setData(null);
      fetchDetails({
        chave: keyValue,
        tipo: type ? type : '',
        numRegistros: frames / 2,
      })
        .then(({ timeline, cerca }) => {
          setData(
            timeline.map((frame) => ({
              ...frame,
              lat: +frame.latitude,
              lng: +frame.longitude,
            }))
          );

          let match = timeline.findIndex((x) => x.dataKey === keyValue.data && x.id_veiculo === keyValue.veiculo);

          if (match === -1 && type === 'alerta')
            match = timeline.findIndex(
              (x) => isAfter(new Date(x.dataKey), new Date(keyValue.data)) && x.id_veiculo === keyValue.veiculo
            );

          setActive([match]);
          setValue(0);
          setFence(cerca);

          const positions = timeline.map((frame) => ({
            lat: +frame.latitude,
            lng: +frame.longitude,
          }));

          const promises = positions.map((point) =>
            get(
              'location.geocode',
              {
                latitude: point.lat,
                longitude: point.lng,
                origin: '/monitoramento/alertas',
              },
              auth.token,
              dispatch
            )
          );

          Promise.all(promises)
            .then((addresses) => {
              setAddresses(addresses.map(({ location }) => location));
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    }
  }, [keyValue, type, frames, auth.token, dispatch]);

  if (!data)
    return (
      <Dialog open={open} onClose={onClose} title={intl.get('monitoring.alerts.alertsHistory.routeDetail.title')}>
        <div className={classes.root}>
          <Preloader state={intl.get('monitoring.alerts.alertsHistory.routeDetail.loading')} />
        </div>
      </Dialog>
    );

  const range = { min: 0, max: data.length - 1 };
  const tick = 1;

  const frame = data[value.toFixed()];

  if (!frame) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        title={intl.get('monitoring.alerts.alertsHistory.routeDetail.sorry')}
        className={classes.dialogErro}
      >
        <div className={classes.mensagemErro}>
          {intl.get('monitoring.alerts.alertsHistory.routeDetail.notFoundDatas')}
        </div>
      </Dialog>
    );
  }

  const { lat, lng } = frame;

  const configs = {
    center: { lat, lng },
    zoom,
  };

  const position = { lat, lng };

  const address = addresses[value]
    ? addresses[value]
    : intl.get('monitoring.alerts.alertsHistory.routeDetail.loadingAddress');
  return (
    <Dialog open={open} onClose={onClose} title={intl.get('monitoring.alerts.alertsHistory.routeDetail.title')}>
      <div className={classes.root}>
        <Grid container className={classes.indicators}>
          <Grid item xs={6} sm={4} md={2} lg={2}>
            <Indicator
              classes={classes}
              title={intl.get('monitoring.alerts.alertsHistory.routeDetail.fuel')}
              content={`${frame.nivel_combustivel}${
                frame.capacidade_combustivel ? ` (${frame.capacidade_combustivel})` : ``
              }`}
              Icon={FaGasPump}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2} lg={2}>
            <Indicator
              classes={classes}
              title={intl.get('monitoring.alerts.alertsHistory.routeDetail.acceleration')}
              content={frame.acelerador}
              Icon={FaTachometerAlt}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2} lg={2}>
            <Indicator
              classes={classes}
              title={intl.get('monitoring.alerts.alertsHistory.routeDetail.velocity')}
              content={frame.velocidade_atual}
              Icon={FaTachometerAlt}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2} lg={2}>
            <Indicator
              classes={classes}
              title={intl.get('monitoring.alerts.alertsHistory.routeDetail.rotation')}
              content={`${frame.rotacao}`}
              Icon={FaTachometerAlt}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2} lg={2}>
            <Indicator
              classes={classes}
              title={intl.get('monitoring.alerts.alertsHistory.routeDetail.odometer')}
              content={frame.odometro}
              Icon={FaTachometerAlt}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2} lg={2}>
            <Indicator
              classes={classes}
              title={intl.get('monitoring.alerts.alertsHistory.routeDetail.temperature')}
              content={frame.temp_liquido_arref}
              Icon={FaThermometerHalf}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2} lg={2}>
            <Indicator
              classes={classes}
              title={intl.get('monitoring.alerts.alertsHistory.routeDetail.fuelFlow')}
              content={frame.vazao_combustivel}
              Icon={FaGasPump}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2} lg={2}>
            <Indicator
              classes={classes}
              title={intl.get('monitoring.alerts.alertsHistory.routeDetail.date')}
              content={frame.data}
              Icon={FaClock}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2} lg={2}>
            <Indicator
              classes={classes}
              title={intl.get('monitoring.alerts.alertsHistory.routeDetail.ignition')}
              content={frame.ignicao}
              Icon={FaPowerOff}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Indicator
              classes={classes}
              type={'address'}
              title={intl.get('monitoring.alerts.alertsHistory.routeDetail.address')}
              content={address}
              Icon={FaMapMarkedAlt}
            />
          </Grid>
        </Grid>
        <Map height={'56vh'} options={configs} setZoom={setZoom}>
          <Marker position={position}>
            {active.includes(parseInt(value.toFixed())) && (
              <InfoWindow position={position} options={{ pixelOffset: new window.google.maps.Size(0, -42) }}>
                <>
                  {Boolean(info) && info.type === 'card' && (
                    <div className={classes.infoWindow}>
                      <div>
                        <span>{intl.get('monitoring.alerts.alertsHistory.routeDetail.event')}:</span>
                        {info.title}
                      </div>
                      <div>
                        <span>{info.details.label}</span>
                        {info.details.value}
                      </div>
                    </div>
                  )}
                  {Boolean(info) && info.type === 'event' && (
                    <div className={classes.infoWindow}>
                      <div>
                        <span>{intl.get('monitoring.alerts.alertsHistory.routeDetail.event')}:</span>
                        {info.title}
                      </div>
                      {Boolean(info.details) && (
                        <div>
                          <span>{intl.get('monitoring.alerts.alertsHistory.routeDetail.message')}:</span>
                          {info.details}
                        </div>
                      )}
                      {Boolean(fence) && (
                        <div>
                          <span>{intl.get('monitoring.alerts.alertsHistory.routeDetail.fence')}:</span>
                          {fence.descricao}
                        </div>
                      )}
                    </div>
                  )}
                </>
              </InfoWindow>
            )}
          </Marker>
          {Boolean(fence) && <Fence fence={fence.cerca} />}
        </Map>
        <div className={classes.grow} />
        <Slider
          range={range}
          tick={tick}
          isTickPercentage={false}
          controllers
          value={value}
          onChange={onChange}
          activeSpots={active}
          frames={frames}
          setFrames={setFrames}
        />
      </div>
    </Dialog>
  );
};

const Indicator = ({ classes, content, title, Icon, type }) => {
  return (
    <div className={classes.indicator}>
      <span className={classes.label}>{title}</span>
      <Icon className={classes.indicatorIcon} />
      <div className={type === 'address' && content.length > 50 ? classes.indicatorLarge : classes.indicatorText}>
        {content.replace('Unnamed Road', intl.get('monitoring.alerts.alertsHistory.routeDetail.unnamed'))}
      </div>
    </div>
  );
};
