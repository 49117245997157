export const getCategoryLabel = (id, intl) =>{
    switch(id){
        case 1: return intl.get('getCategoryLabel.trucks')
        case 2: return intl.get('getCategoryLabel.cars')
        case 3: return intl.get('getCategoryLabel.rurals')
        default: return intl.get('getCategoryLabel.others')
    }
}

export const nextHundreth = number => (Math.ceil(number / 100.0)) * 100

export const createAxiosHeader = (token, cancelToken = null) =>({
    headers:{
        Authorization : `bearer ${token}`
    },
    cancelToken
})