import React, { useRef, useCallback, useContext } from 'react';

const DriversRefContext = React.createContext();
const OperationsRefContext = React.createContext();
const GroupingsRefContext = React.createContext();
const VehiclesSingleRefContext = React.createContext();
const VehiclesRefContext = React.createContext();
const VehiclesFiltersRefContext = React.createContext();
const StartDateRefContext = React.createContext();
const EndDateRefContext = React.createContext();
const LicensePlateRefContext = React.createContext();
const FetchedVehiclesAndFilterContext = React.createContext();
const FetchedVehicleAndFiltersContext = React.createContext();

const ContextProvider = (props) => {
  const { children } = props;
  const firstFetchRef = useRef(true);
  const singleVehicleFirstFetchRef = useRef(true);
  const initiallySelectedRef = useRef(true);
  const initiallyListedRef = useRef(true);
  const driversRef = useRef([]);
  const operationsRef = useRef([]);
  const groupingsRef = useRef([]);
  const vehiclesRef = useRef([]);
  const vehiclesSingleRef = useRef([]);
  const vehiclesFiltersRef = useRef([]);
  const licensePlateRef = useRef('');
  const startDateRef = useRef(new Date(Date.now() - 86400000));
  const endDateRef = useRef(new Date());

  const fetchedVehiclesAndFiltersCallback = useCallback((vehicles, vehiclesFilters) => {
    const inactiveFilters = vehiclesFiltersRef.current.filter(
      ({ filterParameter, active }) => filterParameter && !active
    );
    const inactiveFiltersIds = inactiveFilters.map(({ id }) => id);
    const selectedVehiclesIds = vehiclesRef.current.filter(({ active }) => active).map(({ id }) => id);

    if (vehiclesFilters)
      vehiclesFiltersRef.current = vehiclesFilters.map((vehicleFilter) => ({
        ...vehicleFilter,
        active: firstFetchRef.current ? initiallyListedRef.current : !inactiveFiltersIds.includes(vehicleFilter.id),
      }));
    else vehiclesFiltersRef.current = [];

    if (vehicles)
      vehiclesRef.current = vehicles.map((vehicle) => {
        const listed = !inactiveFilters.some(({ filterParameter, filterValue }) => {
          return vehicle[filterParameter] === filterValue;
        });

        return {
          ...vehicle,
          active: firstFetchRef.current
            ? initiallyListedRef.current && initiallySelectedRef.current
            : listed && selectedVehiclesIds.includes(vehicle.id),
          listed: firstFetchRef.current ? initiallyListedRef.current : listed,
        };
      });
    else vehiclesRef.current = [];

    firstFetchRef.current = false;
  }, []);

  const fetchedVehicleAndFiltersCallback = useCallback((vehicles, vehiclesFilters) => {
    const inactiveFilters = vehiclesFiltersRef.current.filter(
      ({ filterParameter, active }) => filterParameter && !active
    );
    const inactiveFiltersIds = inactiveFilters.map(({ id }) => id);
    const selectedVehiclesIds = vehiclesSingleRef.current.filter(({ active }) => active).map(({ id }) => id);

    if (vehiclesFilters)
      vehiclesFiltersRef.current = vehiclesFilters.map((vehicleFilter, index) => ({
        ...vehicleFilter,
        active: singleVehicleFirstFetchRef.current ? index === 0 : !inactiveFiltersIds.includes(vehicleFilter.id),
      }));
    else vehiclesFiltersRef.current = [];

    if (vehicles)
      vehiclesSingleRef.current = vehicles.map((vehicle, index) => {
        const listed = !inactiveFilters.some(
          ({ filterParameter, filterValue }) => vehicle[filterParameter] === filterValue
        );
        
        return {
          ...vehicle,
          active: singleVehicleFirstFetchRef.current ? index === 0 : listed && selectedVehiclesIds.includes(vehicle.id),
          listed: singleVehicleFirstFetchRef.current ? true : listed,
        };
      });
    else vehiclesSingleRef.current = [];

    singleVehicleFirstFetchRef.current = false;
  }, []);

  return (
    <DriversRefContext.Provider value={driversRef}>
      <OperationsRefContext.Provider value={operationsRef}>
        <GroupingsRefContext.Provider value={groupingsRef}>
          <VehiclesRefContext.Provider value={vehiclesRef}>
            <VehiclesSingleRefContext.Provider value={vehiclesSingleRef}>
              <VehiclesFiltersRefContext.Provider value={vehiclesFiltersRef}>
                <FetchedVehiclesAndFilterContext.Provider value={fetchedVehiclesAndFiltersCallback}>
                  <FetchedVehicleAndFiltersContext.Provider value={fetchedVehicleAndFiltersCallback}>
                    <LicensePlateRefContext.Provider value={licensePlateRef}>
                      <StartDateRefContext.Provider value={startDateRef}>
                        <EndDateRefContext.Provider value={endDateRef}>{children}</EndDateRefContext.Provider>
                      </StartDateRefContext.Provider>
                    </LicensePlateRefContext.Provider>
                  </FetchedVehicleAndFiltersContext.Provider>
                </FetchedVehiclesAndFilterContext.Provider>
              </VehiclesFiltersRefContext.Provider>
            </VehiclesSingleRefContext.Provider>
          </VehiclesRefContext.Provider>
        </GroupingsRefContext.Provider>
      </OperationsRefContext.Provider>
    </DriversRefContext.Provider>
  );
};

export const useVehiclesSingleRef = () => {
  return useContext(VehiclesSingleRefContext);
};

export const useVehiclesRef = () => {
  return useContext(VehiclesRefContext);
};

export const useVehiclesFiltersRef = () => {
  return useContext(VehiclesFiltersRefContext);
};

export const useLicensePlateRef = () => {
  return useContext(LicensePlateRefContext);
};

export const useStartDateRef = () => {
  return useContext(StartDateRefContext);
};

export const useEndDateRef = () => {
  return useContext(EndDateRefContext);
};

export const useDriversRef = () => {
  return useContext(DriversRefContext);
};

export const useOperationsRef = () => {
  return useContext(OperationsRefContext);
};

export const useGroupingsRef = () => {
  return useContext(GroupingsRefContext);
};

export const useFetchedVehiclesAndFiltersCallback = () => {
  return useContext(FetchedVehiclesAndFilterContext);
};

export const useFetchedVehicleAndFiltersCallback = () => {
  return useContext(FetchedVehicleAndFiltersContext);
};

export const ReportsContextProvider = ContextProvider;

export default ReportsContextProvider;
