import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { get } from '../../api';

export const Location = ({ lat, lng, origin = '' }) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState('');

  const auth = useSelector((s) => s.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);

    (async () => {
      try {
        const { location } = await get(
          'location.geocode',
          {
            latitude: lat,
            longitude: lng,
            origin,
          },
          auth.token,
          dispatch
        );

        setLoading(false);
        setAddress(location);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, [auth.token, dispatch, lat, lng, origin]);

  const renderAddress = () => {
    if (loading) return intl.get('monitoring.macros.detail.loadingAddress').toUpperCase();
    if (error) return intl.get('monitoring.macros.detail.errorAddress').toUpperCase();
    return address;
  };

  return <span>{renderAddress()}</span>;
};
