import React from 'react'
import { Circle, Polygon } from '@react-google-maps/api'

export const Fence = ({ fence }) => {

    const renderRadiusFence = () => {
        const circle = JSON.parse(fence)
        return (
            <Circle
                options={{
                    strokeWeight: 0,
                    fillOpacity: 0.45,
                    fillColor: '#004297',
                    radius: circle.radius,
                    center: circle.center,
                }}
            />
        )
    }

    const renderPolygonFence = () => {
        const polygon = JSON.parse(fence)
        return (
            <Polygon
                paths={polygon}
                options={{
                    strokeWeight: 0,
                    fillOpacity: 0.45,
                    fillColor: '#004297',
                }}
            />
        )
    }

    const renderFence = () => {
        if (JSON.parse(fence).hasOwnProperty('radius'))
            return renderRadiusFence()

        return renderPolygonFence()
    }

    return renderFence()
}

export default Fence