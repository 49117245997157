export const styles = (theme) => ({
  menuBarMapButtons: {
    padding: '8px',
    boxSizing: 'border-box',
    flexDirection: 'column',
    display: 'flex',
    zIndex: 300,
    visibility: 'hidden',
    position: 'absolute',
    background: theme.palette.background.paper,
    boxShadow: `0px 0px 2.5px 0.5px ${theme.palette.text.default}`,
    fontSize: theme.typography.standard.fontSize,
    textAlign: 'center',
    fontWeight: theme.typography.standard.fontWeightExtraBold,
    width: '100%',
    opacity: 0,
    transition: 'all 0.1s linear',

    '@media(min-width: 820px)': {
      zIndex: 1,
      width: '148px',
      top: '53px',
      right: '17px',
      boxShadow: 'none',
    },

    '@media(max-width: 819px)': {
      bottom: 1,
    },
  },

  optionMapCheck: {
    width: theme.dimensions.customHeight.full,
    fontWeight: theme.typography.standard.fontWeightRegular,
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    boxSizing: 'border-box',
    '&:active ': {
      borderRadius: '0.3rem',
      backgroundColor: 'rgba(154, 154, 154, 0.20)',
    },

    '&:hover': {
      cursor: 'pointer',
    },

    '@media(min-width: 820px)': {
      height: 52,
      bottom: 1,
    },

    '@media(max-width: 819px)': {
      padding: '8px',
      bottom: 1,
    },
  },

  menuOpen: {
    visibility: 'visible',
    opacity: 1,
  },

  checkIcon: {
    fontSize: '20px',
  },

  hrStyle: {
    width: theme.dimensions.customWidth.full,
  },

  toggleOn: {
    fontSize: 29,
    color: theme.palette.success.dark,
  },

  toggleOff: {
    fontSize: 29,
    color: theme.palette.default.dark,
  },
});

export default styles;
