import React from 'react'
import Error from './Error'

class Catcher extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        console.error('Error: ', error, errorInfo)
    }

    render() {
        if (this.state.hasError) {
            return (
                <Error />
            )
        }

        const { children } = this.props

        return children
    }
}

export default Catcher