export const styles = (theme) => ({
    input: {},
    root: {
      position: "relative",
      width: "100%",
      userSelect: "none",
    },
    customTitleContainer: {
      minHeight: "1rem",
      marginBottom: 3,
      marginLeft: 3,
      fontSize: theme.typography.standard.fontSizeSm,
    },
  });
  
  export default styles;