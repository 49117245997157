export default theme => ({
    root: {
        position: 'relative'
    },

    badge: {
        color: 'white',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: -7,
        left: 7,
        backgroundColor: '#007bff',
        width: 13,
        height: 13,
        padding: 2,
        fontSize: 10,
        fontWeight: 700,
        borderRadius: '50%'
    }
})