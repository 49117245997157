export default (theme) => ({
	root: {
		position: 'relative',
		backgroundColor: theme.palette.background.paper,
		width: theme.dimensions.customWidth.full,
		userSelect: 'none',
		'& * input': {
			userSelect: 'text',
			cursor: 'text',
		},
	},
	font: {
		fontFamily: theme.typography.standard.fontFamily,
		fontSize: theme.typography.standard.fontSize,
		fontWeight: theme.typography.standard.fontWeightBold,
		letterSpacing: theme.typography.standard.letterSpacing,
		color: theme.palette.text.secondary,
	},
	disabled: {
		pointerEvents: 'none',
		opacity: 0.7,
	},
	title: {
		marginLeft: theme.dimensions.customMargin.xSmall,
		marginBottom: theme.dimensions.customMargin.xSmall,
		fontSize: theme.typography.standard.fontSizeSm,
	},
	container: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		boxSizing: 'border-box',
		backgroundColor: theme.palette.background.paper,
		width: theme.dimensions.customWidth.full,
		height: theme.dimensions.customHeight.small,
		borderColor: theme.palette.text.secondary,
		borderStyle: 'solid',
		borderWidth: theme.dimensions.customBorder.small,
		borderRadius: theme.dimensions.customBorderRadius.small,
		fontSize: '0.95em',
		overflow: 'hidden',
		cursor: 'pointer',
		'&:hover': {
			borderColor: theme.palette.text.primary,
		},
	},
	responsiveContainer: {
		[theme.breakpoints.down('sm')]: {
			height: '80px',
		},
	},
	focusedContainer: {
		borderColor: theme.palette.primary.dark,
		borderWidth: theme.dimensions.customBorder.medium,
		borderRadius: `${theme.dimensions.customBorderRadius.small}px ${theme.dimensions.customBorderRadius.small}px ${theme.dimensions.customBorderRadius.none} ${theme.dimensions.customBorderRadius.none}`,
		padding: `0 0`,
		'&:hover': {
			borderColor: theme.palette.primary.dark,
			borderWidth: theme.dimensions.customBorder.medium,
			borderRadius: `${theme.dimensions.customBorderRadius.small}px ${theme.dimensions.customBorderRadius.small}px ${theme.dimensions.customBorderRadius.none} ${theme.dimensions.customBorderRadius.none}`,
		},
	},
	labelContainer: {
		display: 'flex',
		flexGrow: 1,
		alignItems: 'center',
		justifyContent: 'flex-start',
		height: 'inherit',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
	},
	labelSelectedContainer: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		height: '100%',
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	labelSelectedFilterContainer: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		height: '100%',
		width: '100%',
	},
	labelSelected: {
		width: '100%',
		height: '100%',
		display: 'flex',
		textAlign: 'center',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '0 8px',
		maxWidth: '256px',
		boxSizing: 'border-box',
		background: theme.palette.default.main,
		borderRadius: '0px 5px 5px 0',
		[theme.breakpoints.down('sm')]: {
			maxWidth: '100%',
			borderBottomColor: theme.palette.text.secondary,
			borderBottomStyle: 'solid',
			borderBottomWidth: theme.dimensions.customBorder.small,
			borderBottomRadius: theme.dimensions.customBorderRadius.small,
		},
	},
	labelInput: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		height: '100%',
	},
	labelSelectedText: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},

	labelIcon: {
		fontSize: '1.2em',
		marginLeft: '8px',
		transition: 'color .2s',
		width: '100%',
		'&:hover': {
			color: theme.palette.text.primary,
		},
	},
	labelFilter: {
		marginLeft: '8px',
	},
	input: {
		width: '100%',
		border: 'none',
		marginLeft: '8px',
		fontFamily: theme.typography.standard.fontFamily,
		fontSize: theme.typography.standard.fontSize,
		fontWeight: theme.typography.standard.fontWeightBold,
		letterSpacing: theme.typography.standard.letterSpacing,
		color: theme.palette.text.secondary,
		caretColor: theme.palette.primary.dark,
		textOverflow: 'ellipsis',
		'&:focus-visible': {
			outline: 'none',
			boxShadow: 'none',
		},
	},
	focusedInput: {
		border: 'none',
		marginLeft: '8px',
		fontFamily: theme.typography.standard.fontFamily,
		fontSize: theme.typography.standard.fontSize,
		fontWeight: theme.typography.standard.fontWeightBold,
		letterSpacing: theme.typography.standard.letterSpacing,
		color: theme.palette.text.secondary,
	},
	focusedVisibleInput: {
		border: 'none',
		marginLeft: '8px',
		fontFamily: theme.typography.standard.fontFamily,
		fontSize: theme.typography.standard.fontSize,
		fontWeight: theme.typography.standard.fontWeightBold,
		letterSpacing: theme.typography.standard.letterSpacing,
		color: theme.palette.text.secondary,
	},
	caretContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-around',
		width: '1em',
		height: 'inherit',
		paddingRight: theme.dimensions.customPadding.medium,
		paddingLeft: theme.dimensions.customPadding.small,
	},
	filterContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-around',
		height: 'inherit',
		'& :hover': {
			color: theme.palette.text.primary,
		},
	},
	focusedFilterContainer: {
		'& :hover': {
			color: theme.palette.primary.dark,
		},
	},
	filter: {
		fontSize: '1em',
		marginLeft: theme.dimensions.customMargin.small,
		padding: theme.dimensions.customPadding.medium,
	},
	focusedFilter: {
		color: theme.palette.primary.dark,
	},
});
