import React, { useRef, useState, useEffect } from 'react';
import { useStyles } from '../../hooks';
import intl from 'react-intl-universal';
import { uniqueId } from '../../helpers';
import { Slider } from '@material-ui/core';
import { CustomTitle } from '..';

import styles from './jss/CustomSlider';

const FunctionComponent = (props) => {
  const {
    title = '',
    value = NaN,
    setValue = () => 0,
    defaultItem = 0,
    unit = '',
    min = 0,
    max = 10,
    step,
    marks = [], //quando adicionar marcas o css da ultima marca vai parar
    decimalPlaces = 0,
    loading = false,
    setCustomError = () => false,
    style = null,
    titleComponent = null,
  } = props;
  const point = intl.get('global.point');
  const decimalSeparator = intl.get('global.decimalSeparator');
  const regex =
    decimalPlaces === 0 ? /^-{0,1}\d+$/ : new RegExp(`^-{0,1}\\d+\\${decimalSeparator}{0,1}\\d{0,${decimalPlaces}}$`);

  const idRef = useRef(uniqueId('SliderInput-'));
  const timeoutRef = useRef();
  const [input, setInput] = useState(() => '');
  const [error, setError] = useState(() => '');
  const classes = useStyles(styles, props);

  const validate = (input) => {
    if (input === '') {
      setCustomError(false);
      setError('');
      setValue(input);
      setInput(input);
      return;
    }

    if (!regex.test(input)) {
      return;
    }

    setInput(input);

    const value = Number(input.replace(decimalSeparator, point));

    if (step && value % step > 0) {
      setCustomError(true);
      setError(intl.get(`core.CustomNumberInput.multipleError`, { step: step ? step : '' }));
      setValue(0);
      return;
    }

    if (value < min) {
      setCustomError(true);
      setError(
        intl
          .get(`core.CustomNumberInput.minError`)
          .replace('{min}', min.toFixed(decimalPlaces).replace(point, decimalSeparator))
          .replace('{unit}', unit ? unit : '')
      );
      setValue(0);
      return;
    }

    if (value > max) {
      setCustomError(true);
      setError(
        intl
          .get(`core.CustomNumberInput.maxError`)
          .replace('{max}', max.toFixed(decimalPlaces).replace(point, decimalSeparator))
          .replace('{unit}', unit ? unit : '')
      );
      setValue(0);
      return;
    }
    setCustomError(false);
    setError('');
    setValue(value);
  };

  const handleChange = (input) => {
    if (!loading) {
      setInput(input);
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => validate(input), 300);
    }
  };

  useEffect(() => {
    if (!isNaN(value) && (value === 0 || value !== Number(input.replace(decimalSeparator, point))))
      validate(value.toString().replace(point, decimalSeparator));
  }, [value]);

  return (
    <div className={style ? style : classes.root}>
      {title && (
        <CustomTitle
          classes={{
            container: classes.customTitleContainer,
          }}
          title={title}
          message={error}
          messageType={!titleComponent ? (error ? 'error' : '') : ''}
          component={titleComponent}
        />
      )}
      <div className={classes.sliderContainer}>
        <Slider
          className={classes.slider}
          id={idRef}
          defaultValue={Boolean(defaultItem) ? defaultItem : min}
          value={Number(input)}
          min={min}
          max={max}
          valueLabelDisplay={'on'}
          step={step}
          onChange={(event, newValue) => {
            handleChange(newValue.toString());
          }}
          marks={[
            { value: min, label: min + unit },
            {
              value: Boolean(defaultItem) ? defaultItem : min,
              label: (Boolean(defaultItem) ? defaultItem : min) + unit,
            },
            ...marks,
            { value: max, label: max + unit },
          ]}
        />
      </div>
    </div>
  );
};

export const CustomSlider = React.memo(FunctionComponent);

export default CustomSlider;
