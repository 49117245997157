import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';

import { GoogleMap, useLoadScript, TrafficLayer, MarkerClusterer, StreetViewPanorama } from '@react-google-maps/api';
import debounce from 'lodash.debounce';
import clsx from 'clsx';
import intl from 'react-intl-universal';

import { FaLayerGroup } from 'react-icons/fa';

import { setLocalStorage, getLocalStorage } from '../../utils/browserUserStorage';

import { useStyles, useBreakpoint } from '../../hooks';
import styles from './jss/CustomGoogleMap';

import MenuBarMapButtons from './ButtonsMenuBar';

const { REACT_APP_GOOGLE_KEY: key } = process.env;
const libraries = ['geometry', 'drawing', 'places'];
const defaultStyle = { height: '100%', width: '100%' };

const FunctionComponent = ({
  googleMapsRef = null,
  callModule = '',
  callSubModule = '',
  setForceCloseMenu = () => {},
  forceCloseMenu = false,
  optionsMap = {},
  children,
  optionsMenuButton = {
    trafficLayer: true,
    ungroupVehicles: true,
    saveLocationButton: true,
    relief: true,
    satellite: true,
    hybrid: true,
  },
  menuBarMapButtons = true,
  routeBounds,
  mapContainerStyle = defaultStyle,
  classButtonMenu = null,
  functionRenderMarker = () => [<div></div>],
  onClickMenuMap = () => {},
  setCenter,
}) => {
  const auth = useSelector((state) => state.auth);
  const localStorageValues = getLocalStorage(auth.id, callModule) || null;

  const firstFetchRef = useRef(true);
  const savedOptionsRef = useRef(
    localStorageValues
      ? callModule
        ? localStorageValues[callSubModule].centerMap
        : localStorageValues.centerMap
      : null
  );
  const [openMenuButtonsMap, setOpenMenuButtonsMap] = useState(false);
  const [options, setOptions] = useState();
  const [buttonTrafficLayer, setButtonTrafficLayer] = useState(false);
  const [buttonSaveLocation, setButtonSaveLocation] = useState(() => {
    return localStorageValues
      ? callModule
        ? localStorageValues[callSubModule].stateOfSaveLocationGroup
        : localStorageValues.stateOfSaveLocationGroup
      : false;
  });
  const [buttonGroupVehicles, setButtonGroupVehicles] = useState(() => {
    return localStorageValues
      ? callModule
        ? localStorageValues[callSubModule].stateOfGroupButton
        : localStorageValues.stateOfGroupButton
      : true;
  });

  const [mapTypeId, setMapTypeId] = useState(
    localStorageValues
      ? callModule
        ? localStorageValues[callSubModule].mapType
        : localStorageValues.mapType
      : 'roadmap'
  );
  const [mapRef, setMapRef] = useState(null);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: key,
    libraries,
    language: auth.language,
  });
  const classes = useStyles(styles);
  const pointSm = useBreakpoint('(min-width: 820px)');

  const buttonStyle = clsx({
    [classes.buttonOpenMenuButtonsMap]: true,
    [classes.buttonActive]: openMenuButtonsMap && pointSm,
    [classes.configHoverButton]: !openMenuButtonsMap,
    [classButtonMenu]: classButtonMenu,
  });

  const fitBounds = (routeBounds, mapRef) => {
    if (routeBounds) {
      const bounds = new window.google.maps.LatLngBounds();
      routeBounds.map((point) => {
        return bounds.extend({ lat: point.lat, lng: point.lng });
      });
      mapRef.fitBounds(bounds);
    }
  };

  const centralizeMap = (latlng, mapRef) => {
    if (latlng) {
      mapRef.setCenter(latlng);
    }
  };

  useEffect(() => {
    const savedOptions = buttonSaveLocation ? savedOptionsRef.current : null;
    if (window.google && mapRef && routeBounds && routeBounds.length && !savedOptions) fitBounds(routeBounds, mapRef);
  }, [routeBounds, mapRef]);

  useEffect(() => {
    if (firstFetchRef.current) {
      const savedOptions = buttonSaveLocation ? savedOptionsRef.current : null;
      if (savedOptions) {
        optionsMap.center = {
          ...optionsMap.center,
          lat: savedOptions.lat,
          lng: savedOptions.lng,
        };
        optionsMap.zoom = savedOptions.zoom;
      }
      firstFetchRef.current = false;
    }
    setOptions((prev) => {
      if ((prev && prev.mapTypeId) || mapTypeId)
        return {
          ...optionsMap,
          mapTypeId: optionsMap.mapTypeId ? optionsMap.mapTypeId : mapTypeId || prev.mapTypeId,
        };
      return optionsMap;
    });
  }, [optionsMap]);

  useEffect(() => {
    if (window.google && mapRef && setCenter) centralizeMap(setCenter, mapRef);
  }, [setCenter, mapRef]);

  const handleGroupVehicles = useCallback((active) => {
    setLocalStorage(auth.id, { stateOfGroupButton: active }, callModule, callSubModule);
    setButtonGroupVehicles(active);
  }, []);

  const handleSaveConfigMaps = useCallback((active) => {
    setLocalStorage(auth.id, { stateOfSaveLocationGroup: active }, callModule, callSubModule);
    setButtonSaveLocation(active);
  }, []);

  const debounceBounds = useCallback(
    debounce((mapRef) => {
      setLocalStorage(
        auth.id,
        {
          centerMap: {
            lat: mapRef.center.lat(),
            lng: mapRef.center.lng(),
            zoom: mapRef.zoom,
          },
        },
        callModule,
        callSubModule
      );
    }, 500),
    []
  );

  const renderMakerCluster = () => {
    return <MarkerClusterer>{(clusterer) => functionRenderMarker(clusterer)}</MarkerClusterer>;
  };

  const renderGoogleMap = () => {
    const loadHandler = (map) => {
      setMapRef(map);
    };

    const handleMenuMap = () => {
      if (forceCloseMenu === true) {
        setForceCloseMenu(null);
        setOpenMenuButtonsMap(true);
        return;
      }
      setOpenMenuButtonsMap(!openMenuButtonsMap);
      onClickMenuMap();
    };

    const handleMapType = (type) => {
      setMapTypeId(type);
      setLocalStorage(auth.id, { mapType: type }, callModule, callSubModule);
    };

    const handleonBoundsChanged = () => {
      buttonSaveLocation && debounceBounds(mapRef);
    };

    return (
      <GoogleMap
        ref={googleMapsRef}
        options={options}
        mapContainerStyle={mapContainerStyle}
        onLoad={loadHandler}
        onBoundsChanged={handleonBoundsChanged}
      >
        {menuBarMapButtons && (
          <div onClick={handleMenuMap} className={buttonStyle}>
            <FaLayerGroup className={classes.layersIcon} />
          </div>
        )}

        <StreetViewPanorama
          options={{
            addressControl: true,
            addressControlOptions: {
              position: window.google.maps.ControlPosition.BOTTOM_CENTER,
            },
            panControl: true,
            enableCloseButton: true,
          }}
        />

        <MenuBarMapButtons
          forceCloseMenu={forceCloseMenu}
          mapRef={mapRef}
          setOptions={setOptions}
          mapTypeId={mapTypeId}
          setMapTypeId={handleMapType}
          optionsMenuButton={optionsMenuButton}
          openMenuButtonsMap={openMenuButtonsMap}
          buttonTrafficLayer={buttonTrafficLayer}
          setButtonTrafficLayer={setButtonTrafficLayer}
          setButtonGroupVehicles={handleGroupVehicles}
          setButtonSaveLocation={handleSaveConfigMaps}
          buttonSaveLocation={buttonSaveLocation}
          buttonGroupVehicles={buttonGroupVehicles}
        />

        {buttonTrafficLayer && <TrafficLayer autoUpdate />}

        {buttonGroupVehicles ? renderMakerCluster() : functionRenderMarker(null)}

        {children}
      </GoogleMap>
    );
  };

  if (loadError) {
    return <div>{intl.get('components.Map.errorLoadMap')}</div>;
  }

  return isLoaded && options ? renderGoogleMap() : null;
};

export const CustomGoogleMap = React.memo(FunctionComponent);

export default CustomGoogleMap;
