import { useCallback, useEffect } from "react";
import debounce from "lodash.debounce";

export const useDebounce = (callback, delay) => {
  const debouncedFunction = useCallback(debounce(callback, delay));

  useEffect(() => debouncedFunction.cancel, [debouncedFunction]);

  return debouncedFunction;
};

export default useDebounce;
