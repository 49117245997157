import React from 'react';
import { CardTypes as types } from '../types';
import { initialCards } from './initialCards';
import { mergeCards } from '../../helpers';
import { hash } from '../actions/hash';

const initialPreference = {
  id: 0,
  descricao: 'Padrão',
  preferencia: [],
  publica: false,
};

const initialState = {
  isFetched: false,
  preferences: [],
  selected: initialPreference,
  cards: initialCards,
  initialFetch: false,
};
export const CardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.STORE_CARDS:
      return storeCards(state, action);
    case types.STORE_PREFERENCES:
      return storePreferences(state, action);
    case types.SELECT_PREFERENCE:
      return storeSelectedPreferene(state, action);
    case types.STORE_ADITIONAL:
      return storeAditional(state, action);
    case types.IS_LOADING:
      return setLoading(state, action);
    case types.INITIAL_FETCH:
      return { ...state, initialFetch: true };
    default:
      return state;
  }
};

const setLoading = (state, action) => {
  return {
    ...state,
    cards: state.cards.map((x) => {
      return {
        ...x,
        isLoading: action.cards.includes(hash[x.title]),
      };
    }),
  };
};

const storeCards = (state, action) => {
  return {
    ...state,
    cards: mergeCards(state.cards, parseCards(action.data, action.filters)),
    initialFetch: true,
  };
};

const storeAditional = (state, action) => {
  return {
    ...state,
    cards: mergeCards(state.cards, parseCards(action.data, action.filters)),
    initialFetch: true,
  };
};

const storePreferences = (state, action) => {
  return {
    ...state,
    preferences: action.data.map((x) => ({
      ...x,
      preferencia: JSON.parse(x.preferencia),
    })),
    selected: !action.data.length
      ? initialPreference
      : {
          id: action.id
            ? action.data.find((x) => x.id === action.id).id
            : action.data[0].id,
          id_usuario: action.id
            ? action.data.find((x) => x.id === action.id).id_usuario
            : action.data[0].id_usuario,
          descricao: action.id
            ? action.data.find((x) => x.id === action.id).descricao
            : action.data[0].descricao,
          preferencia: action.id
            ? JSON.parse(
                action.data.find((x) => x.id === action.id).preferencia
              )
            : JSON.parse(action.data[0].preferencia),
          publica: action.id
            ? action.data.find((x) => x.id === action.id).publica
            : action.data[0].publica,
        },
    initial: !Boolean(action.data.length),
  };
};

const storeSelectedPreferene = (state, action) => ({
  ...state,
  selected: action.preference,
});

export const parseCards = (cards, filters) => {
  const result = [];
  Object.keys(cards).map((key) => {
    switch (key) {
      case 'evento':
        return result.push(getEvents(cards[key]));
      case 'velocidade':
        return result.push(getSpeed(cards[key]));
      case 'rotacao':
        return result.push(getRotation(cards[key]));
      case 'vazaocombustivel':
        return result.push(getFlow(cards[key]));
      case 'acelerador':
        return result.push(getAccelerator(cards[key]));
      case 'freio':
        return result.push(getBrake(cards[key]));
      case 'portas':
        return result.push(getDoor(cards[key]));
      case 'luzes':
        return result.push(getLight(cards[key]));
      case 'parabrisa':
        return result.push(getWiper(cards[key]));
      case 'statustempo':
        return result.push(getTimeStatus(cards[key]));
      case 'frota':
        return result.push(getFleet(cards[key]));
      case 'graficodistancia':
        return result.push(getDistance(cards[key]));
      case 'marcha':
        return result.push(getGear(cards[key]));
      case 'nivelcombustivel':
        return result.push(getFuel(cards[key]));
      case 'arcondicionado':
        return result.push(getAc(cards[key]));
      case 'graficotemperatura':
        return result.push(getTemp(cards[key]));
      case 'cinto':
        return result.push(getBelt(cards[key]));
      case 'consumo':
        return result.push(getConsumption(cards[key]));
      case 'graficovelocidade':
        return result.push(getSpeedChart(cards[key]));
      case 'graficorotacao':
        return result.push(getRotationChart(cards[key]));
      case 'graficovazao':
        return result.push(getFlowChart(cards[key]));
      case 'graficopedais':
        return result.push(getPedalsChart(cards[key]));
      default:
        return null;
    }
  });

  let fix = process.env.REACT_APP_FIX_TIME
    ? parseInt(process.env.REACT_APP_FIX_TIME)
    : 0;

  return result.map((x) => ({
    ...x,
    lastFetch: Date.now() + fix,
    lastFilters: filters,
  }));
};

const getEvents = (data) => {
  const accelerations = data.aceleracao_brusca;
  const brakes = data.frenagem_brusca;
  const collisions = data.colisao + data.colisao_estacionamento;
  const total = accelerations + brakes + collisions;
  return {
    title: 'Eventos',
    content: `${total} Eventos`,
    footer: [
      { label: 'Acelerações', value: accelerations },
      { label: 'Frenagens', value: brakes },
      { label: 'Colisões', value: collisions },
    ],
    variant: 'displayer',
    isLoading: false,
  };
};

const getSpeed = (payload) => {
  if (!payload)
    return {
      title: 'Velocidade',
      label: 'Média',
      content: `0 km/h`,
      chartData: [],

      footer: [
        //  { label: "Mínima", value: `0 km/h` },
        { label: 'Máxima', value: `0 km/h` },
      ],
      variant: 'displayer',
      isLoading: false,
    };
  const { data, periodo } = payload;
  if (!data)
    return {
      title: 'Velocidade',
      label: 'Média',
      content: `0 km/h`,
      chartData: [],
      footer: [
        //{ label: "Mínima", value: `0 km/h` },
        { label: 'Máxima', value: `0 km/h` },
      ],
      variant: 'displayer',
      isLoading: false,
    };

  const averageSpeed = data.velocidade;

  const maxSpeed = Math.round(data.maxVelocidade);
  const minSpeed = Math.round(data.minVelocidade);

  const idMax = data.idMax;
  const idMin = data.idMin;

  const minTool = (
    <>
      <div>Veículo : {data.minDescricao}</div>
      <div>Velocidade: {`${minSpeed} km/h`}</div>
      <div>Data: {data.minVelocidadeData}</div>
    </>
  );
  const maxTool = (
    <>
      <div>Veículo : {data.maxDescricao}</div>
      <div>Velocidade: {`${maxSpeed} km/h`}</div>
      <div>Data: {data.maxVelocidadeData}</div>
    </>
  );

  return {
    title: 'Velocidade',
    label: 'Média',
    content: `${averageSpeed} km/h`,
    info: `Média no Período`,
    footer: [
      //   {
      //     label: "Mínima",
      //     value: `${minSpeed} km/h`,
      //     detailsId: idMin,
      //     tooltip: minTool,
      //     type: "details",
      //   },
      { label: 'Período', value: periodo },
      {
        label: 'Máxima',
        value: `${maxSpeed} km/h`,
        detailsId: idMax,
        tooltip: maxTool,
        type: 'details',
      },
    ],
    variant: 'displayer',
    isLoading: false,
  };
};

const getRotation = (data) => {
  if (!data)
    return {
      title: 'Rotação',
      label: 'Média',
      content: '0 RPM',
      footer: [
        { label: 'Mínima', value: `0 RPM` },
        { label: 'Máxima', value: `0 RPM` },
      ],
      variant: 'displayer',
      isLoading: false,
    };

  const min = Math.round(data.minRotacao);
  const max = Math.round(data.maxRotacao);
  const average = Math.round(data.rotacao);

  const idMax = data.idMax;
  const idMin = data.idMin;

  const minTool = (
    <>
      <div>Veículo : {data.minDescricao}</div>
      <div>Velocidade: {`${min} RPM`}</div>
      <div>Data: {data.minRotacaoData}</div>
    </>
  );
  const maxTool = (
    <>
      <div>Veículo : {data.maxDescricao}</div>
      <div>Velocidade: {`${max} RPM`}</div>
      <div>Data: {data.maxRotacaoData}</div>
    </>
  );

  return {
    title: 'Rotação',
    label: 'Média',
    content: `${average} RPM`,
    info: 'Média de Rotação no Período',
    footer: [
      {
        label: 'Mínima',
        value: `${min} RPM`,
        tooltip: minTool,
        detailsId: idMin,
        type: 'details',
      },
      //   {
      //     label: "Média",
      //     value: `${average} RPM`,
      //     to: "/monitoramento/relatorios",
      //     type: "redirect",
      //     tooltip: "Ver relatórios",
      //   },
      {
        label: 'Máxima',
        value: `${max} RPM`,
        tooltip: maxTool,
        detailsId: idMax,
        type: 'details',
      },
    ],
    variant: 'displayer',
    isLoading: false,
  };
};

const getFlow = (data) => {
  if (!data)
    return {
      title: 'Vazão Combustível',
      label: 'Média',
      content: '0 L/h',
      info: 'Média no Período',
      footer: [
        { label: 'Mínima', value: '0 L/h' },
        {
          label: 'Média',
          value: '0 L/h',
          to: '/monitoramento/relatorios',
          type: 'redirect',
          tooltip: 'Ver relatórios',
        },
        { label: 'Máxima', value: '0 L/h' },
        { label: '< 1500 RPM', value: '0 L/h' },
        { label: '< 3000 RPM', value: '0 L/h' },
        { label: '< 6000 RPM', value: '0 L/h' },
      ],
      variant: 'displayer',
      isLoading: false,
    };

  const flow1500 = data.vazao1500;
  const flow3000 = data.vazao3000;
  const flow6000 = data.vazao6000;

  const min = data.minVazao.toFixed(2);
  const max = data.maxVazao.toFixed(2);

  const average = data.vazao_combustivel;

  const minTool = (
    <>
      <div>Veículo : {data.minDescricao}</div>
      <div>Vazão: {`${data.minVazao} L/h`}</div>
      <div>Data: {data.minData}</div>
    </>
  );

  const maxTool = (
    <>
      <div>Veículo : {data.maxDescricao}</div>
      <div>Vazão: {`${data.maxVazao} L/h`}</div>
      <div>Data: {data.maxData}</div>
    </>
  );

  return {
    title: 'Vazão Combustível',
    label: 'Média',
    content: `${average} L/h`,
    info: 'Média no Período',
    footer: [
      {
        label: 'Mínima',
        value: `${min} L/h`,
        detailsId: data.idMin,
        tooltip: minTool,
        type: 'details',
      },
      {
        label: 'Média',
        value: `${average} L/h`,
        to: '/monitoramento/relatorios',
        type: 'redirect',
        tooltip: 'Ver relatórios',
      },
      {
        label: 'Máxima',
        value: `${max} L/h`,
        detailsId: data.idMax,
        tooltip: maxTool,
        type: 'details',
      },
      { label: '< 1500 RPM', value: `${flow1500} L/h` },
      { label: '< 3000 RPM', value: `${flow3000} L/h` },
      { label: '< 6000 RPM', value: `${flow6000} L/h` },
    ],
    variant: 'displayer',
    isLoading: false,
  };
};

const getAccelerator = (data) => {
  if (!data)
    return {
      title: 'Acionamento Acelerador',
      label: 'Média',
      content: '0 %',
      info: 'Média no Período',
      footer: [
        { label: 'Média', value: '0 %' },
        { label: 'Máxima', value: '0 %' },
      ],
      variant: 'displayer',
      isLoading: false,
    };

  const average = data.mediaAcelerador;
  const max = Math.round(data.maxAcelerador);

  const maxTool = (
    <>
      <div>Veículo : {data.maxDescricao}</div>
      <div>Aceleração: {`${data.maxAcelerador} %`}</div>
      <div>Data: {data.maxData}</div>
    </>
  );

  return {
    title: 'Acionamento Acelerador',
    label: 'Média',
    content: `${average} %`,
    info: 'Média no Período',
    footer: [
      {
        label: 'Média',
        value: `${average} %`,
        to: '/monitoramento/relatorios',
        type: 'redirect',
        tooltip: 'Ver relatórios',
      },
      {
        label: 'Máxima',
        value: `${max} %`,
        detailsId: data.idMax,
        tooltip: maxTool,
        type: 'details',
      },
    ],
    variant: 'displayer',
    isLoading: false,
  };
};

const getBrake = (data) => {
  if (!data)
    return {
      title: 'Acionamento Pedais',
      content: `0 vezes`,
      footer: [
        { label: 'Freio', value: `0 vezes` },
        { label: 'Embreagem', value: `0 vezes` },
      ],
      variant: 'displayer',
      isLoading: false,
    };

  const totalBrake = data.totalFreio;
  const totalClutch = data.totalEmbreagem;

  return {
    title: 'Acionamento Pedais',
    content: `${totalBrake + totalClutch} x`,
    footer: [
      { label: 'Freio', value: `${totalBrake} x` },
      { label: 'Embreagem', value: `${totalClutch} x` },
    ],
    variant: 'displayer',
    isLoading: false,
  };
};

const getDoor = (data) => {
  if (!data)
    return {
      title: 'Portas',
      label: 'Travados',
      content: '0 Veículos',
      footer: [
        { label: 'Fechados', value: '0 Veículos' },
        { label: 'Travados', value: '0 Veículos' },
      ],
      variant: 'displayer',
      isLoading: false,
    };

  const locked = data.travada;
  const closed = data.fechada;

  return {
    title: 'Portas',
    label: 'Travados',
    content: `${locked} Veículos`,
    footer: [
      { label: 'Fechados', value: `${closed} Veículos` },
      { label: 'Travados', value: `${locked} Veículos` },
    ],
    variant: 'displayer',
    isLoading: false,
  };
};

const getLight = (data) => {
  if (!data.length)
    return {
      title: 'Sinalizadores Ligados',
      label: 'Ligados',
      content: `0 Piscas / 0 setas`,
      footer: [
        { label: 'Conversão Esquerda', value: `0 km/h` },
        { label: 'Conversão Direita', value: `0 km/h` },
      ],
      variant: 'displayer',
      isLoading: false,
    };

  const totalOn = data
    .map((x) => x.status_pisca_alerta)
    .reduce((a, c) => a + c, 0);
  const averageRight = Math.round(
    data.map((x) => x.direita).reduce((a, c) => a + c) / data.length
  );
  const averageLeft = Math.round(
    data.map((x) => x.esquerda).reduce((a, c) => a + c) / data.length
  );

  return {
    title: 'Sinalizadores Ligados',
    label: 'Ligados',
    content: `${totalOn} Piscas / 0 setas`,
    footer: [
      { label: 'Conversão Esquerda', value: `${averageLeft} km/h` },
      { label: 'Conversão Direita', value: `${averageRight} km/h` },
    ],
    variant: 'displayer',
    isLoading: false,
  };
};

const getWiper = (data) => {
  if (!data)
    return {
      title: 'Limpadores',
      content: `Sem dados`,
      footer: [
        { label: 'Vel. média chuva', value: `N/A` },
        { label: 'Vel. máxima chuva', value: `N/A` },
      ],
      variant: 'displayer',
      isLoading: false,
    };

  const maxTool = (
    <>
      <div>Veículo : {data.descMax}</div>
      <div>Velocidade: {`${data.velMax} km/h`}</div>
    </>
  );

  const max = data.velMax ? `${data.velMax} km/h` : 'N/A';
  const media = data.velMedia ? `${data.velMedia} km/h` : 'N/A';

  return {
    title: 'Limpadores',
    content: `${data.totalLigados} Ligados`,
    footer: [
      {
        label: 'Vel. Média chuva',
        value: media,
        type: 'redirect',
        to: '/monitoramento/relatorios',
        tooltip: 'Ver relatórios',
      },
      {
        label: 'Vel. Máxima chuva',
        value: max,
        type: 'details',
        detailsId: data.idMax,
        tooltip: data.velMax ? maxTool : null,
      },
    ],
    variant: 'displayer',
    isLoading: false,
  };
};

const getTimeStatus = (data) => {
  if (!data)
    return {
      title: 'Tempo de uso',
      data: [],
      footer: [
        { label: 'Desligado', value: `0 horas` },
        { label: 'Movimento', value: `0 horas` },
        { label: 'Ligado Parado', value: `0 horas` },
        { label: 'Pós-Chave', value: `0 horas` },
      ],
      variant: 'chart',
      isLoading: false,
    };

  const total = data.movimentoSec;
  const totalOff = data.desligadoSec;
  const totalIdle = data.ociosoSec;
  const totalAfterKey = data.posChaveSec;
  const totalPeriod = total + totalOff + totalIdle + totalAfterKey;

  const offPct = Math.round((totalOff / totalPeriod) * 100);
  const onPct = Math.round((total / totalPeriod) * 100);
  const idlePct = Math.round((totalIdle / totalPeriod) * 100);
  const afterKey = Math.round((totalAfterKey / totalPeriod) * 100);

  const chartData = [
    { name: 'Desligado', value: offPct, color: '#ffc247' },
    { name: 'Movimento', value: onPct, color: '#3abf94' },
    { name: 'Ligado Parado', value: idlePct, color: '#f55d5d' },
    { name: 'Pós-Chave', value: afterKey, color: '#191970' },
  ];

  return {
    title: 'Tempo de uso',
    data: chartData,
    footer: [
      { label: 'Desligado', value: `${data.desligado} h` },
      { label: 'Movimento', value: `${data.movimento} h` },
      { label: 'Ligado Parado', value: `${data.ocioso} h` },
      { label: 'Pós-Chave', value: `${data.posChave} h` },
    ],
    variant: 'chart',
    isLoading: false,
  };
};

const getAc = (data) => {
  if (!data)
    return {
      title: 'Ar Condicionado',
      variant: 'chart',
      data: [],
      footer: [
        { label: 'Ligado', value: '0 h' },
        { label: 'Em Movimento', value: '0 h' },
        { label: 'Parado', value: '0 h' },
      ],
      isLoading: false,
    };

  const totalOn = data.acMovimentoSec;
  const totalStopped = data.acParadoSec;
  const total = data.acTotalSec;

  const pctOn = Math.round((totalOn / total) * 100);
  const pctStopped = Math.round((totalStopped / total) * 100);

  const chartData = [
    { name: 'Em Movimento', value: pctOn, color: '#3abf94' },
    { name: 'Parado', value: pctStopped, color: '#ffc247' },
  ];

  return {
    title: 'Ar Condicionado',
    variant: 'chart',
    data: chartData,
    footer: [
      { label: 'Ligado', value: `${data.acTotal} h` },
      { label: 'Em Movimento', value: `${data.acMovimento} h` },
      { label: 'Parado', value: `${data.acParado} h` },
    ],
    isLoading: false,
  };
};

const getGear = (data) => {
  if (!data)
    return {
      title: 'Marcha',
      variant: 'chart',
      data: [],
      footer: [
        { label: 'Lenta', value: '0 h' },
        { label: 'Engrenada', value: '0h' },
      ],
      isLoading: false,
    };

  const totalSlow = data.desengatadoSec;
  const totalNormal = data.engatadoSec;
  const total = totalSlow + totalNormal;

  const pctSlow = Math.round((totalSlow / total) * 100);
  const pctNormal = Math.round((totalNormal / total) * 100);

  const chartData = [
    { name: 'Lenta', value: pctSlow, color: '#ffc247' },
    { name: 'Engrenada', value: pctNormal, color: '#3abf94' },
  ];

  return {
    title: 'Marcha',
    variant: 'chart',
    data: chartData,
    footer: [
      { label: 'Lenta', value: `${data.desengatado} h` },
      { label: 'Engrenada', value: `${data.engatado} h` },
    ],
    isLoading: false,
  };
};

const getBelt = (data) => {
  if (!data)
    return {
      title: 'Cinto de segurança',
      variant: 'chart',
      data: [],
      footer: [
        { label: 'Com cinto', value: '0 h' },
        { label: 'Sem cinto', value: '0 h' },
      ],
      isLoading: false,
    };

  const totalBuckled = data.comCintoSec;
  const totalUnbuckled = data.semCintoSec;
  const total = totalBuckled + totalUnbuckled;

  const pctBuckled = Math.round((totalBuckled / total) * 100);
  const pctUnbuckled = Math.round((totalUnbuckled / total) * 100);

  const chartData = [
    { name: 'Afivelado', value: pctBuckled, color: '#3abf94' },
    { name: 'Não afivelado', value: pctUnbuckled, color: '#ffc247' },
  ];

  return {
    title: 'Cinto de segurança',
    variant: 'chart',
    data: chartData,
    footer: [
      { label: 'Com cinto', value: `${data.comCinto} h` },
      { label: 'Sem cinto', value: `${data.semCinto} h` },
    ],
    isLoading: false,
  };
};

const getFleet = (data) => {
  const { caminhoes, carros, agricolas } = data;
  const total = caminhoes + carros + agricolas;

  const trucks = Math.round((caminhoes / total) * 100);
  const cars = Math.round((carros / total) * 100);
  const rurals = Math.round((agricolas / total) * 100);

  const chartData = [
    { name: 'Caminhões', value: trucks, color: '#4365cc' },
    { name: 'Carros', value: cars, color: '#3abf94' },
    { name: 'Agrícolas', value: rurals, color: '#f55d5d' },
  ];

  return {
    title: 'Frota',
    data: chartData,
    footer: [
      { label: 'Caminhões', value: `${caminhoes}` },
      { label: 'Carros', value: `${carros}` },
      { label: 'Agrícolas', value: `${agricolas}` },
    ],
    variant: 'chart',
    isLoading: false,
  };
};

const getDistance = (data) => {
  if (!data.results.length)
    return {
      title: 'Distância Percorrida',
      variant: 'chart',
      subvariant: 'bar',
      dataKey: 'Distância',
      lineData: [],
      data: [],
      footer: [
        { label: 'Caminhões', value: `0 km` },
        { label: 'Carros', value: `0 km` },
        { label: 'Agrícolas', value: `0 km` },
      ],
      isLoading: false,
    };

  const totalTruck = Math.round(
    data.results.map((x) => x.caminhao).reduce((a, c) => a + c)
  );
  const totalCar = Math.round(
    data.results.map((x) => x.carro).reduce((a, c) => a + c)
  );
  const totalRural = Math.round(
    data.results.map((x) => x.agricola).reduce((a, c) => a + c)
  );

  const entries = ['Caminhões', 'Carros', 'Agrícolas'];

  const lineChart = data.results.map((frame) => ({
    Distância: frame.caminhao + frame.carro + frame.agricola,
    part: getPart(data.parte, frame.parte),
  }));

  const chart = data.results.map((frame) => ({
    name: 'Distância',
    Caminhões: frame.caminhao,
    Carros: frame.carro,
    Agrícolas: frame.agricola,
    part: getPart(data.parte, frame.parte),
  }));

  return {
    title: 'Distância Percorrida',
    variant: 'chart',
    subvariant: 'bar',
    data: chart,
    lineData: lineChart,
    dataKey: 'Distância',
    footer: [
      { label: 'Caminhões', value: `${totalTruck} km` },
      { label: 'Carros', value: `${totalCar} km` },
      { label: 'Agrícolas', value: `${totalRural} km` },
    ],
    entries,
    unit: 'km',
    isLoading: false,
  };
};

const getFuel = (data) => {
  if (!data)
    return {
      title: 'Nível Combustível',
      variant: 'chart',
      data: [],
      footer: [
        { label: 'Cheio', value: '0' },
        { label: '< 75%', value: '0 ' },
        { label: '< 50%', value: '0' },
        { label: 'Reserva', value: '0' },
      ],
    };

  const full = data.nivelCompleto.qtd;
  const under75 = data.nivel75.qtd;
  const under50 = data.nivel50.qtd;
  const under10 = data.nivel10.qtd;

  const pctFull = Math.round((full / data.count) * 100);
  const pct75 = Math.round((under75 / data.count) * 100);
  const pct50 = Math.round((under50 / data.count) * 100);
  const pct10 = Math.round((under10 / data.count) * 100);

  const chartData = [
    { name: 'Cheio', value: pctFull, color: '#3abf94' },
    { name: '< 75%', value: pct75, color: '#ffc247' },
    { name: '< 50%', value: pct50, color: '#ff9566' },
    { name: 'Reserva', value: pct10, color: '#f55d5d' },
  ];

  const tooltips = {
    full: data.nivelCompleto.veiculos,
    under75: data.nivel75.veiculos,
    under50: data.nivel50.veiculos,
    under10: data.nivel10.veiculos,
  };

  return {
    title: 'Nível Combustível',
    variant: 'chart',
    data: chartData,
    footer: [
      {
        label: 'Cheio',
        value: `${full}`,
        tooltip: tooltips.full,
        type: 'list',
      },
      {
        label: '< 75%',
        value: `${under75}`,
        tooltip: tooltips.under75,
        type: 'list',
      },
      {
        label: '< 50%',
        value: `${under50}`,
        tooltip: tooltips.under50,
        type: 'list',
      },
      {
        label: 'Reserva',
        value: `${under10}`,
        tooltip: tooltips.under10,
        type: 'list',
      },
    ],
    isLoading: false,
  };
};

const getTemp = (data) => {
  if (!data || !(data.results && data.results.length))
    return {
      title: 'Temperatura',
      variant: 'chart',
      data: [],
      dataKey: 'Temperatura',
      footer: [
        { label: 'Mínima', value: `0 °C` },
        { label: 'Média', value: `0 °C` },
        { label: 'Máxima', value: `0 °C` },
      ],
      unit: '°C',
    };

  const average = Math.round(
    data.results.map((x) => x.temp_liquido_arref).reduce((a, c) => a + c) /
      data.results.length
  );

  const entries = ['Temperatura'];

  const chart = data.results.map((frame) => ({
    name: 'Temperatura',
    Temperatura: Math.round(frame.temp_liquido_arref),
    part: getPart(data.parte, frame.parte),
  }));

  const minTool = (
    <>
      <div>Veículo : {data.min.descricao}</div>
      <div>Temperatura: {`${data.min.minTemp} °C`}</div>
    </>
  );

  const maxTool = (
    <>
      <div>Veículo : {data.max.descricao}</div>
      <div>Temperatura: {`${data.max.maxTemp} °C`}</div>
    </>
  );

  const {
    min: { idMin },
    max: { idMax },
  } = data;

  return {
    title: 'Temperatura',
    variant: 'chart',
    subvariant: 'bar',
    dataKey: 'Temperatura',
    data: chart,
    entries,
    footer: [
      {
        label: 'Mínima',
        value: `${Math.round(data.min.minTemp)} °C`,
        detailsId: idMin,
        tooltip: minTool,
        type: 'details',
      },
      {
        label: 'Média',
        value: `${average} °C`,
        to: '/monitoramento/relatorios',
        type: 'redirect',
        tooltip: 'Ver relatórios',
      },
      {
        label: 'Máxima',
        value: `${Math.round(data.max.maxTemp)} °C`,
        detailsId: idMax,
        tooltip: maxTool,
        type: 'details',
      },
    ],
    unit: '°C',
    isLoading: false,
  };
};

const getConsumption = (data) => {
  if (!data)
    return {
      title: 'Consumo',
      variant: 'displayer',
      content: `0 km/l`,
      label: 'Média',
      footer: [
        { label: 'Mínimo', value: `0 km/l` },
        { label: 'Máximo', value: `0 km/l` },
      ],
    };

  const average = data.consumo;
  const min = data.minConsumo;
  const max = data.maxConsumo;

  const minTool = (
    <>
      <div>Menor média no período</div>
      <div>Veículo : {data.minDescricao}</div>
      <div>Consumo: {`${min} km/l`}</div>
    </>
  );

  const maxTool = (
    <>
      <div>Maior média no período</div>
      <div>Veículo : {data.maxDescricao}</div>
      <div>Consumo: {`${max} km/l`}</div>
    </>
  );

  return {
    title: 'Consumo',
    variant: 'displayer',
    content: parseFloat(average) >= 0 ? `${average} km/l` : 'N/D',
    label: 'Média',
    info: 'Quilometragem / Consumo Total no Período',
    footer: [
      {
        label: 'Mínima',
        value: parseFloat(min) >= 0 ? `${min} km/l` : 'N/D',
        tooltip: minTool,
        detailsId: data.minId,
        type: 'info',
      },
      {
        label: 'Máximo',
        value: parseFloat(max) >= 0 ? `${max} km/l` : 'N/D',
        tooltip: maxTool,
        detailsId: data.maxId,
        type: 'info',
      },
    ],
    isLoading: false,
  };
};

const getPart = (part, value) => {
  switch (part) {
    case 'Hora':
      return `${value} h`;
    case 'Dia':
      return `Dia ${value}`;
    case 'Mês':
      return `${getMonth(value)}`;
    case 'Ano':
      return `Ano ${value}`;
    default:
      return part;
  }
};

const getMonth = (value) => {
  switch (value) {
    case 1:
      return 'Jan';
    case 2:
      return 'Fev';
    case 3:
      return 'Mar';
    case 4:
      return 'Abr';
    case 5:
      return 'Mai';
    case 6:
      return 'Jun';
    case 7:
      return 'Jul';
    case 8:
      return 'Ago';
    case 9:
      return 'Set';
    case 10:
      return 'Out';
    case 11:
      return 'Nov';
    case 12:
      return 'Dez';

    default:
      return null;
  }
};

const getSpeedChart = (data) => {
  const chartData = data.results.map((x) => ({
    Velocidade: x.velocidade,
    part: getPart(data.parte, x.parte),
  }));
  return {
    title: 'Gráfico Velocidade',
    variant: 'custom-chart',
    data: chartData,
    dataKey: 'Velocidade',
    isLoading: false,
  };
};

const getRotationChart = (data) => {
  const chartData = data.results.map((x) => ({
    Rotação: x.rotacao,
    part: getPart(data.parte, x.parte),
  }));
  return {
    title: 'Gráfico Rotação',
    variant: 'custom-chart',
    data: chartData,
    dataKey: 'Rotação',
    isLoading: false,
  };
};

const getFlowChart = (data) => {
  const chartData = data.results.map((x) => ({
    Vazão: x.vazaoCombustivel,
    part: getPart(data.parte, x.parte),
  }));
  return {
    title: 'Gráfico Vazão',
    variant: 'custom-chart',
    data: chartData,
    dataKey: 'Vazão',
    isLoading: false,
  };
};

const getPedalsChart = (data) => {
  const chartData = data.results.map((x) => ({
    Pedais: x.totalFreio + x.totalEmbreagem,
    part: getPart(data.parte, x.parte),
  }));
  return {
    title: 'Gráfico Pedais',
    variant: 'custom-chart',
    data: chartData,
    dataKey: 'Pedais',
    isLoading: false,
  };
};
