const styles = (theme) => ({
  root: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    width: theme.dimensions.customWidth.full,
  },
  font: {
    fontFamily: theme.typography.standard.fontFamily,
    fontSize: theme.typography.standard.fontSizeSm,
    fontWeight: theme.typography.standard.fontWeightBold,
    letterSpacing: theme.typography.standard.letterSpacing,
    color: theme.palette.text.secondary,
  },
  title: {
    marginLeft: theme.dimensions.customMargin.xSmall,
    marginBottom: theme.dimensions.customMargin.xSmall,
    fontSize: theme.typography.standard.fontSizeSm,
  },
  titleError: {
    marginLeft: theme.dimensions.customMargin.xSmall,
    marginBottom: theme.dimensions.customMargin.xSmall,
    fontSize: theme.typography.standard.fontSizeSm,
    color: `${theme.palette.danger.dark} !important`,
  },
  container: {
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    letterSpacing: 'inherit',
    color: 'inherit',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.background.paper,
    minHeight: theme.dimensions.customHeight.small,
    borderColor: theme.palette.text.secondary,
    borderStyle: 'solid',
    borderWidth: theme.dimensions.customBorder.small,
    borderRadius: theme.dimensions.customBorderRadius.small,
    padding: `${theme.dimensions.customPadding.medium}px ${
      theme.dimensions.customPadding.medium +
      (theme.dimensions.customBorder.medium - theme.dimensions.customBorder.small)
    }px`,
    overflow: 'hidden',
    cursor: 'pointer',
    '&:hover': {
      borderColor: theme.palette.text.primary,
    },
    '&:focus': {
      borderColor: theme.palette.primary.dark,
      borderWidth: theme.dimensions.customBorder.medium,
      padding: theme.dimensions.customPadding.medium,
    },
    '& :first-child :first-child::-webkit-calendar-picker-indicator': {
      marginLeft: theme.dimensions.customMargin.none,
      cursor: 'pointer',
    },
    '&::before': {
      content: 'none',
    },
    '&::after': {
      content: 'none',
    },
    '&*input': {
      width: '100%',
      '&:invalid': {
        borderColor: `${theme.palette.danger.main} !important`,
      },
    },
  },

  input: {
    width: '100%',
    fontFamily: theme.typography.standard.fontFamily,
    fontSize: theme.typography.standard.fontSize,
    fontWeight: theme.typography.standard.fontWeightBold,
    letterSpacing: theme.typography.standard.letterSpacing,
    color: theme.palette.text.secondary,
  },
  containerError: {
    '& :first-child :first-child': {
      borderColor: theme.palette.danger.main,
    },
    '& :first-child :first-child:hover': {
      borderColor: theme.palette.danger.dark,
    },
    '& :first-child :first-child:focus': {
      borderColor: theme.palette.danger.dark,
    },
  },
});

export default styles;
