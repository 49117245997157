import {
  FaHome,
  FaGlobe,
  FaTools,
  FaFileInvoice,
  FaDollyFlatbed,
  FaGasPump,
  FaUserClock,
  FaShareAlt,
  FaUserShield,
  FaTractor,
  FaCogs,
  FaTrophy,
  FaChartLine,
  FaChartBar,
  FaClipboardCheck,
  FaRegAddressCard,
  FaChartPie,
  FaPoll,
  FaExclamationTriangle,
  FaLock,
  FaBug,
  FaBorderAll,
  FaDesktop,
} from 'react-icons/fa';
import { FaRankingStar } from 'react-icons/fa6';

import { MdDashboard } from 'react-icons/md';

export const icons = {
  home: FaHome,
  dashboard: MdDashboard,
  globe: FaGlobe,
  tools: FaTools,
  report: FaFileInvoice,
  trophy: FaTrophy,
  dollyFlatbed: FaDollyFlatbed,
  gaspump: FaGasPump,
  userclock: FaUserClock,
  sharealt: FaShareAlt,
  usershield: FaUserShield,
  tractor: FaTractor,
  cogs: FaCogs,
  analytics: FaChartLine,
  driverrating: FaChartBar,
  clipboard: FaClipboardCheck,
  regAddressCard: FaRegAddressCard,
  faChartPie: FaChartPie,
  faPoll: FaPoll,
  faExclamationTriangle: FaExclamationTriangle,
  faLock: FaLock,
  faBug: FaBug,
  faBorderAll: FaBorderAll,
  faDesktop: FaDesktop,
  faRankingStar: FaRankingStar,
};
