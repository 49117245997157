/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Pagination from '@material-ui/lab/Pagination';
import { Tooltip } from '@material-ui/core';
import { Button } from '../../core';

import { useMediaQuery } from '@material-ui/core';

import reverseGeocode from '../../utils/reverseGeocode';
import { Map } from '../../core/Map/Map';
import { MarkerClusterer } from '@react-google-maps/api';
import { VehicleMarker } from '../Tracker';

import clsx from 'clsx';
import mapStyles from '../../core/Map/jss/customStyle_v2';
import { useStyles } from '../../styles';
import styles from './jss/notificationPage';

import { MdHighlight, MdTimer } from 'react-icons/md';
import { FaTimes, FaWhmcs, FaWater, FaWind, FaBandAid } from 'react-icons/fa';
import { IoMdSpeedometer, IoIosSpeedometer } from 'react-icons/io';
import { AiFillIdcard } from 'react-icons/ai';

const temperature = require('../../assets/images/temperature.png');
const hazard = require('../../assets/images/hazard.png');
const alert = require('../../assets/images/alert.png');

const getInitialMapProps = (greaterThanMinWidth) => {
  return {
    center: {
      lat: -15.36476,
      lng: -55.445009,
    },
    zoom: greaterThanMinWidth ? 4 : 3,
    disableDefaultUI: greaterThanMinWidth ? false : true,
    showTraffic: false,
    zoomControl: true,
    fullscreenControl: true,
    styles: mapStyles,
  };
};

export default function PaginationControlled({
  notifications,
  history,
  onClose,
  intl,
}) {
  const classes = useStyles(styles);
  const auth = useSelector((s) => s.auth);
  const dispatch = useDispatch();

  const [notification, setNotification] = useState(notifications);
  const [index, setIndex] = useState(0);
  const [page, setPage] = React.useState(1);
  const [idVeiculo, setIdVeiculo] = useState();
  const [vehicleMarker, setVehicleMarker] = useState();
  const [address, setAddress] = useState();
  const [newPage, setNewPage] = useState();
  const [viewed, setViewed] = useState([]);
  const [mapProps, setMapProps] = useState(
    getInitialMapProps(window.innerWidth >= 768)
  );
  const [showVehicleDetails, setShowVehicleDetails] = useState(
    idVeiculo ? idVeiculo : 30 //null
  );
  const greaterThanMinWidth = useMediaQuery('(min-width:768px)');
  const getBadgeStyle = () => {
    const color = ['danger'];
    return clsx({
      [classes.badge]: true,
      [color]: true,
    });
  };

  const handleChange = (event, value) => {
    setIndex(value - 1);
    setPage(value);
  };
  const handleClose = (value) => {
    if (notification.length === 1) {
      onClose(true);
    }
    if (value === 1) {
      setIndex(0);
      setPage(value + 1);
    } else {
      setIndex(value - 2);
      setPage(value - 1);
    }
    notification.splice(value - 1, 1);
    setNotification(notification);
  };

  useEffect(() => {
    const { id_veiculo, id_categoria, latitude, longitude, bussola, placa } =
      notification[index];

    setIdVeiculo(idVeiculo ? idVeiculo : id_veiculo);
    setVehicleMarker({
      id_veiculo,
      latitude,
      longitude,
      bussola,
      placa,
      id_categoria,
    });
    const center = {
      lat: latitude,
      lng: longitude,
    };
    const zoom = 15;
    setMapProps({
      ...getInitialMapProps(greaterThanMinWidth),
      center,
      zoom,
      mapTypeControl: false,
      controlSize: 28,
    });

    reverseGeocode({ lat: latitude, lng: longitude }, auth.token, dispatch)
      .then((address) => {
        setAddress(address);
      })
      .catch((e) => {
        setAddress(e);
      });
  }, [notification, page]);

  useEffect(() => {
    if (!viewed.includes(notification.length)) {
      if (notification.length > 1) {
        setNewPage(notification.length);
      }
    }
    if (page === notification.length) {
      viewed.push(page);
      setViewed(viewed);
      setNewPage(null);
    }
  }, [notification.length, page]);

  return (
    <div className={classes.root}>
      {notification.length > 1 && (
        <Pagination
          count={notification.length}
          page={page}
          onChange={handleChange}
          className={newPage ? classes.blinck : classes.pagination}
        />
      )}
      <div className={classes.header}>
        <div className={classes.title}>
          <div className={classes.imgTitle}>
            <span className={getBadgeStyle()}></span>
            <img src={alert} alt="alert-icon" className={classes.image} />
          </div>

          <span className={classes.titleText}>
            {notification[index].descricaoAlerta}
          </span>
        </div>
        {notification.length > 1 && (
          <div className={classes.close}>
            <FaTimes
              className={classes.closeIcon}
              onClick={() => handleClose(page)}
            ></FaTimes>
          </div>
        )}
      </div>
      {address && (
        <div className={classes.addressMobile}>
          <span>{address}</span>
        </div>
      )}
      <div className={classes.body}>
        {address && (
          <div className={classes.address}>
            <span>{address}</span>
          </div>
        )}
        <Map
          options={mapProps}
          mapTypeControl={false}
          containerStyle={{
            height: '100%',
            mapTypeControl: false,
            borderRadius: '0.3em',
            marginRight: 1,
            marginBottom: 1,
          }}
          style={{ '& > button': { widht: 24, height: 24 } }}
        >
          <MarkerClusterer>
            {(clusterer) => {
              return (
                <VehicleMarker
                  key={idVeiculo}
                  {...vehicleMarker}
                  showVehicleDetails={showVehicleDetails}
                  setShowVehicleDetails={setShowVehicleDetails}
                  clusterer={clusterer}
                />
              );
            }}
          </MarkerClusterer>
        </Map>
      </div>
      <div className={classes.footer}>
        {notification[index].temp_liquido_arref && (
          <Tooltip
            title={'Temperatura do Líquido de Arrefecimento'}
            placement="top"
          >
            <div className={classes.footerContent}>
              <>
                <img
                  src={temperature}
                  alt="temperature-icon"
                  className={classes.icons}
                />
                <span>{notification[index].temp_liquido_arref}</span>
              </>
            </div>
          </Tooltip>
        )}
        {notification[index].rotacao && (
          <Tooltip title={'Rotação'} placement="top">
            <div className={classes.footerContent}>
              <IoIosSpeedometer className={classes.icons} />
              <span>{notification[index].rotacao}</span>
            </div>
          </Tooltip>
        )}
        {notification[index].velocidade && (
          <Tooltip title={'Velocidade'} placement="top">
            <div className={classes.footerContent}>
              <IoMdSpeedometer className={classes.icons} />
              <span>{notification[index].velocidade}</span>
            </div>
          </Tooltip>
        )}
        {notification[index].motorista && (
          <Tooltip title={'Motorista'} placement="top">
            <div className={classes.footerContent}>
              <AiFillIdcard className={classes.icons} />
              <span>{notification[index].motorista}</span>
            </div>
          </Tooltip>
        )}
        {notification[index].marcha_engatada && (
          <Tooltip title={'Marcha Engatada'} placement="top">
            <div className={classes.footerContent}>
              <FaWhmcs className={classes.icons} />
              <span>{notification[index].marcha_engatada}</span>
            </div>
          </Tooltip>
        )}
        {notification[index].status_farol && (
          <Tooltip title={'Estado dos Farois'} placement="top">
            <div className={classes.footerContent}>
              <MdHighlight className={classes.icons} />
              <span>{notification[index].status_farol}</span>
            </div>
          </Tooltip>
        )}
        {notification[index].acelerador && (
          <Tooltip title={'Pedal do Acelerador'} placement="top">
            <div className={classes.footerContent}>
              <FaBandAid className={classes.icons} />
              <span>{notification[index].acelerador}</span>
            </div>
          </Tooltip>
        )}
        {notification[index].status_freio && (
          <Tooltip title={'Pedal do freio'} placement="top">
            <div className={classes.footerContent}>
              <FaBandAid className={classes.icons} />
              <span>{notification[index].status_freio}</span>
            </div>
          </Tooltip>
        )}
        {notification[index].status_freio_motor && (
          <Tooltip title={'Freio Motor'} placement="top">
            <div className={classes.footerContent}>
              hazard
              <img
                src={hazard}
                alt="freio-motor-icon"
                className={classes.icons}
              />
              <span>{notification[index].status_freio_motor}</span>
            </div>
          </Tooltip>
        )}
        {notification[index].pressao_turbo && (
          <Tooltip title={'Freio Motor'} placement="top">
            <div className={classes.footerContent}>
              <MdTimer className={classes.icons} />
              <span>{notification[index].pressao_turbo}</span>
            </div>
          </Tooltip>
        )}
        {notification[index].status_agua_combustivel && (
          <Tooltip title={'Freio Motor'} placement="top">
            <div className={classes.footerContent}>
              <FaWater className={classes.icons} />
              <span>{notification[index].status_agua_combustivel}</span>
            </div>
          </Tooltip>
        )}
        {notification[index].pressao_ar_reserv_primario && (
          <Tooltip title={'Freio Motor'} placement="top">
            <div className={classes.footerContent}>
              <FaWind className={classes.icons} />
              <span>{notification[index].pressao_ar_reserv_primario}</span>
            </div>
          </Tooltip>
        )}
      </div>
      <div className={classes.btnContent}>
        <Button
          color="default"
          className={classes.btnClose}
          onClick={() => {
            onClose(true);
          }}
        >
          {notification.length > 1 ? 'Fechar Tudo' : 'Fechar'}
        </Button>
        <Button
          color="primary"
          className={classes.btnDetail}
          onClick={() => {
            onClose(true);
            history.push('/monitoramento/alertas');
          }}
        >
          {intl.get('navbar.notificationPage.details')}
        </Button>
      </div>
    </div>
  );
}
