const styles = (theme) => ({
  root: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    left: 0,
    boxSizing: 'border-box',
    background: 'rgb(224, 225, 227)',
    overflow: 'hidden',
  },
  withNavBar: {
    top: theme.dimensions.customHeight.navBar,
  },
  withNavBarTabs: {
    top:
      theme.dimensions.customHeight.navBar +
      theme.dimensions.customHeight.navTabs,
  },
  withNavBarFilters: {
    top:
      theme.dimensions.customHeight.navBar +
      theme.dimensions.customHeight.navFilters,
  },
  temporary: {
    marginLeft: theme.drawer.noWidth,
  },
  permanent: {
    marginLeft: theme.drawer.width,
    transition: theme.drawer.transition('margin'),
  },
  expanded: {
    marginLeft: theme.drawer.expandedWidth,
  },
  shadowTop: {
    position: 'absolute',
    width: '100%',
    height: 5,
    zIndex: theme.zIndex.scrollShadow,
    top: 0,
    right: 0,
    left: 0,
    boxShadow: 'inset 0 5px 6px -6px rgba(0,0,0,1)',
  },
  borderTop: {
    borderTop: '1px solid #D6D6D6 !important',
  },
  container: {
    height: theme.dimensions.customHeight.full,
    padding: theme.dimensions.customPadding.xLarge,
    overflow: 'auto',
    boxSizing: 'border-box',
  },
  panel: {
    boxSizing: 'border-box',
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 1px 5px 0px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 3px 1px -2px',
    background: theme.palette.background.paper,
    borderRadius: theme.dimensions.customBorderRadius.default,
    padding: theme.dimensions.customPadding.small,
  },
});

export default styles;
