export default theme => ({
    root: {
        userSelect: 'none',
        minHeight: 36,
        minWidth: 90,
        border: 'none',
        outline: 'none',
        borderRadius: '4px',
        fontSize: 14,
        fontWeight: 500,
        cursor: 'pointer',
        boxShadow: theme.elevation[1],
        transition: 'box-shadow 100ms ease',
        '&:active': {
            boxShadow: theme.elevation[5]
        },
        position: 'relative',
        overflow: 'hidden'
    },

    disabled: {
        backgroundColor: theme.palette.default.main,
        color: theme.palette.default.dark,
        pointerEvents: 'none',
        boxShadow: 'none'
    },

    flat: {
        boxShadow: 'none',
        '&:active': { boxShadow: 'none' }
    },

    primary: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.text,
    },

    accent: {
        backgroundColor: theme.palette.accent.main,
        color: theme.palette.text.accent,
    },

    success: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.text.success,
    },

    danger: {
        backgroundColor: theme.palette.danger.main,
        color: theme.palette.text.danger,
    },

    warning: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.text.warning,
    }
})