export default (theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    justifyContent: 'space-around',
    marginTop: theme.dimensions.customMargin.large,
  },
  column: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    boxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {
      width: `calc(100% - ${2 * theme.dimensions.customMargin.small}px)`,
    },
    [theme.breakpoints.between('md', 'lg')]: {
      width: `calc(50% - ${2 * theme.dimensions.customMargin.small}px)`,
    },
    [theme.breakpoints.up('lg')]: {
      width: `calc(19% - ${2 * theme.dimensions.customMargin.small}px)`,
    },
  },
  cardContent: {
    width: '100%',
    display: 'flex',
    margin: '5px 3px',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 5px',
    border: `solid ${theme.palette.text.secondary}`,
    borderWidth: theme.dimensions.customBorder.small,
    borderRadius: theme.dimensions.customBorderRadius.small,
    position: 'relative',
    whiteSpace: 'nowrap',
  },
  title: {
    background: '#FFF',
    overflow: 'hidden',
    maxWidth: '90%',
    position: 'absolute',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: '0px 2px',
    fontSize: 12,
    fontWeight: 600,
    top: -8,
    left: 5,
  },
  content: {
    fontSize: 14,
    color: theme.palette.default.text,
    marginLeft: 10,
    fontWeight: 500,
    '@media (max-width: 900px)': {
      fontSize: 12,
    },
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    '& > div': {
      width: '1rem',
      height: '1rem',
      margin: '2rem 0.3rem',
      background: '#aaa',
      borderRadius: '50%',
      animation: '0.9s $bounce infinite alternate',
      '&:nth-child(2)': {
        animationDelay: '0.3s',
      },
      '&:nth-child(3)': {
        animationDelay: '0.6s',
      },
    },
  },
  '@keyframes bounce': {
    to: {
      opacity: '0.3',
      transform: 'translate3d(0, -1rem, 0)',
    },
  },
});
