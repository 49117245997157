import { SearchbarTypes as types } from '../types'

export const storeFilters = filters => ({
    type: types.STORE_FILTERS,
    filters
})

export const storeCustomFilters = custom => ({
  type: types.STORE_CUSTOM_FILTERS,
  custom
})

export const storeLastFilters = filters => ({
    type: types.STORE_LAST_FILTERS,
    filters
})

export const storeLastPreference = preference => ({
    type: types.STORE_LAST_PREFERENCE,
    preference
})

export const setRefresh = bool => ({
    type: types.SET_REFRESH,
    data: bool
})
