import React, { useLayoutEffect, useRef, useCallback } from 'react'

import { useStyles } from '..'
import styles from './jss/trail'
import { uniqueId } from '../../helpers'
import { mapValueToRange, getOffset } from '../../utils/helpers'

export const Trail = ({ children, container, onChange, tick, range }) => {
    const classes = useStyles(styles)
    const id = uniqueId()
    const origin = useRef(null)

    const stableOnChange = useCallback(onChange, [])

    useLayoutEffect(() => {

        const onMouseMove = ({ pageX }) => {
            if (!container.current.contains(origin.current)) return
            const { offsetLeft, offsetParent, clientWidth } = container.current
            const { accLeft } = getOffset(offsetParent)

            const offLeft = offsetLeft + accLeft

            const x = pageX - offLeft
            const pos = Math.round((x / clientWidth) * 100)
            const left = pos > 100 ? 100 : pos < 0 ? 0 : pos
            const value = Math.round(left / tick) * tick
            stableOnChange(mapValueToRange(value, { min: 0, max: 100 }, range))
        }

        const onMouseDown = ({ target }) => origin.current = target

        const onMouseUp = e => {
            const { pageX } = e

            if (!container.current.contains(origin.current)) return

            const { offsetLeft, offsetParent, clientWidth } = container.current
            const { accLeft } = getOffset(offsetParent)

            const offLeft = offsetLeft + accLeft

            const x = pageX - offLeft
            const pos = Math.round((x / clientWidth) * 100)
            const left = pos > 100 ? 100 : pos < 0 ? 0 : pos
            const value = Math.round(left / tick) * tick
            origin.current = null
            stableOnChange(mapValueToRange(value, { min: 0, max: 100 }, range))
        }

        document.addEventListener('mouseup', onMouseUp)
        document.addEventListener('mousedown', onMouseDown)
        document.addEventListener('mousemove', onMouseMove)

        return () => {
            document.removeEventListener('mouseup', onMouseUp)
            document.removeEventListener('mousedown', onMouseDown)
            document.removeEventListener('mousemove', onMouseMove)
        }

    }, [container, stableOnChange, tick, range])

    return (
        <div className={classes.root} id={id}>
            {children}
        </div>
    )
}