import { combineReducers } from 'redux';

// Reducers
import { AuthReducer } from './Auth';
import { UserReducer } from './User';
import { FleetReducer } from './Fleet';
import { TrackerReducer } from './Tracker';
import { CompanyReducer } from './Company';
import { ProfileReducer } from './Profile';
import { DialogReducer } from './Dialog';
import { SnackBarReducer } from './Snackbar';
import { ProfilePermissionReducer } from './ProfilePermission';
import { MacroReducer } from './Macro';
import { TravelReducer } from './Travel';
import { VehicleReducer } from './Vehicle';
import { DriverReducer } from './Driver';
import { DtcReducer } from './Dtc';
import { SearchbarReducer } from './Searchbar';
import { CardReducer } from './Card';

// First defining the App's state
const reducer = combineReducers({
  auth: AuthReducer,
  user: UserReducer,
  fleet: FleetReducer,
  tracker: TrackerReducer,
  profile: ProfileReducer,
  snackbar: SnackBarReducer,
  dialog: DialogReducer,
  company: CompanyReducer,
  macro: MacroReducer,
  travel: TravelReducer,
  profilepermission: ProfilePermissionReducer,
  vehicle: VehicleReducer,
  driver: DriverReducer,
  dtc: DtcReducer,
  searchbar: SearchbarReducer,
  card: CardReducer,
});

// An action creator which intercept the action. Case action.type = LOGOUT return the initialState (resets the state)
const root = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
    localStorage.removeItem('token');
  }

  return reducer(state, action);
};

export default root;
