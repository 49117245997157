const { REACT_APP_API: path } = process.env;

export const endpoints = {
  'filter.company': `${path}/filter/company`,
  'filter.driver': `${path}/filter/driver`,
  'filter.event': `${path}/filter/event`,
  'filter.parameter': `${path}/filter/parameter`,
  'filter.vehicle': `${path}/filter/vehicle`,
  'filter.vehicle.telemetry': `${path}/filter/vehicle/telemetry`,
  'filter.vehicle.filterable': `${path}/filter/vehicle/filterable`,
  'filter.vehicle.dtc.filterable': `${path}/filter/vehicle/dtc/filterable`,
  'filter.vehicle.dtc': `${path}/filter/vehicle/dtc`,
  'filter.management.company': `${path}/filter/management/company`,
  'filter.fence': `${path}/filter/fence`,
  'ranking.driver': `${path}/relatorio/rankingmotorista`,
  'report.distance.fleet': `${path}/report/distance/fleet`,
  'report.distance.fleet.column': `${path}/report/distance/fleet/column`,
  'report.distance.fleet.dump': `${path}/report/distance/fleet/dump`,
  'report.distance.fleet.total': `${path}/report/distance/fleet/total`,
  'report.distance.vehicle': `${path}/report/distance/vehicle`,
  'report.distance.vehicle.column': `${path}/report/distance/vehicle/column`,
  'report.distance.vehicle.dump': `${path}/report/distance/vehicle/dump`,
  'report.distance.vehicle.total': `${path}/report/distance/vehicle/total`,
  'report.telemetry': `${path}/report/telemetry`,
  'report.telemetry.column': `${path}/report/telemetry/column`,
  'report.telemetry.dump': `${path}/report/telemetry/dump`,
  'report.telemetry.total': `${path}/report/telemetry/total`,
  'report.telemetry.phenix': `${path}/report/telemetry/phenix`,
  'report.telemetry.phenix.column': `${path}/report/telemetry/phenix/column`,
  'report.telemetry.phenix.dump': `${path}/report/telemetry/phenix/dump`,
  'report.telemetry.phenix.total': `${path}/report/telemetry/phenix/total`,
  'report.consumption': `${path}/report/consumption`,
  'report.consumption.column': `${path}/report/consumption/column`,
  'report.consumption.dump': `${path}/report/consumption/dump`,
  'report.consumption.total': `${path}/report/consumption/total`,
  'report.consumptionac': `${path}/report/consumptionac`,
  'report.consumptionac.column': `${path}/report/consumptionac/column`,
  'report.consumptionac.dump': `${path}/report/consumptionac/dump`,
  'report.consumptionac.total': `${path}/report/consumptionac/total`,
  'report.consumptionidle': `${path}/report/consumptionidle`,
  'report.consumptionidle.column': `${path}/report/consumptionidle/column`,
  'report.consumptionidle.dump': `${path}/report/consumptionidle/dump`,
  'report.consumptionidle.total': `${path}/report/consumptionidle/total`,
  'report.status': `${path}/report/status`,
  'report.status.total': `${path}/report/status/total`,
  'report.status.column': `${path}/report/status/column`,
  'report.status.dump': `${path}/report/status/dump`,
  'report.history': `${path}/report/history`,
  'report.history.total': `${path}/report/history/total`,
  'report.history.column': `${path}/report/history/column`,
  'report.history.dump': `${path}/report/history/dump`,
  'report.history.route': `${path}/report/history/route`,
  'report.fence': `${path}/report/fence`,
  'report.fence.total': `${path}/report/fence/total`,
  'report.fence.column': `${path}/report/fence/column`,
  'report.fence.dump': `${path}/report/fence/dump`,
  'report.vehicleProcessData': `${path}/report/vehicleProcessData`,
  'report.vehicleProcessData.column': `${path}/report/vehicleProcessData/column`,
  'report.vehicleProcessData.dump': `${path}/report/vehicleProcessData/dump`,
  'components.Reports.Options': `${path}/reports/options`,
  'installation.tracker': `${path}/installation/tracker`,
  'installation.tracker.valid': `${path}/installation/tracker/valid`,
  'installation.tracker.config': `${path}/installation/tracker/config`,
  'installation.tracker.unlinked': `${path}/installation/tracker/unlinked`,
  'installation.tracker.connect': `${path}/installation/tracker/connect`,
  'installation.tracker.operators': `${path}/installation/tracker/operators`,
  'installation.tracker.simcards': `${path}/installation/tracker/simcards`,
  'installation.tracker.add': `${path}/installation/tracker`,
  'installation.tracker.edit': `${path}/installation/tracker`,
  'installation.trackerById': `${path}/installation/tracker/id`,
  'installation.trackerInfo': `${path}/installation/tracker/info`,
  'installation.tracker.column': `${path}/installation/tracker/column`,
  'installation.vehicle': `${path}/installation/vehicle`,
  'installation.vehicle.config': `${path}/installation/vehicle/config`,
  'installation.vehicleId': `${path}/installation/vehicle/id`,
  'installation.vehiclePermission': `${path}/installation/vehicle/permission`,
  'installation.vehicle.column': `${path}/installation/vehicle/column`,
  'installation.vehicle.untracked': `${path}/installation/vehicle/untracked`,
  'installation.user': `${path}/installation/user`,
  'installation.user.config': `${path}/installation/user/config`,
  'installation.userId': `${path}/installation/user/id`,
  'installation.userPermission': `${path}/installation/user/permission`,
  'installation.user.column': `${path}/installation/user/column`,
  'installation.company': `${path}/installation/company`,
  'installation.companyId': `${path}/installation/company/id`,
  'installation.company.column': `${path}/installation/company/column`,
  'report.installation.company.dump': `${path}/installation/company/dump`,
  'travel': `${path}/travel`,
  'travel.column': `${path}/travel/column`,
  'travel.dump': `${path}/travel/dump`,
  'read.dtc': `${path}/fault/read/request`,
  'read.dtc.status': `${path}/fault/read/status/request`,
  'read.dtc.column': `${path}/fault/read/request/column`,
  'read.dtc.post': `${path}/fault/read/request`,
  'read.dtc.put': `${path}/fault/read/request`,
  'dtc.vehicles': `${path}/filter/vehicle/dtc`,
  'delete.dtc': `${path}/fault/delete/request`,
  'delete.dtc.status': `${path}/fault/delete/status/request`,
  'delete.dtc.column': `${path}/fault/delete/request/column`,
  'delete.dtc.post': `${path}/fault/delete/request`,
  'delete.dtc.put': `${path}/fault/delete/request`,
  'driverLog': `${path}/driverlog`,
  'driverLog.dump': `${path}/driverlog/dump`,
  'driverLog.column': `${path}/driverlog/column`,
  'driverLog.driver': `${path}/driverlog/driver`,
  'driverLog.driver.column': `${path}/driverlog/driver/column`,
  'driverLog.driver.dump': `${path}/driverlog/driver/dump`,
  'driverLog.configuration': `${path}/driverlog/configuration`,
  'driverLog.clocking': `${path}/driverlog/clocking`,
  'driverLog.comparative': `${path}/driverlog/comparative`,
  'driverLog.comparative.totals': `${path}/driverlog/comparative/totals`,
  'driverLog.event.column': `${path}/driverlog/event/column`,
  'driverLog.icon': `${path}/driverlog/icon`,
  'driverLog.event': `${path}/driverlog/event`,
  'driverLog.event.macro': `${path}/driverlog/event/macro`,
  'driverLog.event.category.column': `${path}/driverlog/event/category/column`,
  'driverLog.event.category': `${path}/driverlog/event/category`,
  'driverLog.user.role.column': `${path}/driverlog/user/role/column`,
  'driverLog.notification.column': `${path}/driverlog/notification/column`,
  'driverLog.notification.dump': `${path}/driverlog/notification/dump`,
  'driverLog.notification': `${path}/driverlog/notification`,
  'driverLog.notification.type.column': `${path}/driverlog/notification/type/column`,
  'driverLog.notification.type': `${path}/driverlog/notification/type`,
  'driverLog.report': `${path}/driverlog/report`,
  'driverLog.report.column': `${path}/driverlog/report/column`,
  'driverLog.report.dump': `${path}/driverlog/report/dump`,
  'driverLog.vector.defect.column': `${path}/v2/driverLog/checkList/vector/defect/column`,
  'driverLog.vector.defect': `${path}/v2/driverLog/checkList/vector/defect`,
  'driverLog.checklist': `${path}/v2/driverLog/checkList/report`,
  'driverLog.checklist.column': `${path}/v2/driverLog/checkList/report/column`,
  'driverLog.checklist.column.dump': `${path}/v2/driverLog/checkList/report/dump`,
  'driverLog.checklist.report.detail': `${path}/v2/driverLog/checkList/report/detail`,
  'driverLog.checklist.item': `${path}/v2/driverLog/checkList/item`,
  'driverLog.checklist.item.column': `${path}/v2/driverLog/checkList/item/column`,
  'driverLog.tire.condition': `${path}/v2/driverLog/checkList/tire/condition`,
  'driverLog.tire.condition.column': `${path}/v2/driverLog/checkList/tire/condition/column`,
  'journey': `${path}/journey`,
  'journey.column': `${path}/journey/column`,
  'journey.dump': `${path}/journey/dump`,
  'journey.macro': `${path}/journey/macro`,
  'journey.macro.column': `${path}/journey/macro/column`,
  'journey.macro.history': `${path}/journey/macro/history`,
  'journey.macro.history.macro': `${path}/journey/macro/history/macro`,
  'journey.macro.history.column': `${path}/journey/macro/history/column`,
  'journey.macro.usuario': `${path}/journey/macro/users`,
  'journey.macro.code': `${path}/journey/macro/code`,
  'maintenance.column': `${path}/maintenance/column`,
  'maintenance.dump': `${path}/maintenance/dump`,
  'maintenance.history.dump': `${path}/maintenance/history/dump`,
  'maintenance.history.column': `${path}/maintenance/history/column`,
  'maintenance.history': `${path}/maintenance/history`,
  'maintenance': `${path}/maintenance`,
  'maintenance.vehicle': `${path}/maintenance/vehicle`,
  'maintenance.user': `${path}/maintenance/user`,
  'maintenance.type': `${path}/maintenance/type`,
  'maintenance.status': `${path}/maintenance/status`,
  'vehicle.status': `${path}/vehicle/status`,
  'vehicle.event': `${path}/vehicle/events`,
  'vehicle.timeline': `${path}/vehicle/timeline`,
  'v2.vehicle.events': `${path}/v2/vehicle/events`,
  'v2.vehicle.data.trunktemperature': `${path}/v2/vehicle/data/trunktemperature`,
  'v2.vehicle.data.trunktemperature.dump': `${path}/v2/vehicle/data/trunktemperature/dump`,
  'vehicle.user': `${path}/vehicle/user`,
  'alerts.list.column': `${path}/alerts/list/request/column`,
  'alerts.list': `${path}/alerts/list/request`,
  'alerts.history.column': `${path}/alerts/history/request/column`,
  'alerts.history': `${path}/alerts/history/request`,
  'alerts.history.filters': `${path}/alerts/history/request/filters`,
  'alerts.history.specific': `${path}/alerts/history/request/specific`,
  'alerts.dump': `${path}/alerts/history/request/dump`,
  'alerts.list.specific': `${path}/alerts/list/request/specific`,
  'alerts.list.parameters': `${path}/alerts/list/request/parameters`,
  'alerts.list.fence': `${path}/alerts/list/request/fence`,
  'alerts.list.status': `${path}/alerts/list/request/status`,
  'filter.users': `${path}/filter/users`,
  'location.geocode': `${path}/location/geocode`,
  'vehicle.idle.engine': `${path}/vehicle/events/idleEngine`,
  'vehicle.mileage.alerts': `${path}/vehicle/events/mileage`,
  'driver.rating.bi': `${path}/rating/bi`,
  'rating': `${path}/rating`,
  'rating.report.dump': `${path}/rating/report`,
  'rating.report.speed.dump': `${path}/rating/report/speed`,
  'rating.automaker': `${path}/rating/automaker`,
  'rating.automaker.vehicle': `${path}/rating/automaker/vehicle`,
  'rating.company.automaker': `${path}/rating/company/automaker`,
  'rating.company.vehicle': `${path}/rating/company/vehicle`,
  'rating.driver': `${path}/rating/driver`,
  'rating.event': `${path}/rating/event`,
  'rating.performance': `${path}/rating/performance`,
  'rating.performance.driver': `${path}/rating/performance/driver`,
  'rating.performance.event': `${path}/rating/performance/event`,
  'rating.performance.trip': `${path}/rating/performance/trip`,
  'rating.performance.vehicle': `${path}/rating/performance/vehicle`,
  'rating.parameters': `${path}/rating/parameters`,
  'rating.parameters.period': `${path}/rating/parameters/period`,
  'rating.vehicle': `${path}/rating/vehicle`,
  'filter.vehicle.lockable': `${path}/filter/vehicle/lockable`,
  'report.trunk': `${path}/report/trunk`,
  'report.trunk.column': `${path}/report/trunk/column`,
  'report.trunk.dump': `${path}/report/trunk/dump`,
  'lock.request': `${path}/lock/request`,
  'lock.request.column': `${path}/lock/request/column`,
  'lock.request.dump': `${path}/lock/request/dump`,
  'lock.request.total': `${path}/lock/request/total`,
  'lock.control': `${path}/lock/control`,
  'lock.control.column': `${path}/lock/control/column`,
  'lock.control.vehicle': `${path}/lock/control/vehicle`,
  'lock.control.vehicle.column': `${path}/lock/control/vehicle/column`,
  'lock.control.user': `${path}/lock/control/user`,
  'lock.control.history': `${path}/control/history`,
  'lock.control.history.dump': `${path}/control/history/dump`,
  'lock.control.history.column': `${path}/control/history/column`,
  'block': `${path}/block/request`,
  'block.column': `${path}/block/request/column`,
  'block.dump': `${path}/block/request/dump`,
  'filter.vehicle.blocking': `${path}/filter/vehicle/blockable`,
  'control.request.column': `${path}/control/request/column`,
  'control.request': `${path}/control/request`,
  'control.request.all': `${path}/control/request/all`,
  'advancedImobillization.config.vehicles': `${path}/veiculo/configevents/telemetry`,
  'advancedImobillization.config.resources': `${path}/veiculo/configevents/available`,
  'advancedImobillization.config.processing': `${path}/veiculo/configevents/processing`,
  'advancedImobillization.config.save': `${path}/veiculo/configevents/save`,
  //=-=-=-=-=-=-= Route V2 =-=-=-=-=-=-=//
  'vehicle.dms': `${path}/v2/vehicle/dms`,
  'vehicle.dms.column': `${path}/v2/vehicle/dms/column`,
  'vehicle.dms.dump': `${path}/v2/vehicle/dms/dump`,
  'vehicle.category': `${path}/v2/vehicle/category`,
  'filter.vehicle.dms': `${path}/v2/vehicle/filter/dms`,
  'filter.user': `${path}/v2/filter/user`,
  'gatewayCommands.operationLogs': `${path}/v2/gatewayCommands/operationLogs`,
  'gatewayCommands.operationLogs.column': `${path}/v2/gatewayCommands/operationLogs/column`,
  'gatewayCommands.operationLogs.dump': `${path}/v2/gatewayCommands/operationLogs/dump`,
  'cylinder.request': `${path}/v2/vehicle/cylinder`,
  'cylinder.request.column': `${path}/v2/vehicle/cylinder/column`,
  'cylinder.request.dump': `${path}/v2/vehicle/cylinder/dump`,
  'alerts.occurrence': `${path}/v2/alerts/occurrences`,
  'alerts.occurrence.dump': `${path}/v2/alerts/occurrences/dump`,
  'alerts.occurrence.column': `${path}/v2/alerts/occurrences/column`,
  'alerts.configuration': `${path}/v2/alerts/configurations`,
  'alerts.configuration.column': `${path}/v2/alerts/configurations/column`,
  'notifications': `${path}/v2/notification`,
  'event.types': `${path}/v2/events/types`,
  'immobilization': `${path}/v2/immobilization`,
  'immobilization.history': `${path}/v2/immobilization/history`,
  'immobilization.pending': `${path}/v2/immobilization/pending`,
  'immobilization.setting': `${path}/v2/immobilization/setting`,
  'immobilization.setting.progress': `${path}/v2/immobilization/setting/progress`,
  'immobilization.synchronize': `${path}/v2/immobilization/synchronize`,
  'alert': `${path}/v2/alert`,
  'v2.alert.dump': `${path}/v2/alert/dump`,
  'alert.filter': `${path}/v2/alert/filter`,
  'alert.detail': `${path}/v2/alert/detail`,
  'alert.totalizer': `${path}/v2/alert/totalizer`,
  'alert.setting': `${path}/v2/alert/setting`,
  'alert.setting.progress': `${path}/v2/alert/setting/progress`,
  'v2.maintenance': `${path}/v2/maintenance`,
  'operation': `${path}/v2/operation`,
  'operation.vehicle': `${path}/v2/operation/vehicle`,
  'implement': `${path}/v2/implement`,
  'operation.implement': `${path}/v2/operation/implement`,
  'grouping': `${path}/v2/grouping`,
  'grouping.vehicle': `${path}/v2/grouping/vehicle`,
  'v2.vehicle': `${path}/v2/vehicle`,
  'driver': `${path}/v2/driver`,
  'driver.card': `${path}/v2/driver/card`,
  'v2.driver': `${path}/v2/driver`,
  'v2.driverLog.summary': `${path}/v2/driverLog/journey/summary`,
  'v2.driverLog.summary.dump': `${path}/v2/driverLog/journey/summary/dump`,
  'gatewayCommands.dtc': `${path}/v2/gatewayCommands/dtc`,
  'v2.fence.operation': `${path}/v2/fence/operation`,
  'v2.fence': `${path}/v2/fence`,
  'v2.pilotebem.driver': `${path}/v2/pilotebem/driver`,
  'v2.pilotebem.vehicle': `${path}/v2/pilotebem/vehicle`,
  'v2.pilotebem.parameter': `${path}/v2/pilotebem/parameter`,
};

export default endpoints;
