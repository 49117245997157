import React, { useEffect, useState } from "react";
import { FaTruck, FaCar, FaTractor } from "react-icons/fa";
import clsx from "clsx";
import { Tabs, Tab } from "@material-ui/core";
import { Paper } from "../../core";
import VehiclesPanel from "./VehiclesPanel";
import DetailsPanel from "./DetailsPanel";
import BlockPanel from "./BlockPanel";
import PlacesPanel from "./PlacesPanel";

import { useStyles } from "../../styles";
import styles from "./jss/panel";

const allVehicleCategories = [
  { id: 1, label: "Caminhões", Icon: FaTruck },
  { id: 2, label: "Carros", Icon: FaCar },
  { id: 3, label: "Agrícolas", Icon: FaTractor },
];

export const Panel = ({
  vehicles,
  vehicleMarkers,
  showVehicleDetails,
  setShowVehicleDetails,
  showVehicleBlock,
  setShowVehicleBlock,
  onTrack,
  telemetryPermission,
  route,
  setRoute,
  visibleRoute,
  setVisibleRoute,
  showRoute,
  setShowRoute,
}) => {
  const interpolation = { showVehicleDetails };
  const classes = useStyles(styles, interpolation);

  const [vehicleCategories, setVehicleCategories] = useState(
    allVehicleCategories
  );
  const [query, setQuery] = useState("");
  const [value, setValue] = useState(0);
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState(null);
  const [ignition, setIgnition] = useState("");
  const [color, setColor] = useState("");
  const [speed, setSpeed] = useState("");
  const [license, setLicense] = useState("");
  const [lat, setLat] = useState(null);
  const [lng, setLang] = useState(null);
  const [updatedAt, setUpdatedAt] = useState("");
  const [fuel, setFuel] = useState("");
  const [odometer, setOdometer] = useState("");
  const [id, setId] = useState(null);
  const [withSlide, setWithSlide] = useState(false);
  const [slideDirection, setSlideDirection] = useState("left");
  const [searchOn, setSearchOn] = useState(false);
  const [vehiclesPanelListScrollTop, setVehiclesPanelListScrollTop] = useState(
    0
  );

  const handleChange = (event, newValue) => {
    if (newValue !== value) {
      setValue(newValue);
      setWithSlide(true);
    }
  };

  const panelViewsStyle = clsx({
    [classes.panelViews]: true,
    [classes.panelViewsVisible]: value === 0 && !showVehicleDetails,
    [classes.panelViewsHidden]: value === 1 || showVehicleDetails,
  });

  useEffect(() => {
    if (showVehicleDetails && vehicleMarkers.length) {
      const {
        descricao: description,
        id_categoria: category,
        status_ignicao: ignition,
        color,
        velocidade_atual: speed,
        placa: license,
        latitude: lat,
        longitude: lng,
        data: updatedAt,
        nivel_combustivel: fuel,
        odometro: odometer,
        id_veiculo: id,
      } = vehicleMarkers.find(
        (vehicleMarker) => vehicleMarker.id_veiculo === showVehicleDetails
      );
      setDescription(description);
      setCategory(category);
      setIgnition(ignition);
      setColor(color);
      setSpeed(speed);
      setLicense(license);
      setLat(lat);
      setLang(lng);
      setUpdatedAt(updatedAt);
      setFuel(fuel);
      setOdometer(odometer);
      setId(id);
    }
  }, [showVehicleDetails, vehicleMarkers]);

  useEffect(() => {
    if (showVehicleDetails) {
      setWithSlide(true);
      setSlideDirection("right");
    } else {
      setSlideDirection("left");
    }
  }, [showVehicleDetails]);

  return (
    <Paper className={classes.root}>
      <Tabs
        classes={{ root: classes.tabs, indicator: classes.indicator }}
        value={value}
        onChange={handleChange}
      >
        <Tab
          label={
            showVehicleBlock
              ? "Bloquear"
              : !showVehicleDetails
              ? "Veículos"
              : "Veículo"
          }
          classes={{ root: classes.tab }}
        />
        <Tab disabled={true} label="Locais" classes={{ root: classes.tab }} />
      </Tabs>
      <div className={panelViewsStyle}>
        {value === 0 && !showVehicleDetails && !showVehicleBlock && (
          <VehiclesPanel
            vehiclesPanelListScrollTop={vehiclesPanelListScrollTop}
            setVehiclesPanelListScrollTop={setVehiclesPanelListScrollTop}
            allVehicleCategories={allVehicleCategories}
            searchOn={searchOn}
            setSearchOn={setSearchOn}
            vehicleCategories={vehicleCategories}
            setVehicleCategories={setVehicleCategories}
            query={query}
            setQuery={setQuery}
            vehicles={vehicles}
            setShowVehicleDetails={setShowVehicleDetails}
            setShowVehicleBlock={setShowVehicleBlock}
            onTrack={onTrack}
            withSlide={withSlide}
          />
        )}
        {value === 0 && showVehicleDetails && !showVehicleBlock && (
          <DetailsPanel
            description={description}
            category={category}
            ignition={ignition}
            color={color}
            speed={speed}
            license={license}
            lat={lat}
            lng={lng}
            updatedAt={updatedAt}
            telemetryPermission={telemetryPermission}
            fuel={fuel}
            odometer={odometer}
            id={id}
            setShowVehicleDetails={setShowVehicleDetails}
            route={route}
            setRoute={setRoute}
            visibleRoute={visibleRoute}
            setVisibleRoute={setVisibleRoute}
            showRoute={showRoute}
            setShowRoute={setShowRoute}
            withSlide={withSlide}
            slideDirection={slideDirection}
          />
        )}
        {value === 0 && showVehicleDetails && showVehicleBlock && (
          <BlockPanel
            description={description}
            setShowVehicleDetails={setShowVehicleDetails}
            setShowVehicleBlock={setShowVehicleBlock}
            withSlide={withSlide}
            slideDirection={slideDirection}
          />
        )}
        {value === 1 && <PlacesPanel withSlide={withSlide} />}
      </div>
    </Paper>
  );
};

export default Panel;
