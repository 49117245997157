import clsx from 'clsx';
import intl from 'react-intl-universal';
import React, { useEffect, useState } from 'react';
import { CustomTitle } from '..';

import { useStyles } from '../../hooks';
import styles from './jss/CustomGroupedRadio';

const FunctionComponent = ({
  options = [
    { id: 0, label: intl.get('components.CustomGroupedRadio.labelText2'), value: 'no', checked: false },
    { id: 1, label: intl.get('components.CustomGroupedRadio.labelText1'), value: 'yes', checked: false },
  ],
  checkedOption = null,
  title = '',
  customError = '',
  disabled = null,
  onChange = () => {},
}) => {
  const classes = useStyles(styles);

  const [radioOptions, setRadioOptions] = useState(
    options.map((option) => ({
      ...option,
      checked: option.id === checkedOption,
    }))
  );

  const rootStyle = clsx({
    [classes.root]: true,
    [classes.font]: true,
    [classes.disabled]: disabled,
  });
  const groupedStyled = clsx({
    [classes.rowContainer]: true,
    [classes.columnContainer]: false,
  });

  const handleOptionChange = (changeEvent) => {
    const changedOptions = radioOptions.map((option) => ({
      ...option,
      checked: Boolean(option.value === changeEvent.target.value),
    }));

    setRadioOptions(changedOptions);
    onChange(changedOptions.find(({ checked }) => checked).id);
  };

  useEffect(() => {
    setRadioOptions((prevOptions) =>
      prevOptions.map((option) => ({
        ...option,
        checked: option.id === checkedOption,
      }))
    );
  }, [checkedOption]);

  return (
    <div className={rootStyle}>
      {title && (
        <CustomTitle
          classes={{
            container: classes.title,
          }}
          title={title}
        />
      )}
      <div className={groupedStyled}>
        {radioOptions.map((option) => (
          <div className={classes.container} key={option.id}>
            <label className={classes.label}>{option.label}</label>
            <input
              type="radio"
              value={option.value}
              checked={option.checked}
              className={classes.radio}
              onChange={handleOptionChange}
              disabled={disabled}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export const CustomGroupedRadio = React.memo(FunctionComponent);

export default CustomGroupedRadio;
