import React from 'react';

import { useStyles, Overlay, Paper } from '..';
import styles from './jss/dialog';
import { uniqueId } from '../../helpers';
import { FaTimes } from 'react-icons/fa';

export const Dialog = ({ children, open, title, onClose, className, onClick }) => {
  const classes = useStyles(styles);
  const id = uniqueId('paper-');
  if (!open) return null;

  const paperStyle = `${classes.root} ${className}`;

  return (
    <Overlay>
      <Paper className={paperStyle} id={id} onClick={onClick ? onClick : () => {}}>
        {title && (
          <div className={classes.header}>
            <span className={classes.title}>{title}</span>
            <div className={classes.grow} />
            <div className={classes.close} onClick={onClose}>
              <FaTimes className={classes.closeIcon} />
            </div>
          </div>
        )}
        {children}
      </Paper>
    </Overlay>
  );
};

Dialog.defaultProps = {
  open: false,
  className: '',
  onClose: () => {},
  title: '',
};

export default Dialog;
