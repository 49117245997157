import { useCallback, useEffect, useState } from 'react';

import debounce from 'lodash.debounce';

export const useResize = (myRef) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const debounceCallback = debounce(() => handleResize(), 450);

  const handleResize = useCallback(() => {
    if (myRef.current) {
      setWidth(myRef.current.offsetWidth);
      setHeight(myRef.current.offsetHeight);
    }
  }, [myRef]);

  useEffect(() => {
    window.addEventListener('load', debounceCallback());
    window.addEventListener('resize', debounceCallback);

    return () => {
      window.removeEventListener('load', debounceCallback);
      window.removeEventListener('resize', debounceCallback);
    };
  }, [debounceCallback, myRef]);
  return { width, height };
};

export default useResize;
