import background from '../../../assets/images/background/background.jpg';

export default (theme) => ({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    fontFamily: theme.typography.standard.fontFamily,
    fontSize: theme.typography.standard.fontSize,
    fontWeight: theme.typography.standard.fontWeightBold,
    letterSpacing: theme.typography.standard.letterSpacing,
    '@media(max-width: 693px)': {
      justifyContent: 'center',
      background: '#FFFFFF',
    },
  },
  background: {
    width: '100vw',
    height: '100vh',
    background: 'linear-gradient(to right, #136a8a, #267871)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `url(${background})`,
    '@media(max-width: 693px)': {
      display: 'none',
    },
  },

  paper: {
    width: 'calc(100% - 20px)',
    maxWidth: 370,
    padding: '24px 8px',
    display: 'flex',
    background: '#FFFFFF',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    zIndex: 10,
    right: '50px',
    borderRadius: '5px',
    overflow: 'hidden',
    transition: 'background .8s',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
    '@media(max-width: 693px)': {
      boxShadow: 'none',
      background: 'transparent',
      position: 'unset',
    },
  },

  logoContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '32px',
  },

  logoContainerExpanded: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '8px 0px',
  },

  formContainer: {
    width: '100%',
    padding: 5,
  },

  logo: {
    height: 'auto',
    marginTop: 10,
    width: '80%',
  },

  logoContainerFooter: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '8px 0px',
    opacity: 0.6,
    fontSize: '10px',
  },

  logoChiptronic: {
    height: 'auto',
    width: '30%',
    objectFit: 'contain',
  },

  loginFormTitle: {
    '& > h3': {
      fontSize: 24,
      color: '#444444',
      textAlign: 'center',
      fontWeight: 400,
      marginTop: 8,
    },
  },

  title: {
    flex: 0.1,
    padding: 10,
    paddingTop: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
  },

  grow: {
    flexGrow: 1,
  },
});
