import { SearchbarTypes as types } from '../types';

let fix = process.env.REACT_APP_FIX_TIME
  ? parseInt(process.env.REACT_APP_FIX_TIME)
  : 0;

const initialState = {
  categories: [],
  vehicles: [],
  filters: {
    categories: [],
    vehicles: [],
    begin: new Date(Date.now() + fix - 86400000),
    end: new Date(Date.now() + fix),
  },
  lastFilters: null,
  lastPreference: null,
  isFetched: false,
  refreshed: false,
};

export const SearchbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.STORE_SEARCHBAR:
      return storeSearchbar(state, action);
    case types.STORE_FILTERS:
      return storeFilters(state, action);
    case types.STORE_CUSTOM_FILTERS:
      return storeCustomFilters(state, action);
    case types.STORE_LAST_FILTERS:
      return storeLastFilters(state, action);
    case types.STORE_LAST_PREFERENCE:
      return storeLastPreference(state, action);
    case types.REFRESH_SEARCHBAR:
      return refreshSearchbar(state, action);
    case types.SET_REFRESH:
      return { ...state, refresh: action.data };
    default:
      return state;
  }
};

const storeSearchbar = (state, action) => {
  const { categorias, results } = action.data;

  if (!categorias || !results) {
    return {
      ...state,
      categories: [],
      vehicles: [],
      isFetched: true,
    };
  }

  const categories = categorias.map((x) => ({ id: x.id, label: x.descricao }));
  const vehicles = results
    .map((x) => ({
      id: x.id,
      category: x.id_categoria,
      empresa: x.id_empresa,
      label: x.descricao,
    }))
    .sort((a, b) => a.label - b.label);

  return {
    ...state,
    categories,
    vehicles,
    filters: {
      categories,
      vehicles: [vehicles[0]],
      begin: new Date(Date.now() + fix - 86400000),
      end: new Date(Date.now() + fix),
    },
    lastFilters: {
      categories,
      vehicles: [vehicles[0]],
      begin: new Date(Date.now() + fix - 86400000),
      end: new Date(Date.now() + fix),
    },
    isFetched: true,
  };
};

const storeFilters = (state, action) => ({
  ...state,
  filters: action.filters,
});

const storeCustomFilters = (state, action) => ({
  ...state,
  filters: {
    ...state.filters,
    ...action.custom,
  },
  refreshed: true,
});

const storeLastFilters = (state, action) => ({
  ...state,
  lastFilters: action.filters,
});

const storeLastPreference = (state, action) => ({
  ...state,
  lastPreference: action.preference,
});

const refreshSearchbar = (state, action) => {
  const { categorias, results } = action.data;

  //const vIds = results.map(x => x.id)
  //const cIds = results.map(x => x.id_categoria)

  const categories = categorias.map((x) => ({ id: x.id, label: x.descricao }));
  const vehicles = results.map((x) => ({
    id: x.id,
    category: x.id_categoria,
    empresa: x.id_empresa,
    label: x.descricao,
  }));
  return {
    ...state,
    categories,
    vehicles,
    filters: {
      ...state.filters,
      vehicles: [vehicles[0]], //: state.filters.vehicles.filter(x => vIds.includes(x.id)),
      categories, //: state.filters.categories.filter(x => cIds.includes(x.id))
    },
    refreshed: true,
  };
};
