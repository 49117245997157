import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { format, isValid } from 'date-fns';
import intl from 'react-intl-universal';
import clsx from 'clsx';
import DateFnsUtils from '@date-io/date-fns';
import ptBRLocale from 'date-fns/locale/pt-BR';
import enUSLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';

import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Tooltip } from '@material-ui/core';
import { FaRegClock } from 'react-icons/fa';
import CustomTitle from '../../core/CustomTitle';

import { useBreakpoint, useStyles } from '../../hooks';
import styles from './jss/CustomDateTimePicker';

const FunctionComponent = ({
  title,
  disabled = false,
  disablePast = false,
  disableFuture = true,
  error = false,
  errorMessage = intl.get('datetime.dateInvalid'),
  now = false,
  mask = intl.get('global.dateInput'),
  value = format(new Date(), intl.get('global.dateValue'),),
  setValue = () => format(new Date(), intl.get('global.dateValue'),),
  maxDate = '2100-01-01',
  minDate = '2000-01-01',
  setError = () => {},
}) => {
  const { language } = useSelector((state) => state.auth);
  const classes = useStyles(styles);

  const [selectedDate, handleDateChange] = useState(value);

  const breakPoint = useBreakpoint('(max-width: 600px)');

  const handleChange = (value) => {
    handleDateChange(value);
    setValue(value);

    if (value && isValid(new Date(value))) {
      if (new Date(value).getTime() < new Date(minDate).getTime()) {
        setError(`${intl.get('datetime.minimumDate')}: `, format(new Date(minDate), intl.get('global.dateInput')));
      } else if (new Date(value).getTime() > new Date(maxDate).getTime()) {
        setError(`${intl.get('datetime.minimumDate')}: `, format(new Date(minDate), intl.get('global.dateInput')));
      }
    }
  };

  const handleNow = (e) => {
    e.stopPropagation();

    handleDateChange(new Date(Date.now()));
    setValue(new Date(Date.now()));
  };

  const pickerContainerStyle = clsx({
    [classes.pickerContainer]: true,
    [classes.pickerNowContainer]: now,
    [classes.pickerContainerError]: error,
  });

  const nowContainerStyle = clsx({
    [classes.now]: true,
    [classes.nowError]: error,
  });

  const disableContainerStyle = clsx({
    [classes.disable]: disabled,
  });

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={
        language === 'pt-BR'
          ? ptBRLocale
          : language === 'en-US' || language === 'en'
          ? enUSLocale
          : language === 'es'
          ? esLocale
          : ptBRLocale
      }
    >
      <div className={classes.root}>
        {title && (
          <CustomTitle
            classes={{
              container: classes.title,
            }}
            title={title}
            message={error ? errorMessage : null}
            messageType={error ? 'error' : null}
          />
        )}
        <div className={classes.container}>
          <div className={pickerContainerStyle}>
            <KeyboardDateTimePicker
              autoOk
              variant={breakPoint ? 'dialog' : 'inline'}
              ampm={false}
              value={value}
              onChange={handleChange}
              format={mask}
              disabled={disabled}
              disablePast={disablePast}
              disableFuture={disableFuture}
              inputVariant="standard"
              invalidDateMessage=""
              maxDateMessage=""
              minDateMessage=""
              maxDate={maxDate}
              minDate={minDate}
              onError={(err) => {
                if (err) setError(intl.get('coreV2.simpleFilter.paramsError'));
              }}
            />
          </div>
          {now && (
            <>
              <Tooltip className={disableContainerStyle} title={intl.get('datetime.now')} placement={'top'}>
                <span className={nowContainerStyle} onClick={(e) => handleNow(e)}>
                  <FaRegClock />
                </span>
              </Tooltip>
            </>
          )}
        </div>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export const CustomDateTimePicker = React.memo(FunctionComponent);

export default CustomDateTimePicker;
