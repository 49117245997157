import mainLogo from './mainLogo.png';
import shortLogo from './shortLogo.png';
import sponsorFullLogo from '../global/sponsorFullLogo.png';
import sponsorLogo from '../global/sponsorLogo.png';
import sponsorLogoLittle from '../global/sponsorLogoLittle.png';

const settings = {
  mainDistribution: true,
  name: 'Chiptronic',
  originName: 'Chiptronic Science Telematics',
  shortName: 'CT',
  logo: mainLogo,
  shortLogo: shortLogo,
  sponsorFullLogo: sponsorFullLogo,
  sponsorLogo: sponsorLogo,
  sponsorLogoLittle: sponsorLogoLittle,
  url: 'http://localhost:3011',
};

export default settings;
