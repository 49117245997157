import React from 'react'

import { useStyles } from '..'
import styles from './jss/option'
import clsx from 'clsx'
import { FaCheck } from 'react-icons/fa';

export const Option = ({ item, onClickOption, selected, multi }) => {
    const classes = useStyles(styles)

    const onClick = e => {
        onClickOption(item)
        e.stopPropagation()
    }

    const checked = (multi) ? true : false

    const isActive = !selected ? checked : Array.isArray(selected) ? selected.map(x => x.id).includes(item.id) : (selected && selected.id === item.id)


    const rootStyle = clsx({
        [classes.root]: true,
        [classes.active]: isActive
    })

    return (
        <div className={rootStyle} onClick={onClick}>
            <div className={item.enhanced ? classes.enhanced : ''}>{item.label}</div>
            <div className={classes.grow} />
            {isActive && <FaCheck className={classes.icon} />}
        </div>
    )
}

export default Option