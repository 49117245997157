import React from 'react'

import { withRouter } from 'react-router-dom'

import { crumbs } from './crumbs'
import { icons } from '../Drawer/Items/icons'

import { useStyles } from '..'
import styles from './jss/breadcrumbs'

import { FaChevronRight } from 'react-icons/fa'

export const Breadcrumbs = withRouter(({ location }) => {
    const classes = useStyles(styles)
    const { pathname } = location
    const crumb = crumbs.find(x => x.path === pathname)
    const Icon = icons[crumb.icon]

    return (
        <div className={classes.root}>
            <div className={classes.main}>
                <Icon className={classes.icon} />
            </div>
            <FaChevronRight className={classes.chevron} />
            {
                crumb.crumbs.map((x, i) => (
                    <React.Fragment key={x.label}>
                        <div className={classes.crumb}>
                            {x.label}
                        </div>
                        {!(i +1  === crumb.crumbs.length) && <FaChevronRight className={classes.chevron} />}
                    </React.Fragment>
                ))
            }
        </div>
    )
})

export default Breadcrumbs