import React, { useContext } from 'react';
import { context } from '../../components/Navbar/context';

import { useStyles, Button } from '..';
import styles from './styles';
import clsx from 'clsx';

import { FaBars } from 'react-icons/fa';
import getConfig from '../../config/configLoader';

export const Bar = ({ children, variant }) => {
  const { mainDistribution, logo } = getConfig();

  const classes = useStyles(styles);
  const drawer = useContext(context);

  const { expanded, locked, setLocked, setExpanded } = drawer;

  const rootStyle = clsx({
    [classes.permanent]: variant === 'permanent',
    [classes.expanded]: expanded && variant === 'permanent',
    [classes.temporary]: variant === 'temporary',
  });

  const onMenuClick = () => {
    setLocked(!locked);
    setExpanded(!locked);
  };

  return (
    <div className={rootStyle}>
      <Button variant={'icon'} onClick={onMenuClick}>
        <FaBars className={classes.hamburguer} />
      </Button>
      {!expanded && <img src={logo} alt="logo" className={mainDistribution ? classes.logo : classes.logoClient} />}
      <div className={classes.grow} />
      {children}
    </div>
  );
};

export default Bar;
