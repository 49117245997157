export default (theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    color: ({ color }) => (color ? color : "#999999"),
    fontSize: 20,
  },

  container: {
    position: "relative",
  },

  "@keyframes loader": {
    from: {
      transform: "rotate(0deg)",
    },

    to: {
      transform: "rotate(360deg)",
    },
  },

  loader: {
    width: ({ size }) => (size ? size : 20),
    height: ({ size }) => (size ? size : 20),
    display: "inline-block",
    padding: 0,
    borderRadius: "100%",
    border: "solid",
    borderTopColor: ({ color }) => (color ? color : "#999999"),
    borderBottomColor: "rgba(255,255,255, 0.3)",
    borderLeftColor: ({ color }) => (color ? color : "#999999"),
    borderRightColor: "rgba(255,255,255, 0.3)",
    animation: "$loader 1s ease-in-out infinite",
  },

  label: {
    fontSize: 12,
    color: "#212121",
    marginLeft: 5,
  },
});
