import React, { useState } from 'react'

import { useStyles } from '..'
import styles from './jss/multiSelect'
import clsx from 'clsx'

import { FaCaretDown } from 'react-icons/fa'

//i18n
import intl from 'react-intl-universal'

import { uniqueId } from '../../helpers'

import { OptionPanel } from './OptionPanel'

export const MultiSelect = ({ placeholder, items, selected, onSelect, onSelectAll, feminine, label, className }) => {
 
    const classes = useStyles(styles)

    const id = uniqueId('multi-')

    const [open, setOpen] = useState(false)

    const handleClick = e => {
        e.stopPropagation()
        e.preventDefault()
        setOpen(true)
    }

    const onClose = () => open ? setOpen(false) : null

    const onClickOption = option => {
        const { id } = option
        const included = selected.map(x => x.id).includes(id)
        return included ? onSelect(selected.filter(x => x.id !== id)) : onSelect(selected.concat(option))
    }

    const getItems = () => items


    const renderTitle = () => {
        if (selected.length === 1 && items.length !== 1) return selected[0].label

        if (selected.length === items.length) return feminine ? intl.get('multiselect.allTextFeminine') : intl.get('multiselect.allText')

        return feminine ? `${selected.length} ${intl.get('multiselect.allFeminine')}` : `${selected.length} ${intl.get('multiselect.all')}`
    }


    const rootStyle = clsx({
        [classes.root]: true,
        [classes.focus]: open
    })

    const caretStyle = clsx({
        [classes.caret]: true,
        [classes.caretDown]: open
    })

    const wrapperStyle = `${classes.wrapper} ${className ? className : ''}`

    return (
        <div className={wrapperStyle} onClick={handleClick} style={{flex: 1}}>
            <div className={classes.label} >{label}</div>
            <label htmlFor={id} className={rootStyle} >
                <div className={classes.innerContainer} >
                    {
                        selected.length > 0 &&
                        <div className={classes.tags}>
                            {renderTitle()}
                        </div>
                    }
                    {
                        !selected.length &&
                        <div className={classes.placeholder}>
                            {placeholder}
                        </div>
                    }
                </div>
                <div className={classes.grow} />
                <FaCaretDown className={caretStyle} />
            </label>

            <OptionPanel
                open={open}
                id={id}
                items={getItems()}
                selected={selected}
                onClickOption={onClickOption}
                onClose={onClose}
                onSelectAll={onSelectAll}
                feminine={feminine}
            />
        </div>
    )
}

MultiSelect.defaultProps = {
    placeholder: '',
    allText: 'Todos',
    label: '',
    feminine: false,
    items: [],
    onSelectAll: () => { }
}

export default MultiSelect