export default theme => ({
    notifications: {
        padding: 10,
        color: '#3c484f',
        cursor: 'pointer',
    },

    toast: {
        borderRadius: 5,
        userSelect: 'none'
    },

    containerToast: {
        display: 'flex',
        flexDirection: 'column',
    },

    title: {
        fontSize: 12,
        fontWeight: 700,
        padding: 5,
    },

    text: {
        fontSize: 12,
        padding: 5,
    },

    veiculo: {
        fontSize: 12,
        fontWeight: 700,
        padding: 5,
    },

    action: {
        fontSize: 12,
        fontWeight: 700,
        padding: 5,
    },
})
