import React from 'react'

import { useStyles } from '..'
import styles from './jss/tag'
import { FaTimes } from 'react-icons/fa';

export const Tag = ({ item, onClick }) => {
    const classes = useStyles(styles)

    const clickHandler = e => {
        e.stopPropagation()
        e.preventDefault()
        onClick(item)
    }

    return (
        <div className={classes.root}>
            <div className={classes.label}>
                {item.label}
            </div>
            <FaTimes className={classes.icon} onClick={clickHandler} />
        </div>
    )
}

export default Tag