import React from "react";
import Flags from "./Flags";

export const LicensePlate = ({ width, x, y, countryAbbreviation, license }) => {
  const ratio = 1 / 3;
  const viewBoxWidth = 1500;
  const viewBoxHeight = viewBoxWidth * ratio;

  const plateDraw = {
    borderRadius: 70,
    strokeWidth: 35,
    stroke: "#505050",
    fill: "white",
    opacity: 1,
  };
  plateDraw.width = viewBoxWidth - plateDraw.strokeWidth;
  plateDraw.height = viewBoxHeight - plateDraw.strokeWidth;
  plateDraw.x = plateDraw.strokeWidth / 2;
  plateDraw.y = plateDraw.strokeWidth / 2;

  const divider = {
    x1: plateDraw.width / 4 + plateDraw.strokeWidth,
    y1: plateDraw.y,
    x2: plateDraw.width / 4 + plateDraw.strokeWidth,
    y2: plateDraw.y + plateDraw.height,
    stroke: plateDraw.stroke,
    strokeWidth: plateDraw.strokeWidth,
  };

  const Flag = Flags[countryAbbreviation];
  let flagDraw = {
    width: 220,
    height: 220,
  };
  flagDraw.x = (plateDraw.strokeWidth + divider.x1 - flagDraw.width) / 2;
  flagDraw.y = plateDraw.y + 0.35 * plateDraw.height - flagDraw.height / 2;

  const flagAbbrevText = {
    textAnchor: "middle",
    alignmentBaseline: "central",
    x: (plateDraw.strokeWidth + divider.x1) / 2,
    y: plateDraw.y + 0.75 * plateDraw.height,
    fontFamily: "Noto Sans",
    fontSize: "145",
    fontWeight: "600",
    fill: "#505050",
  };

  const licenseText = {
    textAnchor: "middle",
    alignmentBaseline: "central",
    x: divider.x1 + (plateDraw.width - divider.x1 + plateDraw.strokeWidth) / 2,
    y: plateDraw.y + 0.5 * plateDraw.height,
    fontFamily: "Noto Sans",
    fontSize: "205",
    fontWeight: "600",
    fill: "#505050",
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="svg171"
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      version="1.0"
      width={width}
      height={width * ratio}
      x={x}
      y={y}
    >
      <rect
        width={plateDraw.width}
        height={plateDraw.height}
        rx={plateDraw.borderRadius}
        ry={plateDraw.borderRadius}
        strokeWidth={plateDraw.strokeWidth}
        stroke={plateDraw.stroke}
        fill={plateDraw.fill}
        opacity={plateDraw.opacity}
        x={plateDraw.x}
        y={plateDraw.y}
      />
      <Flag {...flagDraw} />
      <line
        x1={divider.x1}
        y1={divider.y1}
        x2={divider.x2}
        y2={divider.y2}
        stroke={divider.stroke}
        strokeWidth={divider.strokeWidth}
      />
      <text
        textAnchor={flagAbbrevText.textAnchor}
        alignmentBaseline={flagAbbrevText.alignmentBaseline}
        x={flagAbbrevText.x}
        y={flagAbbrevText.y}
        fontFamily={flagAbbrevText.fontFamily}
        fontSize={flagAbbrevText.fontSize}
        fontWeight={flagAbbrevText.fontWeight}
        fill={flagAbbrevText.fill}
      >
        {countryAbbreviation}
      </text>
      <text
        textAnchor={licenseText.textAnchor}
        alignmentBaseline={licenseText.alignmentBaseline}
        x={licenseText.x}
        y={licenseText.y}
        fontFamily={licenseText.fontFamily}
        fontSize={licenseText.fontSize}
        fontWeight={licenseText.fontWeight}
        fill={licenseText.fill}
      >
        {license}
      </text>
    </svg>
  );
};

export default LicensePlate;
