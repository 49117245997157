export default theme => ({
    root: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        height: 40,
        borderBox: 'box-sizing',
    },

    title: {
        fontSize: 18,
        fontWeight: 600,
        color: theme.palette.warning.main
    },

    logo: {
        height: 50,
        width: 50
    }
})