import React from "react";
import { Polyline } from "@react-google-maps/api";

export const Route = ({ route, color }) => {
  const { google } = window;

  if (!route) return null;

  const iconsetngs = {
    path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
  };

  return (
    <Polyline
      path={route}
      options={{
        strokeColor: color ? color : "#ff0000",
        icons: [
          {
            icon: iconsetngs,
            repeat: "2.5%",
            offset: "100%",
          },
        ],
      }}
    />
  );
};

export default Route;
