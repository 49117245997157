import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { FaExclamation } from 'react-icons/fa';
import { loginAttempt } from '../../store/actions/Auth';
import { forgotPassword, resetPassword } from '../../store/actions/ForgotResetPassword';
import { setSnackbar } from '../../store/actions/Snackbar';

import { useStyles } from '../../styles';
import styles from './jss/login';

import { Snackbar } from '../../core';
import { isEmail } from '../../utils/helpers';

import FormComp from './Form';
import LoginForgotDialog from './LoginForgotDialog';
import LoginResetDialog from './LoginResetDialog';

import getConfig from '../../config/configLoader';
import translation from './translation';

export const Login = () => {
  const { mainDistribution, logo, sponsorFullLogo } = getConfig();
  const classes = useStyles(styles);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    email: '',
    password: '',
  });
  const snackbar = useSelector((s) => s.snackbar.snackbar);
  const [loading, setLoading] = useState(false);
  const [forgot, openForgot] = useState(false);
  const [reset, openReset] = useState(false);
  const [main, setMain] = useState(null);
  const [token, setToken] = useState(null);
  const [languageChrome, setlanguageChrome] = useState(translation['en-US']);

  useEffect(() => {
    const route = window.location.pathname.split('/');

    if (navigator.language) {
      setlanguageChrome(translation[navigator.language]);
    } else {
      setlanguageChrome(translation['en-US']);
    }
    window.history.pushState('', '', '/');

    if (!reset) {
      setMain(route[1]);
      setToken(route[2]);
    }
  }, [reset]);

  if (main === 'reset-password' && token && !reset) {
    openReset(true);
    setMain(null);

    // limpa a url
    window.history.pushState('', 'TELEMATIC', '/');
  }

  const onChange = (e) => {
    const {
      target: { name, value },
    } = e;
    setState({
      ...state,
      [name]: value,
    });
  };

  const onSubmit = () => {
    setLoading(true);
    dispatch(loginAttempt(state)).catch((_) => {
      dispatch(
        setSnackbar({
          title: languageChrome.errorMessage,
          color: 'danger',
          open: true,
          icon: FaExclamation,
          duration: 4000,
        })
      );
      setLoading(false);
    });
  };

  const onSubmitForgot = () => {
    if (state.email === '' || !isEmail(state.email))
      return dispatch(
        setSnackbar({
          title: languageChrome.errorMessageEmail,
          color: 'danger',
          open: true,
          icon: FaExclamation,
          duration: 4000,
        })
      );

    dispatch(forgotPassword(state));
    return openForgot(false);
  };

  const onSubmitReset = () => {
    const { email, senha, confirmacao } = state;

    if (!email || !senha || !confirmacao || email === '' || senha === '' || confirmacao === '') {
      return dispatch(
        setSnackbar({
          title: languageChrome.errorMessageEmpty,
          color: 'danger',
          open: true,
          icon: FaExclamation,
          duration: 4000,
        })
      );
    }

    if (!isEmail(email)) {
      return dispatch(
        setSnackbar({
          title: languageChrome.errorMessageEmail,
          color: 'danger',
          open: true,
          icon: FaExclamation,
          duration: 4000,
        })
      );
    }

    if (senha.length < 6 || senha.length > 16) {
      return dispatch(
        setSnackbar({
          title: languageChrome.errorMessagePassword,
          color: 'danger',
          open: true,
          icon: FaExclamation,
          duration: 4000,
        })
      );
    }

    if (senha !== confirmacao) {
      return dispatch(
        setSnackbar({
          title: languageChrome.errorMessageConfirmPassword,
          color: 'danger',
          open: true,
          icon: FaExclamation,
          duration: 4000,
        })
      );
    }

    const data = { email, senha, token };

    dispatch(resetPassword(data));
    setMain(null);
    setToken(null);
    return openReset(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.background} />
      <div className={classes.paper} style={{ display: (forgot || reset) && 'none' }}>
        <div className={mainDistribution ? classes.logoContainer : classes.logoContainerExpanded}>
          <img src={logo} className={classes.logo} alt="logo" />
        </div>
        <div className={classes.formContainer}>
          <FormComp
            language={languageChrome}
            state={state}
            onChange={onChange}
            onSubmit={onSubmit}
            openForgot={openForgot}
            loading={loading}
          />

          <LoginForgotDialog
            language={languageChrome}
            open={forgot}
            openForgot={openForgot}
            state={state}
            onChange={onChange}
            onSubmit={onSubmitForgot}
          />
          <LoginResetDialog
            language={languageChrome}
            open={reset}
            openReset={openReset}
            state={state}
            onChange={onChange}
            onSubmit={onSubmitReset}
          />
          <Snackbar {...snackbar} onClose={() => dispatch(setSnackbar({ open: false }))} />
          {!mainDistribution ? (
            <div className={classes.logoContainerFooter}>
              <span>Powered by</span>
              <img src={sponsorFullLogo} className={classes.logoChiptronic} alt="Powered By Chiptronic" />
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
