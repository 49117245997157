export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 5
    },

    grow: {
        flexGrow: 1
    },

    title: {
        fontSize: 15,
        color: theme.palette.default.text
    },

    icon: {
        fontSize: 12,
        color: theme.palette.default.dark,
        marginLeft: 10,
        padding: 3,
        cursor: 'pointer',
        borderRadius: '50%',
        '&:hover':{
            backgroundColor: 'rgba(0,0,0,.1)',
        },
        '&:active':{
            backgroundColor: 'rgba(0,0,0,.2)',
        }
    }
})