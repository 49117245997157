export default (theme) => ({
  root: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'flex-start',
    padding: '8px',
    userSelect: 'none',
    borderBottom: '1px solid #e3e6f0',
    borderRadius: '.3rem',
    // height: '94px',
    minHeight: "94px",
    '& * div': {
      boxSizing: 'border-box',
    },
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,.1)',
    },
    '&:active': {
      backgroundColor: 'rgba(0,0,0,.2)',
    },
  },

  left: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    alignSelf: "center"
  },

  image: {
    width: '30px',
    height: '30px',
    margin: '8px',
  },

  right: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },

  content: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
  },

  title: {
    fontSize: '.8rem',
    fontWeight: 600,
    color: '#444444',
    padding: '8px',
  },

  message: {
    fontSize: '0.75rem',
    color: '#444444',
    padding: '8px',
    paddingTop: 0,
    fontWeight: 500,
  },

  footer: {
    color: '#444444',
    fontSize: '0.75rem',
    fontWeight: 600,
    padding: '8px',
  },

  badge: {
    width: '100%',
    maxWidth: '10px',
    minWidth: '10px',
    height: '10px',
    borderRadius: '50%',
    marginTop: '10px',
  },

  wasRead: { backgroundColor: theme.palette.primary.dark },
});
