export default theme => ({
    tableSearch: {
        height: 30,
        padding: 10,
        paddingRight: 30,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },

    input: {
        opacity: 0,
        transition: 'opacity 150ms ease',
        '& > label': {
            color: 'rgba(0,0,0,0.54)'
        }
    },

    inputShow: {
        opacity: 1
    },

    icon: {
        color: '#444444',
        marginTop: 5,
        cursor: 'pointer'
    },

    underline: {

    }
})