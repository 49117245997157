import React, { useState } from 'react'

//Helpers
import { chunker } from '../../utils/helpers'

//Styles
import { makeStyles, TablePagination } from '@material-ui/core'
import styles from './jss/table'
import classnames from 'classnames'

//Material UI components
import { Paper, Table as MuiTable, TableBody as MuiTableBody, TableRow } from '@material-ui/core'

//Components
import TableHeader from './TableHeader'
import TableBody from './TableBody'
import TableSearch from './TableSearch'

const useStyles = makeStyles(styles)

const paginationInitialState = {
    rowsPerPage: 5,
    currentPage: 0
}

export const Table = ({ height, header, items, labelRowsPerPage, customAction, onClickItem, title, report, isLoading, customFilters }) => {
    const classes = useStyles()

    //State
    const [searchString, setSearchString] = useState('')
    const [pagination, setPagination] = useState(paginationInitialState)

    //Style
    const paperStyle = classnames({
        [classes.paperRoot]: true
    })

    const onSearch = value => setSearchString(value)

    const filterItems = () => {
        if (!items.length)
            return items

        const keys = Object.keys(items[0])

        const result = items.filter(item => {
            for (let key of keys) {
                if (typeof item[key] === 'object') return false
                if (item[key].toString().toLowerCase().includes(searchString.toLowerCase()))
                    return true
            }
            return false
        })

        return result
    }

    const handlePageChange = (_, page) => setPagination({
        ...pagination,
        currentPage: page
    })

    const onRowsPerPageChange = e => setPagination({
        rowsPerPage: e.target.value,
        currentPage: 0
    })

    const renderItems = items => {
        if (!items.length)
            return items

        const chunks = chunker(items, pagination.rowsPerPage)

        return chunks[pagination.currentPage]
    }

    if (isLoading) return null

    return (
        <Paper classes={{ root: paperStyle }}>
            <div style={{ height: height ? height : null }}>
                <div className={classes.header}>
                    <div className={classes.title}>{title}</div>
                    <TableSearch
                        title={title}
                        searchString={searchString}
                        setSearchString={setSearchString}
                        onSearch={onSearch}
                        customAction={customAction}
                        items={items}
                        header={header}
                        report={report}
                    />
                </div>
                {customFilters}
                <div className={classes.listContainer}>
                    <MuiTable className={classes.table} style={{ minWidth: Boolean(header) ? Object.keys(header).length * 150 : 0 }}>
                        <TableHeader header={header} />
                        <TableBody items={renderItems(filterItems())} onClickItem={(onClickItem) ? onClickItem : false} />
                    </MuiTable>
                    {(!items.length) ?
                        <MuiTable className={classes.table} style={{ minWidth: Boolean(header) ? Object.keys(header).length * 150 : 0 }}>
                            <MuiTableBody className={classes.containerEmpty}>
                                <TableRow key={1}>
                                    <td><div className={classes.empty}>Nenhum registro encontrado!</div></td>
                                </TableRow>
                            </MuiTableBody>
                        </MuiTable>
                        : null
                    }
                </div>
                <div className={classes.paginationContainer}>
                    <TablePagination
                        rowsPerPage={pagination.rowsPerPage}
                        count={items.length}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                        labelRowsPerPage={labelRowsPerPage}
                        rowsPerPageOptions={[5, 10, 20, 100]}
                        page={pagination.currentPage}
                        onPageChange={handlePageChange}
                        component={'div'}
                        onRowsPerPageChange={onRowsPerPageChange}
                    />
                </div>
            </div>
        </Paper>

    )
}

export default Table