import React, { useState } from 'react';
import clsx from 'clsx';

import { CustomTitle } from '..';

import { useStyles } from '../../hooks';
import styles from './jss/input';

const FunctionComponent = (props) => {
  const {
    title = '',
    value = '',
    error = false,
    errorMessage = '',
    handleChange = () => {},
    step = 60,
    min = '00:00',
    max = '23:59',
  } = props;

  const classes = useStyles(styles, props);
  const [input, setInput] = useState(() => value);

  const rootStyle = clsx({
    [classes.root]: true,
    [classes.font]: true,
  });

  const containerStyle = clsx({
    [classes.container]: true,
    [classes.containerError]: error,
  });

  const onChange = (event) => {
    const { value } = event.target;
    setInput(value);
    handleChange(value);
  };

  return (
    <div className={rootStyle}>
      {title && (
        <CustomTitle
          classes={{
            container: classes.title,
          }}
          title={title}
          message={error ? errorMessage : null}
          messageType={error ? 'error' : null}
        />
      )}
      <div className={containerStyle}>
        <input
          className={classes.input}
          type="time"
          value={input}
          onChange={onChange}
          step={step}
          min={min}
          max={max}
        />
      </div>
    </div>
  );
};

export const InputTime = React.memo(FunctionComponent);

export default InputTime;
