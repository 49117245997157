import React from 'react';

import { useStyles } from '..';
import styles from './jss/header';

import getConfig from '../../config/configLoader';

export const Header = ({ expanded }) => {
  const { shortLogo, name, sortName } = getConfig();

  const classes = useStyles(styles);

  const getTitle = () => {
    if (expanded || name.length < 3) return name;

    if (sortName) return sortName;

    return name.slice(0, 2).toUpperCase();
  };

  return (
    <div className={classes.root}>
      {expanded && <div className={classes.title}>{getTitle()}</div>}
      {!expanded && Boolean(shortLogo) && <img src={shortLogo} alt="logo" className={classes.logo} />}
    </div>
  );
};

export default Header;
