import { FleetTypes as types } from '../types'
import { getCategoryLabel } from '../../helpers'

//i18n
import intl from 'react-intl-universal'

const initialState = {
    vehicles: [],
    categories: [],
    automakers: [],
    allVehicles: [],
    makersCategories: [],
    faults: [
        { id: 1, label: 'Com falhas' },
        { id: 2, label: 'Sem falhas' }
    ],
    status: [
        { id: 1, label: 'Ligados' },
        { id: 2, label: 'Desligados' },
        { id: 3, label: 'Ligados Parados' }
    ],
    health: [
        { id: 1, label: 'Ok' },
        { id: 2, label: 'Requer Atenção' },
        { id: 3, label: 'Requer Ação' }
    ],
    lastFetch: null,
    initial: true
}

export const FleetReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.STORE_FLEET: return storeFleet(state, action)
        case types.CLEAR_TIMER: return clearTimer(state, action)

        default: return state
    }
}

const storeFleet = (state, action) => {
    let fix = (process.env.REACT_APP_FIX_TIME) ? parseInt(process.env.REACT_APP_FIX_TIME) : 0
    if (!action.data) return { ...state, lastFetch: Date.now() + fix }

    return {
        ...state,
        faults: [
            { id: 1, label: intl.get('reducerfleet.faults.withfaults') },
            { id: 2, label: intl.get('reducerfleet.faults.nofaults') }
        ],
        status: [
            { id: 1, label: intl.get('reducerfleet.status.on') },
            { id: 2, label: intl.get('reducerfleet.status.off') },
            { id: 3, label: intl.get('reducerfleet.status.idle') }
        ],
        health: [
            { id: 1, label: intl.get('reducerfleet.health.ok') },
            { id: 2, label: intl.get('reducerfleet.health.warning') },
            { id: 3, label: intl.get('reducerfleet.health.danger') }
        ],
        vehicles: action.data.data,
        categories: action.data.categorias.map(id => ({ id, label: getCategoryLabel(id, intl) })),
        automakers: action.data.montadoras.map(x => ({
            id: x.id_montadora,
            label: x.montadora,
        })),
        allVehicles: action.data.itens.map(x => ({
            id: x.id,
            maker_id: x.id_montadora,
            category: x.id_categoria,
            label: x.descricao,
            ignitionStatus: x.ignicao,
            status: x.status
        })),
        makersCategories: action.data.montadoras.map(x => ({ id: x.id_montadora, categories: x.categorias })),
        lastFetch: Date.now() + fix
    }
}

const clearTimer = (state, action) => ({
    ...state,
    lastFetch: null,
    initial: Boolean(action.initial)
})
