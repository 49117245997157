import React from 'react';

import { withRouter } from 'react-router-dom';

import { useStyles } from '..';
import styles from './jss/list';

import { NestedParent, ShallowParent } from './Items';

export const List = withRouter(({ items, expanded, location, wrapper, setExpanded }) => {
  const classes = useStyles(styles);

  const renderItems = () => {
    const result = [];
    items.forEach((item) => {
      const itemProps = { item, expanded, location, wrapper, setExpanded };
      if (item.hasOwnProperty('itens') && item.itens.length && item.icon)
        return result.push(<NestedParent {...itemProps} key={item.descricao} />);

      if (item.path) return result.push(<ShallowParent {...itemProps} key={item.descricao} />);
    });
    return result;
  };

  return <div className={classes.root}>{renderItems()}</div>;
});

export default React.memo(List);
