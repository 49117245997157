/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useCallback } from "react";
import { useStyles } from "../../hooks";
import intl from "react-intl-universal";
import clsx from "clsx";
import { uniqueId } from "../../helpers";
import { FaCaretUp, FaCaretDown, FaFilter } from "react-icons/fa";
import { Tooltip, IconButton } from "@material-ui/core";
import {
  CustomTitle,
  CustomCloseListener,
  CustomFilterOptions,
  CustomOptions,
} from "..";

import styles from "./jss/CustomFilterableComboBox";

const FunctionComponent = (props) => {
  const {
    title = "",
    items = [],
    setItems = () => [],
    filtersRef = { current: [] },
    feminine = false,
    required = false,
    multiSelect = false,
    loading = false,
  } = props;

  const comboBoxIdRef = useRef(uniqueId("ComboBox-"));
  const filterContainerRef = useRef(null);
  const itemsRef = useRef([]);
  const [showOptions, setShowOptions] = useState(() => false);
  const [showFilters, setShowFilters] = useState(() => false);
  const classes = useStyles(styles, props);
  
  itemsRef.current = items.filter(({ listed }) => listed);

  const rootStyle = clsx({
    [classes.root]: true,
    [classes.font]: true,
  });
  const containerStyle = clsx({
    [classes.container]: true,
    [classes.focusedContainer]: showOptions,
  });
  const filterContainerStyle = clsx({
    [classes.filterContainer]: true,
    [classes.focusedFilterContainer]: showFilters,
  });
  const filterStyle = clsx({
    [classes.filter]: true,
    [classes.focusedFilter]: showFilters,
  });

  const renderLabel = () => {
    if (showFilters) return intl.get("core.CustomFilterableComboBox.filters");

    const selectedItems = itemsRef.current.filter(({ active }) => active);
    if (selectedItems.length === 1 && selectedItems[0])
      return selectedItems[0].label;

    if (!selectedItems.length || !itemsRef.current.length)
      return feminine
        ? intl.get("core.CustomFilterableComboBox.noneSelectedFeminine")
        : intl.get("core.CustomFilterableComboBox.noneSelected");

    if (selectedItems.length === itemsRef.current.length)
      return feminine
        ? intl.get("core.CustomFilterableComboBox.allSelectedFeminine")
        : intl.get("core.CustomFilterableComboBox.allSelected");

    return feminine
      ? `${selectedItems.length} ${intl.get(
          "core.CustomFilterableComboBox.selectedFeminine"
        )}`
      : `${selectedItems.length} ${intl.get(
          "core.CustomFilterableComboBox.selected"
        )}`;
  };

  const toggleOptions = () => {
    if (showOptions && showFilters) setShowFilters(false);
    setShowOptions((showOptions) => !showOptions);
  };

  const toggleFilters = () => {
    if (!showOptions) setShowOptions(true);
    setShowFilters((showFilters) => !showFilters);
  };

  const closeOptionsAndFilters = () => {
    setShowFilters(false);
    setShowOptions(false);
  };

  const handleClick = useCallback(
    ({ id, active }) => {
      let updatedItemsRef = [];
      let updatedItems = [];

      items.forEach((item) => {
        const updatedItem = {
          ...item,
          active: item.id === id && (required || !active),
        };

        if (item.listed) updatedItemsRef.push(updatedItem);

        updatedItems.push(updatedItem);
      });

      itemsRef.current = updatedItemsRef;
      setItems(updatedItems);
    },
    [items]
  );

  const handleClickMultiSelect = useCallback(
    ({ id, active }) => {
      const totalSelectedItems = itemsRef.current.filter(({ active }) => active)
        .length;
      let updatedItemsRef = [];
      let updatedItems = [];

      items.forEach((item) => {
        const updatedItem = {
          ...item,
          active:
            item.id === id
              ? (required && totalSelectedItems === 1) || !active
              : item.active,
        };

        if (item.listed) updatedItemsRef.push(updatedItem);

        updatedItems.push(updatedItem);
      });

      itemsRef.current = updatedItemsRef;
      setItems(updatedItems);
    },
    [items]
  );

  const toggleAll = useCallback(
    ({ active }) => {
      const firstListedId = itemsRef.current[0].id;
      let updatedItemsRef = [];
      let updatedItems = [];

      items.forEach((item) => {
        const updatedItem = {
          ...item,
          active:
            required && item.id === firstListedId && active
              ? true
              : item.listed && !active,
        };

        if (item.listed) updatedItemsRef.push(updatedItem);

        updatedItems.push(updatedItem);
      });

      itemsRef.current = updatedItemsRef;
      setItems(updatedItems);
    },
    [items]
  );

  const handleClickMultiSelectFilter = useCallback(
    ({ id, active }) => {
      filtersRef.current = filtersRef.current.map((filterItem) => ({
        ...filterItem,
        active: filterItem.id === id ? !active : filterItem.active,
      }));

      const inactiveFilters = filtersRef.current.filter(
        ({ filterParameter, active }) => filterParameter && !active
      );
      let someActive = false;
      let firstListedIndex = null;
      let updatedItemsRef = [];
      let updatedItems = [];

      items.forEach((item, index) => {
        const listed = !inactiveFilters.some(
          ({ filterParameter, filterValue }) =>
            item[filterParameter] === filterValue
        );
        const updatedItem = {
          ...item,
          listed,
          active: listed && item.active,
        };

        someActive = someActive || (listed && item.active);

        if (listed && firstListedIndex === null) {
          firstListedIndex = index;
        }

        if (listed) updatedItemsRef.push(updatedItem);

        updatedItems.push(updatedItem);
      });

      if (required && !someActive && updatedItemsRef.length) {
        updatedItemsRef[0].active = true;
        updatedItems[firstListedIndex].active = true;
      }

      itemsRef.current = updatedItemsRef;
      setItems(updatedItems);
    },
    [items]
  );

  return (
    <div className={rootStyle}>
      {title && (
        <CustomTitle
          classes={{
            container: classes.title,
          }}
          title={title}
        />
      )}
      <label htmlFor={comboBoxIdRef.current} className={containerStyle}>
        <div
          id={comboBoxIdRef.current}
          className={classes.labelContainer}
          onClick={toggleOptions}
        >
          {renderLabel()}
        </div>
        <div
          ref={filterContainerRef}
          className={filterContainerStyle}
          onClick={toggleFilters}
        >
          <Tooltip
            title={intl.get("core.CustomFilterableComboBox.filters")}
            placement="top"
          >
            <IconButton className={filterStyle}>
              <FaFilter />
            </IconButton>
          </Tooltip>
        </div>
        <div className={classes.caretContainer} onClick={toggleOptions}>
          {showOptions ? <FaCaretUp /> : <FaCaretDown />}
        </div>
      </label>
      {showOptions && (
        <CustomCloseListener
          ignoreRefs={[filterContainerRef]}
          onClose={closeOptionsAndFilters}
        >
          {showFilters ? (
            <CustomFilterOptions
              items={filtersRef.current}
              handleClick={handleClickMultiSelectFilter}
              loading={loading}
            />
          ) : (
            <CustomOptions
              items={itemsRef.current}
              handleClick={multiSelect ? handleClickMultiSelect : handleClick}
              toggleAll={multiSelect ? toggleAll : null}
              feminine={feminine}
              loading={loading}
            />
          )}
        </CustomCloseListener>
      )}
    </div>
  );
};

export const CustomFilterableComboBox = React.memo(FunctionComponent);

export default CustomFilterableComboBox;