import axios from "axios";
import { createRequestHeader, endpoints } from ".";
import { setSnackbar } from "../store/actions/Snackbar";

import intl from "react-intl-universal";

export const get = (component, payload, token, dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${endpoints[component]}/get`, payload, createRequestHeader(token))
      .then(({ data }) => resolve(data))
      .catch((error) => {
        dispatch(
          setSnackbar({
            open: true,
            message: error.response &&  error.response.data
              ? error.response.data.message
              : `${intl.get("api.internalError")}`,
            duration: 4000,
            variant: "danger",
          })
        );

        return reject(error);
      });
  });
};

export default get;
